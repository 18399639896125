@import '~antd/lib/divider/style/index';
@import '../../../styles/core/colors.less';

.syt-antd-divider {
  border-color: @cws-neutral-20;
}

.syt-antd-divider-vertical {
  border-color: @cws-neutral-20;
}
