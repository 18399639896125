@import '~antd/lib/collapse/style/index';

.syt-antd-collapse {
    border: 1px solid #d9d9d9
}
.syt-antd-collapse .syt-antd-collapse-item {
    border: 1px solid #d9d9d9
}
.syt-antd-collapse-content {
    border-top: 1px solid #d9d9d9
}