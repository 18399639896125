@import '~antd/lib/tag/style/index';


span.syt-antd-tag {
  height: 16px;
  left: 4px;
  right: 4px;
  top: calc(50% - 16px/2);

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: -0.0075em;
  text-transform: uppercase;

  flex: none;
  flex-direction: row;
  flex-grow: 0;
  order: 1;
  align-items: center;
  padding: 0px 4px;

  color: @cws-neutral-70;
  background: @cws-neutral-20;
  border: 1px solid @cws-neutral-20;
  box-sizing: border-box;
  border-radius: 4px;

  &.bold {
    color: @cws-neutral-00;
    background: @cws-neutral-60;
    border: 1px solid @cws-neutral-60;
  }

  &:hover {
    opacity: 1.0 !important;
  }

  &.closable:hover {
    border: 1px solid @cws-neutral-30;
  }

  &:focus {
    outline: none;
  }

  &.closable:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25);
  }
}

span.syt-antd-tag.syt-antd-tag-green {
  color: @cws-green-70;
  background: @cws-green-20;
  border: 1px solid @cws-green-20;

  &.bold {
    color: @cws-neutral-00;
    background: @cws-green-60;
    border: 1px solid @cws-green-60;
  }
}

span.syt-antd-tag.syt-antd-tag-blue {
  color: @cws-blue-70;
  background: @cws-blue-20;
  border: 1px solid @cws-blue-20;

  &.bold {
    color: @cws-neutral-00;
    background: @cws-blue-60;
    border: 1px solid @cws-blue-60;
  }
}

span.syt-antd-tag.syt-antd-tag-red {
  color: @cws-red-70;
  background: @cws-red-20;
  border: 1px solid @cws-red-20;

  &.bold {
    color: @cws-neutral-00;
    background: @cws-red-60;
    border: 1px solid @cws-red-60;
  }
}

span.syt-antd-tag.syt-antd-tag-orange {
  color: @cws-orange-70;
  background: @cws-orange-20;
  border: 1px solid @cws-orange-20;

  &.bold {
    color: @cws-neutral-00;
    background: @cws-orange-60;
    border: 1px solid @cws-orange-60;
  }
}

span.syt-antd-tag.syt-antd-tag-yellow {
  color: @cws-yellow-70;
  background: @cws-yellow-20;
  border: 1px solid @cws-yellow-20;

  &.bold {
    color: @cws-neutral-00;
    background: @cws-yellow-60;
    border: 1px solid @cws-yellow-60;
  }
}

span.syt-antd-tag[style*="background-color: teal;"] {
  color: @cws-teal-70;
  background: @cws-teal-20 !important;
  border: 1px solid @cws-teal-20;

  &.bold {
    color: @cws-neutral-00;
    background: @cws-teal-60 !important;
    border: 1px solid @cws-teal-60;
  }
}

span.syt-antd-tag.syt-antd-tag-purple {
  color: @cws-purple-70;
  background: @cws-purple-20;
  border: 1px solid @cws-purple-20;

  &.bold {
    color: @cws-neutral-00;
    background: @cws-purple-60;
    border: 1px solid @cws-purple-60;
  }
}

span.syt-antd-tag.syt-antd-tag-pink {
  color: @cws-pink-70;
  background: @cws-pink-20;
  border: 1px solid @cws-pink-20;

  &.bold {
    color: @cws-neutral-00;
    background: @cws-pink-60;
    border: 1px solid @cws-pink-60;
  }
}

.syt-antd-tag-close-icon svg {
  width: 10px;
  height: 10px;
  border-radius: 4px;

  &:hover {
    background: @cws-neutral-30;
  }
}

span.syt-antd-tag.syt-antd-tag-checkable:not(.syt-antd-tag-checkable-checked) {
  background: @cws-neutral-00;
  border: 1px solid @cws-neutral-30;
  color: @cws-neutral-100;
  nav-index: 1;
  tab-index: 1;

  &:hover, &:focus {
    color: @cws-blue-50 !important;
  }

  &:focus {
    color: @cws-neutral-00;
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25) !important;
  }

  &:active {
    color: @cws-neutral-00 !important;
    background: @cws-blue-70 !important;
    border: 1px solid @cws-blue-70 !important;
  }
}

.cws-tag-focus-wrapper {
  display: inline-block;
}

span.syt-antd-tag.syt-antd-tag-checkable.syt-antd-tag-checkable-checked {
  background: @cws-blue-60;
  border: 1px solid @cws-blue-60;
  color: @cws-neutral-00;

  &:hover, &:focus {
    background: @cws-blue-50 !important;
    border: 1px solid @cws-blue-50 !important;
  }

  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25) !important;
  }

  &:active {
    background: @cws-blue-70 !important;
    border: 1px solid @cws-blue-70 !important;
  }
}
