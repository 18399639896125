@import '~antd/lib/slider/style/index';

.syt-antd-slider-track,
.syt-antd-slider:hover .syt-antd-slider-track {
  background-color: @cws-blue-50;
}

.syt-antd-slider-rail,
.syt-antd-slider:hover .syt-antd-slider-rail {
  background-color: @cws-neutral-30;
}

.syt-antd-slider-handle,
.syt-antd-slider:hover .syt-antd-slider-handle:not(.syt-antd-tooltip-open) {
  border: 2px solid @cws-blue-50;
}

.syt-antd-slider-handle.syt-antd-tooltip-open {
  border: 2px solid @cws-blue-60;
}

.syt-antd-slider-handle:focus,
.syt-antd-slider-handle.syt-antd-slider-handle-click-focused {
  border: 2px solid @cws-blue-50;
  box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25);
}

.syt-antd-slider-dot {
  border: 2px solid @cws-neutral-40;
}

.syt-antd-slider-dot.syt-antd-slider-dot-active {
  border-color: @cws-blue-60;
}

.syt-antd-slider-disabled .syt-antd-slider-handle {
  background-color: @cws-neutral-10;
}
