@import '~antd/lib/page-header/style/index';

.syt-antd-page-header-heading-left,
.syt-antd-page-header-heading-extra {
  margin: 0px;
}

.syt-antd-page-header-back-button {
  display: inline-block;
  min-width: 40px;
  width: 40px;
  height: 40px;

  border: 1px solid @cws-neutral-30 !important;
  border-radius: 50%;
  color: @cws-neutral-100 !important;
  background-color: @cws-neutral-00 !important;
  background-image: none;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  position: relative;

  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;

  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;

  span {
    padding: 11px;
  }

  &:hover {
    color: @cws-neutral-100 !important;
    background-color: @cws-neutral-20 !important;
    border-color: @cws-neutral-20 !important;
  }

  &:focus {
      color: @cws-neutral-100 !important;
      background-color: @cws-neutral-10 !important;
      border-color: @cws-neutral-30 !important;
  }

  &:active {
      background-color: @cws-neutral-30 !important;
      border-color: @cws-neutral-30 !important;
  }

  &:disabled {
      background: @cws-neutral-10 !important;
      border: 1px solid @cws-neutral-20 !important;
      box-sizing: border-box;
  }
}
