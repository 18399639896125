@import '~antd/lib/drawer/style/index';

.syt-antd-drawer-header {
  padding: 24px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0075em;

  border-bottom: none;
}

.syt-antd-drawer-close {
  padding: 24px;
}

.syt-antd-drawer-body {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;

  color: @cws-neutral-100;
}

.syt-antd-drawer-footer {
  padding: 16px 24px;
  border-top: none;
}
