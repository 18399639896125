@import '~antd/lib/switch/style/index';

.syt-antd-switch {
  background-color: @cws-neutral-40;
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.4;
  }
}
.syt-antd-switch-checked {
  background-color: @cws-blue-60;
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.4;
  }
}
