@import '~antd/lib/modal/style/index';
@import '../styles/index.less';

.ant-modal-confirm {
  .ant-modal-body {
    .ant-modal-confirm-body {
      margin-left: 40px;
      svg {
        position: absolute;
        left: 25px;
      }
      .ant-modal-confirm-title {
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.0075em;
        color: @cws-neutral-100;
      }
      .ant-modal-confirm-content {
        line-height: 20px;
        letter-spacing: -0.0075em;
      }
    }
    .outlined-button:extend(.ant-btn-primary) {
      border-radius: 4px;
      color: @cws-neutral-100;
      background-color: @cws-neutral-00;
      border-color: @cws-neutral-30;

      &:hover {
        color: @cws-neutral-100;
        background-color: @cws-neutral-10;
        border-color: @cws-neutral-30;
      }
      &:focus {
        color: @cws-neutral-100;
        background-color: @cws-neutral-10;
        border-color: @cws-neutral-30;
        box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25);
      }
      &:active {
        color: @cws-neutral-100;
        background-color: @cws-neutral-20;
        border-color: @cws-neutral-30;
      }
    }
    .danger-button:extend(.ant-btn-primary) {
      color: @cws-neutral-00;
      background-color: @cws-red-60;
      border-color: @cws-red-60;

      &:hover,
      &:focus {
        color: @cws-neutral-00;
        background-color: @cws-red-50;
        border-color: @cws-red-50;
      }
      &:focus {
        box-shadow: 0px 0px 0px 3px rgba(@cws-red-50, 0.25);
      }
      &:active {
        background-color: @cws-red-70;
        border-color: @cws-red-70;
      }
      &:disabled {
        background: @cws-neutral-10;
        border: 1px solid @cws-neutral-20;
        box-sizing: border-box;
      }
    }
  }
  .ant-btn-primary {
    color: @cws-neutral-00;
    background-color: @cws-green-60;
    border-color: @cws-green-60;
    border-radius: 4px;

    &:hover,
    &:focus {
      color: @cws-neutral-00;
      background-color: @cws-green-50;
      border-color: @cws-green-50;
    }
    &:focus {
      box-shadow: 0px 0px 0px 3px rgba(@cws-green-50, 0.25);
    }
    &:active {
      background-color: @cws-green-70;
      border-color: @cws-green-70;
    }
    &:disabled {
      background: @cws-neutral-10;
      border: 1px solid @cws-neutral-20;
      box-sizing: border-box;
    }
  }
}

.syt-antd-modal {
  .syt-antd-modal-footer {
    border-top: none;
    padding: 24px;
    .syt-antd-btn {
      border-color: @cws-neutral-30;
    }
  }
}
