@import '~antd/lib/radio/style/index';

.syt-antd-radio-inner::after {
  top: 4px;
  left: 4px;
  background-color: @cws-blue-60;
}

.syt-antd-radio-checked::after {
  animation: none;
  border: none;
}

.syt-antd-radio .syt-antd-radio-inner {
  border-color: @cws-blue-60;
}

.syt-antd-radio:hover .syt-antd-radio-inner {
  border-color: @cws-blue-50;

  &::after {
    background-color: @cws-blue-50;
  }
}

.syt-antd-radio:focus-within .syt-antd-radio-inner {
  border-color: @cws-blue-50;
  box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25);
}

.syt-antd-radio:active .syt-antd-radio-inner {
  border-color: @cws-blue-70;

  &::after {
    background-color: @cws-blue-70;
  }
}

.syt-antd-radio-button-wrapper {
    border: 1px solid @cws-neutral-40
}

.syt-antd-radio-button-wrapper:hover {
    color: @cws-blue-50;
    border-color: @cws-blue-50;
}

.syt-antd-radio-button-wrapper:active {
    color: @cws-blue-70;
    border-color: @cws-blue-70;
}

.syt-antd-radio-disabled .syt-antd-radio-inner {
  border-color: @cws-neutral-30 !important;
  background: @cws-neutral-10;
}

.syt-antd-radio-disabled + span {
  color: @cws-neutral-40;
}

.syt-antd-radio-disabled .syt-antd-radio-inner::after {
  background-color: @cws-neutral-40;
}

.syt-antd-radio-button-wrapper:first-child {
    border-left: 1px solid @cws-neutral-40
}

.syt-antd-radio-button-wrapper:last-child {
    border-left: 1px solid @cws-neutral-40
}

.syt-antd-radio-group-solid .syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled) {
    background-color: @cws-blue-60;
    border-color: @cws-blue-60;
}

.syt-antd-radio-group-solid  .syt-antd-radio-button-wrapper:hover {
    background-color: @cws-blue-50;
    border-color: @cws-blue-50;
    color: @cws-neutral-00
}

.syt-antd-radio-group-solid  .syt-antd-radio-button-wrapper:active {
    background-color: @cws-blue-70;
    border-color: @cws-blue-70;
    color: @cws-neutral-00
}

.syt-antd-radio-group-solid .syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):hover {
    background-color: @cws-blue-50;
    border-color: @cws-blue-50;
}

.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):hover {
    color: @cws-blue-50;
    border-color: @cws-blue-50;
}

.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled){
    color: @cws-blue-60;
    border-color: @cws-blue-60;
}

.syt-antd-radio-group .syt-antd-radio-group-outline .syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):hover {
    color: @cws-blue-60;
    border-color: @cws-blue-60;
}

.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled)::before {
    border-color: @cws-blue-60;
}

.syt-antd-radio-button-wrapper-checked:not([class*=' syt-antd-radio-button-wrapper-disabled']).syt-antd-radio-button-wrapper:first-child {
    border-color: @cws-blue-60;
}

.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):first-child{
    border-color: @cws-blue-60;
}

.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):hover::before {
    background-color: transparent;
}

.syt-antd-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
}

.syt-antd-radio-button-wrapper-disabled{
    color: @cws-neutral-40;
    background-color: @cws-neutral-20;
    border: 1px solid @cws-neutral-30;
}

.syt-antd-radio-button-wrapper-disabled:hover {
    color: @cws-neutral-40;
    background-color: @cws-neutral-20;
    border: 1px solid @cws-neutral-30;
}

.syt-antd-radio-button-wrapper-disabled:first-child, .syt-antd-radio-button-wrapper-disabled:hover{
    color: @cws-neutral-40;
    background-color: @cws-neutral-20;
    border: 1px solid @cws-neutral-30;
}

.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px #0092E420;
}
.syt-antd-radio-group-solid .syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px #0092E420;
}
