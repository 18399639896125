@import '~antd/lib/popover/style/index';
.syt-antd-popover-content{  
  width: 280px;
  .syt-antd-popover-inner-content{
    padding-top: 5px;
    padding-bottom: 20px;
  }
  .syt-antd-popover-title {
    padding-top: 15px;
    border-bottom: none;
  }
}

.dark {
  .syt-antd-popover-inner{
    .syt-antd-popover-title{
      color: @cws-neutral-00;
    }
  }
  .syt-antd-btn-default{
    color: @cws-neutral-00;
    background-color: @cws-neutral-90;
  }
  .syt-antd-popover-inner{
    background-color: @cws-neutral-90;
  }
  .syt-antd-popover-inner-content{
    color: @cws-neutral-00;
  }
  .syt-antd-popover-content {
    .syt-antd-popover-arrow {
      border-right-color:@cws-neutral-90;
      border-bottom-color: @cws-neutral-90;
    }
  }
}
