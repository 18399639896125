@import '~antd/lib/message/style/index.css';

.syt-antd-message {
  margin: 0;
  padding: 0;
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1;
  width: 100%;
  pointer-events: none;
  .syt-antd-message-notice {
    font-size: 16px;
    padding: 8px;
    text-align: center;
    color: @cws-neutral-100;
    background-color: @base-white;
    .syt-antd-message-notice-content { 
      border-radius: 4px;
      display: inline-block;
      padding: 12px 18px;
      box-shadow: 0 4px 12px @base-dark-grey;
      line-height: 24px;
      .syt-antd-message-custom-content {
        height: 24px;
        svg {
          margin-right: 10px;
        }
        span{
          vertical-align: top; 
        }
      }
    }
  }
  .dark {
    .syt-antd-message-notice-content{
      background-color: @cws-neutral-90;
      color: @cws-neutral-00;
    }
  }
}
