@import '~antd/lib/pagination/style/index';

.syt-antd-pagination {
  
  .syt-antd-pagination-item-link .syt-antd-pagination-item-container .syt-antd-pagination-item-ellipsis {
    color: @cws-neutral-60
  }

  .syt-antd-pagination-jump-next-custom-icon .syt-antd-pagination-item-link {
    border: none;
  }
  
  .syt-antd-pagination-jump-prev-custom-icon .syt-antd-pagination-item-link {
    border: none;
  }
 

  .syt-antd-pagination-item-link, .syt-antd-pagination-item {
    border: 1px solid @cws-neutral-30;
    &:hover,  &:active {
      border: 1px solid @cws-blue-50;
      a {
        color: @cws-blue-50;
      }
    }
    &:focus {
      border: 1px solid @cws-blue-50;
      a {
        color: @cws-blue-50;
      }
    }
  }
  
  .syt-antd-pagination-item-active {
    border: 1 solid @cws-blue-60;
    a {
      color: @cws-neutral-00;
    }
    &:focus, &:hover {
      a {
        color: @cws-neutral-00;
      }
    }
    box-shadow: 0 0 1px @cws-blue-60;
    background-color: @cws-blue-60;
  }
}

.syt-antd-pagination-next .syt-antd-pagination-item-link span {
  vertical-align: initial;
}

.syt-antd-pagination-prev .syt-antd-pagination-item-link span {
  vertical-align: initial;
}

ul.syt-antd-pagination-disabled {
.syt-antd-pagination-item-link, li.syt-antd-pagination-item {
    border: none;
    &:focus, &:hover {
      border: none;
      a {
        color: @base-dark-grey;
      }
    }
  }
  li.syt-antd-pagination-item-active {
    box-shadow: none;
    a:hover {
      color: @cws-neutral-00;
    }
  }
} 
