/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@import url("https://use.typekit.net/yhy1vco.css");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap&.css");
@import '../../lib/components/message/styles/~antd/lib/message/style/index.css';
.syt-antd-modal,
.syt-antd-image-preview {
  pointer-events: none;
}
.syt-antd-modal.zoom-enter,
.syt-antd-modal.zoom-appear,
.syt-antd-image-preview.zoom-enter,
.syt-antd-image-preview.zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
.syt-antd-modal-mask,
.syt-antd-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50);
}
.syt-antd-modal-mask-hidden,
.syt-antd-image-preview-mask-hidden {
  display: none;
}
.syt-antd-modal-wrap,
.syt-antd-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
/* --- Main Colors -- */
/* --- Miscellaneous Colors -- */
/* --- CWS Colors -- */
* {
  font-family: 'Noto Sans';
}
.text-default {
  color: #2f3031;
}
.bold {
  color: #2f3031;
}
/* Override Ant Design variables */
.ant-result-success .ant-result-icon > .anticon {
  color: #14803c;
}
.text-default {
  /*color: @base-black;*/
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
}
.bold {
  /*color: @base-black;*/
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
}
.baseText {
  color: #2f3031;
  /*color: @base-black;*/
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.baseText .bold {
  font-size: 14px;
  line-height: 17px;
  color: #2f3031;
  /*color: @base-black;*/
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
}
.baseTextHeader {
  color: #2f3031;
  /*color: @base-black;*/
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
}
.baseTextHeader .bold {
  font-size: 27.7941px;
  line-height: 33px;
  color: #2f3031;
  /*color: @base-black;*/
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
}
.baseTextTitle {
  color: #2f3031;
  /*color: @base-black;*/
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.baseTextTitle .bold {
  font-size: 16px;
  line-height: 19px;
  color: #2f3031;
  /*color: @base-black;*/
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
}
.syt-antd-btn {
  line-height: 20px;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: #14151C;
  background: #fff;
  border-color: #0092e4;
}
.syt-antd-btn > .anticon {
  line-height: 1;
}
.syt-antd-btn,
.syt-antd-btn:active,
.syt-antd-btn:focus {
  outline: 0;
}
.syt-antd-btn:not([disabled]):hover {
  text-decoration: none;
}
.syt-antd-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.syt-antd-btn[disabled] {
  cursor: not-allowed;
}
.syt-antd-btn[disabled] > * {
  pointer-events: none;
}
.syt-antd-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
.syt-antd-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
}
.syt-antd-btn > a:only-child {
  color: currentColor;
}
.syt-antd-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn:hover,
.syt-antd-btn:focus {
  color: #238dd9;
  background: #fff;
  border-color: #238dd9;
}
.syt-antd-btn:hover > a:only-child,
.syt-antd-btn:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn:hover > a:only-child::after,
.syt-antd-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn:active {
  color: #0056a6;
  background: #fff;
  border-color: #0056a6;
}
.syt-antd-btn:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn[disabled],
.syt-antd-btn[disabled]:hover,
.syt-antd-btn[disabled]:focus,
.syt-antd-btn[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn[disabled] > a:only-child,
.syt-antd-btn[disabled]:hover > a:only-child,
.syt-antd-btn[disabled]:focus > a:only-child,
.syt-antd-btn[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn[disabled] > a:only-child::after,
.syt-antd-btn[disabled]:hover > a:only-child::after,
.syt-antd-btn[disabled]:focus > a:only-child::after,
.syt-antd-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn:hover,
.syt-antd-btn:focus,
.syt-antd-btn:active {
  text-decoration: none;
  background: #fff;
}
.syt-antd-btn > span {
  display: inline-block;
}
.syt-antd-btn-primary {
  color: #fff;
  background: #0071CD;
  border-color: #0071CD;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.syt-antd-btn-primary > a:only-child {
  color: currentColor;
}
.syt-antd-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-primary:hover,
.syt-antd-btn-primary:focus {
  color: #fff;
  background: #238dd9;
  border-color: #238dd9;
}
.syt-antd-btn-primary:hover > a:only-child,
.syt-antd-btn-primary:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-primary:hover > a:only-child::after,
.syt-antd-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-primary:active {
  color: #fff;
  background: #0056a6;
  border-color: #0056a6;
}
.syt-antd-btn-primary:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-primary[disabled],
.syt-antd-btn-primary[disabled]:hover,
.syt-antd-btn-primary[disabled]:focus,
.syt-antd-btn-primary[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-primary[disabled] > a:only-child,
.syt-antd-btn-primary[disabled]:hover > a:only-child,
.syt-antd-btn-primary[disabled]:focus > a:only-child,
.syt-antd-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-primary[disabled] > a:only-child::after,
.syt-antd-btn-primary[disabled]:hover > a:only-child::after,
.syt-antd-btn-primary[disabled]:focus > a:only-child::after,
.syt-antd-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-group .syt-antd-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #238dd9;
  border-left-color: #238dd9;
}
.syt-antd-btn-group .syt-antd-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #0092e4;
}
.syt-antd-btn-group .syt-antd-btn-primary:first-child:not(:last-child) {
  border-right-color: #238dd9;
}
.syt-antd-btn-group .syt-antd-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #0092e4;
}
.syt-antd-btn-group .syt-antd-btn-primary:last-child:not(:first-child),
.syt-antd-btn-group .syt-antd-btn-primary + .syt-antd-btn-primary {
  border-left-color: #238dd9;
}
.syt-antd-btn-group .syt-antd-btn-primary:last-child:not(:first-child)[disabled],
.syt-antd-btn-group .syt-antd-btn-primary + .syt-antd-btn-primary[disabled] {
  border-left-color: #0092e4;
}
.syt-antd-btn-ghost {
  color: #14151C;
  background: transparent;
  border-color: #0092e4;
}
.syt-antd-btn-ghost > a:only-child {
  color: currentColor;
}
.syt-antd-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-ghost:hover,
.syt-antd-btn-ghost:focus {
  color: #238dd9;
  background: transparent;
  border-color: #238dd9;
}
.syt-antd-btn-ghost:hover > a:only-child,
.syt-antd-btn-ghost:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-ghost:hover > a:only-child::after,
.syt-antd-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-ghost:active {
  color: #0056a6;
  background: transparent;
  border-color: #0056a6;
}
.syt-antd-btn-ghost:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-ghost[disabled],
.syt-antd-btn-ghost[disabled]:hover,
.syt-antd-btn-ghost[disabled]:focus,
.syt-antd-btn-ghost[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-ghost[disabled] > a:only-child,
.syt-antd-btn-ghost[disabled]:hover > a:only-child,
.syt-antd-btn-ghost[disabled]:focus > a:only-child,
.syt-antd-btn-ghost[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-ghost[disabled] > a:only-child::after,
.syt-antd-btn-ghost[disabled]:hover > a:only-child::after,
.syt-antd-btn-ghost[disabled]:focus > a:only-child::after,
.syt-antd-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dashed {
  color: #14151C;
  background: #fff;
  border-color: #0092e4;
  border-style: dashed;
}
.syt-antd-btn-dashed > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dashed:hover,
.syt-antd-btn-dashed:focus {
  color: #238dd9;
  background: #fff;
  border-color: #238dd9;
}
.syt-antd-btn-dashed:hover > a:only-child,
.syt-antd-btn-dashed:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dashed:hover > a:only-child::after,
.syt-antd-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dashed:active {
  color: #0056a6;
  background: #fff;
  border-color: #0056a6;
}
.syt-antd-btn-dashed:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dashed[disabled],
.syt-antd-btn-dashed[disabled]:hover,
.syt-antd-btn-dashed[disabled]:focus,
.syt-antd-btn-dashed[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-dashed[disabled] > a:only-child,
.syt-antd-btn-dashed[disabled]:hover > a:only-child,
.syt-antd-btn-dashed[disabled]:focus > a:only-child,
.syt-antd-btn-dashed[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dashed[disabled] > a:only-child::after,
.syt-antd-btn-dashed[disabled]:hover > a:only-child::after,
.syt-antd-btn-dashed[disabled]:focus > a:only-child::after,
.syt-antd-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-danger {
  color: #fff;
  background: #CF3537;
  border-color: #CF3537;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.syt-antd-btn-danger > a:only-child {
  color: currentColor;
}
.syt-antd-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-danger:hover,
.syt-antd-btn-danger:focus {
  color: #fff;
  background: #db5e5c;
  border-color: #db5e5c;
}
.syt-antd-btn-danger:hover > a:only-child,
.syt-antd-btn-danger:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-danger:hover > a:only-child::after,
.syt-antd-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-danger:active {
  color: #fff;
  background: #a8232a;
  border-color: #a8232a;
}
.syt-antd-btn-danger:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-danger[disabled],
.syt-antd-btn-danger[disabled]:hover,
.syt-antd-btn-danger[disabled]:focus,
.syt-antd-btn-danger[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-danger[disabled] > a:only-child,
.syt-antd-btn-danger[disabled]:hover > a:only-child,
.syt-antd-btn-danger[disabled]:focus > a:only-child,
.syt-antd-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-danger[disabled] > a:only-child::after,
.syt-antd-btn-danger[disabled]:hover > a:only-child::after,
.syt-antd-btn-danger[disabled]:focus > a:only-child::after,
.syt-antd-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-link {
  color: #00a0be;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.syt-antd-btn-link > a:only-child {
  color: currentColor;
}
.syt-antd-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-link:hover,
.syt-antd-btn-link:focus {
  color: #238dd9;
  background: transparent;
  border-color: #238dd9;
}
.syt-antd-btn-link:hover > a:only-child,
.syt-antd-btn-link:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-link:hover > a:only-child::after,
.syt-antd-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-link:active {
  color: #0056a6;
  background: transparent;
  border-color: #0056a6;
}
.syt-antd-btn-link:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-link[disabled],
.syt-antd-btn-link[disabled]:hover,
.syt-antd-btn-link[disabled]:focus,
.syt-antd-btn-link[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-link[disabled] > a:only-child,
.syt-antd-btn-link[disabled]:hover > a:only-child,
.syt-antd-btn-link[disabled]:focus > a:only-child,
.syt-antd-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-link[disabled] > a:only-child::after,
.syt-antd-btn-link[disabled]:hover > a:only-child::after,
.syt-antd-btn-link[disabled]:focus > a:only-child::after,
.syt-antd-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-link:hover {
  background: transparent;
}
.syt-antd-btn-link:hover,
.syt-antd-btn-link:focus,
.syt-antd-btn-link:active {
  border-color: transparent;
}
.syt-antd-btn-link[disabled],
.syt-antd-btn-link[disabled]:hover,
.syt-antd-btn-link[disabled]:focus,
.syt-antd-btn-link[disabled]:active {
  color: #c1c5c8;
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-link[disabled] > a:only-child,
.syt-antd-btn-link[disabled]:hover > a:only-child,
.syt-antd-btn-link[disabled]:focus > a:only-child,
.syt-antd-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-link[disabled] > a:only-child::after,
.syt-antd-btn-link[disabled]:hover > a:only-child::after,
.syt-antd-btn-link[disabled]:focus > a:only-child::after,
.syt-antd-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-text {
  color: #14151C;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.syt-antd-btn-text > a:only-child {
  color: currentColor;
}
.syt-antd-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-text:hover,
.syt-antd-btn-text:focus {
  color: #238dd9;
  background: transparent;
  border-color: #238dd9;
}
.syt-antd-btn-text:hover > a:only-child,
.syt-antd-btn-text:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-text:hover > a:only-child::after,
.syt-antd-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-text:active {
  color: #0056a6;
  background: transparent;
  border-color: #0056a6;
}
.syt-antd-btn-text:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-text[disabled],
.syt-antd-btn-text[disabled]:hover,
.syt-antd-btn-text[disabled]:focus,
.syt-antd-btn-text[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-text[disabled] > a:only-child,
.syt-antd-btn-text[disabled]:hover > a:only-child,
.syt-antd-btn-text[disabled]:focus > a:only-child,
.syt-antd-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-text[disabled] > a:only-child::after,
.syt-antd-btn-text[disabled]:hover > a:only-child::after,
.syt-antd-btn-text[disabled]:focus > a:only-child::after,
.syt-antd-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-text:hover,
.syt-antd-btn-text:focus {
  color: #14151C;
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.syt-antd-btn-text:active {
  color: #14151C;
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.syt-antd-btn-text[disabled],
.syt-antd-btn-text[disabled]:hover,
.syt-antd-btn-text[disabled]:focus,
.syt-antd-btn-text[disabled]:active {
  color: #c1c5c8;
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-text[disabled] > a:only-child,
.syt-antd-btn-text[disabled]:hover > a:only-child,
.syt-antd-btn-text[disabled]:focus > a:only-child,
.syt-antd-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-text[disabled] > a:only-child::after,
.syt-antd-btn-text[disabled]:hover > a:only-child::after,
.syt-antd-btn-text[disabled]:focus > a:only-child::after,
.syt-antd-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous {
  color: #CF3537;
  background: #fff;
  border-color: #CF3537;
}
.syt-antd-btn-dangerous > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous:hover,
.syt-antd-btn-dangerous:focus {
  color: #db5e5c;
  background: #fff;
  border-color: #db5e5c;
}
.syt-antd-btn-dangerous:hover > a:only-child,
.syt-antd-btn-dangerous:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous:hover > a:only-child::after,
.syt-antd-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous:active {
  color: #a8232a;
  background: #fff;
  border-color: #a8232a;
}
.syt-antd-btn-dangerous:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous[disabled],
.syt-antd-btn-dangerous[disabled]:hover,
.syt-antd-btn-dangerous[disabled]:focus,
.syt-antd-btn-dangerous[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-dangerous[disabled] > a:only-child,
.syt-antd-btn-dangerous[disabled]:hover > a:only-child,
.syt-antd-btn-dangerous[disabled]:focus > a:only-child,
.syt-antd-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous[disabled] > a:only-child::after,
.syt-antd-btn-dangerous[disabled]:hover > a:only-child::after,
.syt-antd-btn-dangerous[disabled]:focus > a:only-child::after,
.syt-antd-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-primary {
  color: #fff;
  background: #CF3537;
  border-color: #CF3537;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.syt-antd-btn-dangerous.syt-antd-btn-primary > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-primary:hover,
.syt-antd-btn-dangerous.syt-antd-btn-primary:focus {
  color: #fff;
  background: #db5e5c;
  border-color: #db5e5c;
}
.syt-antd-btn-dangerous.syt-antd-btn-primary:hover > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-primary:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-primary:hover > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-primary:active {
  color: #fff;
  background: #a8232a;
  border-color: #a8232a;
}
.syt-antd-btn-dangerous.syt-antd-btn-primary:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-primary[disabled],
.syt-antd-btn-dangerous.syt-antd-btn-primary[disabled]:hover,
.syt-antd-btn-dangerous.syt-antd-btn-primary[disabled]:focus,
.syt-antd-btn-dangerous.syt-antd-btn-primary[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-dangerous.syt-antd-btn-primary[disabled] > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-primary[disabled]:hover > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-primary[disabled]:focus > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-primary[disabled] > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-primary[disabled]:hover > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-primary[disabled]:focus > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-link {
  color: #CF3537;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.syt-antd-btn-dangerous.syt-antd-btn-link > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-link:hover,
.syt-antd-btn-dangerous.syt-antd-btn-link:focus {
  color: #238dd9;
  background: transparent;
  border-color: #238dd9;
}
.syt-antd-btn-dangerous.syt-antd-btn-link:hover > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-link:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-link:hover > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-link:active {
  color: #0056a6;
  background: transparent;
  border-color: #0056a6;
}
.syt-antd-btn-dangerous.syt-antd-btn-link:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled],
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:hover,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:focus,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled] > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:hover > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:focus > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled] > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:hover > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:focus > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-link:hover,
.syt-antd-btn-dangerous.syt-antd-btn-link:focus {
  color: #db5e5c;
  background: transparent;
  border-color: transparent;
}
.syt-antd-btn-dangerous.syt-antd-btn-link:hover > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-link:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-link:hover > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-link:active {
  color: #a8232a;
  background: transparent;
  border-color: transparent;
}
.syt-antd-btn-dangerous.syt-antd-btn-link:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled],
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:hover,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:focus,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:active {
  color: #c1c5c8;
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled] > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:hover > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:focus > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled] > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:hover > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:focus > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-text {
  color: #CF3537;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.syt-antd-btn-dangerous.syt-antd-btn-text > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-text:hover,
.syt-antd-btn-dangerous.syt-antd-btn-text:focus {
  color: #238dd9;
  background: transparent;
  border-color: #238dd9;
}
.syt-antd-btn-dangerous.syt-antd-btn-text:hover > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-text:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-text:hover > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-text:active {
  color: #0056a6;
  background: transparent;
  border-color: #0056a6;
}
.syt-antd-btn-dangerous.syt-antd-btn-text:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled],
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:hover,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:focus,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled] > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:hover > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:focus > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled] > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:hover > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:focus > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-text:hover,
.syt-antd-btn-dangerous.syt-antd-btn-text:focus {
  color: #db5e5c;
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.syt-antd-btn-dangerous.syt-antd-btn-text:hover > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-text:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-text:hover > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-text:active {
  color: #a8232a;
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.syt-antd-btn-dangerous.syt-antd-btn-text:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled],
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:hover,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:focus,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:active {
  color: #c1c5c8;
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled] > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:hover > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:focus > a:only-child,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled] > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:hover > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:focus > a:only-child::after,
.syt-antd-btn-dangerous.syt-antd-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 0 0;
  font-size: 16px;
  border-radius: 4px;
  vertical-align: -1px;
}
.syt-antd-btn-icon-only > * {
  font-size: 16px;
}
.syt-antd-btn-icon-only.syt-antd-btn-lg {
  width: 40px;
  height: 40px;
  padding: 0 0;
  font-size: 18px;
  border-radius: 4px;
}
.syt-antd-btn-icon-only.syt-antd-btn-lg > * {
  font-size: 18px;
}
.syt-antd-btn-icon-only.syt-antd-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0 0;
  font-size: 14px;
  border-radius: 4px;
}
.syt-antd-btn-icon-only.syt-antd-btn-sm > * {
  font-size: 14px;
}
.syt-antd-btn-round {
  height: 32px;
  padding: 0 16px;
  font-size: 14px;
  border-radius: 32px;
}
.syt-antd-btn-round.syt-antd-btn-lg {
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  border-radius: 40px;
}
.syt-antd-btn-round.syt-antd-btn-sm {
  height: 24px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 24px;
}
.syt-antd-btn-round.syt-antd-btn-icon-only {
  width: auto;
}
.syt-antd-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.syt-antd-btn-circle.syt-antd-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.syt-antd-btn-circle.syt-antd-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.syt-antd-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.syt-antd-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-btn .anticon.anticon-plus > svg,
.syt-antd-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}
.syt-antd-btn.syt-antd-btn-loading {
  position: relative;
}
.syt-antd-btn.syt-antd-btn-loading:not([disabled]) {
  pointer-events: none;
}
.syt-antd-btn.syt-antd-btn-loading::before {
  display: block;
}
.syt-antd-btn > .syt-antd-btn-loading-icon {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-btn > .syt-antd-btn-loading-icon .anticon {
  padding-right: 8px;
}
.syt-antd-btn > .syt-antd-btn-loading-icon:only-child .anticon {
  padding-right: 0;
}
.syt-antd-btn-group {
  position: relative;
  display: inline-flex;
}
.syt-antd-btn-group > .syt-antd-btn,
.syt-antd-btn-group > span > .syt-antd-btn {
  position: relative;
}
.syt-antd-btn-group > .syt-antd-btn:hover,
.syt-antd-btn-group > span > .syt-antd-btn:hover,
.syt-antd-btn-group > .syt-antd-btn:focus,
.syt-antd-btn-group > span > .syt-antd-btn:focus,
.syt-antd-btn-group > .syt-antd-btn:active,
.syt-antd-btn-group > span > .syt-antd-btn:active {
  z-index: 2;
}
.syt-antd-btn-group > .syt-antd-btn[disabled],
.syt-antd-btn-group > span > .syt-antd-btn[disabled] {
  z-index: 0;
}
.syt-antd-btn-group .syt-antd-btn-icon-only {
  font-size: 14px;
}
.syt-antd-btn-group-lg > .syt-antd-btn,
.syt-antd-btn-group-lg > span > .syt-antd-btn {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 0;
}
.syt-antd-btn-group-lg .syt-antd-btn.syt-antd-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}
.syt-antd-btn-group-sm > .syt-antd-btn,
.syt-antd-btn-group-sm > span > .syt-antd-btn {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 0;
}
.syt-antd-btn-group-sm > .syt-antd-btn > .anticon,
.syt-antd-btn-group-sm > span > .syt-antd-btn > .anticon {
  font-size: 14px;
}
.syt-antd-btn-group-sm .syt-antd-btn.syt-antd-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
}
.syt-antd-btn-group .syt-antd-btn + .syt-antd-btn,
.syt-antd-btn + .syt-antd-btn-group,
.syt-antd-btn-group span + .syt-antd-btn,
.syt-antd-btn-group .syt-antd-btn + span,
.syt-antd-btn-group > span + span,
.syt-antd-btn-group + .syt-antd-btn,
.syt-antd-btn-group + .syt-antd-btn-group {
  margin-left: -1px;
}
.syt-antd-btn-group .syt-antd-btn-primary + .syt-antd-btn:not(.syt-antd-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.syt-antd-btn-group .syt-antd-btn {
  border-radius: 0;
}
.syt-antd-btn-group > .syt-antd-btn:first-child,
.syt-antd-btn-group > span:first-child > .syt-antd-btn {
  margin-left: 0;
}
.syt-antd-btn-group > .syt-antd-btn:only-child {
  border-radius: 4px;
}
.syt-antd-btn-group > span:only-child > .syt-antd-btn {
  border-radius: 4px;
}
.syt-antd-btn-group > .syt-antd-btn:first-child:not(:last-child),
.syt-antd-btn-group > span:first-child:not(:last-child) > .syt-antd-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.syt-antd-btn-group > .syt-antd-btn:last-child:not(:first-child),
.syt-antd-btn-group > span:last-child:not(:first-child) > .syt-antd-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.syt-antd-btn-group-sm > .syt-antd-btn:only-child {
  border-radius: 4px;
}
.syt-antd-btn-group-sm > span:only-child > .syt-antd-btn {
  border-radius: 4px;
}
.syt-antd-btn-group-sm > .syt-antd-btn:first-child:not(:last-child),
.syt-antd-btn-group-sm > span:first-child:not(:last-child) > .syt-antd-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.syt-antd-btn-group-sm > .syt-antd-btn:last-child:not(:first-child),
.syt-antd-btn-group-sm > span:last-child:not(:first-child) > .syt-antd-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.syt-antd-btn-group > .syt-antd-btn-group {
  float: left;
}
.syt-antd-btn-group > .syt-antd-btn-group:not(:first-child):not(:last-child) > .syt-antd-btn {
  border-radius: 0;
}
.syt-antd-btn-group > .syt-antd-btn-group:first-child:not(:last-child) > .syt-antd-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syt-antd-btn-group > .syt-antd-btn-group:last-child:not(:first-child) > .syt-antd-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syt-antd-btn-rtl.syt-antd-btn-group .syt-antd-btn + .syt-antd-btn,
.syt-antd-btn-rtl.syt-antd-btn + .syt-antd-btn-group,
.syt-antd-btn-rtl.syt-antd-btn-group span + .syt-antd-btn,
.syt-antd-btn-rtl.syt-antd-btn-group .syt-antd-btn + span,
.syt-antd-btn-rtl.syt-antd-btn-group > span + span,
.syt-antd-btn-rtl.syt-antd-btn-group + .syt-antd-btn,
.syt-antd-btn-rtl.syt-antd-btn-group + .syt-antd-btn-group,
.syt-antd-btn-group-rtl.syt-antd-btn-group .syt-antd-btn + .syt-antd-btn,
.syt-antd-btn-group-rtl.syt-antd-btn + .syt-antd-btn-group,
.syt-antd-btn-group-rtl.syt-antd-btn-group span + .syt-antd-btn,
.syt-antd-btn-group-rtl.syt-antd-btn-group .syt-antd-btn + span,
.syt-antd-btn-group-rtl.syt-antd-btn-group > span + span,
.syt-antd-btn-group-rtl.syt-antd-btn-group + .syt-antd-btn,
.syt-antd-btn-group-rtl.syt-antd-btn-group + .syt-antd-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.syt-antd-btn-group.syt-antd-btn-group-rtl {
  direction: rtl;
}
.syt-antd-btn-group-rtl.syt-antd-btn-group > .syt-antd-btn:first-child:not(:last-child),
.syt-antd-btn-group-rtl.syt-antd-btn-group > span:first-child:not(:last-child) > .syt-antd-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.syt-antd-btn-group-rtl.syt-antd-btn-group > .syt-antd-btn:last-child:not(:first-child),
.syt-antd-btn-group-rtl.syt-antd-btn-group > span:last-child:not(:first-child) > .syt-antd-btn {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}
.syt-antd-btn-group-rtl.syt-antd-btn-group-sm > .syt-antd-btn:first-child:not(:last-child),
.syt-antd-btn-group-rtl.syt-antd-btn-group-sm > span:first-child:not(:last-child) > .syt-antd-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.syt-antd-btn-group-rtl.syt-antd-btn-group-sm > .syt-antd-btn:last-child:not(:first-child),
.syt-antd-btn-group-rtl.syt-antd-btn-group-sm > span:last-child:not(:first-child) > .syt-antd-btn {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}
.syt-antd-btn:focus > span,
.syt-antd-btn:active > span {
  position: relative;
}
.syt-antd-btn > .anticon + span,
.syt-antd-btn > span + .anticon {
  margin-left: 8px;
}
.syt-antd-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}
.syt-antd-btn-background-ghost.syt-antd-btn-primary {
  color: #0071CD;
  background: transparent;
  border-color: #0071CD;
  text-shadow: none;
}
.syt-antd-btn-background-ghost.syt-antd-btn-primary > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-primary:hover,
.syt-antd-btn-background-ghost.syt-antd-btn-primary:focus {
  color: #238dd9;
  background: transparent;
  border-color: #238dd9;
}
.syt-antd-btn-background-ghost.syt-antd-btn-primary:hover > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-primary:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-primary:hover > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-primary:active {
  color: #0056a6;
  background: transparent;
  border-color: #0056a6;
}
.syt-antd-btn-background-ghost.syt-antd-btn-primary:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-primary[disabled],
.syt-antd-btn-background-ghost.syt-antd-btn-primary[disabled]:hover,
.syt-antd-btn-background-ghost.syt-antd-btn-primary[disabled]:focus,
.syt-antd-btn-background-ghost.syt-antd-btn-primary[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-background-ghost.syt-antd-btn-primary[disabled] > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-primary[disabled]:hover > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-primary[disabled]:focus > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-primary[disabled] > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-primary[disabled]:hover > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-primary[disabled]:focus > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-danger {
  color: #CF3537;
  background: transparent;
  border-color: #CF3537;
  text-shadow: none;
}
.syt-antd-btn-background-ghost.syt-antd-btn-danger > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-danger:hover,
.syt-antd-btn-background-ghost.syt-antd-btn-danger:focus {
  color: #db5e5c;
  background: transparent;
  border-color: #db5e5c;
}
.syt-antd-btn-background-ghost.syt-antd-btn-danger:hover > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-danger:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-danger:hover > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-danger:active {
  color: #a8232a;
  background: transparent;
  border-color: #a8232a;
}
.syt-antd-btn-background-ghost.syt-antd-btn-danger:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-danger[disabled],
.syt-antd-btn-background-ghost.syt-antd-btn-danger[disabled]:hover,
.syt-antd-btn-background-ghost.syt-antd-btn-danger[disabled]:focus,
.syt-antd-btn-background-ghost.syt-antd-btn-danger[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-background-ghost.syt-antd-btn-danger[disabled] > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-danger[disabled]:hover > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-danger[disabled]:focus > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-danger[disabled] > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-danger[disabled]:hover > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-danger[disabled]:focus > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous {
  color: #CF3537;
  background: transparent;
  border-color: #CF3537;
  text-shadow: none;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous:hover,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous:focus {
  color: #db5e5c;
  background: transparent;
  border-color: #db5e5c;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous:hover > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous:hover > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous:active {
  color: #a8232a;
  background: transparent;
  border-color: #a8232a;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous[disabled],
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous[disabled]:hover,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous[disabled]:focus,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous[disabled] > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous[disabled]:hover > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous[disabled]:focus > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous[disabled] > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous[disabled]:hover > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous[disabled]:focus > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link {
  color: #CF3537;
  background: transparent;
  border-color: transparent;
  text-shadow: none;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link:hover,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link:focus {
  color: #db5e5c;
  background: transparent;
  border-color: transparent;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link:hover > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link:focus > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link:hover > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link:active {
  color: #a8232a;
  background: transparent;
  border-color: transparent;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link[disabled],
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:hover,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:focus,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:active {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  text-shadow: none;
  box-shadow: none;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link[disabled] > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:hover > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:focus > a:only-child,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link[disabled] > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:hover > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:focus > a:only-child::after,
.syt-antd-btn-background-ghost.syt-antd-btn-dangerous.syt-antd-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syt-antd-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.syt-antd-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.syt-antd-btn-block {
  width: 100%;
}
.syt-antd-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.syt-antd-btn {
  padding-top: 0.1px;
  line-height: 30px;
}
a.syt-antd-btn-lg {
  line-height: 38px;
}
a.syt-antd-btn-sm {
  line-height: 22px;
}
.syt-antd-btn-rtl {
  direction: rtl;
}
.syt-antd-btn-group-rtl.syt-antd-btn-group .syt-antd-btn-primary:last-child:not(:first-child),
.syt-antd-btn-group-rtl.syt-antd-btn-group .syt-antd-btn-primary + .syt-antd-btn-primary {
  border-right-color: #238dd9;
  border-left-color: #0092e4;
}
.syt-antd-btn-group-rtl.syt-antd-btn-group .syt-antd-btn-primary:last-child:not(:first-child)[disabled],
.syt-antd-btn-group-rtl.syt-antd-btn-group .syt-antd-btn-primary + .syt-antd-btn-primary[disabled] {
  border-right-color: #0092e4;
  border-left-color: #238dd9;
}
.syt-antd-btn-rtl.syt-antd-btn > .syt-antd-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.syt-antd-btn > .syt-antd-btn-loading-icon:only-child .anticon {
  padding-right: 0;
  padding-left: 0;
}
.syt-antd-btn-rtl.syt-antd-btn > .anticon + span,
.syt-antd-btn-rtl.syt-antd-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}
.primaryButton {
  color: #FFFFFF;
  background-color: #14803C;
  border-color: #14803C;
  border-radius: 4px;
}
.primaryButton:hover,
.primaryButton:focus {
  color: #FFFFFF;
  background-color: #19A04B;
  border-color: #19A04B;
}
.primaryButton:focus {
  box-shadow: 0px 0px 0px 3px rgba(25, 160, 75, 0.25);
}
.primaryButton:active {
  background-color: #0C612C;
  border-color: #0C612C;
}
.primaryButton:disabled {
  background: #F3F4F6;
  border: 1px solid #DFE2E7;
  box-sizing: border-box;
}
.successButton {
  color: #FFFFFF;
  background-color: #aab400;
  border-color: #aab400;
}
.successButton:hover,
.successButton:focus {
  color: #FFFFFF;
  background-color: #19A04B;
  border-color: #19A04B;
}
.warnButton {
  color: #FFFFFF;
  background-color: #ffca41;
  border-color: #ffca41;
}
.warnButton:hover,
.warnButton:focus {
  color: #FFFFFF;
  background-color: #ffd17e;
  border-color: #ffd17e;
}
.outlineButton {
  color: #14151C;
  background-color: #FFFFFF;
  border-color: #C2C7D0;
}
.outlineButton:hover {
  color: #14151C;
  background-color: #F3F4F6;
  border-color: #C2C7D0;
}
.outlineButton:focus {
  color: #14151C;
  background-color: #F3F4F6;
  border-color: #C2C7D0;
  box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
}
.outlineButton:active {
  color: #14151C;
  background-color: #DFE2E7;
  border-color: #C2C7D0;
}
.overlayButton {
  color: #14151C;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(92, 101, 117, 0.3), 0px 0px 1px rgba(92, 101, 117, 0.47);
}
.overlayButton:hover {
  color: #14151C;
  background-color: #F3F4F6;
  border-color: #F3F4F6;
  box-shadow: 0px 2px 4px rgba(92, 101, 117, 0.3), 0px 0px 1px rgba(92, 101, 117, 0.47);
}
.overlayButton:focus {
  color: #14151C;
  background-color: #F3F4F6;
  border-color: #F3F4F6;
  box-shadow: 0px 2px 4px rgba(92, 101, 117, 0.3), 0px 0px 1px rgba(92, 101, 117, 0.47), 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
}
.overlayButton:active {
  color: #14151C;
  background-color: #DFE2E7;
  border-color: #DFE2E7;
  box-shadow: 0px 2px 4px rgba(92, 101, 117, 0.3), 0px 0px 1px rgba(92, 101, 117, 0.47);
}
.dashedButton {
  color: #FFFFFF;
  background-color: transparent;
  border: 1px dashed #FFFFFF;
}
.dashedButton:hover,
.dashedButton:focus {
  color: #19A04B;
  background-color: transparent;
  border: 1px dashed #19A04B;
}
.dashedButton:active {
  color: #0C612C;
  background-color: transparent;
  border: 1px dashed #0C612C;
}
.dashedButton:disabled {
  color: #DFE2E7;
  background-color: #FFFFFF;
  border: 1px solid #DFE2E7;
  box-sizing: border-box;
}
.dangerButton {
  color: #FFFFFF;
  background-color: #CF3537;
  border-color: #CF3537;
}
.dangerButton:hover,
.dangerButton:focus {
  color: #FFFFFF;
  background-color: #EB4B4B;
  border-color: #EB4B4B;
}
.dangerButton:focus {
  box-shadow: 0px 0px 0px 3px rgba(235, 75, 75, 0.25);
}
.dangerButton:active {
  background-color: #9C2628;
  border-color: #9C2628;
}
.dangerButton:disabled {
  background: #F3F4F6;
  border: 1px solid #DFE2E7;
  box-sizing: border-box;
}
.defaultButton {
  color: #14151C;
  background-color: #F3F4F6;
  border-color: #F3F4F6;
}
.defaultButton:hover {
  color: #14151C;
  background-color: #DFE2E7;
  border-color: #DFE2E7;
}
.defaultButton:focus {
  color: #14151C;
  background-color: #F3F4F6;
  border-color: #F3F4F6;
  box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
}
.defaultButton:active {
  background-color: #C2C7D0;
  border-color: #C2C7D0;
}
.defaultButton:disabled {
  background: #F3F4F6;
  border: 1px solid #DFE2E7;
  box-sizing: border-box;
}
.blueLinkButton {
  color: #0071CD;
  background-color: transparent;
  border-color: transparent;
}
.blueLinkButton:hover,
.blueLinkButton:focus {
  color: #0092E4;
  background-color: transparent;
  border-color: transparent;
}
.blueLinkButton:active {
  color: #005693;
  background-color: transparent;
  border-color: transparent;
}
.blueLinkButton:disabled {
  color: #C2C7D0;
  background: transparent;
  border: transparent;
}
.greenLinkButton {
  color: #14803C;
  background-color: transparent;
  border-color: transparent;
}
.greenLinkButton:hover,
.greenLinkButton:focus {
  color: #19A04B;
  background-color: transparent;
  border-color: transparent;
}
.greenLinkButton:active {
  color: #0C612C;
  background-color: transparent;
  border-color: transparent;
}
.greenLinkButton:disabled {
  color: #C2C7D0;
  background: transparent;
  border: transparent;
}
.syt-antd-affix {
  position: fixed;
  z-index: 10;
}
.syt-antd-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding-left: 2px;
}
.syt-antd-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff;
}
.syt-antd-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.syt-antd-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  content: ' ';
}
.syt-antd-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #0071CD;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.syt-antd-anchor-ink-ball.visible {
  display: inline-block;
}
.syt-antd-anchor.fixed .syt-antd-anchor-ink .syt-antd-anchor-ink-ball {
  display: none;
}
.syt-antd-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}
.syt-antd-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: #14151C;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.syt-antd-anchor-link-title:only-child {
  margin-bottom: 0;
}
.syt-antd-anchor-link-active > .syt-antd-anchor-link-title {
  color: #0071CD;
}
.syt-antd-anchor-link .syt-antd-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
.syt-antd-anchor-rtl {
  direction: rtl;
}
.syt-antd-anchor-rtl.syt-antd-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}
.syt-antd-anchor-rtl .syt-antd-anchor-ink {
  right: 0;
  left: auto;
}
.syt-antd-anchor-rtl .syt-antd-anchor-ink-ball {
  right: 50%;
  left: 0;
  transform: translateX(50%);
}
.syt-antd-anchor-rtl .syt-antd-anchor-link {
  padding: 7px 16px 7px 0;
}
.syt-antd-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
}
.syt-antd-select-auto-complete .syt-antd-select-clear {
  right: 13px;
}
.syt-antd-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 8px 15px 8px 37px;
  word-wrap: break-word;
  border-radius: 4px;
}
.syt-antd-alert.syt-antd-alert-no-icon {
  padding: 8px 15px;
}
.syt-antd-alert.syt-antd-alert-no-icon .syt-antd-alert-close-icon {
  top: 141px;
}
.syt-antd-alert.syt-antd-alert-closable {
  padding-right: 30px;
}
.syt-antd-alert-icon {
  position: absolute;
  top: 141px;
  left: 16px;
}
.syt-antd-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.syt-antd-alert-success {
  background-color: #f5f2dc;
  border: 1px solid #dbd669;
}
.syt-antd-alert-success .syt-antd-alert-icon {
  color: #aab400;
}
.syt-antd-alert-info {
  background-color: #e6f8ff;
  border: 1px solid #74c6f2;
}
.syt-antd-alert-info .syt-antd-alert-icon {
  color: #0071CD;
}
.syt-antd-alert-warning {
  background-color: #fffdf0;
  border: 1px solid #fff2ba;
}
.syt-antd-alert-warning .syt-antd-alert-icon {
  color: #ffca41;
}
.syt-antd-alert-error {
  background-color: #fff2f0;
  border: 1px solid #f5bab5;
}
.syt-antd-alert-error .syt-antd-alert-icon {
  color: #CF3537;
}
.syt-antd-alert-error .syt-antd-alert-description > pre {
  margin: 0;
  padding: 0;
}
.syt-antd-alert-close-icon {
  position: absolute;
  top: 141px;
  right: 16px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.syt-antd-alert-close-icon .anticon-close {
  color: #696F88;
  transition: color 0.3s;
}
.syt-antd-alert-close-icon .anticon-close:hover {
  color: #868CA2;
}
.syt-antd-alert-close-text {
  color: #696F88;
  transition: color 0.3s;
}
.syt-antd-alert-close-text:hover {
  color: #868CA2;
}
.syt-antd-alert-with-description {
  position: relative;
  padding: 15px 15px 15px 63px;
  color: #14151C;
  line-height: 20px;
  border-radius: 4px;
}
.syt-antd-alert-with-description.syt-antd-alert-no-icon {
  padding: 15px 15px;
}
.syt-antd-alert-with-description .syt-antd-alert-icon {
  position: absolute;
  top: 15px;
  left: 24px;
  font-size: 24px;
}
.syt-antd-alert-with-description .syt-antd-alert-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 14px;
  cursor: pointer;
}
.syt-antd-alert-with-description .syt-antd-alert-message {
  display: block;
  margin-bottom: 4px;
  color: #14151C;
  font-size: 16px;
}
.syt-antd-alert-message {
  color: #14151C;
}
.syt-antd-alert-with-description .syt-antd-alert-description {
  display: block;
}
.syt-antd-alert.syt-antd-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.syt-antd-alert.syt-antd-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.syt-antd-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.syt-antd-alert.syt-antd-alert-rtl {
  padding: 8px 37px 8px 15px;
  direction: rtl;
}
.syt-antd-alert-rtl.syt-antd-alert.syt-antd-alert-no-icon {
  padding: 8px 15px;
}
.syt-antd-alert.syt-antd-alert-rtl.syt-antd-alert.syt-antd-alert-closable {
  padding-right: 37px;
  padding-left: 30px;
}
.syt-antd-alert.syt-antd-alert-rtl.syt-antd-alert.syt-antd-alert-no-icon.syt-antd-alert-closable {
  padding-right: 15px;
  padding-left: 30px;
}
.syt-antd-alert-rtl .syt-antd-alert-icon {
  right: 16px;
  left: auto;
}
.syt-antd-alert-rtl .syt-antd-alert-close-icon {
  right: auto;
  left: 16px;
}
.syt-antd-alert.syt-antd-alert-rtl.syt-antd-alert-with-description,
.syt-antd-alert.syt-antd-alert-rtl.syt-antd-alert-with-description.syt-antd-alert-closable {
  padding: 15px 63px 15px 15px;
}
.syt-antd-alert.syt-antd-alert-rtl.syt-antd-alert-with-description.syt-antd-alert-no-icon {
  padding: 15px;
}
.syt-antd-alert-rtl.syt-antd-alert-with-description .syt-antd-alert-icon {
  right: 24px;
  left: auto;
}
.syt-antd-alert-rtl.syt-antd-alert-with-description .syt-antd-alert-close-icon {
  right: auto;
  left: 16px;
}
.syt-antd-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.syt-antd-avatar-image {
  background: transparent;
}
.syt-antd-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.syt-antd-avatar.syt-antd-avatar-icon {
  font-size: 18px;
}
.syt-antd-avatar.syt-antd-avatar-icon > .anticon {
  margin: 0;
}
.syt-antd-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.syt-antd-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.syt-antd-avatar-lg.syt-antd-avatar-icon {
  font-size: 24px;
}
.syt-antd-avatar-lg.syt-antd-avatar-icon > .anticon {
  margin: 0;
}
.syt-antd-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.syt-antd-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.syt-antd-avatar-sm.syt-antd-avatar-icon {
  font-size: 14px;
}
.syt-antd-avatar-sm.syt-antd-avatar-icon > .anticon {
  margin: 0;
}
.syt-antd-avatar-square {
  border-radius: 4px;
}
.syt-antd-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.syt-antd-avatar-group {
  display: inline-flex;
}
.syt-antd-avatar-group .syt-antd-avatar {
  border: 1px solid #fff;
}
.syt-antd-avatar-group .syt-antd-avatar:not(:first-child) {
  margin-left: -8px;
}
.syt-antd-avatar-group-popover .syt-antd-avatar + .syt-antd-avatar {
  margin-left: 3px;
}
.syt-antd-avatar-group-rtl .syt-antd-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}
.syt-antd-avatar-group-popover.syt-antd-popover-rtl .syt-antd-avatar + .syt-antd-avatar {
  margin-right: 3px;
  margin-left: 0;
}
.avatarPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatarOrgIcon {
  background-color: #14803C !important;
  color: #F3F4F6 ! important;
}
.syt-antd-avatar {
  background: #F3F4F6;
  color: #14803C;
}
.syt-antd-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.syt-antd-back-top:empty {
  display: none;
}
.syt-antd-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.syt-antd-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: #696F88;
  border-radius: 20px;
  transition: all 0.3s;
}
.syt-antd-back-top-content:hover {
  background-color: #14151C;
  transition: all 0.3s;
}
.syt-antd-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .syt-antd-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .syt-antd-back-top {
    right: 20px;
  }
}
.syt-antd-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  color: unset;
  line-height: 1;
}
.syt-antd-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.syt-antd-badge-count a,
.syt-antd-badge-count a:hover {
  color: #fff;
}
.syt-antd-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.syt-antd-badge-multiple-words {
  padding: 0 8px;
}
.syt-antd-badge-dot {
  z-index: auto;
  width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.syt-antd-badge-count,
.syt-antd-badge-dot,
.syt-antd-badge .syt-antd-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.syt-antd-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.syt-antd-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.syt-antd-badge-status-success {
  background-color: #aab400;
}
.syt-antd-badge-status-processing {
  position: relative;
  background-color: #1890ff;
}
.syt-antd-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.syt-antd-badge-status-default {
  background-color: #d9d9d9;
}
.syt-antd-badge-status-error {
  background-color: #CF3537;
}
.syt-antd-badge-status-warning {
  background-color: #ffca41;
}
.syt-antd-badge-status-pink {
  background: #eb2f96;
}
.syt-antd-badge-status-magenta {
  background: #eb2f96;
}
.syt-antd-badge-status-red {
  background: #f5222d;
}
.syt-antd-badge-status-volcano {
  background: #fa541c;
}
.syt-antd-badge-status-orange {
  background: #fa8c16;
}
.syt-antd-badge-status-yellow {
  background: #fadb14;
}
.syt-antd-badge-status-gold {
  background: #faad14;
}
.syt-antd-badge-status-cyan {
  background: #13c2c2;
}
.syt-antd-badge-status-lime {
  background: #a0d911;
}
.syt-antd-badge-status-green {
  background: #52c41a;
}
.syt-antd-badge-status-blue {
  background: #1890ff;
}
.syt-antd-badge-status-geekblue {
  background: #2f54eb;
}
.syt-antd-badge-status-purple {
  background: #722ed1;
}
.syt-antd-badge-status-text {
  margin-left: 8px;
  color: #14151C;
  font-size: 14px;
}
.syt-antd-badge-zoom-appear,
.syt-antd-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
.syt-antd-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
.syt-antd-badge-not-a-wrapper:not(.syt-antd-badge-status) {
  vertical-align: middle;
}
.syt-antd-badge-not-a-wrapper .syt-antd-scroll-number {
  position: relative;
  top: auto;
  display: block;
}
.syt-antd-badge-not-a-wrapper .syt-antd-badge-count {
  transform: none;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.syt-antd-scroll-number {
  overflow: hidden;
}
.syt-antd-scroll-number-only {
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-scroll-number-only > p.syt-antd-scroll-number-only-unit {
  height: 20px;
  margin: 0;
}
.syt-antd-scroll-number-symbol {
  vertical-align: top;
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
.syt-antd-ribbon-wrapper {
  position: relative;
}
.syt-antd-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #0071CD;
  border-radius: 4px;
}
.syt-antd-ribbon-text {
  color: #fff;
}
.syt-antd-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentColor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
}
.syt-antd-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}
.syt-antd-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}
.syt-antd-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}
.syt-antd-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}
.syt-antd-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}
.syt-antd-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}
.syt-antd-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}
.syt-antd-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}
.syt-antd-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}
.syt-antd-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}
.syt-antd-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}
.syt-antd-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}
.syt-antd-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}
.syt-antd-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}
.syt-antd-ribbon.syt-antd-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.syt-antd-ribbon.syt-antd-ribbon-placement-end .syt-antd-ribbon-corner {
  right: 0;
  border-color: currentColor transparent transparent currentColor;
}
.syt-antd-ribbon.syt-antd-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.syt-antd-ribbon.syt-antd-ribbon-placement-start .syt-antd-ribbon-corner {
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}
.syt-antd-badge-rtl {
  direction: rtl;
}
.syt-antd-badge-rtl .syt-antd-badge-count,
.syt-antd-badge-rtl .syt-antd-badge-dot,
.syt-antd-badge-rtl .syt-antd-badge .syt-antd-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.syt-antd-badge-rtl.syt-antd-badge .syt-antd-scroll-number-custom-component {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.syt-antd-badge-rtl .syt-antd-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}
.syt-antd-badge-rtl .syt-antd-badge-zoom-appear,
.syt-antd-badge-rtl .syt-antd-badge-zoom-enter {
  animation-name: antZoomBadgeInRtl;
}
.syt-antd-badge-rtl .syt-antd-badge-zoom-leave {
  animation-name: antZoomBadgeOutRtl;
}
.syt-antd-badge-not-a-wrapper .syt-antd-badge-count {
  transform: none;
}
.syt-antd-ribbon-rtl {
  direction: rtl;
}
.syt-antd-ribbon-rtl.syt-antd-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.syt-antd-ribbon-rtl.syt-antd-ribbon-placement-end .syt-antd-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}
.syt-antd-ribbon-rtl.syt-antd-ribbon-placement-end .syt-antd-ribbon-corner::after {
  border-color: currentColor currentColor transparent transparent;
}
.syt-antd-ribbon-rtl.syt-antd-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}
.syt-antd-ribbon-rtl.syt-antd-ribbon-placement-start .syt-antd-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentColor transparent transparent currentColor;
}
.syt-antd-ribbon-rtl.syt-antd-ribbon-placement-start .syt-antd-ribbon-corner::after {
  border-color: currentColor transparent transparent currentColor;
}
@keyframes antZoomBadgeInRtl {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}
.head-example {
  background: #eee;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  vertical-align: middle;
  width: 42px;
}
.notification-example-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.syt-antd-badge-count {
  background-color: #CF3537;
}
.syt-antd-badge-status .syt-antd-badge-status-success {
  background-color: #19A04B;
}
.syt-antd-badge-status .syt-antd-badge-status-error {
  background-color: #EB4B4B;
}
.syt-antd-badge-status .syt-antd-badge-status-default {
  background-color: #868CA2;
}
.syt-antd-badge-status .syt-antd-badge-status-processing {
  background-color: #0092E4;
}
.syt-antd-badge-status .syt-antd-badge-status-warning {
  background-color: #E99921;
}
.syt-antd-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  color: #696F88;
  font-size: 14px;
}
.syt-antd-breadcrumb .anticon {
  font-size: 14px;
}
.syt-antd-breadcrumb a {
  color: #696F88;
  transition: color 0.3s;
}
.syt-antd-breadcrumb a:hover {
  color: #238dd9;
}
.syt-antd-breadcrumb > span:last-child {
  color: #14151C;
}
.syt-antd-breadcrumb > span:last-child a {
  color: #14151C;
}
.syt-antd-breadcrumb > span:last-child .syt-antd-breadcrumb-separator {
  display: none;
}
.syt-antd-breadcrumb-separator {
  margin: 0 8px;
  color: #696F88;
}
.syt-antd-breadcrumb-link > .anticon + span,
.syt-antd-breadcrumb-link > .anticon + a {
  margin-left: 4px;
}
.syt-antd-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.syt-antd-breadcrumb-rtl {
  direction: rtl;
}
.syt-antd-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.syt-antd-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-breadcrumb-rtl > span {
  float: right;
}
.syt-antd-breadcrumb-rtl .syt-antd-breadcrumb-link > .anticon + span,
.syt-antd-breadcrumb-rtl .syt-antd-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0;
}
.syt-antd-breadcrumb-rtl .syt-antd-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}
.syt-antd-breadcrumb a {
  color: #696F88;
}
.syt-antd-breadcrumb a:hover {
  color: #14151C;
  text-decoration: underline;
}
.syt-antd-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
}
.syt-antd-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.syt-antd-picker-calendar-header .syt-antd-picker-calendar-year-select {
  min-width: 80px;
}
.syt-antd-picker-calendar-header .syt-antd-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.syt-antd-picker-calendar-header .syt-antd-picker-calendar-mode-switch {
  margin-left: 8px;
}
.syt-antd-picker-calendar .syt-antd-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}
.syt-antd-picker-calendar .syt-antd-picker-panel .syt-antd-picker-month-panel,
.syt-antd-picker-calendar .syt-antd-picker-panel .syt-antd-picker-date-panel {
  width: auto;
}
.syt-antd-picker-calendar .syt-antd-picker-panel .syt-antd-picker-body {
  padding: 8px 0;
}
.syt-antd-picker-calendar .syt-antd-picker-panel .syt-antd-picker-content {
  width: 100%;
}
.syt-antd-picker-calendar-mini {
  border-radius: 4px;
}
.syt-antd-picker-calendar-mini .syt-antd-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.syt-antd-picker-calendar-mini .syt-antd-picker-panel {
  border-radius: 0 0 4px 4px;
}
.syt-antd-picker-calendar-mini .syt-antd-picker-content {
  height: 256px;
}
.syt-antd-picker-calendar-mini .syt-antd-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-body th,
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-body td {
  padding: 0;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-cell::before {
  display: none;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-cell:hover .syt-antd-picker-calendar-date {
  background: #f5f5f5;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-cell .syt-antd-picker-calendar-date-today::before {
  display: none;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-cell-selected .syt-antd-picker-calendar-date,
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-cell-selected:hover .syt-antd-picker-calendar-date,
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-cell-selected .syt-antd-picker-calendar-date-today,
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-cell-selected:hover .syt-antd-picker-calendar-date-today {
  background: #e6f8ff;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-cell-selected .syt-antd-picker-calendar-date .syt-antd-picker-calendar-date-value,
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-cell-selected:hover .syt-antd-picker-calendar-date .syt-antd-picker-calendar-date-value,
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-cell-selected .syt-antd-picker-calendar-date-today .syt-antd-picker-calendar-date-value,
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-cell-selected:hover .syt-antd-picker-calendar-date-today .syt-antd-picker-calendar-date-value {
  color: #0071CD;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  transition: background 0.3s;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: #14151C;
  line-height: 20px;
  text-align: left;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-calendar-date-today {
  border-color: #0071CD;
}
.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-calendar-date-today .syt-antd-picker-calendar-date-value {
  color: #14151C;
}
@media only screen and (max-width: 480px) {
  .syt-antd-picker-calendar-header {
    display: block;
  }
  .syt-antd-picker-calendar-header .syt-antd-picker-calendar-year-select {
    width: 50%;
  }
  .syt-antd-picker-calendar-header .syt-antd-picker-calendar-month-select {
    width: calc(50% - 8px);
  }
  .syt-antd-picker-calendar-header .syt-antd-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .syt-antd-picker-calendar-header .syt-antd-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.syt-antd-picker-calendar-rtl {
  direction: rtl;
}
.syt-antd-picker-calendar-rtl .syt-antd-picker-calendar-header .syt-antd-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.syt-antd-picker-calendar-rtl .syt-antd-picker-calendar-header .syt-antd-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}
.syt-antd-picker-calendar-rtl.syt-antd-picker-calendar-full .syt-antd-picker-panel {
  text-align: left;
}
.syt-antd-picker-calendar-rtl.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-body th {
  padding: 0 0 5px 12px;
}
.syt-antd-picker-calendar-rtl.syt-antd-picker-calendar-full .syt-antd-picker-panel .syt-antd-picker-calendar-date-content {
  text-align: right;
}
.syt-antd-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  background: #fff;
  border-radius: 4px;
}
.syt-antd-card-rtl {
  direction: rtl;
}
.syt-antd-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.syt-antd-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.syt-antd-card-bordered {
  border: 1px solid #f0f0f0;
}
.syt-antd-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: #14151C;
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.syt-antd-card-head::before {
  display: table;
  content: '';
}
.syt-antd-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-card-head-wrapper {
  display: flex;
  align-items: center;
}
.syt-antd-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-card-head .syt-antd-tabs {
  clear: both;
  margin-bottom: -17px;
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
}
.syt-antd-card-head .syt-antd-tabs-bar {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
}
.syt-antd-card-rtl .syt-antd-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.syt-antd-card-body {
  padding: 24px;
}
.syt-antd-card-body::before {
  display: table;
  content: '';
}
.syt-antd-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-card-contain-grid:not(.syt-antd-card-loading) .syt-antd-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.syt-antd-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}
.syt-antd-card-rtl .syt-antd-card-grid {
  float: right;
}
.syt-antd-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.syt-antd-card-contain-tabs > .syt-antd-card-head .syt-antd-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.syt-antd-card-contain-tabs > .syt-antd-card-head .syt-antd-card-extra {
  padding-bottom: 0;
}
.syt-antd-card-bordered .syt-antd-card-cover {
  margin-right: -1px;
  margin-left: -1px;
  transform: translateY(-1px);
}
.syt-antd-card-cover > * {
  display: block;
  width: 100%;
}
.syt-antd-card-cover img {
  border-radius: 4px 4px 0 0;
}
.syt-antd-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.syt-antd-card-actions::before {
  display: table;
  content: '';
}
.syt-antd-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-card-actions > li {
  float: left;
  margin: 12px 0;
  color: #696F88;
  text-align: center;
}
.syt-antd-card-rtl .syt-antd-card-actions > li {
  float: right;
}
.syt-antd-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.syt-antd-card-actions > li > span:hover {
  color: #0071CD;
  transition: color 0.3s;
}
.syt-antd-card-actions > li > span a:not(.syt-antd-btn),
.syt-antd-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: #696F88;
  line-height: 22px;
  transition: color 0.3s;
}
.syt-antd-card-actions > li > span a:not(.syt-antd-btn):hover,
.syt-antd-card-actions > li > span > .anticon:hover {
  color: #0071CD;
}
.syt-antd-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.syt-antd-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
.syt-antd-card-type-inner .syt-antd-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.syt-antd-card-type-inner .syt-antd-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.syt-antd-card-type-inner .syt-antd-card-body {
  padding: 16px 24px;
}
.syt-antd-card-type-inner .syt-antd-card-extra {
  padding: 13.5px 0;
}
.syt-antd-card-meta {
  margin: -4px 0;
}
.syt-antd-card-meta::before {
  display: table;
  content: '';
}
.syt-antd-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
.syt-antd-card-rtl .syt-antd-card-meta-avatar {
  float: right;
  padding-right: 0;
  padding-left: 16px;
}
.syt-antd-card-meta-detail {
  overflow: hidden;
}
.syt-antd-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.syt-antd-card-meta-title {
  overflow: hidden;
  color: #14151C;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-card-meta-description {
  color: #696F88;
}
.syt-antd-card-loading {
  overflow: hidden;
}
.syt-antd-card-loading .syt-antd-card-body {
  user-select: none;
}
.syt-antd-card-loading-content p {
  margin: 0;
}
.syt-antd-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 4px;
  animation: card-loading 1.4s ease infinite;
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.syt-antd-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  background: #fff;
  border-radius: 4px;
}
.syt-antd-card-rtl {
  direction: rtl;
}
.syt-antd-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.syt-antd-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.syt-antd-card-bordered {
  border: 1px solid #f0f0f0;
}
.syt-antd-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: #14151C;
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.syt-antd-card-head::before {
  display: table;
  content: '';
}
.syt-antd-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-card-head-wrapper {
  display: flex;
  align-items: center;
}
.syt-antd-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-card-head .syt-antd-tabs {
  clear: both;
  margin-bottom: -17px;
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
}
.syt-antd-card-head .syt-antd-tabs-bar {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
}
.syt-antd-card-rtl .syt-antd-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.syt-antd-card-body {
  padding: 24px;
}
.syt-antd-card-body::before {
  display: table;
  content: '';
}
.syt-antd-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-card-contain-grid:not(.syt-antd-card-loading) .syt-antd-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.syt-antd-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}
.syt-antd-card-rtl .syt-antd-card-grid {
  float: right;
}
.syt-antd-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.syt-antd-card-contain-tabs > .syt-antd-card-head .syt-antd-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.syt-antd-card-contain-tabs > .syt-antd-card-head .syt-antd-card-extra {
  padding-bottom: 0;
}
.syt-antd-card-bordered .syt-antd-card-cover {
  margin-right: -1px;
  margin-left: -1px;
  transform: translateY(-1px);
}
.syt-antd-card-cover > * {
  display: block;
  width: 100%;
}
.syt-antd-card-cover img {
  border-radius: 4px 4px 0 0;
}
.syt-antd-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.syt-antd-card-actions::before {
  display: table;
  content: '';
}
.syt-antd-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-card-actions > li {
  float: left;
  margin: 12px 0;
  color: #696F88;
  text-align: center;
}
.syt-antd-card-rtl .syt-antd-card-actions > li {
  float: right;
}
.syt-antd-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.syt-antd-card-actions > li > span:hover {
  color: #0071CD;
  transition: color 0.3s;
}
.syt-antd-card-actions > li > span a:not(.syt-antd-btn),
.syt-antd-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: #696F88;
  line-height: 22px;
  transition: color 0.3s;
}
.syt-antd-card-actions > li > span a:not(.syt-antd-btn):hover,
.syt-antd-card-actions > li > span > .anticon:hover {
  color: #0071CD;
}
.syt-antd-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.syt-antd-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
.syt-antd-card-type-inner .syt-antd-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.syt-antd-card-type-inner .syt-antd-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.syt-antd-card-type-inner .syt-antd-card-body {
  padding: 16px 24px;
}
.syt-antd-card-type-inner .syt-antd-card-extra {
  padding: 13.5px 0;
}
.syt-antd-card-meta {
  margin: -4px 0;
}
.syt-antd-card-meta::before {
  display: table;
  content: '';
}
.syt-antd-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
.syt-antd-card-rtl .syt-antd-card-meta-avatar {
  float: right;
  padding-right: 0;
  padding-left: 16px;
}
.syt-antd-card-meta-detail {
  overflow: hidden;
}
.syt-antd-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.syt-antd-card-meta-title {
  overflow: hidden;
  color: #14151C;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-card-meta-description {
  color: #696F88;
}
.syt-antd-card-loading {
  overflow: hidden;
}
.syt-antd-card-loading .syt-antd-card-body {
  user-select: none;
}
.syt-antd-card-loading-content p {
  margin: 0;
}
.syt-antd-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 4px;
  animation: card-loading 1.4s ease infinite;
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.syt-antd-card-small > .syt-antd-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.syt-antd-card-small > .syt-antd-card-head > .syt-antd-card-head-wrapper > .syt-antd-card-head-title {
  padding: 8px 0;
}
.syt-antd-card-small > .syt-antd-card-head > .syt-antd-card-head-wrapper > .syt-antd-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.syt-antd-card-small > .syt-antd-card-body {
  padding: 12px;
}
.syt-antd-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #0092e4;
  border-bottom: 0;
  border-radius: 4px;
}
.syt-antd-collapse > .syt-antd-collapse-item {
  border-bottom: 1px solid #0092e4;
}
.syt-antd-collapse > .syt-antd-collapse-item:last-child,
.syt-antd-collapse > .syt-antd-collapse-item:last-child > .syt-antd-collapse-header {
  border-radius: 0 0 4px 4px;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: #14151C;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header::before {
  display: table;
  content: '';
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-block;
  font-size: 12px;
  transform: translateY(-50%);
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow > * {
  line-height: 1;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow svg {
  display: inline-block;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow::before {
  display: none;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow .syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow-icon {
  display: block;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow svg {
  transition: transform 0.24s;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-extra {
  float: right;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header:focus {
  outline: none;
}
.syt-antd-collapse > .syt-antd-collapse-item.syt-antd-collapse-no-arrow > .syt-antd-collapse-header {
  padding-left: 12px;
}
.syt-antd-collapse-icon-position-right > .syt-antd-collapse-item > .syt-antd-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.syt-antd-collapse-icon-position-right > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow {
  right: 16px;
  left: auto;
}
.syt-antd-collapse-anim-active {
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.syt-antd-collapse-content {
  overflow: hidden;
  color: #14151C;
  background-color: #fff;
  border-top: 1px solid #0092e4;
}
.syt-antd-collapse-content > .syt-antd-collapse-content-box {
  padding: 16px;
}
.syt-antd-collapse-content-inactive {
  display: none;
}
.syt-antd-collapse-item:last-child > .syt-antd-collapse-content {
  border-radius: 0 0 4px 4px;
}
.syt-antd-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.syt-antd-collapse-borderless > .syt-antd-collapse-item {
  border-bottom: 1px solid #0092e4;
}
.syt-antd-collapse-borderless > .syt-antd-collapse-item:last-child,
.syt-antd-collapse-borderless > .syt-antd-collapse-item:last-child .syt-antd-collapse-header {
  border-radius: 0;
}
.syt-antd-collapse-borderless > .syt-antd-collapse-item > .syt-antd-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.syt-antd-collapse-borderless > .syt-antd-collapse-item > .syt-antd-collapse-content > .syt-antd-collapse-content-box {
  padding-top: 4px;
}
.syt-antd-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.syt-antd-collapse-ghost > .syt-antd-collapse-item {
  border-bottom: 0;
}
.syt-antd-collapse-ghost > .syt-antd-collapse-item > .syt-antd-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.syt-antd-collapse-ghost > .syt-antd-collapse-item > .syt-antd-collapse-content > .syt-antd-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.syt-antd-collapse .syt-antd-collapse-item-disabled > .syt-antd-collapse-header,
.syt-antd-collapse .syt-antd-collapse-item-disabled > .syt-antd-collapse-header > .arrow {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-collapse-rtl {
  direction: rtl;
}
.syt-antd-collapse-rtl .syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.syt-antd-collapse-rtl.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow svg {
  transform: rotate(180deg);
}
.syt-antd-collapse-rtl.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-extra {
  float: left;
}
.syt-antd-collapse-rtl.syt-antd-collapse > .syt-antd-collapse-item.syt-antd-collapse-no-arrow > .syt-antd-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}
.syt-antd-collapse {
  border: 1px solid #d9d9d9;
}
.syt-antd-collapse .syt-antd-collapse-item {
  border: 1px solid #d9d9d9;
}
.syt-antd-collapse-content {
  border-top: 1px solid #d9d9d9;
}
.syt-antd-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
}
.syt-antd-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.syt-antd-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.syt-antd-carousel .slick-list:focus {
  outline: none;
}
.syt-antd-carousel .slick-list.dragging {
  cursor: pointer;
}
.syt-antd-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.syt-antd-carousel .slick-list .slick-slide input.syt-antd-radio-input,
.syt-antd-carousel .slick-list .slick-slide input.syt-antd-checkbox-input {
  visibility: hidden;
}
.syt-antd-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.syt-antd-carousel .slick-list .slick-slide.slick-active input.syt-antd-radio-input,
.syt-antd-carousel .slick-list .slick-slide.slick-active input.syt-antd-checkbox-input {
  visibility: visible;
}
.syt-antd-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.syt-antd-carousel .slick-slider .slick-track,
.syt-antd-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.syt-antd-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.syt-antd-carousel .slick-track::before,
.syt-antd-carousel .slick-track::after {
  display: table;
  content: '';
}
.syt-antd-carousel .slick-track::after {
  clear: both;
}
.slick-loading .syt-antd-carousel .slick-track {
  visibility: hidden;
}
.syt-antd-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.syt-antd-carousel .slick-slide img {
  display: block;
}
.syt-antd-carousel .slick-slide.slick-loading img {
  display: none;
}
.syt-antd-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.syt-antd-carousel .slick-initialized .slick-slide {
  display: block;
}
.syt-antd-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.syt-antd-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.syt-antd-carousel .slick-arrow.slick-hidden {
  display: none;
}
.syt-antd-carousel .slick-prev,
.syt-antd-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.syt-antd-carousel .slick-prev:hover,
.syt-antd-carousel .slick-next:hover,
.syt-antd-carousel .slick-prev:focus,
.syt-antd-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.syt-antd-carousel .slick-prev:hover::before,
.syt-antd-carousel .slick-next:hover::before,
.syt-antd-carousel .slick-prev:focus::before,
.syt-antd-carousel .slick-next:focus::before {
  opacity: 1;
}
.syt-antd-carousel .slick-prev.slick-disabled::before,
.syt-antd-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.syt-antd-carousel .slick-prev {
  left: -25px;
}
.syt-antd-carousel .slick-prev::before {
  content: '←';
}
.syt-antd-carousel .slick-next {
  right: -25px;
}
.syt-antd-carousel .slick-next::before {
  content: '→';
}
.syt-antd-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.syt-antd-carousel .slick-dots-bottom {
  bottom: 12px;
}
.syt-antd-carousel .slick-dots-top {
  top: 12px;
}
.syt-antd-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 2px;
  margin-right: 3px;
  margin-left: 3px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.syt-antd-carousel .slick-dots li button {
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.syt-antd-carousel .slick-dots li button:hover,
.syt-antd-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.syt-antd-carousel .slick-dots li.slick-active {
  width: 24px;
}
.syt-antd-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.syt-antd-carousel .slick-dots li.slick-active:hover,
.syt-antd-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.syt-antd-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.syt-antd-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.syt-antd-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.syt-antd-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 2px;
  vertical-align: baseline;
}
.syt-antd-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.syt-antd-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.syt-antd-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.syt-antd-carousel-rtl {
  direction: rtl;
}
.syt-antd-carousel-rtl .syt-antd-carousel .slick-track {
  right: 0;
  left: auto;
}
.syt-antd-carousel-rtl .syt-antd-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.syt-antd-carousel-rtl .syt-antd-carousel .slick-prev::before {
  content: '→';
}
.syt-antd-carousel-rtl .syt-antd-carousel .slick-next {
  right: auto;
  left: -25px;
}
.syt-antd-carousel-rtl .syt-antd-carousel .slick-next::before {
  content: '←';
}
.syt-antd-carousel-rtl.syt-antd-carousel .slick-dots {
  flex-direction: row-reverse;
}
.syt-antd-carousel-rtl.syt-antd-carousel-vertical .slick-dots {
  flex-direction: column;
}
.syt-antd-cascader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
}
.syt-antd-cascader-input.syt-antd-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer;
}
.syt-antd-cascader-picker-show-search .syt-antd-cascader-input.syt-antd-input {
  position: relative;
}
.syt-antd-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.syt-antd-cascader-picker-with-value .syt-antd-cascader-picker-label {
  color: transparent;
}
.syt-antd-cascader-picker-disabled {
  color: #c1c5c8;
  background: #f5f5f5;
  cursor: not-allowed;
}
.syt-antd-cascader-picker-disabled .syt-antd-cascader-input {
  cursor: not-allowed;
}
.syt-antd-cascader-picker:focus .syt-antd-cascader-input {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-cascader-picker:focus .syt-antd-cascader-input {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-cascader-picker-borderless .syt-antd-cascader-input {
  border-color: transparent !important;
  box-shadow: none !important;
}
.syt-antd-cascader-picker-show-search.syt-antd-cascader-picker-focused {
  color: #c1c5c8;
}
.syt-antd-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #c1c5c8;
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.syt-antd-cascader-picker-clear:hover {
  color: #696F88;
}
.syt-antd-cascader-picker:hover .syt-antd-cascader-picker-clear {
  opacity: 1;
}
.syt-antd-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #c1c5c8;
  font-size: 12px;
  line-height: 12px;
}
.syt-antd-cascader-picker-label:hover + .syt-antd-cascader-input:not(.syt-antd-cascader-picker-disabled .syt-antd-cascader-picker-label:hover + .syt-antd-cascader-input) {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-rtl .syt-antd-cascader-picker-label:hover + .syt-antd-cascader-input:not(.syt-antd-cascader-picker-disabled .syt-antd-cascader-picker-label:hover + .syt-antd-cascader-input) {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-cascader-picker-small .syt-antd-cascader-picker-clear,
.syt-antd-cascader-picker-small .syt-antd-cascader-picker-arrow {
  right: 8px;
}
.syt-antd-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
}
.syt-antd-cascader-menus ul,
.syt-antd-cascader-menus ol {
  margin: 0;
  list-style: none;
}
.syt-antd-cascader-menus-empty,
.syt-antd-cascader-menus-hidden {
  display: none;
}
.syt-antd-cascader-menus.slide-up-enter.slide-up-enter-active.syt-antd-cascader-menus-placement-bottomLeft,
.syt-antd-cascader-menus.slide-up-appear.slide-up-appear-active.syt-antd-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.syt-antd-cascader-menus.slide-up-enter.slide-up-enter-active.syt-antd-cascader-menus-placement-topLeft,
.syt-antd-cascader-menus.slide-up-appear.slide-up-appear-active.syt-antd-cascader-menus-placement-topLeft {
  animation-name: antSlideDownIn;
}
.syt-antd-cascader-menus.slide-up-leave.slide-up-leave-active.syt-antd-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.syt-antd-cascader-menus.slide-up-leave.slide-up-leave-active.syt-antd-cascader-menus-placement-topLeft {
  animation-name: antSlideDownOut;
}
.syt-antd-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.syt-antd-cascader-menu:first-child {
  border-radius: 4px 0 0 4px;
}
.syt-antd-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 4px 4px 0;
}
.syt-antd-cascader-menu:only-child {
  border-radius: 4px;
}
.syt-antd-cascader-menu-item {
  padding: 5px 12px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-cascader-menu-item:hover {
  background: #f5f5f5;
}
.syt-antd-cascader-menu-item-disabled {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-cascader-menu-item-disabled:hover {
  background: transparent;
}
.syt-antd-cascader-menu-empty .syt-antd-cascader-menu-item {
  color: #c1c5c8;
  cursor: default;
  pointer-events: none;
}
.syt-antd-cascader-menu-item-active:not(.syt-antd-cascader-menu-item-disabled),
.syt-antd-cascader-menu-item-active:not(.syt-antd-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #e6f8ff;
}
.syt-antd-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
.syt-antd-cascader-menu-item-expand .syt-antd-cascader-menu-item-expand-icon,
.syt-antd-cascader-menu-item-loading-icon {
  position: absolute;
  right: 12px;
  color: #696F88;
  font-size: 10px;
}
.syt-antd-cascader-menu-item-disabled.syt-antd-cascader-menu-item-expand .syt-antd-cascader-menu-item-expand-icon,
.syt-antd-cascader-menu-item-disabled.syt-antd-cascader-menu-item-loading-icon {
  color: #c1c5c8;
}
.syt-antd-cascader-menu-item .syt-antd-cascader-menu-item-keyword {
  color: #ff4d4f;
}
.syt-antd-cascader-picker-rtl .syt-antd-cascader-input.syt-antd-input {
  padding-right: 11px;
  padding-left: 24px;
  text-align: right;
}
.syt-antd-cascader-picker-rtl {
  direction: rtl;
}
.syt-antd-cascader-picker-rtl .syt-antd-cascader-picker-label {
  padding: 0 12px 0 20px;
  text-align: right;
}
.syt-antd-cascader-picker-rtl .syt-antd-cascader-picker-clear {
  right: auto;
  left: 12px;
}
.syt-antd-cascader-picker-rtl .syt-antd-cascader-picker-arrow {
  right: auto;
  left: 12px;
}
.syt-antd-cascader-picker-rtl.syt-antd-cascader-picker-small .syt-antd-cascader-picker-clear,
.syt-antd-cascader-picker-rtl.syt-antd-cascader-picker-small .syt-antd-cascader-picker-arrow {
  right: auto;
  left: 8px;
}
.syt-antd-cascader-menu-rtl .syt-antd-cascader-menu {
  direction: rtl;
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.syt-antd-cascader-menu-rtl .syt-antd-cascader-menu:first-child {
  border-radius: 0 4px 4px 0;
}
.syt-antd-cascader-menu-rtl .syt-antd-cascader-menu:last-child {
  margin-right: 0;
  margin-left: -1px;
  border-left-color: transparent;
  border-radius: 4px 0 0 4px;
}
.syt-antd-cascader-menu-rtl .syt-antd-cascader-menu:only-child {
  border-radius: 4px;
}
.syt-antd-cascader-menu-rtl .syt-antd-cascader-menu-item-expand {
  padding-right: 12px;
  padding-left: 24px;
}
.syt-antd-cascader-menu-rtl .syt-antd-cascader-menu-item-expand .syt-antd-cascader-menu-item-expand-icon,
.syt-antd-cascader-menu-rtl .syt-antd-cascader-menu-item-loading-icon {
  right: auto;
  left: 12px;
}
.syt-antd-cascader-menu-rtl .syt-antd-cascader-menu-item-loading-icon {
  transform: scaleY(-1);
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.syt-antd-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.syt-antd-checkbox-wrapper:hover .syt-antd-checkbox-inner,
.syt-antd-checkbox:hover .syt-antd-checkbox-inner,
.syt-antd-checkbox-input:focus + .syt-antd-checkbox-inner {
  border-color: #0071CD;
}
.syt-antd-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #0071CD;
  border-radius: 4px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.syt-antd-checkbox:hover::after,
.syt-antd-checkbox-wrapper:hover .syt-antd-checkbox::after {
  visibility: visible;
}
.syt-antd-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #0092e4;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}
.syt-antd-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.syt-antd-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.syt-antd-checkbox-checked .syt-antd-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.syt-antd-checkbox-checked .syt-antd-checkbox-inner {
  background-color: #0071CD;
  border-color: #0071CD;
}
.syt-antd-checkbox-disabled {
  cursor: not-allowed;
}
.syt-antd-checkbox-disabled.syt-antd-checkbox-checked .syt-antd-checkbox-inner::after {
  border-color: #c1c5c8;
  animation-name: none;
}
.syt-antd-checkbox-disabled .syt-antd-checkbox-input {
  cursor: not-allowed;
}
.syt-antd-checkbox-disabled .syt-antd-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #0092e4 !important;
}
.syt-antd-checkbox-disabled .syt-antd-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.syt-antd-checkbox-disabled + span {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-checkbox-disabled:hover::after,
.syt-antd-checkbox-wrapper:hover .syt-antd-checkbox-disabled::after {
  visibility: hidden;
}
.syt-antd-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.syt-antd-checkbox-wrapper.syt-antd-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.syt-antd-checkbox-wrapper + .syt-antd-checkbox-wrapper {
  margin-left: 8px;
}
.syt-antd-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.syt-antd-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.syt-antd-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.syt-antd-checkbox-group-item:last-child {
  margin-right: 0;
}
.syt-antd-checkbox-group-item + .syt-antd-checkbox-group-item {
  margin-left: 0;
}
.syt-antd-checkbox-indeterminate .syt-antd-checkbox-inner {
  background-color: #fff;
  border-color: #0092e4;
}
.syt-antd-checkbox-indeterminate .syt-antd-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #0071CD;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.syt-antd-checkbox-indeterminate.syt-antd-checkbox-disabled .syt-antd-checkbox-inner::after {
  background-color: #c1c5c8;
  border-color: #c1c5c8;
}
.syt-antd-checkbox-rtl {
  direction: rtl;
}
.syt-antd-checkbox-group-rtl .syt-antd-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-checkbox-group-rtl .syt-antd-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.syt-antd-checkbox-group-rtl .syt-antd-checkbox-group-item + .syt-antd-checkbox-group-item {
  margin-left: 8px;
}
input.syt-antd-checkbox-input {
  margin: 0 !important;
  color: #14151C;
}
.syt-antd-checkbox-inner {
  background-color: #FFFFFF;
  border: 1px solid #C2C7D0;
  box-sizing: border-box;
  border-radius: 2px;
}
.syt-antd-checkbox-checked .syt-antd-checkbox-inner {
  border-color: #0092E4 !important;
  background-color: #0092E4 !important;
}
.syt-antd-checkbox:hover .syt-antd-checkbox-inner,
.syt-antd-checkbox-wrapper:hover .syt-antd-checkbox-inner,
.syt-antd-checkbox-input:focus + .syt-antd-checkbox-inner {
  border-color: #0092E4 !important;
}
.syt-antd-checkbox-input:focus + .syt-antd-checkbox-inner {
  box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25) !important;
}
.syt-antd-checkbox:active .syt-antd-checkbox-inner,
.syt-antd-checkbox-wrapper:active .syt-antd-checkbox-inner {
  border-color: #0071CD !important;
  background-color: #0071CD !important;
}
.syt-antd-checkbox-checked::after {
  border: none;
}
.syt-antd-checkbox-inner::after {
  top: 42%;
  left: 12%;
}
.syt-antd-checkbox-indeterminate .syt-antd-checkbox-inner::after {
  background-color: #0092E4 !important;
}
.syt-antd-checkbox-indeterminate.syt-antd-checkbox-disabled .syt-antd-checkbox-inner::after {
  background-color: #A3A9B9 !important;
}
.syt-antd-checkbox-disabled .syt-antd-checkbox-inner::after {
  border-color: #A3A9B9 !important;
}
.syt-antd-checkbox-input:disabled + .syt-antd-checkbox-inner {
  background-color: #F3F4F6 !important;
  border-color: #C2C7D0 !important;
}
.syt-antd-checkbox-disabled + span {
  color: #A3A9B9;
  cursor: not-allowed;
}
.syt-antd-row {
  display: flex;
  flex-flow: row wrap;
}
.syt-antd-row::before,
.syt-antd-row::after {
  display: flex;
}
.syt-antd-row-start {
  justify-content: flex-start;
}
.syt-antd-row-center {
  justify-content: center;
}
.syt-antd-row-end {
  justify-content: flex-end;
}
.syt-antd-row-space-between {
  justify-content: space-between;
}
.syt-antd-row-space-around {
  justify-content: space-around;
}
.syt-antd-row-top {
  align-items: flex-start;
}
.syt-antd-row-middle {
  align-items: center;
}
.syt-antd-row-bottom {
  align-items: flex-end;
}
.syt-antd-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.syt-antd-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.syt-antd-col-push-24 {
  left: 100%;
}
.syt-antd-col-pull-24 {
  right: 100%;
}
.syt-antd-col-offset-24 {
  margin-left: 100%;
}
.syt-antd-col-order-24 {
  order: 24;
}
.syt-antd-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.syt-antd-col-push-23 {
  left: 95.83333333%;
}
.syt-antd-col-pull-23 {
  right: 95.83333333%;
}
.syt-antd-col-offset-23 {
  margin-left: 95.83333333%;
}
.syt-antd-col-order-23 {
  order: 23;
}
.syt-antd-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.syt-antd-col-push-22 {
  left: 91.66666667%;
}
.syt-antd-col-pull-22 {
  right: 91.66666667%;
}
.syt-antd-col-offset-22 {
  margin-left: 91.66666667%;
}
.syt-antd-col-order-22 {
  order: 22;
}
.syt-antd-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.syt-antd-col-push-21 {
  left: 87.5%;
}
.syt-antd-col-pull-21 {
  right: 87.5%;
}
.syt-antd-col-offset-21 {
  margin-left: 87.5%;
}
.syt-antd-col-order-21 {
  order: 21;
}
.syt-antd-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.syt-antd-col-push-20 {
  left: 83.33333333%;
}
.syt-antd-col-pull-20 {
  right: 83.33333333%;
}
.syt-antd-col-offset-20 {
  margin-left: 83.33333333%;
}
.syt-antd-col-order-20 {
  order: 20;
}
.syt-antd-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.syt-antd-col-push-19 {
  left: 79.16666667%;
}
.syt-antd-col-pull-19 {
  right: 79.16666667%;
}
.syt-antd-col-offset-19 {
  margin-left: 79.16666667%;
}
.syt-antd-col-order-19 {
  order: 19;
}
.syt-antd-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.syt-antd-col-push-18 {
  left: 75%;
}
.syt-antd-col-pull-18 {
  right: 75%;
}
.syt-antd-col-offset-18 {
  margin-left: 75%;
}
.syt-antd-col-order-18 {
  order: 18;
}
.syt-antd-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.syt-antd-col-push-17 {
  left: 70.83333333%;
}
.syt-antd-col-pull-17 {
  right: 70.83333333%;
}
.syt-antd-col-offset-17 {
  margin-left: 70.83333333%;
}
.syt-antd-col-order-17 {
  order: 17;
}
.syt-antd-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.syt-antd-col-push-16 {
  left: 66.66666667%;
}
.syt-antd-col-pull-16 {
  right: 66.66666667%;
}
.syt-antd-col-offset-16 {
  margin-left: 66.66666667%;
}
.syt-antd-col-order-16 {
  order: 16;
}
.syt-antd-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.syt-antd-col-push-15 {
  left: 62.5%;
}
.syt-antd-col-pull-15 {
  right: 62.5%;
}
.syt-antd-col-offset-15 {
  margin-left: 62.5%;
}
.syt-antd-col-order-15 {
  order: 15;
}
.syt-antd-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.syt-antd-col-push-14 {
  left: 58.33333333%;
}
.syt-antd-col-pull-14 {
  right: 58.33333333%;
}
.syt-antd-col-offset-14 {
  margin-left: 58.33333333%;
}
.syt-antd-col-order-14 {
  order: 14;
}
.syt-antd-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.syt-antd-col-push-13 {
  left: 54.16666667%;
}
.syt-antd-col-pull-13 {
  right: 54.16666667%;
}
.syt-antd-col-offset-13 {
  margin-left: 54.16666667%;
}
.syt-antd-col-order-13 {
  order: 13;
}
.syt-antd-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.syt-antd-col-push-12 {
  left: 50%;
}
.syt-antd-col-pull-12 {
  right: 50%;
}
.syt-antd-col-offset-12 {
  margin-left: 50%;
}
.syt-antd-col-order-12 {
  order: 12;
}
.syt-antd-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.syt-antd-col-push-11 {
  left: 45.83333333%;
}
.syt-antd-col-pull-11 {
  right: 45.83333333%;
}
.syt-antd-col-offset-11 {
  margin-left: 45.83333333%;
}
.syt-antd-col-order-11 {
  order: 11;
}
.syt-antd-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.syt-antd-col-push-10 {
  left: 41.66666667%;
}
.syt-antd-col-pull-10 {
  right: 41.66666667%;
}
.syt-antd-col-offset-10 {
  margin-left: 41.66666667%;
}
.syt-antd-col-order-10 {
  order: 10;
}
.syt-antd-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.syt-antd-col-push-9 {
  left: 37.5%;
}
.syt-antd-col-pull-9 {
  right: 37.5%;
}
.syt-antd-col-offset-9 {
  margin-left: 37.5%;
}
.syt-antd-col-order-9 {
  order: 9;
}
.syt-antd-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.syt-antd-col-push-8 {
  left: 33.33333333%;
}
.syt-antd-col-pull-8 {
  right: 33.33333333%;
}
.syt-antd-col-offset-8 {
  margin-left: 33.33333333%;
}
.syt-antd-col-order-8 {
  order: 8;
}
.syt-antd-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.syt-antd-col-push-7 {
  left: 29.16666667%;
}
.syt-antd-col-pull-7 {
  right: 29.16666667%;
}
.syt-antd-col-offset-7 {
  margin-left: 29.16666667%;
}
.syt-antd-col-order-7 {
  order: 7;
}
.syt-antd-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.syt-antd-col-push-6 {
  left: 25%;
}
.syt-antd-col-pull-6 {
  right: 25%;
}
.syt-antd-col-offset-6 {
  margin-left: 25%;
}
.syt-antd-col-order-6 {
  order: 6;
}
.syt-antd-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.syt-antd-col-push-5 {
  left: 20.83333333%;
}
.syt-antd-col-pull-5 {
  right: 20.83333333%;
}
.syt-antd-col-offset-5 {
  margin-left: 20.83333333%;
}
.syt-antd-col-order-5 {
  order: 5;
}
.syt-antd-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.syt-antd-col-push-4 {
  left: 16.66666667%;
}
.syt-antd-col-pull-4 {
  right: 16.66666667%;
}
.syt-antd-col-offset-4 {
  margin-left: 16.66666667%;
}
.syt-antd-col-order-4 {
  order: 4;
}
.syt-antd-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.syt-antd-col-push-3 {
  left: 12.5%;
}
.syt-antd-col-pull-3 {
  right: 12.5%;
}
.syt-antd-col-offset-3 {
  margin-left: 12.5%;
}
.syt-antd-col-order-3 {
  order: 3;
}
.syt-antd-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.syt-antd-col-push-2 {
  left: 8.33333333%;
}
.syt-antd-col-pull-2 {
  right: 8.33333333%;
}
.syt-antd-col-offset-2 {
  margin-left: 8.33333333%;
}
.syt-antd-col-order-2 {
  order: 2;
}
.syt-antd-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.syt-antd-col-push-1 {
  left: 4.16666667%;
}
.syt-antd-col-pull-1 {
  right: 4.16666667%;
}
.syt-antd-col-offset-1 {
  margin-left: 4.16666667%;
}
.syt-antd-col-order-1 {
  order: 1;
}
.syt-antd-col-0 {
  display: none;
}
.syt-antd-col-push-0 {
  left: auto;
}
.syt-antd-col-pull-0 {
  right: auto;
}
.syt-antd-col-push-0 {
  left: auto;
}
.syt-antd-col-pull-0 {
  right: auto;
}
.syt-antd-col-offset-0 {
  margin-left: 0;
}
.syt-antd-col-order-0 {
  order: 0;
}
.syt-antd-col-push-0.syt-antd-col-rtl {
  right: auto;
}
.syt-antd-col-pull-0.syt-antd-col-rtl {
  left: auto;
}
.syt-antd-col-push-0.syt-antd-col-rtl {
  right: auto;
}
.syt-antd-col-pull-0.syt-antd-col-rtl {
  left: auto;
}
.syt-antd-col-offset-0.syt-antd-col-rtl {
  margin-right: 0;
}
.syt-antd-col-push-1.syt-antd-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.syt-antd-col-pull-1.syt-antd-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.syt-antd-col-offset-1.syt-antd-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.syt-antd-col-push-2.syt-antd-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.syt-antd-col-pull-2.syt-antd-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.syt-antd-col-offset-2.syt-antd-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.syt-antd-col-push-3.syt-antd-col-rtl {
  right: 12.5%;
  left: auto;
}
.syt-antd-col-pull-3.syt-antd-col-rtl {
  right: auto;
  left: 12.5%;
}
.syt-antd-col-offset-3.syt-antd-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.syt-antd-col-push-4.syt-antd-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.syt-antd-col-pull-4.syt-antd-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.syt-antd-col-offset-4.syt-antd-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.syt-antd-col-push-5.syt-antd-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.syt-antd-col-pull-5.syt-antd-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.syt-antd-col-offset-5.syt-antd-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.syt-antd-col-push-6.syt-antd-col-rtl {
  right: 25%;
  left: auto;
}
.syt-antd-col-pull-6.syt-antd-col-rtl {
  right: auto;
  left: 25%;
}
.syt-antd-col-offset-6.syt-antd-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.syt-antd-col-push-7.syt-antd-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.syt-antd-col-pull-7.syt-antd-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.syt-antd-col-offset-7.syt-antd-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.syt-antd-col-push-8.syt-antd-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.syt-antd-col-pull-8.syt-antd-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.syt-antd-col-offset-8.syt-antd-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.syt-antd-col-push-9.syt-antd-col-rtl {
  right: 37.5%;
  left: auto;
}
.syt-antd-col-pull-9.syt-antd-col-rtl {
  right: auto;
  left: 37.5%;
}
.syt-antd-col-offset-9.syt-antd-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.syt-antd-col-push-10.syt-antd-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.syt-antd-col-pull-10.syt-antd-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.syt-antd-col-offset-10.syt-antd-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.syt-antd-col-push-11.syt-antd-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.syt-antd-col-pull-11.syt-antd-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.syt-antd-col-offset-11.syt-antd-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.syt-antd-col-push-12.syt-antd-col-rtl {
  right: 50%;
  left: auto;
}
.syt-antd-col-pull-12.syt-antd-col-rtl {
  right: auto;
  left: 50%;
}
.syt-antd-col-offset-12.syt-antd-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.syt-antd-col-push-13.syt-antd-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.syt-antd-col-pull-13.syt-antd-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.syt-antd-col-offset-13.syt-antd-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.syt-antd-col-push-14.syt-antd-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.syt-antd-col-pull-14.syt-antd-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.syt-antd-col-offset-14.syt-antd-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.syt-antd-col-push-15.syt-antd-col-rtl {
  right: 62.5%;
  left: auto;
}
.syt-antd-col-pull-15.syt-antd-col-rtl {
  right: auto;
  left: 62.5%;
}
.syt-antd-col-offset-15.syt-antd-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.syt-antd-col-push-16.syt-antd-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.syt-antd-col-pull-16.syt-antd-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.syt-antd-col-offset-16.syt-antd-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.syt-antd-col-push-17.syt-antd-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.syt-antd-col-pull-17.syt-antd-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.syt-antd-col-offset-17.syt-antd-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.syt-antd-col-push-18.syt-antd-col-rtl {
  right: 75%;
  left: auto;
}
.syt-antd-col-pull-18.syt-antd-col-rtl {
  right: auto;
  left: 75%;
}
.syt-antd-col-offset-18.syt-antd-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.syt-antd-col-push-19.syt-antd-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.syt-antd-col-pull-19.syt-antd-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.syt-antd-col-offset-19.syt-antd-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.syt-antd-col-push-20.syt-antd-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.syt-antd-col-pull-20.syt-antd-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.syt-antd-col-offset-20.syt-antd-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.syt-antd-col-push-21.syt-antd-col-rtl {
  right: 87.5%;
  left: auto;
}
.syt-antd-col-pull-21.syt-antd-col-rtl {
  right: auto;
  left: 87.5%;
}
.syt-antd-col-offset-21.syt-antd-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.syt-antd-col-push-22.syt-antd-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.syt-antd-col-pull-22.syt-antd-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.syt-antd-col-offset-22.syt-antd-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.syt-antd-col-push-23.syt-antd-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.syt-antd-col-pull-23.syt-antd-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.syt-antd-col-offset-23.syt-antd-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.syt-antd-col-push-24.syt-antd-col-rtl {
  right: 100%;
  left: auto;
}
.syt-antd-col-pull-24.syt-antd-col-rtl {
  right: auto;
  left: 100%;
}
.syt-antd-col-offset-24.syt-antd-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.syt-antd-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.syt-antd-col-xs-push-24 {
  left: 100%;
}
.syt-antd-col-xs-pull-24 {
  right: 100%;
}
.syt-antd-col-xs-offset-24 {
  margin-left: 100%;
}
.syt-antd-col-xs-order-24 {
  order: 24;
}
.syt-antd-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.syt-antd-col-xs-push-23 {
  left: 95.83333333%;
}
.syt-antd-col-xs-pull-23 {
  right: 95.83333333%;
}
.syt-antd-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.syt-antd-col-xs-order-23 {
  order: 23;
}
.syt-antd-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.syt-antd-col-xs-push-22 {
  left: 91.66666667%;
}
.syt-antd-col-xs-pull-22 {
  right: 91.66666667%;
}
.syt-antd-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.syt-antd-col-xs-order-22 {
  order: 22;
}
.syt-antd-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.syt-antd-col-xs-push-21 {
  left: 87.5%;
}
.syt-antd-col-xs-pull-21 {
  right: 87.5%;
}
.syt-antd-col-xs-offset-21 {
  margin-left: 87.5%;
}
.syt-antd-col-xs-order-21 {
  order: 21;
}
.syt-antd-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.syt-antd-col-xs-push-20 {
  left: 83.33333333%;
}
.syt-antd-col-xs-pull-20 {
  right: 83.33333333%;
}
.syt-antd-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.syt-antd-col-xs-order-20 {
  order: 20;
}
.syt-antd-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.syt-antd-col-xs-push-19 {
  left: 79.16666667%;
}
.syt-antd-col-xs-pull-19 {
  right: 79.16666667%;
}
.syt-antd-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.syt-antd-col-xs-order-19 {
  order: 19;
}
.syt-antd-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.syt-antd-col-xs-push-18 {
  left: 75%;
}
.syt-antd-col-xs-pull-18 {
  right: 75%;
}
.syt-antd-col-xs-offset-18 {
  margin-left: 75%;
}
.syt-antd-col-xs-order-18 {
  order: 18;
}
.syt-antd-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.syt-antd-col-xs-push-17 {
  left: 70.83333333%;
}
.syt-antd-col-xs-pull-17 {
  right: 70.83333333%;
}
.syt-antd-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.syt-antd-col-xs-order-17 {
  order: 17;
}
.syt-antd-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.syt-antd-col-xs-push-16 {
  left: 66.66666667%;
}
.syt-antd-col-xs-pull-16 {
  right: 66.66666667%;
}
.syt-antd-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.syt-antd-col-xs-order-16 {
  order: 16;
}
.syt-antd-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.syt-antd-col-xs-push-15 {
  left: 62.5%;
}
.syt-antd-col-xs-pull-15 {
  right: 62.5%;
}
.syt-antd-col-xs-offset-15 {
  margin-left: 62.5%;
}
.syt-antd-col-xs-order-15 {
  order: 15;
}
.syt-antd-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.syt-antd-col-xs-push-14 {
  left: 58.33333333%;
}
.syt-antd-col-xs-pull-14 {
  right: 58.33333333%;
}
.syt-antd-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.syt-antd-col-xs-order-14 {
  order: 14;
}
.syt-antd-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.syt-antd-col-xs-push-13 {
  left: 54.16666667%;
}
.syt-antd-col-xs-pull-13 {
  right: 54.16666667%;
}
.syt-antd-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.syt-antd-col-xs-order-13 {
  order: 13;
}
.syt-antd-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.syt-antd-col-xs-push-12 {
  left: 50%;
}
.syt-antd-col-xs-pull-12 {
  right: 50%;
}
.syt-antd-col-xs-offset-12 {
  margin-left: 50%;
}
.syt-antd-col-xs-order-12 {
  order: 12;
}
.syt-antd-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.syt-antd-col-xs-push-11 {
  left: 45.83333333%;
}
.syt-antd-col-xs-pull-11 {
  right: 45.83333333%;
}
.syt-antd-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.syt-antd-col-xs-order-11 {
  order: 11;
}
.syt-antd-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.syt-antd-col-xs-push-10 {
  left: 41.66666667%;
}
.syt-antd-col-xs-pull-10 {
  right: 41.66666667%;
}
.syt-antd-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.syt-antd-col-xs-order-10 {
  order: 10;
}
.syt-antd-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.syt-antd-col-xs-push-9 {
  left: 37.5%;
}
.syt-antd-col-xs-pull-9 {
  right: 37.5%;
}
.syt-antd-col-xs-offset-9 {
  margin-left: 37.5%;
}
.syt-antd-col-xs-order-9 {
  order: 9;
}
.syt-antd-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.syt-antd-col-xs-push-8 {
  left: 33.33333333%;
}
.syt-antd-col-xs-pull-8 {
  right: 33.33333333%;
}
.syt-antd-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.syt-antd-col-xs-order-8 {
  order: 8;
}
.syt-antd-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.syt-antd-col-xs-push-7 {
  left: 29.16666667%;
}
.syt-antd-col-xs-pull-7 {
  right: 29.16666667%;
}
.syt-antd-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.syt-antd-col-xs-order-7 {
  order: 7;
}
.syt-antd-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.syt-antd-col-xs-push-6 {
  left: 25%;
}
.syt-antd-col-xs-pull-6 {
  right: 25%;
}
.syt-antd-col-xs-offset-6 {
  margin-left: 25%;
}
.syt-antd-col-xs-order-6 {
  order: 6;
}
.syt-antd-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.syt-antd-col-xs-push-5 {
  left: 20.83333333%;
}
.syt-antd-col-xs-pull-5 {
  right: 20.83333333%;
}
.syt-antd-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.syt-antd-col-xs-order-5 {
  order: 5;
}
.syt-antd-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.syt-antd-col-xs-push-4 {
  left: 16.66666667%;
}
.syt-antd-col-xs-pull-4 {
  right: 16.66666667%;
}
.syt-antd-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.syt-antd-col-xs-order-4 {
  order: 4;
}
.syt-antd-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.syt-antd-col-xs-push-3 {
  left: 12.5%;
}
.syt-antd-col-xs-pull-3 {
  right: 12.5%;
}
.syt-antd-col-xs-offset-3 {
  margin-left: 12.5%;
}
.syt-antd-col-xs-order-3 {
  order: 3;
}
.syt-antd-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.syt-antd-col-xs-push-2 {
  left: 8.33333333%;
}
.syt-antd-col-xs-pull-2 {
  right: 8.33333333%;
}
.syt-antd-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.syt-antd-col-xs-order-2 {
  order: 2;
}
.syt-antd-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.syt-antd-col-xs-push-1 {
  left: 4.16666667%;
}
.syt-antd-col-xs-pull-1 {
  right: 4.16666667%;
}
.syt-antd-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.syt-antd-col-xs-order-1 {
  order: 1;
}
.syt-antd-col-xs-0 {
  display: none;
}
.syt-antd-col-push-0 {
  left: auto;
}
.syt-antd-col-pull-0 {
  right: auto;
}
.syt-antd-col-xs-push-0 {
  left: auto;
}
.syt-antd-col-xs-pull-0 {
  right: auto;
}
.syt-antd-col-xs-offset-0 {
  margin-left: 0;
}
.syt-antd-col-xs-order-0 {
  order: 0;
}
.syt-antd-col-push-0.syt-antd-col-rtl {
  right: auto;
}
.syt-antd-col-pull-0.syt-antd-col-rtl {
  left: auto;
}
.syt-antd-col-xs-push-0.syt-antd-col-rtl {
  right: auto;
}
.syt-antd-col-xs-pull-0.syt-antd-col-rtl {
  left: auto;
}
.syt-antd-col-xs-offset-0.syt-antd-col-rtl {
  margin-right: 0;
}
.syt-antd-col-xs-push-1.syt-antd-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.syt-antd-col-xs-pull-1.syt-antd-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.syt-antd-col-xs-offset-1.syt-antd-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.syt-antd-col-xs-push-2.syt-antd-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.syt-antd-col-xs-pull-2.syt-antd-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.syt-antd-col-xs-offset-2.syt-antd-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.syt-antd-col-xs-push-3.syt-antd-col-rtl {
  right: 12.5%;
  left: auto;
}
.syt-antd-col-xs-pull-3.syt-antd-col-rtl {
  right: auto;
  left: 12.5%;
}
.syt-antd-col-xs-offset-3.syt-antd-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.syt-antd-col-xs-push-4.syt-antd-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.syt-antd-col-xs-pull-4.syt-antd-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.syt-antd-col-xs-offset-4.syt-antd-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.syt-antd-col-xs-push-5.syt-antd-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.syt-antd-col-xs-pull-5.syt-antd-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.syt-antd-col-xs-offset-5.syt-antd-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.syt-antd-col-xs-push-6.syt-antd-col-rtl {
  right: 25%;
  left: auto;
}
.syt-antd-col-xs-pull-6.syt-antd-col-rtl {
  right: auto;
  left: 25%;
}
.syt-antd-col-xs-offset-6.syt-antd-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.syt-antd-col-xs-push-7.syt-antd-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.syt-antd-col-xs-pull-7.syt-antd-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.syt-antd-col-xs-offset-7.syt-antd-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.syt-antd-col-xs-push-8.syt-antd-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.syt-antd-col-xs-pull-8.syt-antd-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.syt-antd-col-xs-offset-8.syt-antd-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.syt-antd-col-xs-push-9.syt-antd-col-rtl {
  right: 37.5%;
  left: auto;
}
.syt-antd-col-xs-pull-9.syt-antd-col-rtl {
  right: auto;
  left: 37.5%;
}
.syt-antd-col-xs-offset-9.syt-antd-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.syt-antd-col-xs-push-10.syt-antd-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.syt-antd-col-xs-pull-10.syt-antd-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.syt-antd-col-xs-offset-10.syt-antd-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.syt-antd-col-xs-push-11.syt-antd-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.syt-antd-col-xs-pull-11.syt-antd-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.syt-antd-col-xs-offset-11.syt-antd-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.syt-antd-col-xs-push-12.syt-antd-col-rtl {
  right: 50%;
  left: auto;
}
.syt-antd-col-xs-pull-12.syt-antd-col-rtl {
  right: auto;
  left: 50%;
}
.syt-antd-col-xs-offset-12.syt-antd-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.syt-antd-col-xs-push-13.syt-antd-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.syt-antd-col-xs-pull-13.syt-antd-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.syt-antd-col-xs-offset-13.syt-antd-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.syt-antd-col-xs-push-14.syt-antd-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.syt-antd-col-xs-pull-14.syt-antd-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.syt-antd-col-xs-offset-14.syt-antd-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.syt-antd-col-xs-push-15.syt-antd-col-rtl {
  right: 62.5%;
  left: auto;
}
.syt-antd-col-xs-pull-15.syt-antd-col-rtl {
  right: auto;
  left: 62.5%;
}
.syt-antd-col-xs-offset-15.syt-antd-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.syt-antd-col-xs-push-16.syt-antd-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.syt-antd-col-xs-pull-16.syt-antd-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.syt-antd-col-xs-offset-16.syt-antd-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.syt-antd-col-xs-push-17.syt-antd-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.syt-antd-col-xs-pull-17.syt-antd-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.syt-antd-col-xs-offset-17.syt-antd-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.syt-antd-col-xs-push-18.syt-antd-col-rtl {
  right: 75%;
  left: auto;
}
.syt-antd-col-xs-pull-18.syt-antd-col-rtl {
  right: auto;
  left: 75%;
}
.syt-antd-col-xs-offset-18.syt-antd-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.syt-antd-col-xs-push-19.syt-antd-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.syt-antd-col-xs-pull-19.syt-antd-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.syt-antd-col-xs-offset-19.syt-antd-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.syt-antd-col-xs-push-20.syt-antd-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.syt-antd-col-xs-pull-20.syt-antd-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.syt-antd-col-xs-offset-20.syt-antd-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.syt-antd-col-xs-push-21.syt-antd-col-rtl {
  right: 87.5%;
  left: auto;
}
.syt-antd-col-xs-pull-21.syt-antd-col-rtl {
  right: auto;
  left: 87.5%;
}
.syt-antd-col-xs-offset-21.syt-antd-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.syt-antd-col-xs-push-22.syt-antd-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.syt-antd-col-xs-pull-22.syt-antd-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.syt-antd-col-xs-offset-22.syt-antd-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.syt-antd-col-xs-push-23.syt-antd-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.syt-antd-col-xs-pull-23.syt-antd-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.syt-antd-col-xs-offset-23.syt-antd-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.syt-antd-col-xs-push-24.syt-antd-col-rtl {
  right: 100%;
  left: auto;
}
.syt-antd-col-xs-pull-24.syt-antd-col-rtl {
  right: auto;
  left: 100%;
}
.syt-antd-col-xs-offset-24.syt-antd-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .syt-antd-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .syt-antd-col-sm-push-24 {
    left: 100%;
  }
  .syt-antd-col-sm-pull-24 {
    right: 100%;
  }
  .syt-antd-col-sm-offset-24 {
    margin-left: 100%;
  }
  .syt-antd-col-sm-order-24 {
    order: 24;
  }
  .syt-antd-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .syt-antd-col-sm-push-23 {
    left: 95.83333333%;
  }
  .syt-antd-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .syt-antd-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .syt-antd-col-sm-order-23 {
    order: 23;
  }
  .syt-antd-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .syt-antd-col-sm-push-22 {
    left: 91.66666667%;
  }
  .syt-antd-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .syt-antd-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .syt-antd-col-sm-order-22 {
    order: 22;
  }
  .syt-antd-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .syt-antd-col-sm-push-21 {
    left: 87.5%;
  }
  .syt-antd-col-sm-pull-21 {
    right: 87.5%;
  }
  .syt-antd-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .syt-antd-col-sm-order-21 {
    order: 21;
  }
  .syt-antd-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .syt-antd-col-sm-push-20 {
    left: 83.33333333%;
  }
  .syt-antd-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .syt-antd-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .syt-antd-col-sm-order-20 {
    order: 20;
  }
  .syt-antd-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .syt-antd-col-sm-push-19 {
    left: 79.16666667%;
  }
  .syt-antd-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .syt-antd-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .syt-antd-col-sm-order-19 {
    order: 19;
  }
  .syt-antd-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .syt-antd-col-sm-push-18 {
    left: 75%;
  }
  .syt-antd-col-sm-pull-18 {
    right: 75%;
  }
  .syt-antd-col-sm-offset-18 {
    margin-left: 75%;
  }
  .syt-antd-col-sm-order-18 {
    order: 18;
  }
  .syt-antd-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .syt-antd-col-sm-push-17 {
    left: 70.83333333%;
  }
  .syt-antd-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .syt-antd-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .syt-antd-col-sm-order-17 {
    order: 17;
  }
  .syt-antd-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .syt-antd-col-sm-push-16 {
    left: 66.66666667%;
  }
  .syt-antd-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .syt-antd-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .syt-antd-col-sm-order-16 {
    order: 16;
  }
  .syt-antd-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .syt-antd-col-sm-push-15 {
    left: 62.5%;
  }
  .syt-antd-col-sm-pull-15 {
    right: 62.5%;
  }
  .syt-antd-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .syt-antd-col-sm-order-15 {
    order: 15;
  }
  .syt-antd-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .syt-antd-col-sm-push-14 {
    left: 58.33333333%;
  }
  .syt-antd-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .syt-antd-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .syt-antd-col-sm-order-14 {
    order: 14;
  }
  .syt-antd-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .syt-antd-col-sm-push-13 {
    left: 54.16666667%;
  }
  .syt-antd-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .syt-antd-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .syt-antd-col-sm-order-13 {
    order: 13;
  }
  .syt-antd-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .syt-antd-col-sm-push-12 {
    left: 50%;
  }
  .syt-antd-col-sm-pull-12 {
    right: 50%;
  }
  .syt-antd-col-sm-offset-12 {
    margin-left: 50%;
  }
  .syt-antd-col-sm-order-12 {
    order: 12;
  }
  .syt-antd-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .syt-antd-col-sm-push-11 {
    left: 45.83333333%;
  }
  .syt-antd-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .syt-antd-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .syt-antd-col-sm-order-11 {
    order: 11;
  }
  .syt-antd-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .syt-antd-col-sm-push-10 {
    left: 41.66666667%;
  }
  .syt-antd-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .syt-antd-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .syt-antd-col-sm-order-10 {
    order: 10;
  }
  .syt-antd-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .syt-antd-col-sm-push-9 {
    left: 37.5%;
  }
  .syt-antd-col-sm-pull-9 {
    right: 37.5%;
  }
  .syt-antd-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .syt-antd-col-sm-order-9 {
    order: 9;
  }
  .syt-antd-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .syt-antd-col-sm-push-8 {
    left: 33.33333333%;
  }
  .syt-antd-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .syt-antd-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .syt-antd-col-sm-order-8 {
    order: 8;
  }
  .syt-antd-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .syt-antd-col-sm-push-7 {
    left: 29.16666667%;
  }
  .syt-antd-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .syt-antd-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .syt-antd-col-sm-order-7 {
    order: 7;
  }
  .syt-antd-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .syt-antd-col-sm-push-6 {
    left: 25%;
  }
  .syt-antd-col-sm-pull-6 {
    right: 25%;
  }
  .syt-antd-col-sm-offset-6 {
    margin-left: 25%;
  }
  .syt-antd-col-sm-order-6 {
    order: 6;
  }
  .syt-antd-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .syt-antd-col-sm-push-5 {
    left: 20.83333333%;
  }
  .syt-antd-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .syt-antd-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .syt-antd-col-sm-order-5 {
    order: 5;
  }
  .syt-antd-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .syt-antd-col-sm-push-4 {
    left: 16.66666667%;
  }
  .syt-antd-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .syt-antd-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .syt-antd-col-sm-order-4 {
    order: 4;
  }
  .syt-antd-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .syt-antd-col-sm-push-3 {
    left: 12.5%;
  }
  .syt-antd-col-sm-pull-3 {
    right: 12.5%;
  }
  .syt-antd-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .syt-antd-col-sm-order-3 {
    order: 3;
  }
  .syt-antd-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .syt-antd-col-sm-push-2 {
    left: 8.33333333%;
  }
  .syt-antd-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .syt-antd-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .syt-antd-col-sm-order-2 {
    order: 2;
  }
  .syt-antd-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .syt-antd-col-sm-push-1 {
    left: 4.16666667%;
  }
  .syt-antd-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .syt-antd-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .syt-antd-col-sm-order-1 {
    order: 1;
  }
  .syt-antd-col-sm-0 {
    display: none;
  }
  .syt-antd-col-push-0 {
    left: auto;
  }
  .syt-antd-col-pull-0 {
    right: auto;
  }
  .syt-antd-col-sm-push-0 {
    left: auto;
  }
  .syt-antd-col-sm-pull-0 {
    right: auto;
  }
  .syt-antd-col-sm-offset-0 {
    margin-left: 0;
  }
  .syt-antd-col-sm-order-0 {
    order: 0;
  }
  .syt-antd-col-push-0.syt-antd-col-rtl {
    right: auto;
  }
  .syt-antd-col-pull-0.syt-antd-col-rtl {
    left: auto;
  }
  .syt-antd-col-sm-push-0.syt-antd-col-rtl {
    right: auto;
  }
  .syt-antd-col-sm-pull-0.syt-antd-col-rtl {
    left: auto;
  }
  .syt-antd-col-sm-offset-0.syt-antd-col-rtl {
    margin-right: 0;
  }
  .syt-antd-col-sm-push-1.syt-antd-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .syt-antd-col-sm-pull-1.syt-antd-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .syt-antd-col-sm-offset-1.syt-antd-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-2.syt-antd-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .syt-antd-col-sm-pull-2.syt-antd-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .syt-antd-col-sm-offset-2.syt-antd-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-3.syt-antd-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .syt-antd-col-sm-pull-3.syt-antd-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .syt-antd-col-sm-offset-3.syt-antd-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-4.syt-antd-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .syt-antd-col-sm-pull-4.syt-antd-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .syt-antd-col-sm-offset-4.syt-antd-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-5.syt-antd-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .syt-antd-col-sm-pull-5.syt-antd-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .syt-antd-col-sm-offset-5.syt-antd-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-6.syt-antd-col-rtl {
    right: 25%;
    left: auto;
  }
  .syt-antd-col-sm-pull-6.syt-antd-col-rtl {
    right: auto;
    left: 25%;
  }
  .syt-antd-col-sm-offset-6.syt-antd-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-7.syt-antd-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .syt-antd-col-sm-pull-7.syt-antd-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .syt-antd-col-sm-offset-7.syt-antd-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-8.syt-antd-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .syt-antd-col-sm-pull-8.syt-antd-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .syt-antd-col-sm-offset-8.syt-antd-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-9.syt-antd-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .syt-antd-col-sm-pull-9.syt-antd-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .syt-antd-col-sm-offset-9.syt-antd-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-10.syt-antd-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .syt-antd-col-sm-pull-10.syt-antd-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .syt-antd-col-sm-offset-10.syt-antd-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-11.syt-antd-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .syt-antd-col-sm-pull-11.syt-antd-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .syt-antd-col-sm-offset-11.syt-antd-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-12.syt-antd-col-rtl {
    right: 50%;
    left: auto;
  }
  .syt-antd-col-sm-pull-12.syt-antd-col-rtl {
    right: auto;
    left: 50%;
  }
  .syt-antd-col-sm-offset-12.syt-antd-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-13.syt-antd-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .syt-antd-col-sm-pull-13.syt-antd-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .syt-antd-col-sm-offset-13.syt-antd-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-14.syt-antd-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .syt-antd-col-sm-pull-14.syt-antd-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .syt-antd-col-sm-offset-14.syt-antd-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-15.syt-antd-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .syt-antd-col-sm-pull-15.syt-antd-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .syt-antd-col-sm-offset-15.syt-antd-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-16.syt-antd-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .syt-antd-col-sm-pull-16.syt-antd-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .syt-antd-col-sm-offset-16.syt-antd-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-17.syt-antd-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .syt-antd-col-sm-pull-17.syt-antd-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .syt-antd-col-sm-offset-17.syt-antd-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-18.syt-antd-col-rtl {
    right: 75%;
    left: auto;
  }
  .syt-antd-col-sm-pull-18.syt-antd-col-rtl {
    right: auto;
    left: 75%;
  }
  .syt-antd-col-sm-offset-18.syt-antd-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-19.syt-antd-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .syt-antd-col-sm-pull-19.syt-antd-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .syt-antd-col-sm-offset-19.syt-antd-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-20.syt-antd-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .syt-antd-col-sm-pull-20.syt-antd-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .syt-antd-col-sm-offset-20.syt-antd-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-21.syt-antd-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .syt-antd-col-sm-pull-21.syt-antd-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .syt-antd-col-sm-offset-21.syt-antd-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-22.syt-antd-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .syt-antd-col-sm-pull-22.syt-antd-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .syt-antd-col-sm-offset-22.syt-antd-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-23.syt-antd-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .syt-antd-col-sm-pull-23.syt-antd-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .syt-antd-col-sm-offset-23.syt-antd-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-sm-push-24.syt-antd-col-rtl {
    right: 100%;
    left: auto;
  }
  .syt-antd-col-sm-pull-24.syt-antd-col-rtl {
    right: auto;
    left: 100%;
  }
  .syt-antd-col-sm-offset-24.syt-antd-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .syt-antd-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .syt-antd-col-md-push-24 {
    left: 100%;
  }
  .syt-antd-col-md-pull-24 {
    right: 100%;
  }
  .syt-antd-col-md-offset-24 {
    margin-left: 100%;
  }
  .syt-antd-col-md-order-24 {
    order: 24;
  }
  .syt-antd-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .syt-antd-col-md-push-23 {
    left: 95.83333333%;
  }
  .syt-antd-col-md-pull-23 {
    right: 95.83333333%;
  }
  .syt-antd-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .syt-antd-col-md-order-23 {
    order: 23;
  }
  .syt-antd-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .syt-antd-col-md-push-22 {
    left: 91.66666667%;
  }
  .syt-antd-col-md-pull-22 {
    right: 91.66666667%;
  }
  .syt-antd-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .syt-antd-col-md-order-22 {
    order: 22;
  }
  .syt-antd-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .syt-antd-col-md-push-21 {
    left: 87.5%;
  }
  .syt-antd-col-md-pull-21 {
    right: 87.5%;
  }
  .syt-antd-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .syt-antd-col-md-order-21 {
    order: 21;
  }
  .syt-antd-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .syt-antd-col-md-push-20 {
    left: 83.33333333%;
  }
  .syt-antd-col-md-pull-20 {
    right: 83.33333333%;
  }
  .syt-antd-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .syt-antd-col-md-order-20 {
    order: 20;
  }
  .syt-antd-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .syt-antd-col-md-push-19 {
    left: 79.16666667%;
  }
  .syt-antd-col-md-pull-19 {
    right: 79.16666667%;
  }
  .syt-antd-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .syt-antd-col-md-order-19 {
    order: 19;
  }
  .syt-antd-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .syt-antd-col-md-push-18 {
    left: 75%;
  }
  .syt-antd-col-md-pull-18 {
    right: 75%;
  }
  .syt-antd-col-md-offset-18 {
    margin-left: 75%;
  }
  .syt-antd-col-md-order-18 {
    order: 18;
  }
  .syt-antd-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .syt-antd-col-md-push-17 {
    left: 70.83333333%;
  }
  .syt-antd-col-md-pull-17 {
    right: 70.83333333%;
  }
  .syt-antd-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .syt-antd-col-md-order-17 {
    order: 17;
  }
  .syt-antd-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .syt-antd-col-md-push-16 {
    left: 66.66666667%;
  }
  .syt-antd-col-md-pull-16 {
    right: 66.66666667%;
  }
  .syt-antd-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .syt-antd-col-md-order-16 {
    order: 16;
  }
  .syt-antd-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .syt-antd-col-md-push-15 {
    left: 62.5%;
  }
  .syt-antd-col-md-pull-15 {
    right: 62.5%;
  }
  .syt-antd-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .syt-antd-col-md-order-15 {
    order: 15;
  }
  .syt-antd-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .syt-antd-col-md-push-14 {
    left: 58.33333333%;
  }
  .syt-antd-col-md-pull-14 {
    right: 58.33333333%;
  }
  .syt-antd-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .syt-antd-col-md-order-14 {
    order: 14;
  }
  .syt-antd-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .syt-antd-col-md-push-13 {
    left: 54.16666667%;
  }
  .syt-antd-col-md-pull-13 {
    right: 54.16666667%;
  }
  .syt-antd-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .syt-antd-col-md-order-13 {
    order: 13;
  }
  .syt-antd-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .syt-antd-col-md-push-12 {
    left: 50%;
  }
  .syt-antd-col-md-pull-12 {
    right: 50%;
  }
  .syt-antd-col-md-offset-12 {
    margin-left: 50%;
  }
  .syt-antd-col-md-order-12 {
    order: 12;
  }
  .syt-antd-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .syt-antd-col-md-push-11 {
    left: 45.83333333%;
  }
  .syt-antd-col-md-pull-11 {
    right: 45.83333333%;
  }
  .syt-antd-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .syt-antd-col-md-order-11 {
    order: 11;
  }
  .syt-antd-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .syt-antd-col-md-push-10 {
    left: 41.66666667%;
  }
  .syt-antd-col-md-pull-10 {
    right: 41.66666667%;
  }
  .syt-antd-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .syt-antd-col-md-order-10 {
    order: 10;
  }
  .syt-antd-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .syt-antd-col-md-push-9 {
    left: 37.5%;
  }
  .syt-antd-col-md-pull-9 {
    right: 37.5%;
  }
  .syt-antd-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .syt-antd-col-md-order-9 {
    order: 9;
  }
  .syt-antd-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .syt-antd-col-md-push-8 {
    left: 33.33333333%;
  }
  .syt-antd-col-md-pull-8 {
    right: 33.33333333%;
  }
  .syt-antd-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .syt-antd-col-md-order-8 {
    order: 8;
  }
  .syt-antd-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .syt-antd-col-md-push-7 {
    left: 29.16666667%;
  }
  .syt-antd-col-md-pull-7 {
    right: 29.16666667%;
  }
  .syt-antd-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .syt-antd-col-md-order-7 {
    order: 7;
  }
  .syt-antd-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .syt-antd-col-md-push-6 {
    left: 25%;
  }
  .syt-antd-col-md-pull-6 {
    right: 25%;
  }
  .syt-antd-col-md-offset-6 {
    margin-left: 25%;
  }
  .syt-antd-col-md-order-6 {
    order: 6;
  }
  .syt-antd-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .syt-antd-col-md-push-5 {
    left: 20.83333333%;
  }
  .syt-antd-col-md-pull-5 {
    right: 20.83333333%;
  }
  .syt-antd-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .syt-antd-col-md-order-5 {
    order: 5;
  }
  .syt-antd-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .syt-antd-col-md-push-4 {
    left: 16.66666667%;
  }
  .syt-antd-col-md-pull-4 {
    right: 16.66666667%;
  }
  .syt-antd-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .syt-antd-col-md-order-4 {
    order: 4;
  }
  .syt-antd-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .syt-antd-col-md-push-3 {
    left: 12.5%;
  }
  .syt-antd-col-md-pull-3 {
    right: 12.5%;
  }
  .syt-antd-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .syt-antd-col-md-order-3 {
    order: 3;
  }
  .syt-antd-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .syt-antd-col-md-push-2 {
    left: 8.33333333%;
  }
  .syt-antd-col-md-pull-2 {
    right: 8.33333333%;
  }
  .syt-antd-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .syt-antd-col-md-order-2 {
    order: 2;
  }
  .syt-antd-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .syt-antd-col-md-push-1 {
    left: 4.16666667%;
  }
  .syt-antd-col-md-pull-1 {
    right: 4.16666667%;
  }
  .syt-antd-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .syt-antd-col-md-order-1 {
    order: 1;
  }
  .syt-antd-col-md-0 {
    display: none;
  }
  .syt-antd-col-push-0 {
    left: auto;
  }
  .syt-antd-col-pull-0 {
    right: auto;
  }
  .syt-antd-col-md-push-0 {
    left: auto;
  }
  .syt-antd-col-md-pull-0 {
    right: auto;
  }
  .syt-antd-col-md-offset-0 {
    margin-left: 0;
  }
  .syt-antd-col-md-order-0 {
    order: 0;
  }
  .syt-antd-col-push-0.syt-antd-col-rtl {
    right: auto;
  }
  .syt-antd-col-pull-0.syt-antd-col-rtl {
    left: auto;
  }
  .syt-antd-col-md-push-0.syt-antd-col-rtl {
    right: auto;
  }
  .syt-antd-col-md-pull-0.syt-antd-col-rtl {
    left: auto;
  }
  .syt-antd-col-md-offset-0.syt-antd-col-rtl {
    margin-right: 0;
  }
  .syt-antd-col-md-push-1.syt-antd-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .syt-antd-col-md-pull-1.syt-antd-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .syt-antd-col-md-offset-1.syt-antd-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-2.syt-antd-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .syt-antd-col-md-pull-2.syt-antd-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .syt-antd-col-md-offset-2.syt-antd-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-3.syt-antd-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .syt-antd-col-md-pull-3.syt-antd-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .syt-antd-col-md-offset-3.syt-antd-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-4.syt-antd-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .syt-antd-col-md-pull-4.syt-antd-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .syt-antd-col-md-offset-4.syt-antd-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-5.syt-antd-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .syt-antd-col-md-pull-5.syt-antd-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .syt-antd-col-md-offset-5.syt-antd-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-6.syt-antd-col-rtl {
    right: 25%;
    left: auto;
  }
  .syt-antd-col-md-pull-6.syt-antd-col-rtl {
    right: auto;
    left: 25%;
  }
  .syt-antd-col-md-offset-6.syt-antd-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-7.syt-antd-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .syt-antd-col-md-pull-7.syt-antd-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .syt-antd-col-md-offset-7.syt-antd-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-8.syt-antd-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .syt-antd-col-md-pull-8.syt-antd-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .syt-antd-col-md-offset-8.syt-antd-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-9.syt-antd-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .syt-antd-col-md-pull-9.syt-antd-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .syt-antd-col-md-offset-9.syt-antd-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-10.syt-antd-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .syt-antd-col-md-pull-10.syt-antd-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .syt-antd-col-md-offset-10.syt-antd-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-11.syt-antd-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .syt-antd-col-md-pull-11.syt-antd-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .syt-antd-col-md-offset-11.syt-antd-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-12.syt-antd-col-rtl {
    right: 50%;
    left: auto;
  }
  .syt-antd-col-md-pull-12.syt-antd-col-rtl {
    right: auto;
    left: 50%;
  }
  .syt-antd-col-md-offset-12.syt-antd-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-13.syt-antd-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .syt-antd-col-md-pull-13.syt-antd-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .syt-antd-col-md-offset-13.syt-antd-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-14.syt-antd-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .syt-antd-col-md-pull-14.syt-antd-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .syt-antd-col-md-offset-14.syt-antd-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-15.syt-antd-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .syt-antd-col-md-pull-15.syt-antd-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .syt-antd-col-md-offset-15.syt-antd-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-16.syt-antd-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .syt-antd-col-md-pull-16.syt-antd-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .syt-antd-col-md-offset-16.syt-antd-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-17.syt-antd-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .syt-antd-col-md-pull-17.syt-antd-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .syt-antd-col-md-offset-17.syt-antd-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-18.syt-antd-col-rtl {
    right: 75%;
    left: auto;
  }
  .syt-antd-col-md-pull-18.syt-antd-col-rtl {
    right: auto;
    left: 75%;
  }
  .syt-antd-col-md-offset-18.syt-antd-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-19.syt-antd-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .syt-antd-col-md-pull-19.syt-antd-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .syt-antd-col-md-offset-19.syt-antd-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-20.syt-antd-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .syt-antd-col-md-pull-20.syt-antd-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .syt-antd-col-md-offset-20.syt-antd-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-21.syt-antd-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .syt-antd-col-md-pull-21.syt-antd-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .syt-antd-col-md-offset-21.syt-antd-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-22.syt-antd-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .syt-antd-col-md-pull-22.syt-antd-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .syt-antd-col-md-offset-22.syt-antd-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-23.syt-antd-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .syt-antd-col-md-pull-23.syt-antd-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .syt-antd-col-md-offset-23.syt-antd-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-md-push-24.syt-antd-col-rtl {
    right: 100%;
    left: auto;
  }
  .syt-antd-col-md-pull-24.syt-antd-col-rtl {
    right: auto;
    left: 100%;
  }
  .syt-antd-col-md-offset-24.syt-antd-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .syt-antd-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .syt-antd-col-lg-push-24 {
    left: 100%;
  }
  .syt-antd-col-lg-pull-24 {
    right: 100%;
  }
  .syt-antd-col-lg-offset-24 {
    margin-left: 100%;
  }
  .syt-antd-col-lg-order-24 {
    order: 24;
  }
  .syt-antd-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .syt-antd-col-lg-push-23 {
    left: 95.83333333%;
  }
  .syt-antd-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .syt-antd-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .syt-antd-col-lg-order-23 {
    order: 23;
  }
  .syt-antd-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .syt-antd-col-lg-push-22 {
    left: 91.66666667%;
  }
  .syt-antd-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .syt-antd-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .syt-antd-col-lg-order-22 {
    order: 22;
  }
  .syt-antd-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .syt-antd-col-lg-push-21 {
    left: 87.5%;
  }
  .syt-antd-col-lg-pull-21 {
    right: 87.5%;
  }
  .syt-antd-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .syt-antd-col-lg-order-21 {
    order: 21;
  }
  .syt-antd-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .syt-antd-col-lg-push-20 {
    left: 83.33333333%;
  }
  .syt-antd-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .syt-antd-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .syt-antd-col-lg-order-20 {
    order: 20;
  }
  .syt-antd-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .syt-antd-col-lg-push-19 {
    left: 79.16666667%;
  }
  .syt-antd-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .syt-antd-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .syt-antd-col-lg-order-19 {
    order: 19;
  }
  .syt-antd-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .syt-antd-col-lg-push-18 {
    left: 75%;
  }
  .syt-antd-col-lg-pull-18 {
    right: 75%;
  }
  .syt-antd-col-lg-offset-18 {
    margin-left: 75%;
  }
  .syt-antd-col-lg-order-18 {
    order: 18;
  }
  .syt-antd-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .syt-antd-col-lg-push-17 {
    left: 70.83333333%;
  }
  .syt-antd-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .syt-antd-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .syt-antd-col-lg-order-17 {
    order: 17;
  }
  .syt-antd-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .syt-antd-col-lg-push-16 {
    left: 66.66666667%;
  }
  .syt-antd-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .syt-antd-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .syt-antd-col-lg-order-16 {
    order: 16;
  }
  .syt-antd-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .syt-antd-col-lg-push-15 {
    left: 62.5%;
  }
  .syt-antd-col-lg-pull-15 {
    right: 62.5%;
  }
  .syt-antd-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .syt-antd-col-lg-order-15 {
    order: 15;
  }
  .syt-antd-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .syt-antd-col-lg-push-14 {
    left: 58.33333333%;
  }
  .syt-antd-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .syt-antd-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .syt-antd-col-lg-order-14 {
    order: 14;
  }
  .syt-antd-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .syt-antd-col-lg-push-13 {
    left: 54.16666667%;
  }
  .syt-antd-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .syt-antd-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .syt-antd-col-lg-order-13 {
    order: 13;
  }
  .syt-antd-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .syt-antd-col-lg-push-12 {
    left: 50%;
  }
  .syt-antd-col-lg-pull-12 {
    right: 50%;
  }
  .syt-antd-col-lg-offset-12 {
    margin-left: 50%;
  }
  .syt-antd-col-lg-order-12 {
    order: 12;
  }
  .syt-antd-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .syt-antd-col-lg-push-11 {
    left: 45.83333333%;
  }
  .syt-antd-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .syt-antd-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .syt-antd-col-lg-order-11 {
    order: 11;
  }
  .syt-antd-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .syt-antd-col-lg-push-10 {
    left: 41.66666667%;
  }
  .syt-antd-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .syt-antd-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .syt-antd-col-lg-order-10 {
    order: 10;
  }
  .syt-antd-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .syt-antd-col-lg-push-9 {
    left: 37.5%;
  }
  .syt-antd-col-lg-pull-9 {
    right: 37.5%;
  }
  .syt-antd-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .syt-antd-col-lg-order-9 {
    order: 9;
  }
  .syt-antd-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .syt-antd-col-lg-push-8 {
    left: 33.33333333%;
  }
  .syt-antd-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .syt-antd-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .syt-antd-col-lg-order-8 {
    order: 8;
  }
  .syt-antd-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .syt-antd-col-lg-push-7 {
    left: 29.16666667%;
  }
  .syt-antd-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .syt-antd-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .syt-antd-col-lg-order-7 {
    order: 7;
  }
  .syt-antd-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .syt-antd-col-lg-push-6 {
    left: 25%;
  }
  .syt-antd-col-lg-pull-6 {
    right: 25%;
  }
  .syt-antd-col-lg-offset-6 {
    margin-left: 25%;
  }
  .syt-antd-col-lg-order-6 {
    order: 6;
  }
  .syt-antd-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .syt-antd-col-lg-push-5 {
    left: 20.83333333%;
  }
  .syt-antd-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .syt-antd-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .syt-antd-col-lg-order-5 {
    order: 5;
  }
  .syt-antd-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .syt-antd-col-lg-push-4 {
    left: 16.66666667%;
  }
  .syt-antd-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .syt-antd-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .syt-antd-col-lg-order-4 {
    order: 4;
  }
  .syt-antd-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .syt-antd-col-lg-push-3 {
    left: 12.5%;
  }
  .syt-antd-col-lg-pull-3 {
    right: 12.5%;
  }
  .syt-antd-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .syt-antd-col-lg-order-3 {
    order: 3;
  }
  .syt-antd-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .syt-antd-col-lg-push-2 {
    left: 8.33333333%;
  }
  .syt-antd-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .syt-antd-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .syt-antd-col-lg-order-2 {
    order: 2;
  }
  .syt-antd-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .syt-antd-col-lg-push-1 {
    left: 4.16666667%;
  }
  .syt-antd-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .syt-antd-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .syt-antd-col-lg-order-1 {
    order: 1;
  }
  .syt-antd-col-lg-0 {
    display: none;
  }
  .syt-antd-col-push-0 {
    left: auto;
  }
  .syt-antd-col-pull-0 {
    right: auto;
  }
  .syt-antd-col-lg-push-0 {
    left: auto;
  }
  .syt-antd-col-lg-pull-0 {
    right: auto;
  }
  .syt-antd-col-lg-offset-0 {
    margin-left: 0;
  }
  .syt-antd-col-lg-order-0 {
    order: 0;
  }
  .syt-antd-col-push-0.syt-antd-col-rtl {
    right: auto;
  }
  .syt-antd-col-pull-0.syt-antd-col-rtl {
    left: auto;
  }
  .syt-antd-col-lg-push-0.syt-antd-col-rtl {
    right: auto;
  }
  .syt-antd-col-lg-pull-0.syt-antd-col-rtl {
    left: auto;
  }
  .syt-antd-col-lg-offset-0.syt-antd-col-rtl {
    margin-right: 0;
  }
  .syt-antd-col-lg-push-1.syt-antd-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .syt-antd-col-lg-pull-1.syt-antd-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .syt-antd-col-lg-offset-1.syt-antd-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-2.syt-antd-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .syt-antd-col-lg-pull-2.syt-antd-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .syt-antd-col-lg-offset-2.syt-antd-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-3.syt-antd-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .syt-antd-col-lg-pull-3.syt-antd-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .syt-antd-col-lg-offset-3.syt-antd-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-4.syt-antd-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .syt-antd-col-lg-pull-4.syt-antd-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .syt-antd-col-lg-offset-4.syt-antd-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-5.syt-antd-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .syt-antd-col-lg-pull-5.syt-antd-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .syt-antd-col-lg-offset-5.syt-antd-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-6.syt-antd-col-rtl {
    right: 25%;
    left: auto;
  }
  .syt-antd-col-lg-pull-6.syt-antd-col-rtl {
    right: auto;
    left: 25%;
  }
  .syt-antd-col-lg-offset-6.syt-antd-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-7.syt-antd-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .syt-antd-col-lg-pull-7.syt-antd-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .syt-antd-col-lg-offset-7.syt-antd-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-8.syt-antd-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .syt-antd-col-lg-pull-8.syt-antd-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .syt-antd-col-lg-offset-8.syt-antd-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-9.syt-antd-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .syt-antd-col-lg-pull-9.syt-antd-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .syt-antd-col-lg-offset-9.syt-antd-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-10.syt-antd-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .syt-antd-col-lg-pull-10.syt-antd-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .syt-antd-col-lg-offset-10.syt-antd-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-11.syt-antd-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .syt-antd-col-lg-pull-11.syt-antd-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .syt-antd-col-lg-offset-11.syt-antd-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-12.syt-antd-col-rtl {
    right: 50%;
    left: auto;
  }
  .syt-antd-col-lg-pull-12.syt-antd-col-rtl {
    right: auto;
    left: 50%;
  }
  .syt-antd-col-lg-offset-12.syt-antd-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-13.syt-antd-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .syt-antd-col-lg-pull-13.syt-antd-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .syt-antd-col-lg-offset-13.syt-antd-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-14.syt-antd-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .syt-antd-col-lg-pull-14.syt-antd-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .syt-antd-col-lg-offset-14.syt-antd-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-15.syt-antd-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .syt-antd-col-lg-pull-15.syt-antd-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .syt-antd-col-lg-offset-15.syt-antd-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-16.syt-antd-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .syt-antd-col-lg-pull-16.syt-antd-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .syt-antd-col-lg-offset-16.syt-antd-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-17.syt-antd-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .syt-antd-col-lg-pull-17.syt-antd-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .syt-antd-col-lg-offset-17.syt-antd-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-18.syt-antd-col-rtl {
    right: 75%;
    left: auto;
  }
  .syt-antd-col-lg-pull-18.syt-antd-col-rtl {
    right: auto;
    left: 75%;
  }
  .syt-antd-col-lg-offset-18.syt-antd-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-19.syt-antd-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .syt-antd-col-lg-pull-19.syt-antd-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .syt-antd-col-lg-offset-19.syt-antd-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-20.syt-antd-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .syt-antd-col-lg-pull-20.syt-antd-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .syt-antd-col-lg-offset-20.syt-antd-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-21.syt-antd-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .syt-antd-col-lg-pull-21.syt-antd-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .syt-antd-col-lg-offset-21.syt-antd-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-22.syt-antd-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .syt-antd-col-lg-pull-22.syt-antd-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .syt-antd-col-lg-offset-22.syt-antd-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-23.syt-antd-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .syt-antd-col-lg-pull-23.syt-antd-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .syt-antd-col-lg-offset-23.syt-antd-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-lg-push-24.syt-antd-col-rtl {
    right: 100%;
    left: auto;
  }
  .syt-antd-col-lg-pull-24.syt-antd-col-rtl {
    right: auto;
    left: 100%;
  }
  .syt-antd-col-lg-offset-24.syt-antd-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .syt-antd-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .syt-antd-col-xl-push-24 {
    left: 100%;
  }
  .syt-antd-col-xl-pull-24 {
    right: 100%;
  }
  .syt-antd-col-xl-offset-24 {
    margin-left: 100%;
  }
  .syt-antd-col-xl-order-24 {
    order: 24;
  }
  .syt-antd-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .syt-antd-col-xl-push-23 {
    left: 95.83333333%;
  }
  .syt-antd-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .syt-antd-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .syt-antd-col-xl-order-23 {
    order: 23;
  }
  .syt-antd-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .syt-antd-col-xl-push-22 {
    left: 91.66666667%;
  }
  .syt-antd-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .syt-antd-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .syt-antd-col-xl-order-22 {
    order: 22;
  }
  .syt-antd-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .syt-antd-col-xl-push-21 {
    left: 87.5%;
  }
  .syt-antd-col-xl-pull-21 {
    right: 87.5%;
  }
  .syt-antd-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .syt-antd-col-xl-order-21 {
    order: 21;
  }
  .syt-antd-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .syt-antd-col-xl-push-20 {
    left: 83.33333333%;
  }
  .syt-antd-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .syt-antd-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .syt-antd-col-xl-order-20 {
    order: 20;
  }
  .syt-antd-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .syt-antd-col-xl-push-19 {
    left: 79.16666667%;
  }
  .syt-antd-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .syt-antd-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .syt-antd-col-xl-order-19 {
    order: 19;
  }
  .syt-antd-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .syt-antd-col-xl-push-18 {
    left: 75%;
  }
  .syt-antd-col-xl-pull-18 {
    right: 75%;
  }
  .syt-antd-col-xl-offset-18 {
    margin-left: 75%;
  }
  .syt-antd-col-xl-order-18 {
    order: 18;
  }
  .syt-antd-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .syt-antd-col-xl-push-17 {
    left: 70.83333333%;
  }
  .syt-antd-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .syt-antd-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .syt-antd-col-xl-order-17 {
    order: 17;
  }
  .syt-antd-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .syt-antd-col-xl-push-16 {
    left: 66.66666667%;
  }
  .syt-antd-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .syt-antd-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .syt-antd-col-xl-order-16 {
    order: 16;
  }
  .syt-antd-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .syt-antd-col-xl-push-15 {
    left: 62.5%;
  }
  .syt-antd-col-xl-pull-15 {
    right: 62.5%;
  }
  .syt-antd-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .syt-antd-col-xl-order-15 {
    order: 15;
  }
  .syt-antd-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .syt-antd-col-xl-push-14 {
    left: 58.33333333%;
  }
  .syt-antd-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .syt-antd-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .syt-antd-col-xl-order-14 {
    order: 14;
  }
  .syt-antd-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .syt-antd-col-xl-push-13 {
    left: 54.16666667%;
  }
  .syt-antd-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .syt-antd-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .syt-antd-col-xl-order-13 {
    order: 13;
  }
  .syt-antd-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .syt-antd-col-xl-push-12 {
    left: 50%;
  }
  .syt-antd-col-xl-pull-12 {
    right: 50%;
  }
  .syt-antd-col-xl-offset-12 {
    margin-left: 50%;
  }
  .syt-antd-col-xl-order-12 {
    order: 12;
  }
  .syt-antd-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .syt-antd-col-xl-push-11 {
    left: 45.83333333%;
  }
  .syt-antd-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .syt-antd-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .syt-antd-col-xl-order-11 {
    order: 11;
  }
  .syt-antd-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .syt-antd-col-xl-push-10 {
    left: 41.66666667%;
  }
  .syt-antd-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .syt-antd-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .syt-antd-col-xl-order-10 {
    order: 10;
  }
  .syt-antd-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .syt-antd-col-xl-push-9 {
    left: 37.5%;
  }
  .syt-antd-col-xl-pull-9 {
    right: 37.5%;
  }
  .syt-antd-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .syt-antd-col-xl-order-9 {
    order: 9;
  }
  .syt-antd-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .syt-antd-col-xl-push-8 {
    left: 33.33333333%;
  }
  .syt-antd-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .syt-antd-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .syt-antd-col-xl-order-8 {
    order: 8;
  }
  .syt-antd-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .syt-antd-col-xl-push-7 {
    left: 29.16666667%;
  }
  .syt-antd-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .syt-antd-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .syt-antd-col-xl-order-7 {
    order: 7;
  }
  .syt-antd-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .syt-antd-col-xl-push-6 {
    left: 25%;
  }
  .syt-antd-col-xl-pull-6 {
    right: 25%;
  }
  .syt-antd-col-xl-offset-6 {
    margin-left: 25%;
  }
  .syt-antd-col-xl-order-6 {
    order: 6;
  }
  .syt-antd-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .syt-antd-col-xl-push-5 {
    left: 20.83333333%;
  }
  .syt-antd-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .syt-antd-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .syt-antd-col-xl-order-5 {
    order: 5;
  }
  .syt-antd-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .syt-antd-col-xl-push-4 {
    left: 16.66666667%;
  }
  .syt-antd-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .syt-antd-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .syt-antd-col-xl-order-4 {
    order: 4;
  }
  .syt-antd-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .syt-antd-col-xl-push-3 {
    left: 12.5%;
  }
  .syt-antd-col-xl-pull-3 {
    right: 12.5%;
  }
  .syt-antd-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .syt-antd-col-xl-order-3 {
    order: 3;
  }
  .syt-antd-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .syt-antd-col-xl-push-2 {
    left: 8.33333333%;
  }
  .syt-antd-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .syt-antd-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .syt-antd-col-xl-order-2 {
    order: 2;
  }
  .syt-antd-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .syt-antd-col-xl-push-1 {
    left: 4.16666667%;
  }
  .syt-antd-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .syt-antd-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .syt-antd-col-xl-order-1 {
    order: 1;
  }
  .syt-antd-col-xl-0 {
    display: none;
  }
  .syt-antd-col-push-0 {
    left: auto;
  }
  .syt-antd-col-pull-0 {
    right: auto;
  }
  .syt-antd-col-xl-push-0 {
    left: auto;
  }
  .syt-antd-col-xl-pull-0 {
    right: auto;
  }
  .syt-antd-col-xl-offset-0 {
    margin-left: 0;
  }
  .syt-antd-col-xl-order-0 {
    order: 0;
  }
  .syt-antd-col-push-0.syt-antd-col-rtl {
    right: auto;
  }
  .syt-antd-col-pull-0.syt-antd-col-rtl {
    left: auto;
  }
  .syt-antd-col-xl-push-0.syt-antd-col-rtl {
    right: auto;
  }
  .syt-antd-col-xl-pull-0.syt-antd-col-rtl {
    left: auto;
  }
  .syt-antd-col-xl-offset-0.syt-antd-col-rtl {
    margin-right: 0;
  }
  .syt-antd-col-xl-push-1.syt-antd-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .syt-antd-col-xl-pull-1.syt-antd-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .syt-antd-col-xl-offset-1.syt-antd-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-2.syt-antd-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .syt-antd-col-xl-pull-2.syt-antd-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .syt-antd-col-xl-offset-2.syt-antd-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-3.syt-antd-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .syt-antd-col-xl-pull-3.syt-antd-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .syt-antd-col-xl-offset-3.syt-antd-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-4.syt-antd-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .syt-antd-col-xl-pull-4.syt-antd-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .syt-antd-col-xl-offset-4.syt-antd-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-5.syt-antd-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .syt-antd-col-xl-pull-5.syt-antd-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .syt-antd-col-xl-offset-5.syt-antd-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-6.syt-antd-col-rtl {
    right: 25%;
    left: auto;
  }
  .syt-antd-col-xl-pull-6.syt-antd-col-rtl {
    right: auto;
    left: 25%;
  }
  .syt-antd-col-xl-offset-6.syt-antd-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-7.syt-antd-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .syt-antd-col-xl-pull-7.syt-antd-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .syt-antd-col-xl-offset-7.syt-antd-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-8.syt-antd-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .syt-antd-col-xl-pull-8.syt-antd-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .syt-antd-col-xl-offset-8.syt-antd-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-9.syt-antd-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .syt-antd-col-xl-pull-9.syt-antd-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .syt-antd-col-xl-offset-9.syt-antd-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-10.syt-antd-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .syt-antd-col-xl-pull-10.syt-antd-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .syt-antd-col-xl-offset-10.syt-antd-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-11.syt-antd-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .syt-antd-col-xl-pull-11.syt-antd-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .syt-antd-col-xl-offset-11.syt-antd-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-12.syt-antd-col-rtl {
    right: 50%;
    left: auto;
  }
  .syt-antd-col-xl-pull-12.syt-antd-col-rtl {
    right: auto;
    left: 50%;
  }
  .syt-antd-col-xl-offset-12.syt-antd-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-13.syt-antd-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .syt-antd-col-xl-pull-13.syt-antd-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .syt-antd-col-xl-offset-13.syt-antd-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-14.syt-antd-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .syt-antd-col-xl-pull-14.syt-antd-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .syt-antd-col-xl-offset-14.syt-antd-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-15.syt-antd-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .syt-antd-col-xl-pull-15.syt-antd-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .syt-antd-col-xl-offset-15.syt-antd-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-16.syt-antd-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .syt-antd-col-xl-pull-16.syt-antd-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .syt-antd-col-xl-offset-16.syt-antd-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-17.syt-antd-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .syt-antd-col-xl-pull-17.syt-antd-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .syt-antd-col-xl-offset-17.syt-antd-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-18.syt-antd-col-rtl {
    right: 75%;
    left: auto;
  }
  .syt-antd-col-xl-pull-18.syt-antd-col-rtl {
    right: auto;
    left: 75%;
  }
  .syt-antd-col-xl-offset-18.syt-antd-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-19.syt-antd-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .syt-antd-col-xl-pull-19.syt-antd-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .syt-antd-col-xl-offset-19.syt-antd-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-20.syt-antd-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .syt-antd-col-xl-pull-20.syt-antd-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .syt-antd-col-xl-offset-20.syt-antd-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-21.syt-antd-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .syt-antd-col-xl-pull-21.syt-antd-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .syt-antd-col-xl-offset-21.syt-antd-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-22.syt-antd-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .syt-antd-col-xl-pull-22.syt-antd-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .syt-antd-col-xl-offset-22.syt-antd-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-23.syt-antd-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .syt-antd-col-xl-pull-23.syt-antd-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .syt-antd-col-xl-offset-23.syt-antd-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-xl-push-24.syt-antd-col-rtl {
    right: 100%;
    left: auto;
  }
  .syt-antd-col-xl-pull-24.syt-antd-col-rtl {
    right: auto;
    left: 100%;
  }
  .syt-antd-col-xl-offset-24.syt-antd-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .syt-antd-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .syt-antd-col-xxl-push-24 {
    left: 100%;
  }
  .syt-antd-col-xxl-pull-24 {
    right: 100%;
  }
  .syt-antd-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .syt-antd-col-xxl-order-24 {
    order: 24;
  }
  .syt-antd-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .syt-antd-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .syt-antd-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .syt-antd-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .syt-antd-col-xxl-order-23 {
    order: 23;
  }
  .syt-antd-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .syt-antd-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .syt-antd-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .syt-antd-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .syt-antd-col-xxl-order-22 {
    order: 22;
  }
  .syt-antd-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .syt-antd-col-xxl-push-21 {
    left: 87.5%;
  }
  .syt-antd-col-xxl-pull-21 {
    right: 87.5%;
  }
  .syt-antd-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .syt-antd-col-xxl-order-21 {
    order: 21;
  }
  .syt-antd-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .syt-antd-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .syt-antd-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .syt-antd-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .syt-antd-col-xxl-order-20 {
    order: 20;
  }
  .syt-antd-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .syt-antd-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .syt-antd-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .syt-antd-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .syt-antd-col-xxl-order-19 {
    order: 19;
  }
  .syt-antd-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .syt-antd-col-xxl-push-18 {
    left: 75%;
  }
  .syt-antd-col-xxl-pull-18 {
    right: 75%;
  }
  .syt-antd-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .syt-antd-col-xxl-order-18 {
    order: 18;
  }
  .syt-antd-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .syt-antd-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .syt-antd-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .syt-antd-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .syt-antd-col-xxl-order-17 {
    order: 17;
  }
  .syt-antd-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .syt-antd-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .syt-antd-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .syt-antd-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .syt-antd-col-xxl-order-16 {
    order: 16;
  }
  .syt-antd-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .syt-antd-col-xxl-push-15 {
    left: 62.5%;
  }
  .syt-antd-col-xxl-pull-15 {
    right: 62.5%;
  }
  .syt-antd-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .syt-antd-col-xxl-order-15 {
    order: 15;
  }
  .syt-antd-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .syt-antd-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .syt-antd-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .syt-antd-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .syt-antd-col-xxl-order-14 {
    order: 14;
  }
  .syt-antd-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .syt-antd-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .syt-antd-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .syt-antd-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .syt-antd-col-xxl-order-13 {
    order: 13;
  }
  .syt-antd-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .syt-antd-col-xxl-push-12 {
    left: 50%;
  }
  .syt-antd-col-xxl-pull-12 {
    right: 50%;
  }
  .syt-antd-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .syt-antd-col-xxl-order-12 {
    order: 12;
  }
  .syt-antd-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .syt-antd-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .syt-antd-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .syt-antd-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .syt-antd-col-xxl-order-11 {
    order: 11;
  }
  .syt-antd-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .syt-antd-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .syt-antd-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .syt-antd-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .syt-antd-col-xxl-order-10 {
    order: 10;
  }
  .syt-antd-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .syt-antd-col-xxl-push-9 {
    left: 37.5%;
  }
  .syt-antd-col-xxl-pull-9 {
    right: 37.5%;
  }
  .syt-antd-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .syt-antd-col-xxl-order-9 {
    order: 9;
  }
  .syt-antd-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .syt-antd-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .syt-antd-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .syt-antd-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .syt-antd-col-xxl-order-8 {
    order: 8;
  }
  .syt-antd-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .syt-antd-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .syt-antd-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .syt-antd-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .syt-antd-col-xxl-order-7 {
    order: 7;
  }
  .syt-antd-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .syt-antd-col-xxl-push-6 {
    left: 25%;
  }
  .syt-antd-col-xxl-pull-6 {
    right: 25%;
  }
  .syt-antd-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .syt-antd-col-xxl-order-6 {
    order: 6;
  }
  .syt-antd-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .syt-antd-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .syt-antd-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .syt-antd-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .syt-antd-col-xxl-order-5 {
    order: 5;
  }
  .syt-antd-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .syt-antd-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .syt-antd-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .syt-antd-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .syt-antd-col-xxl-order-4 {
    order: 4;
  }
  .syt-antd-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .syt-antd-col-xxl-push-3 {
    left: 12.5%;
  }
  .syt-antd-col-xxl-pull-3 {
    right: 12.5%;
  }
  .syt-antd-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .syt-antd-col-xxl-order-3 {
    order: 3;
  }
  .syt-antd-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .syt-antd-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .syt-antd-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .syt-antd-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .syt-antd-col-xxl-order-2 {
    order: 2;
  }
  .syt-antd-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .syt-antd-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .syt-antd-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .syt-antd-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .syt-antd-col-xxl-order-1 {
    order: 1;
  }
  .syt-antd-col-xxl-0 {
    display: none;
  }
  .syt-antd-col-push-0 {
    left: auto;
  }
  .syt-antd-col-pull-0 {
    right: auto;
  }
  .syt-antd-col-xxl-push-0 {
    left: auto;
  }
  .syt-antd-col-xxl-pull-0 {
    right: auto;
  }
  .syt-antd-col-xxl-offset-0 {
    margin-left: 0;
  }
  .syt-antd-col-xxl-order-0 {
    order: 0;
  }
  .syt-antd-col-push-0.syt-antd-col-rtl {
    right: auto;
  }
  .syt-antd-col-pull-0.syt-antd-col-rtl {
    left: auto;
  }
  .syt-antd-col-xxl-push-0.syt-antd-col-rtl {
    right: auto;
  }
  .syt-antd-col-xxl-pull-0.syt-antd-col-rtl {
    left: auto;
  }
  .syt-antd-col-xxl-offset-0.syt-antd-col-rtl {
    margin-right: 0;
  }
  .syt-antd-col-xxl-push-1.syt-antd-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-1.syt-antd-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .syt-antd-col-xxl-offset-1.syt-antd-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-2.syt-antd-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-2.syt-antd-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .syt-antd-col-xxl-offset-2.syt-antd-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-3.syt-antd-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-3.syt-antd-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .syt-antd-col-xxl-offset-3.syt-antd-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-4.syt-antd-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-4.syt-antd-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .syt-antd-col-xxl-offset-4.syt-antd-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-5.syt-antd-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-5.syt-antd-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .syt-antd-col-xxl-offset-5.syt-antd-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-6.syt-antd-col-rtl {
    right: 25%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-6.syt-antd-col-rtl {
    right: auto;
    left: 25%;
  }
  .syt-antd-col-xxl-offset-6.syt-antd-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-7.syt-antd-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-7.syt-antd-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .syt-antd-col-xxl-offset-7.syt-antd-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-8.syt-antd-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-8.syt-antd-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .syt-antd-col-xxl-offset-8.syt-antd-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-9.syt-antd-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-9.syt-antd-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .syt-antd-col-xxl-offset-9.syt-antd-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-10.syt-antd-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-10.syt-antd-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .syt-antd-col-xxl-offset-10.syt-antd-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-11.syt-antd-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-11.syt-antd-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .syt-antd-col-xxl-offset-11.syt-antd-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-12.syt-antd-col-rtl {
    right: 50%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-12.syt-antd-col-rtl {
    right: auto;
    left: 50%;
  }
  .syt-antd-col-xxl-offset-12.syt-antd-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-13.syt-antd-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-13.syt-antd-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .syt-antd-col-xxl-offset-13.syt-antd-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-14.syt-antd-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-14.syt-antd-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .syt-antd-col-xxl-offset-14.syt-antd-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-15.syt-antd-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-15.syt-antd-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .syt-antd-col-xxl-offset-15.syt-antd-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-16.syt-antd-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-16.syt-antd-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .syt-antd-col-xxl-offset-16.syt-antd-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-17.syt-antd-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-17.syt-antd-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .syt-antd-col-xxl-offset-17.syt-antd-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-18.syt-antd-col-rtl {
    right: 75%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-18.syt-antd-col-rtl {
    right: auto;
    left: 75%;
  }
  .syt-antd-col-xxl-offset-18.syt-antd-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-19.syt-antd-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-19.syt-antd-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .syt-antd-col-xxl-offset-19.syt-antd-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-20.syt-antd-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-20.syt-antd-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .syt-antd-col-xxl-offset-20.syt-antd-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-21.syt-antd-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-21.syt-antd-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .syt-antd-col-xxl-offset-21.syt-antd-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-22.syt-antd-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-22.syt-antd-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .syt-antd-col-xxl-offset-22.syt-antd-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-23.syt-antd-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-23.syt-antd-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .syt-antd-col-xxl-offset-23.syt-antd-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .syt-antd-col-xxl-push-24.syt-antd-col-rtl {
    right: 100%;
    left: auto;
  }
  .syt-antd-col-xxl-pull-24.syt-antd-col-rtl {
    right: auto;
    left: 100%;
  }
  .syt-antd-col-xxl-offset-24.syt-antd-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.syt-antd-row-rtl {
  direction: rtl;
}
.syt-antd-comment {
  position: relative;
  background-color: inherit;
}
.syt-antd-comment-inner {
  display: flex;
  padding: 16px 0;
}
.syt-antd-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.syt-antd-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.syt-antd-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.syt-antd-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.syt-antd-comment-content-author > a,
.syt-antd-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.syt-antd-comment-content-author-name {
  color: #696F88;
  font-size: 14px;
  transition: color 0.3s;
}
.syt-antd-comment-content-author-name > * {
  color: #696F88;
}
.syt-antd-comment-content-author-name > *:hover {
  color: #696F88;
}
.syt-antd-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.syt-antd-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}
.syt-antd-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}
.syt-antd-comment-actions > li {
  display: inline-block;
  color: #696F88;
}
.syt-antd-comment-actions > li > span {
  margin-right: 10px;
  color: #696F88;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  user-select: none;
}
.syt-antd-comment-actions > li > span:hover {
  color: #595959;
}
.syt-antd-comment-nested {
  margin-left: 44px;
}
.syt-antd-comment-rtl {
  direction: rtl;
}
.syt-antd-comment-rtl .syt-antd-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.syt-antd-comment-rtl .syt-antd-comment-content-author > a,
.syt-antd-comment-rtl .syt-antd-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}
.syt-antd-comment-rtl .syt-antd-comment-actions {
  padding-right: 0;
}
.syt-antd-comment-rtl .syt-antd-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}
.syt-antd-comment-rtl .syt-antd-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}
.syt-antd-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  outline: none;
}
.syt-antd-picker-panel-focused {
  border-color: #0071CD;
}
.syt-antd-picker-decade-panel,
.syt-antd-picker-year-panel,
.syt-antd-picker-quarter-panel,
.syt-antd-picker-month-panel,
.syt-antd-picker-week-panel,
.syt-antd-picker-date-panel,
.syt-antd-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.syt-antd-picker-header {
  display: flex;
  padding: 0 8px;
  color: #14151C;
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-picker-header > * {
  flex: none;
}
.syt-antd-picker-header button {
  padding: 0;
  color: #c1c5c8;
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.syt-antd-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.syt-antd-picker-header > button:hover {
  color: #14151C;
}
.syt-antd-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.syt-antd-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.syt-antd-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.syt-antd-picker-header-view button:hover {
  color: #0071CD;
}
.syt-antd-picker-prev-icon,
.syt-antd-picker-next-icon,
.syt-antd-picker-super-prev-icon,
.syt-antd-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.syt-antd-picker-prev-icon::before,
.syt-antd-picker-next-icon::before,
.syt-antd-picker-super-prev-icon::before,
.syt-antd-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.syt-antd-picker-super-prev-icon::after,
.syt-antd-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.syt-antd-picker-prev-icon,
.syt-antd-picker-super-prev-icon {
  transform: rotate(-45deg);
}
.syt-antd-picker-next-icon,
.syt-antd-picker-super-next-icon {
  transform: rotate(135deg);
}
.syt-antd-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.syt-antd-picker-content th,
.syt-antd-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.syt-antd-picker-content th {
  height: 30px;
  color: #14151C;
  line-height: 30px;
}
.syt-antd-picker-cell {
  padding: 3px 0;
  color: #c1c5c8;
  cursor: pointer;
}
.syt-antd-picker-cell-in-view {
  color: #14151C;
}
.syt-antd-picker-cell-disabled {
  cursor: not-allowed;
}
.syt-antd-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  transform: translateY(-50%);
  content: '';
}
.syt-antd-picker-cell .syt-antd-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 4px;
  transition: background 0.3s, border 0.3s;
}
.syt-antd-picker-cell:hover:not(.syt-antd-picker-cell-in-view) .syt-antd-picker-cell-inner,
.syt-antd-picker-cell:hover:not(.syt-antd-picker-cell-selected):not(.syt-antd-picker-cell-range-start):not(.syt-antd-picker-cell-range-end):not(.syt-antd-picker-cell-range-hover-start):not(.syt-antd-picker-cell-range-hover-end) .syt-antd-picker-cell-inner {
  background: #f5f5f5;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-today .syt-antd-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #0071CD;
  border-radius: 4px;
  content: '';
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range {
  position: relative;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range::before {
  background: #e6f8ff;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-selected .syt-antd-picker-cell-inner,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-start .syt-antd-picker-cell-inner,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-end .syt-antd-picker-cell-inner {
  color: #FFFFFF;
  background: #0071CD;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-start:not(.syt-antd-picker-cell-range-start-single)::before,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-end:not(.syt-antd-picker-cell-range-end-single)::before {
  background: #e6f8ff;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-start::before {
  left: 50%;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-end::before {
  right: 50%;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-start:not(.syt-antd-picker-cell-in-range):not(.syt-antd-picker-cell-range-start):not(.syt-antd-picker-cell-range-end)::after,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-end:not(.syt-antd-picker-cell-in-range):not(.syt-antd-picker-cell-range-start):not(.syt-antd-picker-cell-range-end)::after,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-start.syt-antd-picker-cell-range-start-single::after,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-end.syt-antd-picker-cell-range-end-single::after,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover:not(.syt-antd-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #34a4ff;
  border-bottom: 1px dashed #34a4ff;
  transform: translateY(-50%);
  content: '';
}
.syt-antd-picker-cell-range-hover-start::after,
.syt-antd-picker-cell-range-hover-end::after,
.syt-antd-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover::before,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-start.syt-antd-picker-cell-range-hover::before,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-end.syt-antd-picker-cell-range-hover::before,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-start:not(.syt-antd-picker-cell-range-start-single).syt-antd-picker-cell-range-hover-start::before,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-end:not(.syt-antd-picker-cell-range-end-single).syt-antd-picker-cell-range-hover-end::before,
.syt-antd-picker-panel > :not(.syt-antd-picker-date-panel) .syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover-start::before,
.syt-antd-picker-panel > :not(.syt-antd-picker-date-panel) .syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover-end::before {
  background: #81c6ff;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-start:not(.syt-antd-picker-cell-range-start-single):not(.syt-antd-picker-cell-range-end) .syt-antd-picker-cell-inner {
  border-radius: 4px 0 0 4px;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-end:not(.syt-antd-picker-cell-range-end-single):not(.syt-antd-picker-cell-range-start) .syt-antd-picker-cell-inner {
  border-radius: 0 4px 4px 0;
}
.syt-antd-picker-date-panel .syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover-start .syt-antd-picker-cell-inner::after,
.syt-antd-picker-date-panel .syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover-end .syt-antd-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #81c6ff;
  content: '';
}
.syt-antd-picker-date-panel .syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover-start .syt-antd-picker-cell-inner::after {
  right: -7px;
  left: 0;
}
.syt-antd-picker-date-panel .syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover-end .syt-antd-picker-cell-inner::after {
  right: 0;
  left: -7px;
}
.syt-antd-picker-cell-range-hover.syt-antd-picker-cell-range-start::after {
  right: 50%;
}
.syt-antd-picker-cell-range-hover.syt-antd-picker-cell-range-end::after {
  left: 50%;
}
tr > .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover:first-child::after,
tr > .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-end:first-child::after,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-edge-start:not(.syt-antd-picker-cell-range-hover-edge-start-near-range)::after,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #34a4ff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
tr > .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover:last-child::after,
tr > .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-start:last-child::after,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-edge-end:not(.syt-antd-picker-cell-range-hover-edge-end-near-range)::after,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #34a4ff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.syt-antd-picker-cell-disabled {
  pointer-events: none;
}
.syt-antd-picker-cell-disabled .syt-antd-picker-cell-inner {
  color: #c1c5c8;
  background: transparent;
}
.syt-antd-picker-cell-disabled::before {
  background: #f5f5f5;
}
.syt-antd-picker-cell-disabled.syt-antd-picker-cell-today .syt-antd-picker-cell-inner::before {
  border-color: #c1c5c8;
}
.syt-antd-picker-decade-panel .syt-antd-picker-content,
.syt-antd-picker-year-panel .syt-antd-picker-content,
.syt-antd-picker-quarter-panel .syt-antd-picker-content,
.syt-antd-picker-month-panel .syt-antd-picker-content {
  height: 264px;
}
.syt-antd-picker-decade-panel .syt-antd-picker-cell-inner,
.syt-antd-picker-year-panel .syt-antd-picker-cell-inner,
.syt-antd-picker-quarter-panel .syt-antd-picker-cell-inner,
.syt-antd-picker-month-panel .syt-antd-picker-cell-inner {
  padding: 0 8px;
}
.syt-antd-picker-decade-panel .syt-antd-picker-cell-disabled .syt-antd-picker-cell-inner,
.syt-antd-picker-year-panel .syt-antd-picker-cell-disabled .syt-antd-picker-cell-inner,
.syt-antd-picker-quarter-panel .syt-antd-picker-cell-disabled .syt-antd-picker-cell-inner,
.syt-antd-picker-month-panel .syt-antd-picker-cell-disabled .syt-antd-picker-cell-inner {
  background: #f5f5f5;
}
.syt-antd-picker-quarter-panel .syt-antd-picker-content {
  height: 56px;
}
.syt-antd-picker-footer {
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.syt-antd-picker-panel .syt-antd-picker-footer {
  border-top: 1px solid #f0f0f0;
}
.syt-antd-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}
.syt-antd-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-picker-now {
  text-align: left;
}
.syt-antd-picker-today-btn {
  color: #00a0be;
}
.syt-antd-picker-today-btn:hover {
  color: #21b8cc;
}
.syt-antd-picker-today-btn:active {
  color: #007d99;
}
.syt-antd-picker-today-btn.syt-antd-picker-today-btn-disabled {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-picker-decade-panel .syt-antd-picker-cell-inner {
  padding: 0 4px;
}
.syt-antd-picker-decade-panel .syt-antd-picker-cell::before {
  display: none;
}
.syt-antd-picker-year-panel .syt-antd-picker-body,
.syt-antd-picker-quarter-panel .syt-antd-picker-body,
.syt-antd-picker-month-panel .syt-antd-picker-body {
  padding: 0 8px;
}
.syt-antd-picker-year-panel .syt-antd-picker-cell-inner,
.syt-antd-picker-quarter-panel .syt-antd-picker-cell-inner,
.syt-antd-picker-month-panel .syt-antd-picker-cell-inner {
  width: 60px;
}
.syt-antd-picker-year-panel .syt-antd-picker-cell-range-hover-start::after,
.syt-antd-picker-quarter-panel .syt-antd-picker-cell-range-hover-start::after,
.syt-antd-picker-month-panel .syt-antd-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #34a4ff;
  border-radius: 4px 0 0 4px;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-year-panel .syt-antd-picker-cell-range-hover-start::after,
.syt-antd-picker-panel-rtl .syt-antd-picker-quarter-panel .syt-antd-picker-cell-range-hover-start::after,
.syt-antd-picker-panel-rtl .syt-antd-picker-month-panel .syt-antd-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #34a4ff;
  border-radius: 0 4px 4px 0;
}
.syt-antd-picker-year-panel .syt-antd-picker-cell-range-hover-end::after,
.syt-antd-picker-quarter-panel .syt-antd-picker-cell-range-hover-end::after,
.syt-antd-picker-month-panel .syt-antd-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #34a4ff;
  border-radius: 0 4px 4px 0;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-year-panel .syt-antd-picker-cell-range-hover-end::after,
.syt-antd-picker-panel-rtl .syt-antd-picker-quarter-panel .syt-antd-picker-cell-range-hover-end::after,
.syt-antd-picker-panel-rtl .syt-antd-picker-month-panel .syt-antd-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #34a4ff;
  border-radius: 4px 0 0 4px;
}
.syt-antd-picker-week-panel .syt-antd-picker-body {
  padding: 8px 12px;
}
.syt-antd-picker-week-panel .syt-antd-picker-cell:hover .syt-antd-picker-cell-inner,
.syt-antd-picker-week-panel .syt-antd-picker-cell-selected .syt-antd-picker-cell-inner,
.syt-antd-picker-week-panel .syt-antd-picker-cell .syt-antd-picker-cell-inner {
  background: transparent !important;
}
.syt-antd-picker-week-panel-row td {
  transition: background 0.3s;
}
.syt-antd-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.syt-antd-picker-week-panel-row-selected td,
.syt-antd-picker-week-panel-row-selected:hover td {
  background: #0071CD;
}
.syt-antd-picker-week-panel-row-selected td.syt-antd-picker-cell-week,
.syt-antd-picker-week-panel-row-selected:hover td.syt-antd-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.syt-antd-picker-week-panel-row-selected td.syt-antd-picker-cell-today .syt-antd-picker-cell-inner::before,
.syt-antd-picker-week-panel-row-selected:hover td.syt-antd-picker-cell-today .syt-antd-picker-cell-inner::before {
  border-color: #FFFFFF;
}
.syt-antd-picker-week-panel-row-selected td .syt-antd-picker-cell-inner,
.syt-antd-picker-week-panel-row-selected:hover td .syt-antd-picker-cell-inner {
  color: #FFFFFF;
}
.syt-antd-picker-date-panel .syt-antd-picker-body {
  padding: 8px 12px;
}
.syt-antd-picker-date-panel .syt-antd-picker-content {
  width: 252px;
}
.syt-antd-picker-date-panel .syt-antd-picker-content th {
  width: 36px;
}
.syt-antd-picker-datetime-panel {
  display: flex;
}
.syt-antd-picker-datetime-panel .syt-antd-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}
.syt-antd-picker-datetime-panel .syt-antd-picker-date-panel,
.syt-antd-picker-datetime-panel .syt-antd-picker-time-panel {
  transition: opacity 0.3s;
}
.syt-antd-picker-datetime-panel-active .syt-antd-picker-date-panel,
.syt-antd-picker-datetime-panel-active .syt-antd-picker-time-panel {
  opacity: 0.3;
}
.syt-antd-picker-datetime-panel-active .syt-antd-picker-date-panel-active,
.syt-antd-picker-datetime-panel-active .syt-antd-picker-time-panel-active {
  opacity: 1;
}
.syt-antd-picker-time-panel {
  width: auto;
  min-width: auto;
}
.syt-antd-picker-time-panel .syt-antd-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.syt-antd-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.syt-antd-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}
.syt-antd-picker-datetime-panel .syt-antd-picker-time-panel-column::after {
  height: 198px;
}
.syt-antd-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}
.syt-antd-picker-time-panel-column-active {
  background: rgba(230, 248, 255, 0.2);
}
.syt-antd-picker-time-panel-column:hover {
  overflow-y: auto;
}
.syt-antd-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.syt-antd-picker-time-panel-column > li.syt-antd-picker-time-panel-cell .syt-antd-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: #14151C;
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.syt-antd-picker-time-panel-column > li.syt-antd-picker-time-panel-cell .syt-antd-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.syt-antd-picker-time-panel-column > li.syt-antd-picker-time-panel-cell-selected .syt-antd-picker-time-panel-cell-inner {
  background: #e6f8ff;
}
.syt-antd-picker-time-panel-column > li.syt-antd-picker-time-panel-cell-disabled .syt-antd-picker-time-panel-cell-inner {
  color: #c1c5c8;
  background: transparent;
  cursor: not-allowed;
}
/* stylelint-disable-next-line */
_:-ms-fullscreen .syt-antd-picker-range-wrapper .syt-antd-picker-month-panel .syt-antd-picker-cell,
:root .syt-antd-picker-range-wrapper .syt-antd-picker-month-panel .syt-antd-picker-cell,
_:-ms-fullscreen .syt-antd-picker-range-wrapper .syt-antd-picker-year-panel .syt-antd-picker-cell,
:root .syt-antd-picker-range-wrapper .syt-antd-picker-year-panel .syt-antd-picker-cell {
  padding: 21px 0;
}
.syt-antd-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  padding: 0 11px 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #0092e4;
  border-radius: 4px;
  transition: border 0.3s, box-shadow 0.3s;
}
.syt-antd-picker:hover,
.syt-antd-picker-focused {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-rtl .syt-antd-picker:hover,
.syt-antd-input-rtl .syt-antd-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-picker-focused {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-picker.syt-antd-picker-disabled {
  background: #f5f5f5;
  border-color: #0092e4;
  cursor: not-allowed;
}
.syt-antd-picker.syt-antd-picker-disabled .syt-antd-picker-suffix {
  color: #c1c5c8;
}
.syt-antd-picker.syt-antd-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.syt-antd-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.syt-antd-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 3px 11px;
  color: #14151c;
  font-size: 14px;
  line-height: 20px;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #0092e4;
  border-radius: 4px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.syt-antd-picker-input > input::-moz-placeholder {
  opacity: 1;
}
.syt-antd-picker-input > input::placeholder {
  color: #bfbfbf;
}
.syt-antd-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.syt-antd-picker-input > input:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-rtl .syt-antd-picker-input > input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-picker-input > input:focus,
.syt-antd-picker-input > input-focused {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-picker-input > input:focus,
.syt-antd-input-rtl .syt-antd-picker-input > input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-picker-input > input-disabled {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-picker-input > input-disabled:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-picker-input > input[disabled] {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-picker-input > input[disabled]:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-picker-input > input-borderless,
.syt-antd-picker-input > input-borderless:hover,
.syt-antd-picker-input > input-borderless:focus,
.syt-antd-picker-input > input-borderless-focused,
.syt-antd-picker-input > input-borderless-disabled,
.syt-antd-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.syt-antd-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 20px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.syt-antd-picker-input > input-lg {
  padding: -141px 11px;
  font-size: 16px;
}
.syt-antd-picker-input > input-sm {
  padding: 0 7px;
}
.syt-antd-picker-input > input-rtl {
  direction: rtl;
}
.syt-antd-picker-input > input:focus {
  box-shadow: none;
}
.syt-antd-picker-input > input[disabled] {
  background: transparent;
}
.syt-antd-picker-input:hover .syt-antd-picker-clear {
  opacity: 1;
}
.syt-antd-picker-input-placeholder > input {
  color: #bfbfbf;
}
.syt-antd-picker-large {
  padding: 0 11px 0;
}
.syt-antd-picker-large .syt-antd-picker-input > input {
  font-size: 16px;
}
.syt-antd-picker-small {
  padding: 0 7px 0;
}
.syt-antd-picker-suffix {
  align-self: center;
  margin-left: 4px;
  color: #c1c5c8;
  line-height: 1;
  pointer-events: none;
}
.syt-antd-picker-suffix > * {
  vertical-align: top;
}
.syt-antd-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: #c1c5c8;
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.syt-antd-picker-clear > * {
  vertical-align: top;
}
.syt-antd-picker-clear:hover {
  color: #696F88;
}
.syt-antd-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: #c1c5c8;
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.syt-antd-picker-focused .syt-antd-picker-separator {
  color: #696F88;
}
.syt-antd-picker-disabled .syt-antd-picker-range-separator .syt-antd-picker-separator {
  cursor: not-allowed;
}
.syt-antd-picker-range {
  position: relative;
  display: inline-flex;
}
.syt-antd-picker-range .syt-antd-picker-clear {
  right: 11px;
}
.syt-antd-picker-range:hover .syt-antd-picker-clear {
  opacity: 1;
}
.syt-antd-picker-range .syt-antd-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #0071CD;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.syt-antd-picker-range.syt-antd-picker-focused .syt-antd-picker-active-bar {
  opacity: 1;
}
.syt-antd-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.syt-antd-picker-range.syt-antd-picker-small .syt-antd-picker-clear {
  right: 7px;
}
.syt-antd-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1050;
}
.syt-antd-picker-dropdown-hidden {
  display: none;
}
.syt-antd-picker-dropdown-placement-bottomLeft .syt-antd-picker-range-arrow {
  top: 1.66666667px;
  display: block;
  transform: rotate(-45deg);
}
.syt-antd-picker-dropdown-placement-topLeft .syt-antd-picker-range-arrow {
  bottom: 1.66666667px;
  display: block;
  transform: rotate(135deg);
}
.syt-antd-picker-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-picker-dropdown-placement-topLeft,
.syt-antd-picker-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-picker-dropdown-placement-topRight,
.syt-antd-picker-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-picker-dropdown-placement-topLeft,
.syt-antd-picker-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.syt-antd-picker-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-picker-dropdown-placement-bottomLeft,
.syt-antd-picker-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-picker-dropdown-placement-bottomRight,
.syt-antd-picker-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-picker-dropdown-placement-bottomLeft,
.syt-antd-picker-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.syt-antd-picker-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-picker-dropdown-placement-topLeft,
.syt-antd-picker-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.syt-antd-picker-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-picker-dropdown-placement-bottomLeft,
.syt-antd-picker-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.syt-antd-picker-dropdown-range {
  padding: 6.66666667px 0;
}
.syt-antd-picker-dropdown-range-hidden {
  display: none;
}
.syt-antd-picker-dropdown .syt-antd-picker-panel > .syt-antd-picker-time-panel {
  padding-top: 4px;
}
.syt-antd-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.syt-antd-picker-ranges > li {
  display: inline-block;
}
.syt-antd-picker-ranges .syt-antd-picker-preset > .syt-antd-tag-blue {
  color: #0071CD;
  background: #e6f8ff;
  border-color: #74c6f2;
  cursor: pointer;
}
.syt-antd-picker-ranges .syt-antd-picker-ok {
  float: right;
  margin-left: 8px;
}
.syt-antd-picker-range-wrapper {
  display: flex;
}
.syt-antd-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 10px;
  height: 10px;
  margin-left: 16.5px;
  box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
  transition: left 0.3s ease-out;
}
.syt-antd-picker-range-arrow::after {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 10px;
  height: 10px;
  border: 5px solid #f0f0f0;
  border-color: #fff #fff transparent transparent;
  content: '';
}
.syt-antd-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
  transition: margin 0.3s;
}
.syt-antd-picker-panel-container .syt-antd-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.syt-antd-picker-panel-container .syt-antd-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.syt-antd-picker-panel-container .syt-antd-picker-panel-focused {
  border-color: #f0f0f0;
}
.syt-antd-picker-rtl {
  direction: rtl;
}
.syt-antd-picker-rtl .syt-antd-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.syt-antd-picker-rtl .syt-antd-picker-clear {
  right: auto;
  left: 0;
}
.syt-antd-picker-rtl .syt-antd-picker-separator {
  transform: rotate(180deg);
}
.syt-antd-picker-panel-rtl .syt-antd-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.syt-antd-picker-rtl.syt-antd-picker-range .syt-antd-picker-clear {
  right: auto;
  left: 11px;
}
.syt-antd-picker-rtl.syt-antd-picker-range .syt-antd-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}
.syt-antd-picker-dropdown-rtl .syt-antd-picker-ranges {
  text-align: right;
}
.syt-antd-picker-dropdown-rtl .syt-antd-picker-ranges .syt-antd-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.syt-antd-picker-panel-rtl {
  direction: rtl;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-prev-icon,
.syt-antd-picker-panel-rtl .syt-antd-picker-super-prev-icon {
  transform: rotate(135deg);
}
.syt-antd-picker-panel-rtl .syt-antd-picker-next-icon,
.syt-antd-picker-panel-rtl .syt-antd-picker-super-next-icon {
  transform: rotate(-45deg);
}
.syt-antd-picker-cell .syt-antd-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 4px;
  transition: background 0.3s, border 0.3s;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-start.syt-antd-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-date-panel .syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover-start .syt-antd-picker-cell-inner::after {
  right: 0;
  left: -7px;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-date-panel .syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover-end .syt-antd-picker-cell-inner::after {
  right: -7px;
  left: 0;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-cell-range-hover.syt-antd-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-cell-range-hover.syt-antd-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-start:not(.syt-antd-picker-cell-range-start-single):not(.syt-antd-picker-cell-range-end) .syt-antd-picker-cell-inner {
  border-radius: 0 4px 4px 0;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-end:not(.syt-antd-picker-cell-range-end-single):not(.syt-antd-picker-cell-range-start) .syt-antd-picker-cell-inner {
  border-radius: 4px 0 0 4px;
}
.syt-antd-picker-panel-rtl tr > .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover:first-child::after,
.syt-antd-picker-panel-rtl tr > .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-end:first-child::after,
.syt-antd-picker-panel-rtl .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-edge-start:not(.syt-antd-picker-cell-range-hover-edge-start-near-range)::after,
.syt-antd-picker-panel-rtl .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #34a4ff;
  border-left: none;
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.syt-antd-picker-panel-rtl tr > .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover:last-child::after,
.syt-antd-picker-panel-rtl tr > .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-start:last-child::after,
.syt-antd-picker-panel-rtl .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-edge-end:not(.syt-antd-picker-cell-range-hover-edge-end-near-range)::after,
.syt-antd-picker-panel-rtl .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #34a4ff;
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}
.syt-antd-picker-panel-rtl tr > .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-start:last-child::after,
.syt-antd-picker-panel-rtl .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-start.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #34a4ff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.syt-antd-picker-panel-rtl tr > .syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-hover-end:first-child::after {
  left: 6px;
  border-left: 1px dashed #34a4ff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.syt-antd-picker-dropdown-rtl .syt-antd-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.syt-antd-picker-panel-rtl .syt-antd-picker-time-panel {
  direction: ltr;
}
/* -------- Input -------- */
.syt-antd-picker {
  border: 1px solid #C2C7D0;
  height: 32px;
}
.syt-antd-picker .syt-antd-picker-input > input {
  height: 24px;
  left: 0px;
  right: 0px;
  top: calc(50% - 24px/2);
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.0075em;
}
.syt-antd-picker-large {
  height: 40px;
}
.syt-antd-picker-large .syt-antd-picker-input > input {
  height: 24px;
  left: 0px;
  right: 0px;
  top: calc(50% - 24px/2);
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: -0.0075em;
}
.syt-antd-picker-small {
  height: 24px;
}
.syt-antd-picker-small .syt-antd-picker-input > input {
  height: 24px;
  left: 0px;
  right: 0px;
  top: calc(50% - 24px/2);
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.0075em;
}
.syt-antd-picker-input > input::placeholder,
.syt-antd-picker-separator,
.syt-antd-picker-suffix {
  color: #696F88;
}
.syt-antd-picker-focused {
  border: 1px solid #0092E4;
  box-shadow: 0 0 0 3px rgba(0, 146, 228, 0.25);
}
.syt-antd-picker.syt-antd-picker-disabled {
  background: #DFE2E7 !important;
  border: 1px solid #C2C7D0 !important;
}
.syt-antd-picker.syt-antd-picker-disabled .syt-antd-picker-suffix {
  color: #868CA2;
}
.syt-antd-picker.syt-antd-picker-disabled .syt-antd-picker-input > input[disabled] {
  color: #868CA2;
}
.syt-antd-picker.syt-antd-picker-disabled .syt-antd-picker-input > input[disabled]::placeholder {
  color: #868CA2;
}
/* -------- Header -------- */
.syt-antd-picker-header button {
  color: #4D5165;
}
.syt-antd-picker-header-view button {
  color: #14151C;
}
/* -------- Footer -------- */
.syt-antd-picker-footer a {
  color: #0071CD;
}
li.syt-antd-picker-ok button {
  color: #FFFFFF;
  background-color: #14803C;
  border-color: #14803C;
  border-radius: 4px;
}
li.syt-antd-picker-ok button:hover,
li.syt-antd-picker-ok button:focus {
  color: #FFFFFF;
  background-color: #19A04B;
  border-color: #19A04B;
}
li.syt-antd-picker-ok button:focus {
  box-shadow: 0px 0px 0px 3px rgba(25, 160, 75, 0.25);
}
li.syt-antd-picker-ok button:active {
  background-color: #0C612C;
  border-color: #0C612C;
}
li.syt-antd-picker-ok button:disabled {
  background: #F3F4F6;
  border: 1px solid #DFE2E7;
  box-sizing: border-box;
}
/* -------- Date Cells -------- */
.syt-antd-picker-cell.syt-antd-picker-cell-in-view {
  padding: 0px;
}
.syt-antd-picker-cell.syt-antd-picker-cell-in-view .syt-antd-picker-cell-inner {
  width: 36px;
  height: 32px;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 0;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-today .syt-antd-picker-cell-inner {
  text-decoration: underline;
  text-decoration-color: #0071CD;
  text-decoration-thickness: 2px;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-today .syt-antd-picker-cell-inner::before {
  border: none;
}
.syt-antd-picker-cell:not(.syt-antd-picker-cell-in-view) {
  color: #868CA2;
}
.syt-antd-picker-cell:hover:not(.syt-antd-picker-cell-in-view) .syt-antd-picker-cell-inner,
.syt-antd-picker-cell:hover:not(.syt-antd-picker-cell-selected):not(.syt-antd-picker-cell-range-start):not(.syt-antd-picker-cell-range-end):not(.syt-antd-picker-cell-range-hover-start):not(.syt-antd-picker-cell-range-hover-end) .syt-antd-picker-cell-inner {
  background: #F3F4F6;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-selected .syt-antd-picker-cell-inner,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-start .syt-antd-picker-cell-inner,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-end .syt-antd-picker-cell-inner {
  background: #0071CD;
  box-shadow: 0 0 0 3px rgba(0, 146, 228, 0.25);
}
.syt-antd-picker-cell-disabled {
  background: #F3F4F6;
}
.syt-antd-picker-cell-disabled::before {
  background: transparent;
}
.syt-antd-picker-cell-disabled .syt-antd-picker-cell-inner {
  color: #868CA2;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range {
  background: #EAF6FF;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range::before {
  background: transparent;
}
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover::before,
.syt-antd-picker-date-panel .syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover-end .syt-antd-picker-cell-inner::after,
.syt-antd-picker-date-panel .syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover-start .syt-antd-picker-cell-inner::after {
  background: #82CFFF;
}
/* -------- Time Cells --------*/
.syt-antd-picker-time-panel-column > li.syt-antd-picker-time-panel-cell.syt-antd-picker-time-panel-cell-selected .syt-antd-picker-time-panel-cell-inner {
  background: #EAF6FF;
}
.syt-antd-picker-time-panel-column > li.syt-antd-picker-time-panel-cell.syt-antd-picker-time-panel-cell-selected .syt-antd-picker-time-panel-cell-inner:hover {
  background: #F3F4F6;
}
.syt-antd-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.syt-antd-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: #14151C;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-descriptions-extra {
  margin-left: auto;
  color: #14151C;
  font-size: 14px;
}
.syt-antd-descriptions-view {
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
}
.syt-antd-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.syt-antd-descriptions-row > th,
.syt-antd-descriptions-row > td {
  padding-bottom: 16px;
}
.syt-antd-descriptions-row:last-child {
  border-bottom: none;
}
.syt-antd-descriptions-item-label {
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: start;
}
.syt-antd-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.syt-antd-descriptions-item-label.syt-antd-descriptions-item-no-colon::after {
  content: ' ';
}
.syt-antd-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.syt-antd-descriptions-item-content {
  display: table-cell;
  flex: 1;
  color: #14151C;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  overflow-wrap: break-word;
}
.syt-antd-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}
.syt-antd-descriptions-item > span {
  display: inline-flex;
  align-items: baseline;
}
.syt-antd-descriptions-item-container {
  display: flex;
}
.syt-antd-descriptions-middle .syt-antd-descriptions-row > th,
.syt-antd-descriptions-middle .syt-antd-descriptions-row > td {
  padding-bottom: 12px;
}
.syt-antd-descriptions-small .syt-antd-descriptions-row > th,
.syt-antd-descriptions-small .syt-antd-descriptions-row > td {
  padding-bottom: 8px;
}
.syt-antd-descriptions-bordered .syt-antd-descriptions-view {
  border: 1px solid #f0f0f0;
}
.syt-antd-descriptions-bordered .syt-antd-descriptions-view > table {
  table-layout: auto;
}
.syt-antd-descriptions-bordered .syt-antd-descriptions-item-label,
.syt-antd-descriptions-bordered .syt-antd-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}
.syt-antd-descriptions-bordered .syt-antd-descriptions-item-label:last-child,
.syt-antd-descriptions-bordered .syt-antd-descriptions-item-content:last-child {
  border-right: none;
}
.syt-antd-descriptions-bordered .syt-antd-descriptions-item-label {
  background-color: #fafafa;
}
.syt-antd-descriptions-bordered .syt-antd-descriptions-item-label::after {
  display: none;
}
.syt-antd-descriptions-bordered .syt-antd-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-descriptions-bordered .syt-antd-descriptions-row:last-child {
  border-bottom: none;
}
.syt-antd-descriptions-bordered.syt-antd-descriptions-middle .syt-antd-descriptions-item-label,
.syt-antd-descriptions-bordered.syt-antd-descriptions-middle .syt-antd-descriptions-item-content {
  padding: 12px 24px;
}
.syt-antd-descriptions-bordered.syt-antd-descriptions-small .syt-antd-descriptions-item-label,
.syt-antd-descriptions-bordered.syt-antd-descriptions-small .syt-antd-descriptions-item-content {
  padding: 8px 16px;
}
.syt-antd-descriptions-rtl {
  direction: rtl;
}
.syt-antd-descriptions-rtl .syt-antd-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}
.syt-antd-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.syt-antd-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.syt-antd-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}
.syt-antd-divider-horizontal.syt-antd-divider-with-text {
  display: flex;
  margin: 16px 0;
  color: #14151C;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.syt-antd-divider-horizontal.syt-antd-divider-with-text::before,
.syt-antd-divider-horizontal.syt-antd-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
}
.syt-antd-divider-horizontal.syt-antd-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.syt-antd-divider-horizontal.syt-antd-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.syt-antd-divider-horizontal.syt-antd-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.syt-antd-divider-horizontal.syt-antd-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.syt-antd-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.syt-antd-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}
.syt-antd-divider-horizontal.syt-antd-divider-with-text.syt-antd-divider-dashed {
  border-top: 0;
}
.syt-antd-divider-horizontal.syt-antd-divider-with-text.syt-antd-divider-dashed::before,
.syt-antd-divider-horizontal.syt-antd-divider-with-text.syt-antd-divider-dashed::after {
  border-style: dashed none none;
}
.syt-antd-divider-vertical.syt-antd-divider-dashed {
  border-width: 0 0 0 1px;
}
.syt-antd-divider-plain.syt-antd-divider-with-text {
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
}
.syt-antd-divider-rtl {
  direction: rtl;
}
.syt-antd-divider-rtl.syt-antd-divider-horizontal.syt-antd-divider-with-text-left::before {
  width: 95%;
}
.syt-antd-divider-rtl.syt-antd-divider-horizontal.syt-antd-divider-with-text-left::after {
  width: 5%;
}
.syt-antd-divider-rtl.syt-antd-divider-horizontal.syt-antd-divider-with-text-right::before {
  width: 5%;
}
.syt-antd-divider-rtl.syt-antd-divider-horizontal.syt-antd-divider-with-text-right::after {
  width: 95%;
}
.syt-antd-divider {
  border-color: #DFE2E7;
}
.syt-antd-divider-vertical {
  border-color: #DFE2E7;
}
.syt-antd-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.syt-antd-dropdown::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.syt-antd-dropdown-wrap {
  position: relative;
}
.syt-antd-dropdown-wrap .syt-antd-btn > .anticon-down {
  font-size: 10px;
}
.syt-antd-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.syt-antd-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.syt-antd-dropdown-hidden,
.syt-antd-dropdown-menu-hidden {
  display: none;
}
.syt-antd-dropdown-show-arrow.syt-antd-dropdown-placement-topCenter,
.syt-antd-dropdown-show-arrow.syt-antd-dropdown-placement-topLeft,
.syt-antd-dropdown-show-arrow.syt-antd-dropdown-placement-topRight {
  padding-bottom: 10px;
}
.syt-antd-dropdown-show-arrow.syt-antd-dropdown-placement-bottomCenter,
.syt-antd-dropdown-show-arrow.syt-antd-dropdown-placement-bottomLeft,
.syt-antd-dropdown-show-arrow.syt-antd-dropdown-placement-bottomRight {
  padding-top: 10px;
}
.syt-antd-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
.syt-antd-dropdown-placement-topCenter > .syt-antd-dropdown-arrow,
.syt-antd-dropdown-placement-topLeft > .syt-antd-dropdown-arrow,
.syt-antd-dropdown-placement-topRight > .syt-antd-dropdown-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.syt-antd-dropdown-placement-topCenter > .syt-antd-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.syt-antd-dropdown-placement-topLeft > .syt-antd-dropdown-arrow {
  left: 16px;
}
.syt-antd-dropdown-placement-topRight > .syt-antd-dropdown-arrow {
  right: 16px;
}
.syt-antd-dropdown-placement-bottomCenter > .syt-antd-dropdown-arrow,
.syt-antd-dropdown-placement-bottomLeft > .syt-antd-dropdown-arrow,
.syt-antd-dropdown-placement-bottomRight > .syt-antd-dropdown-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.syt-antd-dropdown-placement-bottomCenter > .syt-antd-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.syt-antd-dropdown-placement-bottomLeft > .syt-antd-dropdown-arrow {
  left: 16px;
}
.syt-antd-dropdown-placement-bottomRight > .syt-antd-dropdown-arrow {
  right: 16px;
}
.syt-antd-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
}
.syt-antd-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: #696F88;
  transition: all 0.3s;
}
.syt-antd-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
}
.syt-antd-dropdown-menu-submenu-popup > .syt-antd-dropdown-menu {
  transform-origin: 0 0;
}
.syt-antd-dropdown-menu-submenu-popup ul,
.syt-antd-dropdown-menu-submenu-popup li {
  list-style: none;
}
.syt-antd-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.syt-antd-dropdown-menu-item,
.syt-antd-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-dropdown-menu-item > .anticon:first-child,
.syt-antd-dropdown-menu-submenu-title > .anticon:first-child,
.syt-antd-dropdown-menu-item > a > .anticon:first-child,
.syt-antd-dropdown-menu-submenu-title > a > .anticon:first-child,
.syt-antd-dropdown-menu-item > span > .anticon:first-child,
.syt-antd-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
  vertical-align: -0.1em;
}
.syt-antd-dropdown-menu-item > a,
.syt-antd-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: #14151C;
  transition: all 0.3s;
}
.syt-antd-dropdown-menu-item > a:hover,
.syt-antd-dropdown-menu-submenu-title > a:hover {
  color: #14151C;
}
.syt-antd-dropdown-menu-item > .anticon + span > a,
.syt-antd-dropdown-menu-submenu-title > .anticon + span > a {
  color: #14151C;
  transition: all 0.3s;
}
.syt-antd-dropdown-menu-item > .anticon + span > a:hover,
.syt-antd-dropdown-menu-submenu-title > .anticon + span > a:hover {
  color: #14151C;
}
.syt-antd-dropdown-menu-item-selected,
.syt-antd-dropdown-menu-submenu-title-selected,
.syt-antd-dropdown-menu-item-selected > a,
.syt-antd-dropdown-menu-submenu-title-selected > a {
  color: #0071CD;
  background-color: #e6f8ff;
}
.syt-antd-dropdown-menu-item:hover,
.syt-antd-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
}
.syt-antd-dropdown-menu-item-disabled,
.syt-antd-dropdown-menu-submenu-title-disabled {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-dropdown-menu-item-disabled:hover,
.syt-antd-dropdown-menu-submenu-title-disabled:hover {
  color: #c1c5c8;
  background-color: #fff;
  cursor: not-allowed;
}
.syt-antd-dropdown-menu-item-divider,
.syt-antd-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.syt-antd-dropdown-menu-item .syt-antd-dropdown-menu-submenu-arrow,
.syt-antd-dropdown-menu-submenu-title .syt-antd-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px;
}
.syt-antd-dropdown-menu-item .syt-antd-dropdown-menu-submenu-arrow-icon,
.syt-antd-dropdown-menu-submenu-title .syt-antd-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: #696F88;
  font-size: 10px;
  font-style: normal;
}
.syt-antd-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.syt-antd-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.syt-antd-dropdown-menu-submenu-vertical {
  position: relative;
}
.syt-antd-dropdown-menu-submenu-vertical > .syt-antd-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.syt-antd-dropdown-menu-submenu.syt-antd-dropdown-menu-submenu-disabled .syt-antd-dropdown-menu-submenu-title,
.syt-antd-dropdown-menu-submenu.syt-antd-dropdown-menu-submenu-disabled .syt-antd-dropdown-menu-submenu-title .syt-antd-dropdown-menu-submenu-arrow-icon {
  color: #c1c5c8;
  background-color: #fff;
  cursor: not-allowed;
}
.syt-antd-dropdown-menu-submenu-selected .syt-antd-dropdown-menu-submenu-title {
  color: #0071CD;
}
.syt-antd-dropdown.slide-down-enter.slide-down-enter-active.syt-antd-dropdown-placement-bottomLeft,
.syt-antd-dropdown.slide-down-appear.slide-down-appear-active.syt-antd-dropdown-placement-bottomLeft,
.syt-antd-dropdown.slide-down-enter.slide-down-enter-active.syt-antd-dropdown-placement-bottomCenter,
.syt-antd-dropdown.slide-down-appear.slide-down-appear-active.syt-antd-dropdown-placement-bottomCenter,
.syt-antd-dropdown.slide-down-enter.slide-down-enter-active.syt-antd-dropdown-placement-bottomRight,
.syt-antd-dropdown.slide-down-appear.slide-down-appear-active.syt-antd-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.syt-antd-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-dropdown-placement-topLeft,
.syt-antd-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-dropdown-placement-topLeft,
.syt-antd-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-dropdown-placement-topCenter,
.syt-antd-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-dropdown-placement-topCenter,
.syt-antd-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-dropdown-placement-topRight,
.syt-antd-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.syt-antd-dropdown.slide-down-leave.slide-down-leave-active.syt-antd-dropdown-placement-bottomLeft,
.syt-antd-dropdown.slide-down-leave.slide-down-leave-active.syt-antd-dropdown-placement-bottomCenter,
.syt-antd-dropdown.slide-down-leave.slide-down-leave-active.syt-antd-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.syt-antd-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-dropdown-placement-topLeft,
.syt-antd-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-dropdown-placement-topCenter,
.syt-antd-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.syt-antd-dropdown-trigger > .anticon.anticon-down,
.syt-antd-dropdown-link > .anticon.anticon-down,
.syt-antd-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.syt-antd-dropdown-button {
  white-space: nowrap;
}
.syt-antd-dropdown-button.syt-antd-btn-group > .syt-antd-btn:last-child:not(:first-child):not(.syt-antd-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.syt-antd-dropdown-menu-dark,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu {
  background: #ffffff;
}
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-submenu-title,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item > a,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item > .anticon + span > a {
  color: #868CA2;
}
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item .syt-antd-dropdown-menu-submenu-arrow::after,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-submenu-title .syt-antd-dropdown-menu-submenu-arrow::after,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item > a .syt-antd-dropdown-menu-submenu-arrow::after,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item > .anticon + span > a .syt-antd-dropdown-menu-submenu-arrow::after {
  color: #868CA2;
}
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item:hover,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-submenu-title:hover,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item > a:hover,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item > .anticon + span > a:hover {
  color: #FFFFFF;
  background: transparent;
}
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item-selected,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item-selected:hover,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item-selected > a {
  color: #FFFFFF;
  background: #0071CD;
}
.syt-antd-dropdown-menu-item.syt-antd-dropdown-menu-item-danger {
  color: #CF3537;
}
.syt-antd-dropdown-menu-item.syt-antd-dropdown-menu-item-danger:hover {
  color: #FFFFFF;
  background-color: #CF3537;
}
.syt-antd-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.syt-antd-dropdown::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.syt-antd-dropdown-wrap {
  position: relative;
}
.syt-antd-dropdown-wrap .syt-antd-btn > .anticon-down {
  font-size: 10px;
}
.syt-antd-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.syt-antd-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.syt-antd-dropdown-hidden,
.syt-antd-dropdown-menu-hidden {
  display: none;
}
.syt-antd-dropdown-show-arrow.syt-antd-dropdown-placement-topCenter,
.syt-antd-dropdown-show-arrow.syt-antd-dropdown-placement-topLeft,
.syt-antd-dropdown-show-arrow.syt-antd-dropdown-placement-topRight {
  padding-bottom: 10px;
}
.syt-antd-dropdown-show-arrow.syt-antd-dropdown-placement-bottomCenter,
.syt-antd-dropdown-show-arrow.syt-antd-dropdown-placement-bottomLeft,
.syt-antd-dropdown-show-arrow.syt-antd-dropdown-placement-bottomRight {
  padding-top: 10px;
}
.syt-antd-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
.syt-antd-dropdown-placement-topCenter > .syt-antd-dropdown-arrow,
.syt-antd-dropdown-placement-topLeft > .syt-antd-dropdown-arrow,
.syt-antd-dropdown-placement-topRight > .syt-antd-dropdown-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.syt-antd-dropdown-placement-topCenter > .syt-antd-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.syt-antd-dropdown-placement-topLeft > .syt-antd-dropdown-arrow {
  left: 16px;
}
.syt-antd-dropdown-placement-topRight > .syt-antd-dropdown-arrow {
  right: 16px;
}
.syt-antd-dropdown-placement-bottomCenter > .syt-antd-dropdown-arrow,
.syt-antd-dropdown-placement-bottomLeft > .syt-antd-dropdown-arrow,
.syt-antd-dropdown-placement-bottomRight > .syt-antd-dropdown-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.syt-antd-dropdown-placement-bottomCenter > .syt-antd-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.syt-antd-dropdown-placement-bottomLeft > .syt-antd-dropdown-arrow {
  left: 16px;
}
.syt-antd-dropdown-placement-bottomRight > .syt-antd-dropdown-arrow {
  right: 16px;
}
.syt-antd-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
}
.syt-antd-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: #696F88;
  transition: all 0.3s;
}
.syt-antd-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
}
.syt-antd-dropdown-menu-submenu-popup > .syt-antd-dropdown-menu {
  transform-origin: 0 0;
}
.syt-antd-dropdown-menu-submenu-popup ul,
.syt-antd-dropdown-menu-submenu-popup li {
  list-style: none;
}
.syt-antd-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.syt-antd-dropdown-menu-item,
.syt-antd-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-dropdown-menu-item > .anticon:first-child,
.syt-antd-dropdown-menu-submenu-title > .anticon:first-child,
.syt-antd-dropdown-menu-item > a > .anticon:first-child,
.syt-antd-dropdown-menu-submenu-title > a > .anticon:first-child,
.syt-antd-dropdown-menu-item > span > .anticon:first-child,
.syt-antd-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
  vertical-align: -0.1em;
}
.syt-antd-dropdown-menu-item > a,
.syt-antd-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: #14151C;
  transition: all 0.3s;
}
.syt-antd-dropdown-menu-item > a:hover,
.syt-antd-dropdown-menu-submenu-title > a:hover {
  color: #14151C;
}
.syt-antd-dropdown-menu-item > .anticon + span > a,
.syt-antd-dropdown-menu-submenu-title > .anticon + span > a {
  color: #14151C;
  transition: all 0.3s;
}
.syt-antd-dropdown-menu-item > .anticon + span > a:hover,
.syt-antd-dropdown-menu-submenu-title > .anticon + span > a:hover {
  color: #14151C;
}
.syt-antd-dropdown-menu-item-selected,
.syt-antd-dropdown-menu-submenu-title-selected,
.syt-antd-dropdown-menu-item-selected > a,
.syt-antd-dropdown-menu-submenu-title-selected > a {
  color: #0071CD;
  background-color: #e6f8ff;
}
.syt-antd-dropdown-menu-item:hover,
.syt-antd-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
}
.syt-antd-dropdown-menu-item-disabled,
.syt-antd-dropdown-menu-submenu-title-disabled {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-dropdown-menu-item-disabled:hover,
.syt-antd-dropdown-menu-submenu-title-disabled:hover {
  color: #c1c5c8;
  background-color: #fff;
  cursor: not-allowed;
}
.syt-antd-dropdown-menu-item-divider,
.syt-antd-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.syt-antd-dropdown-menu-item .syt-antd-dropdown-menu-submenu-arrow,
.syt-antd-dropdown-menu-submenu-title .syt-antd-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px;
}
.syt-antd-dropdown-menu-item .syt-antd-dropdown-menu-submenu-arrow-icon,
.syt-antd-dropdown-menu-submenu-title .syt-antd-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: #696F88;
  font-size: 10px;
  font-style: normal;
}
.syt-antd-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.syt-antd-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.syt-antd-dropdown-menu-submenu-vertical {
  position: relative;
}
.syt-antd-dropdown-menu-submenu-vertical > .syt-antd-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.syt-antd-dropdown-menu-submenu.syt-antd-dropdown-menu-submenu-disabled .syt-antd-dropdown-menu-submenu-title,
.syt-antd-dropdown-menu-submenu.syt-antd-dropdown-menu-submenu-disabled .syt-antd-dropdown-menu-submenu-title .syt-antd-dropdown-menu-submenu-arrow-icon {
  color: #c1c5c8;
  background-color: #fff;
  cursor: not-allowed;
}
.syt-antd-dropdown-menu-submenu-selected .syt-antd-dropdown-menu-submenu-title {
  color: #0071CD;
}
.syt-antd-dropdown.slide-down-enter.slide-down-enter-active.syt-antd-dropdown-placement-bottomLeft,
.syt-antd-dropdown.slide-down-appear.slide-down-appear-active.syt-antd-dropdown-placement-bottomLeft,
.syt-antd-dropdown.slide-down-enter.slide-down-enter-active.syt-antd-dropdown-placement-bottomCenter,
.syt-antd-dropdown.slide-down-appear.slide-down-appear-active.syt-antd-dropdown-placement-bottomCenter,
.syt-antd-dropdown.slide-down-enter.slide-down-enter-active.syt-antd-dropdown-placement-bottomRight,
.syt-antd-dropdown.slide-down-appear.slide-down-appear-active.syt-antd-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.syt-antd-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-dropdown-placement-topLeft,
.syt-antd-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-dropdown-placement-topLeft,
.syt-antd-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-dropdown-placement-topCenter,
.syt-antd-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-dropdown-placement-topCenter,
.syt-antd-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-dropdown-placement-topRight,
.syt-antd-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.syt-antd-dropdown.slide-down-leave.slide-down-leave-active.syt-antd-dropdown-placement-bottomLeft,
.syt-antd-dropdown.slide-down-leave.slide-down-leave-active.syt-antd-dropdown-placement-bottomCenter,
.syt-antd-dropdown.slide-down-leave.slide-down-leave-active.syt-antd-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.syt-antd-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-dropdown-placement-topLeft,
.syt-antd-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-dropdown-placement-topCenter,
.syt-antd-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.syt-antd-dropdown-trigger > .anticon.anticon-down,
.syt-antd-dropdown-link > .anticon.anticon-down,
.syt-antd-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.syt-antd-dropdown-button {
  white-space: nowrap;
}
.syt-antd-dropdown-button.syt-antd-btn-group > .syt-antd-btn:last-child:not(:first-child):not(.syt-antd-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.syt-antd-dropdown-menu-dark,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu {
  background: #ffffff;
}
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-submenu-title,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item > a,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item > .anticon + span > a {
  color: #868CA2;
}
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item .syt-antd-dropdown-menu-submenu-arrow::after,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-submenu-title .syt-antd-dropdown-menu-submenu-arrow::after,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item > a .syt-antd-dropdown-menu-submenu-arrow::after,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item > .anticon + span > a .syt-antd-dropdown-menu-submenu-arrow::after {
  color: #868CA2;
}
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item:hover,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-submenu-title:hover,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item > a:hover,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item > .anticon + span > a:hover {
  color: #FFFFFF;
  background: transparent;
}
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item-selected,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item-selected:hover,
.syt-antd-dropdown-menu-dark .syt-antd-dropdown-menu-item-selected > a {
  color: #FFFFFF;
  background: #0071CD;
}
.syt-antd-dropdown-rtl {
  direction: rtl;
}
.syt-antd-dropdown-rtl.syt-antd-dropdown::before {
  right: -7px;
  left: 0;
}
.syt-antd-dropdown-menu.syt-antd-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-submenu-popup ul,
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-submenu-popup li {
  text-align: right;
}
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-item,
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-submenu-title {
  text-align: right;
}
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-item > .anticon:first-child,
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-submenu-title > .anticon:first-child,
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-item > span > .anticon:first-child,
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-item .syt-antd-dropdown-menu-submenu-arrow,
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-submenu-title .syt-antd-dropdown-menu-submenu-arrow {
  right: auto;
  left: 8px;
}
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-item .syt-antd-dropdown-menu-submenu-arrow-icon,
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-submenu-title .syt-antd-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.syt-antd-dropdown-rtl .syt-antd-dropdown-menu-submenu-vertical > .syt-antd-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}
.syt-antd-dropdown-menu:not(.syt-antd-dropdown-menu-submenu) {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
a.syt-antd-dropdown-trigger {
  color: #0071CD;
}
a.syt-antd-dropdown-trigger:hover {
  color: #0092E4;
}
a.syt-antd-dropdown-trigger:active {
  color: #005693;
}
a.syt-antd-dropdown-trigger:disabled {
  color: #A3A9B9;
}
.syt-antd-dropdown-menu-item,
.syt-antd-dropdown-menu-submenu-title {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  padding: 6px 12px;
}
.syt-antd-dropdown-menu-item:hover {
  background-color: #F3F4F6;
}
.syt-antd-dropdown-menu-item-group-title {
  color: #696F88;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.0075em;
}
.syt-antd-dropdown-menu-item-divider,
.syt-antd-dropdown-menu-submenu-title-divider {
  background-color: #DFE2E7;
}
.syt-antd-dropdown-menu-item.syt-antd-dropdown-menu-item-danger {
  color: #CF3537;
}
.syt-antd-dropdown-menu-item.syt-antd-dropdown-menu-item-danger:hover {
  background-color: #FFF1F1;
  color: #CF3537;
}
.syt-antd-dropdown-menu-item-disabled,
.syt-antd-dropdown-menu-submenu.syt-antd-dropdown-menu-submenu-disabled .syt-antd-dropdown-menu-submenu-title {
  color: #A3A9B9;
}
.syt-antd-dropdown-menu-item-disabled:hover,
.syt-antd-dropdown-menu-submenu.syt-antd-dropdown-menu-submenu-disabled .syt-antd-dropdown-menu-submenu-title:hover {
  color: #A3A9B9;
}
.syt-antd-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
}
.syt-antd-drawer > * {
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.syt-antd-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.syt-antd-drawer .syt-antd-drawer-content {
  width: 100%;
  height: 100%;
}
.syt-antd-drawer-left,
.syt-antd-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.syt-antd-drawer-left .syt-antd-drawer-content-wrapper,
.syt-antd-drawer-right .syt-antd-drawer-content-wrapper {
  height: 100%;
}
.syt-antd-drawer-left.syt-antd-drawer-open,
.syt-antd-drawer-right.syt-antd-drawer-open {
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.syt-antd-drawer-left {
  left: 0;
}
.syt-antd-drawer-left .syt-antd-drawer-content-wrapper {
  left: 0;
}
.syt-antd-drawer-left.syt-antd-drawer-open .syt-antd-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.syt-antd-drawer-right {
  right: 0;
}
.syt-antd-drawer-right .syt-antd-drawer-content-wrapper {
  right: 0;
}
.syt-antd-drawer-right.syt-antd-drawer-open .syt-antd-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.syt-antd-drawer-right.syt-antd-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.syt-antd-drawer-top,
.syt-antd-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.syt-antd-drawer-top .syt-antd-drawer-content-wrapper,
.syt-antd-drawer-bottom .syt-antd-drawer-content-wrapper {
  width: 100%;
}
.syt-antd-drawer-top.syt-antd-drawer-open,
.syt-antd-drawer-bottom.syt-antd-drawer-open {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.syt-antd-drawer-top {
  top: 0;
}
.syt-antd-drawer-top.syt-antd-drawer-open .syt-antd-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.syt-antd-drawer-bottom {
  bottom: 0;
}
.syt-antd-drawer-bottom .syt-antd-drawer-content-wrapper {
  bottom: 0;
}
.syt-antd-drawer-bottom.syt-antd-drawer-open .syt-antd-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.syt-antd-drawer-bottom.syt-antd-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.syt-antd-drawer.syt-antd-drawer-open .syt-antd-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  pointer-events: auto;
}
.syt-antd-drawer-title {
  margin: 0;
  color: #14151C;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.syt-antd-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.syt-antd-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 20px;
  color: #696F88;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.syt-antd-drawer-close:focus,
.syt-antd-drawer-close:hover {
  color: #868CA2;
  text-decoration: none;
}
.syt-antd-drawer-header-no-title .syt-antd-drawer-close {
  margin-right: var(--scroll-bar);
  /* stylelint-disable-next-line function-calc-no-invalid */
  padding-right: calc(20px - var(--scroll-bar));
}
.syt-antd-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: #14151C;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.syt-antd-drawer-header-no-title {
  color: #14151C;
  background: #fff;
}
.syt-antd-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.syt-antd-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word;
}
.syt-antd-drawer-footer {
  flex-shrink: 0;
  padding: 10px 10px;
  border-top: 1px solid #f0f0f0;
}
.syt-antd-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.syt-antd-drawer-open-content {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.syt-antd-drawer .syt-antd-picker-clear {
  background: #fff;
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.syt-antd-drawer-rtl {
  direction: rtl;
}
.syt-antd-drawer-rtl .syt-antd-drawer-close {
  right: auto;
  left: 0;
}
.syt-antd-drawer-header {
  padding: 24px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0075em;
  border-bottom: none;
}
.syt-antd-drawer-close {
  padding: 24px;
}
.syt-antd-drawer-body {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: #14151C;
}
.syt-antd-drawer-footer {
  padding: 16px 24px;
  border-top: none;
}
.syt-antd-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.syt-antd-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.syt-antd-empty-image img {
  height: 100%;
}
.syt-antd-empty-image svg {
  height: 100%;
  margin: auto;
}
.syt-antd-empty-description {
  margin: 0;
}
.syt-antd-empty-footer {
  margin-top: 16px;
}
.syt-antd-empty-normal {
  margin: 32px 0;
  color: #c1c5c8;
}
.syt-antd-empty-normal .syt-antd-empty-image {
  height: 40px;
}
.syt-antd-empty-small {
  margin: 8px 0;
  color: #c1c5c8;
}
.syt-antd-empty-small .syt-antd-empty-image {
  height: 35px;
}
.syt-antd-empty-img-default-ellipse {
  fill-opacity: 0.8;
  fill: #f5f5f5;
}
.syt-antd-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.syt-antd-empty-img-default-path-2 {
  fill: url(#linearGradient-1);
}
.syt-antd-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.syt-antd-empty-img-default-path-4 {
  fill: #dce0e6;
}
.syt-antd-empty-img-default-path-5 {
  fill: #dce0e6;
}
.syt-antd-empty-img-default-g {
  fill: #fff;
}
.syt-antd-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.syt-antd-empty-img-simple-g {
  stroke: #d9d9d9;
}
.syt-antd-empty-img-simple-path {
  fill: #fafafa;
}
.syt-antd-empty-rtl {
  direction: rtl;
}
.syt-antd-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
}
.syt-antd-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: #696F88;
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #0092e4;
}
.syt-antd-form label {
  font-size: 14px;
}
.syt-antd-form input[type='search'] {
  box-sizing: border-box;
}
.syt-antd-form input[type='radio'],
.syt-antd-form input[type='checkbox'] {
  line-height: normal;
}
.syt-antd-form input[type='file'] {
  display: block;
}
.syt-antd-form input[type='range'] {
  display: block;
  width: 100%;
}
.syt-antd-form select[multiple],
.syt-antd-form select[size] {
  height: auto;
}
.syt-antd-form input[type='file']:focus,
.syt-antd-form input[type='radio']:focus,
.syt-antd-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.syt-antd-form output {
  display: block;
  padding-top: 15px;
  color: #14151c;
  font-size: 14px;
  line-height: 20px;
}
.syt-antd-form .syt-antd-form-text {
  display: inline-block;
  padding-right: 8px;
}
.syt-antd-form-small .syt-antd-form-item-label > label {
  height: 24px;
}
.syt-antd-form-small .syt-antd-form-item-control-input {
  min-height: 24px;
}
.syt-antd-form-large .syt-antd-form-item-label > label {
  height: 40px;
}
.syt-antd-form-large .syt-antd-form-item-control-input {
  min-height: 40px;
}
.syt-antd-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-bottom: 24px;
  vertical-align: top;
}
.syt-antd-form-item-with-help {
  margin-bottom: 0;
}
.syt-antd-form-item-hidden,
.syt-antd-form-item-hidden.syt-antd-row {
  display: none;
}
.syt-antd-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.syt-antd-form-item-label-left {
  text-align: left;
}
.syt-antd-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  color: #14151C;
  font-size: 14px;
}
.syt-antd-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.syt-antd-form-item-label > label.syt-antd-form-item-required:not(.syt-antd-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.syt-antd-form-hide-required-mark .syt-antd-form-item-label > label.syt-antd-form-item-required:not(.syt-antd-form-item-required-mark-optional)::before {
  display: none;
}
.syt-antd-form-item-label > label .syt-antd-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: #696F88;
}
.syt-antd-form-hide-required-mark .syt-antd-form-item-label > label .syt-antd-form-item-optional {
  display: none;
}
.syt-antd-form-item-label > label .syt-antd-form-item-tooltip {
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
  color: #696F88;
}
.syt-antd-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.syt-antd-form-item-label > label.syt-antd-form-item-no-colon::after {
  content: ' ';
}
.syt-antd-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.syt-antd-form-item-control:first-child:not([class^='syt-antd-col-']):not([class*=' syt-antd-col-']) {
  width: 100%;
}
.syt-antd-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}
.syt-antd-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.syt-antd-form-item-explain,
.syt-antd-form-item-extra {
  clear: both;
  min-height: 24px;
  margin-top: -128px;
  margin-bottom: -128px;
  color: #696F88;
  font-size: 14px;
  line-height: 20px;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.show-help-enter,
.show-help-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  animation-name: antShowHelpIn;
  animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  animation-name: antShowHelpOut;
  animation-play-state: running;
  pointer-events: none;
}
.show-help-enter,
.show-help-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-enter,
.show-help-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  animation-name: antShowHelpIn;
  animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  animation-name: antShowHelpOut;
  animation-play-state: running;
  pointer-events: none;
}
.show-help-enter,
.show-help-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.syt-antd-form-item .syt-antd-mentions,
.syt-antd-form-item textarea.syt-antd-input {
  height: auto;
}
.syt-antd-form-item .syt-antd-upload {
  background: transparent;
}
.syt-antd-form-item .syt-antd-upload.syt-antd-upload-drag {
  background: #fafafa;
}
.syt-antd-form-item input[type='radio'],
.syt-antd-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.syt-antd-form-item .syt-antd-radio-inline,
.syt-antd-form-item .syt-antd-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
.syt-antd-form-item .syt-antd-radio-inline:first-child,
.syt-antd-form-item .syt-antd-checkbox-inline:first-child {
  margin-left: 0;
}
.syt-antd-form-item .syt-antd-checkbox-vertical,
.syt-antd-form-item .syt-antd-radio-vertical {
  display: block;
}
.syt-antd-form-item .syt-antd-checkbox-vertical + .syt-antd-checkbox-vertical,
.syt-antd-form-item .syt-antd-radio-vertical + .syt-antd-radio-vertical {
  margin-left: 0;
}
.syt-antd-form-item .syt-antd-input-number + .syt-antd-form-text {
  margin-left: 8px;
}
.syt-antd-form-item .syt-antd-input-number-handler-wrap {
  z-index: 2;
}
.syt-antd-form-item .syt-antd-select,
.syt-antd-form-item .syt-antd-cascader-picker {
  width: 100%;
}
.syt-antd-form-item .syt-antd-input-group .syt-antd-select,
.syt-antd-form-item .syt-antd-input-group .syt-antd-cascader-picker {
  width: auto;
}
.syt-antd-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.syt-antd-form-inline .syt-antd-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.syt-antd-form-inline .syt-antd-form-item-with-help {
  margin-bottom: 24px;
}
.syt-antd-form-inline .syt-antd-form-item > .syt-antd-form-item-label,
.syt-antd-form-inline .syt-antd-form-item > .syt-antd-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.syt-antd-form-inline .syt-antd-form-item > .syt-antd-form-item-label {
  flex: none;
}
.syt-antd-form-inline .syt-antd-form-item .syt-antd-form-text {
  display: inline-block;
}
.syt-antd-form-inline .syt-antd-form-item .syt-antd-form-item-has-feedback {
  display: inline-block;
}
.syt-antd-form-horizontal .syt-antd-form-item-label {
  flex-grow: 0;
}
.syt-antd-form-horizontal .syt-antd-form-item-control {
  flex: 1 1 0;
}
.syt-antd-form-vertical .syt-antd-form-item {
  flex-direction: column;
}
.syt-antd-form-vertical .syt-antd-form-item-label > label {
  height: auto;
}
.syt-antd-form-vertical .syt-antd-form-item-label,
.syt-antd-col-24.syt-antd-form-item-label,
.syt-antd-col-xl-24.syt-antd-form-item-label {
  padding: 0 0 8px;
  line-height: 20px;
  white-space: initial;
  text-align: left;
}
.syt-antd-form-vertical .syt-antd-form-item-label > label,
.syt-antd-col-24.syt-antd-form-item-label > label,
.syt-antd-col-xl-24.syt-antd-form-item-label > label {
  margin: 0;
}
.syt-antd-form-vertical .syt-antd-form-item-label > label::after,
.syt-antd-col-24.syt-antd-form-item-label > label::after,
.syt-antd-col-xl-24.syt-antd-form-item-label > label::after {
  display: none;
}
.syt-antd-form-rtl.syt-antd-form-vertical .syt-antd-form-item-label,
.syt-antd-form-rtl.syt-antd-col-24.syt-antd-form-item-label,
.syt-antd-form-rtl.syt-antd-col-xl-24.syt-antd-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .syt-antd-form-item .syt-antd-form-item-label {
    padding: 0 0 8px;
    line-height: 20px;
    white-space: initial;
    text-align: left;
  }
  .syt-antd-form-item .syt-antd-form-item-label > label {
    margin: 0;
  }
  .syt-antd-form-item .syt-antd-form-item-label > label::after {
    display: none;
  }
  .syt-antd-form-rtl.syt-antd-form-item .syt-antd-form-item-label {
    text-align: right;
  }
  .syt-antd-form .syt-antd-form-item {
    flex-wrap: wrap;
  }
  .syt-antd-form .syt-antd-form-item .syt-antd-form-item-label,
  .syt-antd-form .syt-antd-form-item .syt-antd-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .syt-antd-col-xs-24.syt-antd-form-item-label {
    padding: 0 0 8px;
    line-height: 20px;
    white-space: initial;
    text-align: left;
  }
  .syt-antd-col-xs-24.syt-antd-form-item-label > label {
    margin: 0;
  }
  .syt-antd-col-xs-24.syt-antd-form-item-label > label::after {
    display: none;
  }
  .syt-antd-form-rtl.syt-antd-col-xs-24.syt-antd-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .syt-antd-col-sm-24.syt-antd-form-item-label {
    padding: 0 0 8px;
    line-height: 20px;
    white-space: initial;
    text-align: left;
  }
  .syt-antd-col-sm-24.syt-antd-form-item-label > label {
    margin: 0;
  }
  .syt-antd-col-sm-24.syt-antd-form-item-label > label::after {
    display: none;
  }
  .syt-antd-form-rtl.syt-antd-col-sm-24.syt-antd-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .syt-antd-col-md-24.syt-antd-form-item-label {
    padding: 0 0 8px;
    line-height: 20px;
    white-space: initial;
    text-align: left;
  }
  .syt-antd-col-md-24.syt-antd-form-item-label > label {
    margin: 0;
  }
  .syt-antd-col-md-24.syt-antd-form-item-label > label::after {
    display: none;
  }
  .syt-antd-form-rtl.syt-antd-col-md-24.syt-antd-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .syt-antd-col-lg-24.syt-antd-form-item-label {
    padding: 0 0 8px;
    line-height: 20px;
    white-space: initial;
    text-align: left;
  }
  .syt-antd-col-lg-24.syt-antd-form-item-label > label {
    margin: 0;
  }
  .syt-antd-col-lg-24.syt-antd-form-item-label > label::after {
    display: none;
  }
  .syt-antd-form-rtl.syt-antd-col-lg-24.syt-antd-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .syt-antd-col-xl-24.syt-antd-form-item-label {
    padding: 0 0 8px;
    line-height: 20px;
    white-space: initial;
    text-align: left;
  }
  .syt-antd-col-xl-24.syt-antd-form-item-label > label {
    margin: 0;
  }
  .syt-antd-col-xl-24.syt-antd-form-item-label > label::after {
    display: none;
  }
  .syt-antd-form-rtl.syt-antd-col-xl-24.syt-antd-form-item-label {
    text-align: right;
  }
}
.syt-antd-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.syt-antd-form-item-explain.syt-antd-form-item-explain-error {
  color: #CF3537;
}
.syt-antd-form-item-explain.syt-antd-form-item-explain-warning {
  color: #ffca41;
}
.syt-antd-form-item-has-feedback .syt-antd-input {
  padding-right: 24px;
}
.syt-antd-form-item-has-feedback .syt-antd-input-affix-wrapper .syt-antd-input-suffix {
  padding-right: 18px;
}
.syt-antd-form-item-has-feedback .syt-antd-input-search:not(.syt-antd-input-search-enter-button) .syt-antd-input-suffix {
  right: 28px;
}
.syt-antd-form-item-has-feedback .syt-antd-switch {
  margin: 2px 0 4px;
}
.syt-antd-form-item-has-feedback > .syt-antd-select .syt-antd-select-arrow,
.syt-antd-form-item-has-feedback > .syt-antd-select .syt-antd-select-clear,
.syt-antd-form-item-has-feedback :not(.syt-antd-input-group-addon) > .syt-antd-select .syt-antd-select-arrow,
.syt-antd-form-item-has-feedback :not(.syt-antd-input-group-addon) > .syt-antd-select .syt-antd-select-clear {
  right: 32px;
}
.syt-antd-form-item-has-feedback > .syt-antd-select .syt-antd-select-selection-selected-value,
.syt-antd-form-item-has-feedback :not(.syt-antd-input-group-addon) > .syt-antd-select .syt-antd-select-selection-selected-value {
  padding-right: 42px;
}
.syt-antd-form-item-has-feedback .syt-antd-cascader-picker-arrow {
  margin-right: 19px;
}
.syt-antd-form-item-has-feedback .syt-antd-cascader-picker-clear {
  right: 32px;
}
.syt-antd-form-item-has-feedback .syt-antd-picker {
  padding-right: 29.2px;
}
.syt-antd-form-item-has-feedback .syt-antd-picker-large {
  padding-right: 29.2px;
}
.syt-antd-form-item-has-feedback .syt-antd-picker-small {
  padding-right: 25.2px;
}
.syt-antd-form-item-has-feedback.syt-antd-form-item-has-success .syt-antd-form-item-children-icon,
.syt-antd-form-item-has-feedback.syt-antd-form-item-has-warning .syt-antd-form-item-children-icon,
.syt-antd-form-item-has-feedback.syt-antd-form-item-has-error .syt-antd-form-item-children-icon,
.syt-antd-form-item-has-feedback.syt-antd-form-item-is-validating .syt-antd-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.syt-antd-form-item-has-feedback.syt-antd-form-item-has-success .syt-antd-form-item-children-icon svg,
.syt-antd-form-item-has-feedback.syt-antd-form-item-has-warning .syt-antd-form-item-children-icon svg,
.syt-antd-form-item-has-feedback.syt-antd-form-item-has-error .syt-antd-form-item-children-icon svg,
.syt-antd-form-item-has-feedback.syt-antd-form-item-is-validating .syt-antd-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.syt-antd-form-item-has-success.syt-antd-form-item-has-feedback .syt-antd-form-item-children-icon {
  color: #aab400;
  animation-name: diffZoomIn1 !important;
}
.syt-antd-form-item-has-warning .syt-antd-form-item-split {
  color: #ffca41;
}
.syt-antd-form-item-has-warning .syt-antd-input,
.syt-antd-form-item-has-warning .syt-antd-input-affix-wrapper,
.syt-antd-form-item-has-warning .syt-antd-input:hover,
.syt-antd-form-item-has-warning .syt-antd-input-affix-wrapper:hover {
  background-color: #ffffff;
  border-color: #ffca41;
}
.syt-antd-form-item-has-warning .syt-antd-input:focus,
.syt-antd-form-item-has-warning .syt-antd-input-affix-wrapper:focus,
.syt-antd-form-item-has-warning .syt-antd-input-focused,
.syt-antd-form-item-has-warning .syt-antd-input-affix-wrapper-focused {
  border-color: #ffd969;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 202, 65, 0.2);
}
.syt-antd-form-item-has-warning .syt-antd-input-disabled {
  background-color: #f5f5f5;
  border-color: #0092e4;
}
.syt-antd-form-item-has-warning .syt-antd-input-affix-wrapper-disabled {
  background-color: #f5f5f5;
  border-color: #0092e4;
}
.syt-antd-form-item-has-warning .syt-antd-input-affix-wrapper-disabled input:focus {
  box-shadow: none !important;
}
.syt-antd-form-item-has-warning .syt-antd-calendar-picker-open .syt-antd-calendar-picker-input {
  border-color: #ffd969;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 202, 65, 0.2);
}
.syt-antd-form-item-has-warning .syt-antd-input-prefix {
  color: #ffca41;
}
.syt-antd-form-item-has-warning .syt-antd-input-group-addon {
  color: #ffca41;
  border-color: #ffca41;
}
.syt-antd-form-item-has-warning .has-feedback {
  color: #ffca41;
}
.syt-antd-form-item-has-warning.syt-antd-form-item-has-feedback .syt-antd-form-item-children-icon {
  color: #ffca41;
  animation-name: diffZoomIn3 !important;
}
.syt-antd-form-item-has-warning .syt-antd-select:not(.syt-antd-select-borderless) .syt-antd-select-selector {
  background-color: #ffffff;
  border-color: #ffca41 !important;
}
.syt-antd-form-item-has-warning .syt-antd-select:not(.syt-antd-select-borderless).syt-antd-select-open .syt-antd-select-selector,
.syt-antd-form-item-has-warning .syt-antd-select:not(.syt-antd-select-borderless).syt-antd-select-focused .syt-antd-select-selector {
  border-color: #ffd969;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 202, 65, 0.2);
}
.syt-antd-form-item-has-warning .syt-antd-input-number,
.syt-antd-form-item-has-warning .syt-antd-picker {
  background-color: #ffffff;
  border-color: #ffca41;
}
.syt-antd-form-item-has-warning .syt-antd-input-number-focused,
.syt-antd-form-item-has-warning .syt-antd-picker-focused,
.syt-antd-form-item-has-warning .syt-antd-input-number:focus,
.syt-antd-form-item-has-warning .syt-antd-picker:focus {
  border-color: #ffd969;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 202, 65, 0.2);
}
.syt-antd-form-item-has-warning .syt-antd-input-number:not([disabled]):hover,
.syt-antd-form-item-has-warning .syt-antd-picker:not([disabled]):hover {
  background-color: #ffffff;
  border-color: #ffca41;
}
.syt-antd-form-item-has-warning .syt-antd-cascader-picker:focus .syt-antd-cascader-input {
  border-color: #ffd969;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 202, 65, 0.2);
}
.syt-antd-form-item-has-error .syt-antd-form-item-split {
  color: #CF3537;
}
.syt-antd-form-item-has-error .syt-antd-input,
.syt-antd-form-item-has-error .syt-antd-input-affix-wrapper,
.syt-antd-form-item-has-error .syt-antd-input:hover,
.syt-antd-form-item-has-error .syt-antd-input-affix-wrapper:hover {
  background-color: #ffffff;
  border-color: #CF3537;
}
.syt-antd-form-item-has-error .syt-antd-input:focus,
.syt-antd-form-item-has-error .syt-antd-input-affix-wrapper:focus,
.syt-antd-form-item-has-error .syt-antd-input-focused,
.syt-antd-form-item-has-error .syt-antd-input-affix-wrapper-focused {
  border-color: #db5e5c;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(207, 53, 55, 0.2);
}
.syt-antd-form-item-has-error .syt-antd-input-disabled {
  background-color: #f5f5f5;
  border-color: #0092e4;
}
.syt-antd-form-item-has-error .syt-antd-input-affix-wrapper-disabled {
  background-color: #f5f5f5;
  border-color: #0092e4;
}
.syt-antd-form-item-has-error .syt-antd-input-affix-wrapper-disabled input:focus {
  box-shadow: none !important;
}
.syt-antd-form-item-has-error .syt-antd-calendar-picker-open .syt-antd-calendar-picker-input {
  border-color: #db5e5c;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(207, 53, 55, 0.2);
}
.syt-antd-form-item-has-error .syt-antd-input-prefix {
  color: #CF3537;
}
.syt-antd-form-item-has-error .syt-antd-input-group-addon {
  color: #CF3537;
  border-color: #CF3537;
}
.syt-antd-form-item-has-error .has-feedback {
  color: #CF3537;
}
.syt-antd-form-item-has-error.syt-antd-form-item-has-feedback .syt-antd-form-item-children-icon {
  color: #CF3537;
  animation-name: diffZoomIn2 !important;
}
.syt-antd-form-item-has-error .syt-antd-select:not(.syt-antd-select-borderless) .syt-antd-select-selector {
  background-color: #ffffff;
  border-color: #CF3537 !important;
}
.syt-antd-form-item-has-error .syt-antd-select:not(.syt-antd-select-borderless).syt-antd-select-open .syt-antd-select-selector,
.syt-antd-form-item-has-error .syt-antd-select:not(.syt-antd-select-borderless).syt-antd-select-focused .syt-antd-select-selector {
  border-color: #db5e5c;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(207, 53, 55, 0.2);
}
.syt-antd-form-item-has-error .syt-antd-input-group-addon .syt-antd-select.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  border: 0;
}
.syt-antd-form-item-has-error .syt-antd-select.syt-antd-select-auto-complete .syt-antd-input:focus {
  border-color: #CF3537;
}
.syt-antd-form-item-has-error .syt-antd-input-number,
.syt-antd-form-item-has-error .syt-antd-picker {
  background-color: #ffffff;
  border-color: #CF3537;
}
.syt-antd-form-item-has-error .syt-antd-input-number-focused,
.syt-antd-form-item-has-error .syt-antd-picker-focused,
.syt-antd-form-item-has-error .syt-antd-input-number:focus,
.syt-antd-form-item-has-error .syt-antd-picker:focus {
  border-color: #db5e5c;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(207, 53, 55, 0.2);
}
.syt-antd-form-item-has-error .syt-antd-input-number:not([disabled]):hover,
.syt-antd-form-item-has-error .syt-antd-picker:not([disabled]):hover {
  background-color: #ffffff;
  border-color: #CF3537;
}
.syt-antd-form-item-has-error .syt-antd-mention-wrapper .syt-antd-mention-editor,
.syt-antd-form-item-has-error .syt-antd-mention-wrapper .syt-antd-mention-editor:not([disabled]):hover {
  background-color: #ffffff;
  border-color: #CF3537;
}
.syt-antd-form-item-has-error .syt-antd-mention-wrapper.syt-antd-mention-active:not([disabled]) .syt-antd-mention-editor,
.syt-antd-form-item-has-error .syt-antd-mention-wrapper .syt-antd-mention-editor:not([disabled]):focus {
  border-color: #db5e5c;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(207, 53, 55, 0.2);
}
.syt-antd-form-item-has-error .syt-antd-cascader-picker:focus .syt-antd-cascader-input {
  background-color: #ffffff;
  border-color: #db5e5c;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(207, 53, 55, 0.2);
}
.syt-antd-form-item-has-error .syt-antd-transfer-list {
  border-color: #CF3537;
}
.syt-antd-form-item-has-error .syt-antd-transfer-list-search:not([disabled]) {
  border-color: #0092e4;
}
.syt-antd-form-item-has-error .syt-antd-transfer-list-search:not([disabled]):hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-rtl .syt-antd-form-item-has-error .syt-antd-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-form-item-has-error .syt-antd-transfer-list-search:not([disabled]):focus {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-form-item-has-error .syt-antd-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-form-item-has-error .syt-antd-radio-button-wrapper {
  border-color: #CF3537 !important;
}
.syt-antd-form-item-has-error .syt-antd-radio-button-wrapper:not(:first-child)::before {
  background-color: #CF3537;
}
.syt-antd-form-item-is-validating.syt-antd-form-item-has-feedback .syt-antd-form-item-children-icon {
  display: inline-block;
  color: #0071CD;
}
.syt-antd-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
}
.syt-antd-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: #696F88;
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #0092e4;
}
.syt-antd-form label {
  font-size: 14px;
}
.syt-antd-form input[type='search'] {
  box-sizing: border-box;
}
.syt-antd-form input[type='radio'],
.syt-antd-form input[type='checkbox'] {
  line-height: normal;
}
.syt-antd-form input[type='file'] {
  display: block;
}
.syt-antd-form input[type='range'] {
  display: block;
  width: 100%;
}
.syt-antd-form select[multiple],
.syt-antd-form select[size] {
  height: auto;
}
.syt-antd-form input[type='file']:focus,
.syt-antd-form input[type='radio']:focus,
.syt-antd-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.syt-antd-form output {
  display: block;
  padding-top: 15px;
  color: #14151c;
  font-size: 14px;
  line-height: 20px;
}
.syt-antd-form .syt-antd-form-text {
  display: inline-block;
  padding-right: 8px;
}
.syt-antd-form-small .syt-antd-form-item-label > label {
  height: 24px;
}
.syt-antd-form-small .syt-antd-form-item-control-input {
  min-height: 24px;
}
.syt-antd-form-large .syt-antd-form-item-label > label {
  height: 40px;
}
.syt-antd-form-large .syt-antd-form-item-control-input {
  min-height: 40px;
}
.syt-antd-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-bottom: 24px;
  vertical-align: top;
}
.syt-antd-form-item-with-help {
  margin-bottom: 0;
}
.syt-antd-form-item-hidden,
.syt-antd-form-item-hidden.syt-antd-row {
  display: none;
}
.syt-antd-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.syt-antd-form-item-label-left {
  text-align: left;
}
.syt-antd-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  color: #14151C;
  font-size: 14px;
}
.syt-antd-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.syt-antd-form-item-label > label.syt-antd-form-item-required:not(.syt-antd-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.syt-antd-form-hide-required-mark .syt-antd-form-item-label > label.syt-antd-form-item-required:not(.syt-antd-form-item-required-mark-optional)::before {
  display: none;
}
.syt-antd-form-item-label > label .syt-antd-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: #696F88;
}
.syt-antd-form-hide-required-mark .syt-antd-form-item-label > label .syt-antd-form-item-optional {
  display: none;
}
.syt-antd-form-item-label > label .syt-antd-form-item-tooltip {
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
  color: #696F88;
}
.syt-antd-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.syt-antd-form-item-label > label.syt-antd-form-item-no-colon::after {
  content: ' ';
}
.syt-antd-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.syt-antd-form-item-control:first-child:not([class^='syt-antd-col-']):not([class*=' syt-antd-col-']) {
  width: 100%;
}
.syt-antd-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}
.syt-antd-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.syt-antd-form-item-explain,
.syt-antd-form-item-extra {
  clear: both;
  min-height: 24px;
  margin-top: -128px;
  margin-bottom: -128px;
  color: #696F88;
  font-size: 14px;
  line-height: 20px;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.show-help-enter,
.show-help-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  animation-name: antShowHelpIn;
  animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  animation-name: antShowHelpOut;
  animation-play-state: running;
  pointer-events: none;
}
.show-help-enter,
.show-help-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-enter,
.show-help-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  animation-name: antShowHelpIn;
  animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  animation-name: antShowHelpOut;
  animation-play-state: running;
  pointer-events: none;
}
.show-help-enter,
.show-help-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.syt-antd-form-rtl {
  direction: rtl;
}
.syt-antd-form-rtl .syt-antd-form-item-label {
  text-align: left;
}
.syt-antd-form-rtl .syt-antd-form-item-label > label.syt-antd-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.syt-antd-form-rtl .syt-antd-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.syt-antd-form-rtl .syt-antd-form-item-label > label .syt-antd-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.syt-antd-col-rtl .syt-antd-form-item-control:first-child {
  width: 100%;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback .syt-antd-input {
  padding-right: 11px;
  padding-left: 24px;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback .syt-antd-input-affix-wrapper .syt-antd-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback .syt-antd-input-affix-wrapper .syt-antd-input {
  padding: 0;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback .syt-antd-input-search:not(.syt-antd-input-search-enter-button) .syt-antd-input-suffix {
  right: auto;
  left: 28px;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback .syt-antd-input-number {
  padding-left: 18px;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback > .syt-antd-select .syt-antd-select-arrow,
.syt-antd-form-rtl .syt-antd-form-item-has-feedback > .syt-antd-select .syt-antd-select-clear,
.syt-antd-form-rtl .syt-antd-form-item-has-feedback :not(.syt-antd-input-group-addon) > .syt-antd-select .syt-antd-select-arrow,
.syt-antd-form-rtl .syt-antd-form-item-has-feedback :not(.syt-antd-input-group-addon) > .syt-antd-select .syt-antd-select-clear {
  right: auto;
  left: 32px;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback > .syt-antd-select .syt-antd-select-selection-selected-value,
.syt-antd-form-rtl .syt-antd-form-item-has-feedback :not(.syt-antd-input-group-addon) > .syt-antd-select .syt-antd-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback .syt-antd-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback .syt-antd-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback .syt-antd-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback .syt-antd-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback .syt-antd-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.syt-antd-form-rtl .syt-antd-form-item-has-feedback.syt-antd-form-item-has-success .syt-antd-form-item-children-icon,
.syt-antd-form-rtl .syt-antd-form-item-has-feedback.syt-antd-form-item-has-warning .syt-antd-form-item-children-icon,
.syt-antd-form-rtl .syt-antd-form-item-has-feedback.syt-antd-form-item-has-error .syt-antd-form-item-children-icon,
.syt-antd-form-rtl .syt-antd-form-item-has-feedback.syt-antd-form-item-is-validating .syt-antd-form-item-children-icon {
  right: auto;
  left: 0;
}
.syt-antd-form-rtl.syt-antd-form-inline .syt-antd-form-item {
  margin-right: 0;
  margin-left: 16px;
}
.syt-antd-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 3px 11px;
  color: #14151c;
  font-size: 14px;
  line-height: 20px;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #0092e4;
  border-radius: 4px;
  transition: all 0.3s;
}
.syt-antd-input::-moz-placeholder {
  opacity: 1;
}
.syt-antd-input::placeholder {
  color: #bfbfbf;
}
.syt-antd-input:placeholder-shown {
  text-overflow: ellipsis;
}
.syt-antd-input:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-rtl .syt-antd-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-input:focus,
.syt-antd-input-focused {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-input:focus,
.syt-antd-input-rtl .syt-antd-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-input-disabled {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-input-disabled:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input[disabled] {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-input[disabled]:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-borderless,
.syt-antd-input-borderless:hover,
.syt-antd-input-borderless:focus,
.syt-antd-input-borderless-focused,
.syt-antd-input-borderless-disabled,
.syt-antd-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.syt-antd-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 20px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.syt-antd-input-lg {
  padding: -141px 11px;
  font-size: 16px;
}
.syt-antd-input-sm {
  padding: 0 7px;
}
.syt-antd-input-rtl {
  direction: rtl;
}
.syt-antd-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.syt-antd-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.syt-antd-input-group > [class*='col-'] {
  padding-right: 8px;
}
.syt-antd-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.syt-antd-input-group-addon,
.syt-antd-input-group-wrap,
.syt-antd-input-group > .syt-antd-input {
  display: table-cell;
}
.syt-antd-input-group-addon:not(:first-child):not(:last-child),
.syt-antd-input-group-wrap:not(:first-child):not(:last-child),
.syt-antd-input-group > .syt-antd-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.syt-antd-input-group-addon,
.syt-antd-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.syt-antd-input-group-wrap > * {
  display: block !important;
}
.syt-antd-input-group .syt-antd-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.syt-antd-input-group .syt-antd-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.syt-antd-input-group .syt-antd-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.syt-antd-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: #14151c;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #0092e4;
  border-radius: 4px;
  transition: all 0.3s;
}
.syt-antd-input-group-addon .syt-antd-select {
  margin: -4px -11px;
}
.syt-antd-input-group-addon .syt-antd-select.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.syt-antd-input-group-addon .syt-antd-select-open .syt-antd-select-selector,
.syt-antd-input-group-addon .syt-antd-select-focused .syt-antd-select-selector {
  color: #0071CD;
}
.syt-antd-input-group > .syt-antd-input:first-child,
.syt-antd-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syt-antd-input-group > .syt-antd-input:first-child .syt-antd-select .syt-antd-select-selector,
.syt-antd-input-group-addon:first-child .syt-antd-select .syt-antd-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syt-antd-input-group > .syt-antd-input-affix-wrapper:not(:first-child) .syt-antd-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syt-antd-input-group > .syt-antd-input-affix-wrapper:not(:last-child) .syt-antd-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syt-antd-input-group-addon:first-child {
  border-right: 0;
}
.syt-antd-input-group-addon:last-child {
  border-left: 0;
}
.syt-antd-input-group > .syt-antd-input:last-child,
.syt-antd-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syt-antd-input-group > .syt-antd-input:last-child .syt-antd-select .syt-antd-select-selector,
.syt-antd-input-group-addon:last-child .syt-antd-select .syt-antd-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syt-antd-input-group-lg .syt-antd-input,
.syt-antd-input-group-lg > .syt-antd-input-group-addon {
  padding: -141px 11px;
  font-size: 16px;
}
.syt-antd-input-group-sm .syt-antd-input,
.syt-antd-input-group-sm > .syt-antd-input-group-addon {
  padding: 0 7px;
}
.syt-antd-input-group-lg .syt-antd-select-single .syt-antd-select-selector {
  height: 40px;
}
.syt-antd-input-group-sm .syt-antd-select-single .syt-antd-select-selector {
  height: 24px;
}
.syt-antd-input-group .syt-antd-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syt-antd-input-group .syt-antd-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syt-antd-input-group.syt-antd-input-group-compact {
  display: block;
}
.syt-antd-input-group.syt-antd-input-group-compact::before {
  display: table;
  content: '';
}
.syt-antd-input-group.syt-antd-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-input-group.syt-antd-input-group-compact-addon:not(:first-child):not(:last-child),
.syt-antd-input-group.syt-antd-input-group-compact-wrap:not(:first-child):not(:last-child),
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.syt-antd-input-group.syt-antd-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.syt-antd-input-group.syt-antd-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.syt-antd-input-group.syt-antd-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.syt-antd-input-group.syt-antd-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.syt-antd-input-group.syt-antd-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input-affix-wrapper {
  display: inline-flex;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-picker-range {
  display: inline-flex;
}
.syt-antd-input-group.syt-antd-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.syt-antd-input-group.syt-antd-input-group-compact .syt-antd-input {
  float: none;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select > .syt-antd-select-selector,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete .syt-antd-input,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker .syt-antd-input,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input-group-wrapper .syt-antd-input {
  border-right-width: 1px;
  border-radius: 0;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select > .syt-antd-select-selector:hover,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete .syt-antd-input:hover,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker .syt-antd-input:hover,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input-group-wrapper .syt-antd-input:hover {
  z-index: 1;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select > .syt-antd-select-selector:focus,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete .syt-antd-input:focus,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker .syt-antd-input:focus,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input-group-wrapper .syt-antd-input:focus {
  z-index: 1;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-focused {
  z-index: 1;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select > .syt-antd-select-arrow {
  z-index: 1;
}
.syt-antd-input-group.syt-antd-input-group-compact > *:first-child,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select:first-child > .syt-antd-select-selector,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete:first-child .syt-antd-input,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker:first-child .syt-antd-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.syt-antd-input-group.syt-antd-input-group-compact > *:last-child,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select:last-child > .syt-antd-select-selector,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker:last-child .syt-antd-input,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker-focused:last-child .syt-antd-input {
  border-right-width: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete .syt-antd-input {
  vertical-align: top;
}
.syt-antd-input-group > .syt-antd-input-rtl:first-child,
.syt-antd-input-group-rtl .syt-antd-input-group-addon:first-child {
  border-radius: 0 4px 4px 0;
}
.syt-antd-input-group-rtl .syt-antd-input-group-addon:first-child {
  border-right: 1px solid #0092e4;
  border-left: 0;
}
.syt-antd-input-group-rtl .syt-antd-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #0092e4;
}
.syt-antd-input-group-rtl.syt-antd-input-group > .syt-antd-input:last-child,
.syt-antd-input-group-rtl.syt-antd-input-group-addon:last-child {
  border-radius: 4px 0 0 4px;
}
.syt-antd-input-group-rtl.syt-antd-input-group .syt-antd-input-affix-wrapper:not(:first-child) {
  border-radius: 4px 0 0 4px;
}
.syt-antd-input-group-rtl.syt-antd-input-group .syt-antd-input-affix-wrapper:not(:last-child) {
  border-radius: 0 4px 4px 0;
}
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > *:first-child,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select:first-child > .syt-antd-select-selector,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete:first-child .syt-antd-input,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker:first-child .syt-antd-input {
  border-radius: 0 4px 4px 0;
}
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > *:last-child,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select:last-child > .syt-antd-select-selector,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete:last-child .syt-antd-input,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker:last-child .syt-antd-input,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker-focused:last-child .syt-antd-input {
  border-left-width: 1px;
  border-radius: 4px 0 0 4px;
}
.syt-antd-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.syt-antd-input-password-icon {
  color: #696F88;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-input-password-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.syt-antd-input[type='color'] {
  height: 32px;
}
.syt-antd-input[type='color'].syt-antd-input-lg {
  height: 40px;
}
.syt-antd-input[type='color'].syt-antd-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.syt-antd-input-textarea-show-count::after {
  display: block;
  color: #696F88;
  text-align: right;
  content: attr(data-count);
}
.syt-antd-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 3px 11px;
  color: #14151c;
  font-size: 14px;
  line-height: 20px;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #0092e4;
  border-radius: 4px;
  transition: all 0.3s;
  display: inline-flex;
}
.syt-antd-input-affix-wrapper::-moz-placeholder {
  opacity: 1;
}
.syt-antd-input-affix-wrapper::placeholder {
  color: #bfbfbf;
}
.syt-antd-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.syt-antd-input-affix-wrapper:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-rtl .syt-antd-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-input-affix-wrapper:focus,
.syt-antd-input-affix-wrapper-focused {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-input-affix-wrapper:focus,
.syt-antd-input-rtl .syt-antd-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-input-affix-wrapper-disabled {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-input-affix-wrapper-disabled:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-affix-wrapper[disabled] {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-input-affix-wrapper[disabled]:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-affix-wrapper-borderless,
.syt-antd-input-affix-wrapper-borderless:hover,
.syt-antd-input-affix-wrapper-borderless:focus,
.syt-antd-input-affix-wrapper-borderless-focused,
.syt-antd-input-affix-wrapper-borderless-disabled,
.syt-antd-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.syt-antd-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 20px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.syt-antd-input-affix-wrapper-lg {
  padding: -141px 11px;
  font-size: 16px;
}
.syt-antd-input-affix-wrapper-sm {
  padding: 0 7px;
}
.syt-antd-input-affix-wrapper-rtl {
  direction: rtl;
}
.syt-antd-input-affix-wrapper-disabled .syt-antd-input[disabled] {
  background: transparent;
}
.syt-antd-input-affix-wrapper > input.syt-antd-input {
  padding: 0;
  border: none;
  outline: none;
}
.syt-antd-input-affix-wrapper > input.syt-antd-input:focus {
  box-shadow: none;
}
.syt-antd-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.syt-antd-input-prefix,
.syt-antd-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.syt-antd-input-prefix {
  margin-right: 4px;
}
.syt-antd-input-suffix {
  margin-left: 4px;
}
.syt-antd-input-clear-icon {
  margin: 0 4px;
  color: #c1c5c8;
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.syt-antd-input-clear-icon:hover {
  color: #696F88;
}
.syt-antd-input-clear-icon:active {
  color: #14151C;
}
.syt-antd-input-clear-icon-hidden {
  visibility: hidden;
}
.syt-antd-input-clear-icon:last-child {
  margin-right: 0;
}
.syt-antd-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important;
}
.syt-antd-input-affix-wrapper-textarea-with-clear-btn .syt-antd-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.syt-antd-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 3px 11px;
  color: #14151c;
  font-size: 14px;
  line-height: 20px;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #0092e4;
  border-radius: 4px;
  transition: all 0.3s;
}
.syt-antd-input::-moz-placeholder {
  opacity: 1;
}
.syt-antd-input::placeholder {
  color: #bfbfbf;
}
.syt-antd-input:placeholder-shown {
  text-overflow: ellipsis;
}
.syt-antd-input:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-rtl .syt-antd-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-input:focus,
.syt-antd-input-focused {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-input:focus,
.syt-antd-input-rtl .syt-antd-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-input-disabled {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-input-disabled:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input[disabled] {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-input[disabled]:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-borderless,
.syt-antd-input-borderless:hover,
.syt-antd-input-borderless:focus,
.syt-antd-input-borderless-focused,
.syt-antd-input-borderless-disabled,
.syt-antd-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.syt-antd-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 20px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.syt-antd-input-lg {
  padding: -141px 11px;
  font-size: 16px;
}
.syt-antd-input-sm {
  padding: 0 7px;
}
.syt-antd-input-rtl {
  direction: rtl;
}
.syt-antd-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.syt-antd-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.syt-antd-input-group > [class*='col-'] {
  padding-right: 8px;
}
.syt-antd-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.syt-antd-input-group-addon,
.syt-antd-input-group-wrap,
.syt-antd-input-group > .syt-antd-input {
  display: table-cell;
}
.syt-antd-input-group-addon:not(:first-child):not(:last-child),
.syt-antd-input-group-wrap:not(:first-child):not(:last-child),
.syt-antd-input-group > .syt-antd-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.syt-antd-input-group-addon,
.syt-antd-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.syt-antd-input-group-wrap > * {
  display: block !important;
}
.syt-antd-input-group .syt-antd-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.syt-antd-input-group .syt-antd-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.syt-antd-input-group .syt-antd-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.syt-antd-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: #14151c;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #0092e4;
  border-radius: 4px;
  transition: all 0.3s;
}
.syt-antd-input-group-addon .syt-antd-select {
  margin: -4px -11px;
}
.syt-antd-input-group-addon .syt-antd-select.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.syt-antd-input-group-addon .syt-antd-select-open .syt-antd-select-selector,
.syt-antd-input-group-addon .syt-antd-select-focused .syt-antd-select-selector {
  color: #0071CD;
}
.syt-antd-input-group > .syt-antd-input:first-child,
.syt-antd-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syt-antd-input-group > .syt-antd-input:first-child .syt-antd-select .syt-antd-select-selector,
.syt-antd-input-group-addon:first-child .syt-antd-select .syt-antd-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syt-antd-input-group > .syt-antd-input-affix-wrapper:not(:first-child) .syt-antd-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syt-antd-input-group > .syt-antd-input-affix-wrapper:not(:last-child) .syt-antd-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syt-antd-input-group-addon:first-child {
  border-right: 0;
}
.syt-antd-input-group-addon:last-child {
  border-left: 0;
}
.syt-antd-input-group > .syt-antd-input:last-child,
.syt-antd-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syt-antd-input-group > .syt-antd-input:last-child .syt-antd-select .syt-antd-select-selector,
.syt-antd-input-group-addon:last-child .syt-antd-select .syt-antd-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syt-antd-input-group-lg .syt-antd-input,
.syt-antd-input-group-lg > .syt-antd-input-group-addon {
  padding: -141px 11px;
  font-size: 16px;
}
.syt-antd-input-group-sm .syt-antd-input,
.syt-antd-input-group-sm > .syt-antd-input-group-addon {
  padding: 0 7px;
}
.syt-antd-input-group-lg .syt-antd-select-single .syt-antd-select-selector {
  height: 40px;
}
.syt-antd-input-group-sm .syt-antd-select-single .syt-antd-select-selector {
  height: 24px;
}
.syt-antd-input-group .syt-antd-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syt-antd-input-group .syt-antd-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syt-antd-input-group.syt-antd-input-group-compact {
  display: block;
}
.syt-antd-input-group.syt-antd-input-group-compact::before {
  display: table;
  content: '';
}
.syt-antd-input-group.syt-antd-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-input-group.syt-antd-input-group-compact-addon:not(:first-child):not(:last-child),
.syt-antd-input-group.syt-antd-input-group-compact-wrap:not(:first-child):not(:last-child),
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.syt-antd-input-group.syt-antd-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.syt-antd-input-group.syt-antd-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.syt-antd-input-group.syt-antd-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.syt-antd-input-group.syt-antd-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.syt-antd-input-group.syt-antd-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input-affix-wrapper {
  display: inline-flex;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-picker-range {
  display: inline-flex;
}
.syt-antd-input-group.syt-antd-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.syt-antd-input-group.syt-antd-input-group-compact .syt-antd-input {
  float: none;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select > .syt-antd-select-selector,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete .syt-antd-input,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker .syt-antd-input,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input-group-wrapper .syt-antd-input {
  border-right-width: 1px;
  border-radius: 0;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select > .syt-antd-select-selector:hover,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete .syt-antd-input:hover,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker .syt-antd-input:hover,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input-group-wrapper .syt-antd-input:hover {
  z-index: 1;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select > .syt-antd-select-selector:focus,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete .syt-antd-input:focus,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker .syt-antd-input:focus,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-input-group-wrapper .syt-antd-input:focus {
  z-index: 1;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-focused {
  z-index: 1;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select > .syt-antd-select-arrow {
  z-index: 1;
}
.syt-antd-input-group.syt-antd-input-group-compact > *:first-child,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select:first-child > .syt-antd-select-selector,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete:first-child .syt-antd-input,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker:first-child .syt-antd-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.syt-antd-input-group.syt-antd-input-group-compact > *:last-child,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select:last-child > .syt-antd-select-selector,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker:last-child .syt-antd-input,
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker-focused:last-child .syt-antd-input {
  border-right-width: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete .syt-antd-input {
  vertical-align: top;
}
.syt-antd-input-group > .syt-antd-input-rtl:first-child,
.syt-antd-input-group-rtl .syt-antd-input-group-addon:first-child {
  border-radius: 0 4px 4px 0;
}
.syt-antd-input-group-rtl .syt-antd-input-group-addon:first-child {
  border-right: 1px solid #0092e4;
  border-left: 0;
}
.syt-antd-input-group-rtl .syt-antd-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #0092e4;
}
.syt-antd-input-group-rtl.syt-antd-input-group > .syt-antd-input:last-child,
.syt-antd-input-group-rtl.syt-antd-input-group-addon:last-child {
  border-radius: 4px 0 0 4px;
}
.syt-antd-input-group-rtl.syt-antd-input-group .syt-antd-input-affix-wrapper:not(:first-child) {
  border-radius: 4px 0 0 4px;
}
.syt-antd-input-group-rtl.syt-antd-input-group .syt-antd-input-affix-wrapper:not(:last-child) {
  border-radius: 0 4px 4px 0;
}
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > *:first-child,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select:first-child > .syt-antd-select-selector,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete:first-child .syt-antd-input,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker:first-child .syt-antd-input {
  border-radius: 0 4px 4px 0;
}
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > *:last-child,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select:last-child > .syt-antd-select-selector,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-select-auto-complete:last-child .syt-antd-input,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker:last-child .syt-antd-input,
.syt-antd-input-group-rtl.syt-antd-input-group.syt-antd-input-group-compact > .syt-antd-cascader-picker-focused:last-child .syt-antd-input {
  border-left-width: 1px;
  border-radius: 4px 0 0 4px;
}
.syt-antd-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.syt-antd-input-password-icon {
  color: #696F88;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-input-password-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.syt-antd-input[type='color'] {
  height: 32px;
}
.syt-antd-input[type='color'].syt-antd-input-lg {
  height: 40px;
}
.syt-antd-input[type='color'].syt-antd-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.syt-antd-input-textarea-show-count::after {
  display: block;
  color: #696F88;
  text-align: right;
  content: attr(data-count);
}
.syt-antd-input-search .syt-antd-input {
  border-right: 0;
}
.syt-antd-input-search .syt-antd-input:hover,
.syt-antd-input-search .syt-antd-input:focus {
  border-color: #0092e4;
}
.syt-antd-input-search .syt-antd-input:hover + .syt-antd-input-group-addon .syt-antd-input-search-button:not(.syt-antd-btn-primary),
.syt-antd-input-search .syt-antd-input:focus + .syt-antd-input-group-addon .syt-antd-input-search-button:not(.syt-antd-btn-primary) {
  border-left-color: #0092e4;
}
.syt-antd-input-search .syt-antd-input-lg {
  line-height: 19.9998px;
}
.syt-antd-input-search > .syt-antd-input-group > .syt-antd-input-affix-wrapper {
  border-right: 0;
}
.syt-antd-input-search > .syt-antd-input-group > .syt-antd-input-group-addon {
  padding: 0;
  border: 0;
}
.syt-antd-input-search > .syt-antd-input-group > .syt-antd-input-group-addon .syt-antd-input-search-button {
  border-radius: 0 4px 4px 0;
}
.syt-antd-input-search > .syt-antd-input-group > .syt-antd-input-group-addon .syt-antd-input-search-button:not(.syt-antd-btn-primary) {
  color: #696F88;
}
.syt-antd-input-search > .syt-antd-input-group > .syt-antd-input-group-addon .syt-antd-input-search-button:not(.syt-antd-btn-primary).syt-antd-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.syt-antd-input-group-wrapper-rtl {
  direction: rtl;
}
.syt-antd-input-group-rtl {
  direction: rtl;
}
.syt-antd-input-affix-wrapper.syt-antd-input-affix-wrapper-rtl > input.syt-antd-input {
  border: none;
  outline: none;
}
.syt-antd-input-affix-wrapper-rtl .syt-antd-input-prefix {
  margin: 0 0 0 4px;
}
.syt-antd-input-affix-wrapper-rtl .syt-antd-input-suffix {
  margin: 0 4px 0 0;
}
.syt-antd-input-textarea-rtl {
  direction: rtl;
}
.syt-antd-input-textarea-rtl.syt-antd-input-textarea-show-count::after {
  text-align: left;
}
.syt-antd-input-affix-wrapper-rtl .syt-antd-input-clear-icon:last-child {
  margin-right: 4px;
  margin-left: 0;
}
.syt-antd-input-affix-wrapper-rtl .syt-antd-input-clear-icon {
  right: auto;
  left: 8px;
}
.syt-antd-input-search-rtl {
  direction: rtl;
}
.syt-antd-input-search-rtl .syt-antd-input {
  border-right: 1px solid #0092e4;
  border-left: 0;
}
.syt-antd-input-search-rtl .syt-antd-input:hover + .syt-antd-input-group-addon .syt-antd-input-search-button:not(.syt-antd-btn-primary),
.syt-antd-input-search-rtl .syt-antd-input:focus + .syt-antd-input-group-addon .syt-antd-input-search-button:not(.syt-antd-btn-primary) {
  border-right-color: #0092e4;
  border-left-color: #0092e4;
}
.syt-antd-input-search-rtl > .syt-antd-input-group > .syt-antd-input-affix-wrapper {
  border-right: 1px solid #0092e4;
  border-left: 0;
}
.syt-antd-input-search-rtl > .syt-antd-input-group > .syt-antd-input-affix-wrapper:hover,
.syt-antd-input-search-rtl > .syt-antd-input-group > .syt-antd-input-affix-wrapper-focused {
  border-right-color: #0092e4;
}
.syt-antd-input-search-rtl > .syt-antd-input-group > .syt-antd-input-group-addon .syt-antd-input-search-button {
  border-radius: 4px 0 0 4px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .syt-antd-input {
    height: 32px;
  }
  .syt-antd-input-lg {
    height: 40px;
  }
  .syt-antd-input-sm {
    height: 24px;
  }
  .syt-antd-input-affix-wrapper > input.syt-antd-input {
    height: auto;
  }
}
input.syt-antd-input {
  color: #14151C;
  background-color: #FFFFFF;
  padding: 2px 11px 3px 11px;
}
input.syt-antd-input:hover,
input.syt-antd-input:focus {
  color: #14151C;
  background-color: #FFFFFF;
}
input.syt-antd-input::placeholder {
  color: #696F88;
}
input.syt-antd-input[disabled] {
  background: #DFE2E7;
  color: #868CA2;
}
.syt-antd-input-affix-wrapper-disabled {
  background: #DFE2E7;
  color: #868CA2;
}
.syt-antd-input-affix-wrapper-disabled svg {
  color: #696F88 !important;
}
:not(.syt-antd-input-affix-wrapper) > input.syt-antd-input,
.syt-antd-input-affix-wrapper {
  height: 32px;
}
:not(.syt-antd-input-affix-wrapper) > input.syt-antd-input,
.syt-antd-input-affix-wrapper,
:not(.syt-antd-input-affix-wrapper) > input.syt-antd-input input.syt-antd-input,
.syt-antd-input-affix-wrapper input.syt-antd-input {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.0075em;
}
:not(.syt-antd-input-affix-wrapper-lg) > input.syt-antd-input-lg,
.syt-antd-input-affix-wrapper-lg {
  height: 40px;
}
:not(.syt-antd-input-affix-wrapper-lg) > input.syt-antd-input-lg,
.syt-antd-input-affix-wrapper-lg,
:not(.syt-antd-input-affix-wrapper-lg) > input.syt-antd-input-lg input.syt-antd-input-lg,
.syt-antd-input-affix-wrapper-lg input.syt-antd-input-lg {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: -0.0075em;
}
:not(.syt-antd-input-affix-wrapper-sm) > input.syt-antd-input-sm,
.syt-antd-input-affix-wrapper-sm {
  height: 24px;
}
:not(.syt-antd-input-affix-wrapper-sm) > input.syt-antd-input-sm,
.syt-antd-input-affix-wrapper-sm,
:not(.syt-antd-input-affix-wrapper-sm) > input.syt-antd-input-sm input.syt-antd-input-sm,
.syt-antd-input-affix-wrapper-sm input.syt-antd-input-sm {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.0075em;
}
.syt-antd-input-label {
  height: 16px;
  left: 0px;
  right: 0px;
  top: 0px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0075em;
  color: #14151C !important;
}
.syt-antd-input-caption {
  left: 0px;
  right: 0px;
  top: 64px;
  bottom: 0px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0075em;
  color: #696F88;
}
.syt-antd-input-wrapper-default > input.syt-antd-input,
.syt-antd-input-wrapper-success > input.syt-antd-input,
.syt-antd-input-wrapper-default .syt-antd-input-affix-wrapper,
.syt-antd-input-wrapper-success .syt-antd-input-affix-wrapper {
  border: 1px solid #C2C7D0;
  margin: 2px 0px;
}
.syt-antd-input-wrapper-default > input.syt-antd-input svg,
.syt-antd-input-wrapper-success > input.syt-antd-input svg,
.syt-antd-input-wrapper-default .syt-antd-input-affix-wrapper svg,
.syt-antd-input-wrapper-success .syt-antd-input-affix-wrapper svg {
  color: #696F88;
}
.syt-antd-input-wrapper-default > input.syt-antd-input:hover,
.syt-antd-input-wrapper-success > input.syt-antd-input:hover,
.syt-antd-input-wrapper-default .syt-antd-input-affix-wrapper:hover,
.syt-antd-input-wrapper-success .syt-antd-input-affix-wrapper:hover {
  border: 1px solid #0092E4;
}
.syt-antd-input-wrapper-default > input.syt-antd-input:focus,
.syt-antd-input-wrapper-success > input.syt-antd-input:focus,
.syt-antd-input-wrapper-default .syt-antd-input-affix-wrapper:focus,
.syt-antd-input-wrapper-success .syt-antd-input-affix-wrapper:focus,
.syt-antd-input-wrapper-default > input.syt-antd-input-focused,
.syt-antd-input-wrapper-success > input.syt-antd-input-focused,
.syt-antd-input-wrapper-default .syt-antd-input-affix-wrapper-focused,
.syt-antd-input-wrapper-success .syt-antd-input-affix-wrapper-focused {
  border: 1px solid #0092E4 !important;
  box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25) !important;
}
.syt-antd-input-wrapper-default > input.syt-antd-input:focus svg,
.syt-antd-input-wrapper-success > input.syt-antd-input:focus svg,
.syt-antd-input-wrapper-default .syt-antd-input-affix-wrapper:focus svg,
.syt-antd-input-wrapper-success .syt-antd-input-affix-wrapper:focus svg,
.syt-antd-input-wrapper-default > input.syt-antd-input-focused svg,
.syt-antd-input-wrapper-success > input.syt-antd-input-focused svg,
.syt-antd-input-wrapper-default .syt-antd-input-affix-wrapper-focused svg,
.syt-antd-input-wrapper-success .syt-antd-input-affix-wrapper-focused svg {
  color: #696F88;
}
.syt-antd-input-wrapper-default .syt-antd-input-label,
.syt-antd-input-wrapper-success .syt-antd-input-label,
.syt-antd-input-wrapper-default .syt-antd-input-caption,
.syt-antd-input-wrapper-success .syt-antd-input-caption {
  color: #696F88;
}
.syt-antd-input-wrapper-warn > input.syt-antd-input,
.syt-antd-input-wrapper-warn .syt-antd-input-affix-wrapper {
  border: 1px solid #C17E19;
}
.syt-antd-input-wrapper-warn > input.syt-antd-input svg,
.syt-antd-input-wrapper-warn .syt-antd-input-affix-wrapper svg {
  color: #696F88;
}
.syt-antd-input-wrapper-warn > input.syt-antd-input:hover,
.syt-antd-input-wrapper-warn .syt-antd-input-affix-wrapper:hover {
  border: 1px solid #C17E19;
}
.syt-antd-input-wrapper-warn > input.syt-antd-input:focus,
.syt-antd-input-wrapper-warn .syt-antd-input-affix-wrapper:focus,
.syt-antd-input-wrapper-warn > input.syt-antd-input-focused,
.syt-antd-input-wrapper-warn .syt-antd-input-affix-wrapper-focused {
  border: 1px solid #C17E19 !important;
  box-shadow: 0px 0px 0px 3px rgba(233, 153, 33, 0.25) !important;
}
.syt-antd-input-wrapper-warn > input.syt-antd-input:focus svg,
.syt-antd-input-wrapper-warn .syt-antd-input-affix-wrapper:focus svg,
.syt-antd-input-wrapper-warn > input.syt-antd-input-focused svg,
.syt-antd-input-wrapper-warn .syt-antd-input-affix-wrapper-focused svg {
  color: #696F88;
}
.syt-antd-input-wrapper-warn .syt-antd-input-label,
.syt-antd-input-wrapper-warn .syt-antd-input-caption {
  color: #9A6412;
}
.syt-antd-input-wrapper-danger > input.syt-antd-input,
.syt-antd-input-wrapper-danger .syt-antd-input-affix-wrapper {
  border: 1px solid #EB4B4B;
}
.syt-antd-input-wrapper-danger > input.syt-antd-input svg,
.syt-antd-input-wrapper-danger .syt-antd-input-affix-wrapper svg {
  color: #696F88;
}
.syt-antd-input-wrapper-danger > input.syt-antd-input:hover,
.syt-antd-input-wrapper-danger .syt-antd-input-affix-wrapper:hover {
  border: 1px solid #EB4B4B;
}
.syt-antd-input-wrapper-danger > input.syt-antd-input:focus,
.syt-antd-input-wrapper-danger .syt-antd-input-affix-wrapper:focus {
  border: 1px solid #EB4B4B !important;
  box-shadow: 0px 0px 0px 3px rgba(235, 75, 75, 0.25) !important;
}
.syt-antd-input-wrapper-danger > input.syt-antd-input:focus svg,
.syt-antd-input-wrapper-danger .syt-antd-input-affix-wrapper:focus svg {
  color: #696F88;
}
.syt-antd-input-wrapper-danger .syt-antd-input-label,
.syt-antd-input-wrapper-danger .syt-antd-input-caption {
  color: #CF3537;
}
textarea.syt-antd-input {
  border: 1px solid #C2C7D0;
  padding: 8px 12px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0075em;
}
textarea.syt-antd-input::placeholder {
  color: #696F88;
}
textarea.syt-antd-input:hover {
  border: 1px solid #0092E4;
}
textarea.syt-antd-input:focus {
  border: 1px solid #0092E4 !important;
  box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25) !important;
}
textarea.syt-antd-input:focus svg {
  color: #696F88;
}
textarea.syt-antd-input:disabled {
  background: #DFE2E7;
  color: #696F88;
}
textarea.syt-antd-input:disabled::placeholder {
  color: #868CA2;
}
.syt-antd-input-number {
  box-sizing: border-box;
  color: #14151C;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 3px 11px;
  color: #14151c;
  font-size: 14px;
  line-height: 20px;
  background-color: #ffffff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #0092e4;
  border-radius: 4px;
}
.syt-antd-input-number::-moz-placeholder {
  opacity: 1;
}
.syt-antd-input-number::placeholder {
  color: #bfbfbf;
}
.syt-antd-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.syt-antd-input-number:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-rtl .syt-antd-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-input-number:focus,
.syt-antd-input-number-focused {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-input-number:focus,
.syt-antd-input-rtl .syt-antd-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-input-number-disabled {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-input-number-disabled:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-number[disabled] {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-input-number[disabled]:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-number-borderless,
.syt-antd-input-number-borderless:hover,
.syt-antd-input-number-borderless:focus,
.syt-antd-input-number-borderless-focused,
.syt-antd-input-number-borderless-disabled,
.syt-antd-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.syt-antd-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 20px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.syt-antd-input-number-lg {
  padding: -141px 11px;
  font-size: 16px;
}
.syt-antd-input-number-sm {
  padding: 0 7px;
}
.syt-antd-input-number-rtl {
  direction: rtl;
}
.syt-antd-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: #696F88;
  font-weight: bold;
  line-height: 0;
  text-align: center;
  transition: all 0.1s linear;
}
.syt-antd-input-number-handler:active {
  background: #f4f4f4;
}
.syt-antd-input-number-handler:hover .syt-antd-input-number-handler-up-inner,
.syt-antd-input-number-handler:hover .syt-antd-input-number-handler-down-inner {
  color: #238dd9;
}
.syt-antd-input-number-handler-up-inner,
.syt-antd-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: #696F88;
  line-height: 12px;
  transition: all 0.1s linear;
  user-select: none;
}
.syt-antd-input-number-handler-up-inner > *,
.syt-antd-input-number-handler-down-inner > * {
  line-height: 1;
}
.syt-antd-input-number-handler-up-inner svg,
.syt-antd-input-number-handler-down-inner svg {
  display: inline-block;
}
.syt-antd-input-number-handler-up-inner::before,
.syt-antd-input-number-handler-down-inner::before {
  display: none;
}
.syt-antd-input-number-handler-up-inner .syt-antd-input-number-handler-up-inner-icon,
.syt-antd-input-number-handler-up-inner .syt-antd-input-number-handler-down-inner-icon,
.syt-antd-input-number-handler-down-inner .syt-antd-input-number-handler-up-inner-icon,
.syt-antd-input-number-handler-down-inner .syt-antd-input-number-handler-down-inner-icon {
  display: block;
}
.syt-antd-input-number:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-number:hover + .syt-antd-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.syt-antd-input-number-focused {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-input-number-disabled {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-input-number-disabled:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-number-disabled .syt-antd-input-number-input {
  cursor: not-allowed;
}
.syt-antd-input-number-disabled .syt-antd-input-number-handler-wrap {
  display: none;
}
.syt-antd-input-number-readonly .syt-antd-input-number-handler-wrap {
  display: none;
}
.syt-antd-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}
.syt-antd-input-number-input::-moz-placeholder {
  opacity: 1;
}
.syt-antd-input-number-input::placeholder {
  color: #bfbfbf;
}
.syt-antd-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.syt-antd-input-number-input[type='number']::-webkit-inner-spin-button,
.syt-antd-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.syt-antd-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.syt-antd-input-number-lg input {
  height: 38px;
}
.syt-antd-input-number-sm {
  padding: 0;
}
.syt-antd-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.syt-antd-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #0092e4;
  border-radius: 0 4px 4px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.syt-antd-input-number-handler-wrap .syt-antd-input-number-handler .syt-antd-input-number-handler-up-inner,
.syt-antd-input-number-handler-wrap .syt-antd-input-number-handler .syt-antd-input-number-handler-down-inner {
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.syt-antd-input-number-handler-wrap:hover .syt-antd-input-number-handler {
  height: 40%;
}
.syt-antd-input-number:hover .syt-antd-input-number-handler-wrap {
  opacity: 1;
}
.syt-antd-input-number-handler-up {
  border-top-right-radius: 4px;
  cursor: pointer;
}
.syt-antd-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.syt-antd-input-number-handler-up:hover {
  height: 60% !important;
}
.syt-antd-input-number-handler-down {
  top: 0;
  border-top: 1px solid #0092e4;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}
.syt-antd-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.syt-antd-input-number-handler-down:hover {
  height: 60% !important;
}
.syt-antd-input-number-handler-up-disabled,
.syt-antd-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.syt-antd-input-number-handler-up-disabled:hover .syt-antd-input-number-handler-up-inner,
.syt-antd-input-number-handler-down-disabled:hover .syt-antd-input-number-handler-down-inner {
  color: #c1c5c8;
}
.syt-antd-input-number-rtl {
  direction: rtl;
}
.syt-antd-input-number-rtl .syt-antd-input-number-handler-wrap {
  right: auto;
  left: 0;
  border-right: 1px solid #0092e4;
  border-left: 0;
  border-radius: 4px 0 0 4px;
}
.syt-antd-input-number-rtl .syt-antd-input-number-input {
  direction: ltr;
  text-align: right;
}
.syt-antd-input-number {
  border: 1px solid #C2C7D0;
}
.syt-antd-input-number-focused {
  border: 1px solid #0092E4;
  box-shadow: 0 0 0 3px rgba(0, 146, 228, 0.25);
}
.syt-antd-input-number-handler-wrap {
  border-left: 1px solid #C2C7D0;
}
.syt-antd-input-number-handler-down {
  border-top: 1px solid #C2C7D0;
}
.syt-antd-input-number-disabled {
  color: #868CA2;
  border: 1px solid #C2C7D0;
}
.syt-antd-input-number-disabled:hover {
  border: 1px solid #C2C7D0;
}
.syt-antd-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.syt-antd-layout,
.syt-antd-layout * {
  box-sizing: border-box;
}
.syt-antd-layout.syt-antd-layout-has-sider {
  flex-direction: row;
}
.syt-antd-layout.syt-antd-layout-has-sider > .syt-antd-layout,
.syt-antd-layout.syt-antd-layout-has-sider > .syt-antd-layout-content {
  overflow-x: hidden;
}
.syt-antd-layout-header,
.syt-antd-layout-footer {
  flex: 0 0 auto;
}
.syt-antd-layout-header {
  height: 64px;
  padding: 0 50px;
  color: #14151C;
  line-height: 64px;
  background: #ffffff;
}
.syt-antd-layout-footer {
  padding: 24px 50px;
  color: #14151C;
  font-size: 14px;
  background: #f0f2f5;
}
.syt-antd-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.syt-antd-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #ffffff;
  transition: all 0.2s;
}
.syt-antd-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.syt-antd-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.syt-antd-layout-sider-right {
  order: 1;
}
.syt-antd-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.syt-antd-layout-sider-zero-width > * {
  overflow: hidden;
}
.syt-antd-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #ffffff;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.syt-antd-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.syt-antd-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.syt-antd-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 4px 0 0 4px;
}
.syt-antd-layout-sider-light {
  background: #fff;
}
.syt-antd-layout-sider-light .syt-antd-layout-sider-trigger {
  color: #14151C;
  background: #fff;
}
.syt-antd-layout-sider-light .syt-antd-layout-sider-zero-width-trigger {
  color: #14151C;
  background: #fff;
}
.syt-antd-layout-rtl {
  direction: rtl;
}
.syt-antd-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
}
.syt-antd-list * {
  outline: none;
}
.syt-antd-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.syt-antd-list-pagination .syt-antd-pagination-options {
  text-align: left;
}
.syt-antd-list-more {
  margin-top: 12px;
  text-align: center;
}
.syt-antd-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.syt-antd-list-spin {
  min-height: 40px;
  text-align: center;
}
.syt-antd-list-empty-text {
  padding: 16px;
  color: #c1c5c8;
  font-size: 14px;
  text-align: center;
}
.syt-antd-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.syt-antd-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: #14151C;
}
.syt-antd-list-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
}
.syt-antd-list-item-meta-avatar {
  margin-right: 16px;
}
.syt-antd-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: #14151C;
}
.syt-antd-list-item-meta-title {
  margin-bottom: 4px;
  color: #14151C;
  font-size: 14px;
  line-height: 20px;
}
.syt-antd-list-item-meta-title > a {
  color: #14151C;
  transition: all 0.3s;
}
.syt-antd-list-item-meta-title > a:hover {
  color: #0071CD;
}
.syt-antd-list-item-meta-description {
  color: #696F88;
  font-size: 14px;
  line-height: 20px;
}
.syt-antd-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.syt-antd-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: #696F88;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.syt-antd-list-item-action > li:first-child {
  padding-left: 0;
}
.syt-antd-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0;
}
.syt-antd-list-header {
  background: transparent;
}
.syt-antd-list-footer {
  background: transparent;
}
.syt-antd-list-header,
.syt-antd-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.syt-antd-list-empty {
  padding: 16px 0;
  color: #696F88;
  font-size: 12px;
  text-align: center;
}
.syt-antd-list-split .syt-antd-list-item {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-list-split .syt-antd-list-item:last-child {
  border-bottom: none;
}
.syt-antd-list-split .syt-antd-list-header {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-list-split.syt-antd-list-empty .syt-antd-list-footer {
  border-top: 1px solid #f0f0f0;
}
.syt-antd-list-loading .syt-antd-list-spin-nested-loading {
  min-height: 32px;
}
.syt-antd-list-split.syt-antd-list-something-after-last-item .syt-antd-spin-container > .syt-antd-list-items > .syt-antd-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-list-lg .syt-antd-list-item {
  padding: 16px 24px;
}
.syt-antd-list-sm .syt-antd-list-item {
  padding: 8px 16px;
}
.syt-antd-list-vertical .syt-antd-list-item {
  align-items: initial;
}
.syt-antd-list-vertical .syt-antd-list-item-main {
  display: block;
  flex: 1;
}
.syt-antd-list-vertical .syt-antd-list-item-extra {
  margin-left: 40px;
}
.syt-antd-list-vertical .syt-antd-list-item-meta {
  margin-bottom: 16px;
}
.syt-antd-list-vertical .syt-antd-list-item-meta-title {
  margin-bottom: 12px;
  color: #14151C;
  font-size: 16px;
  line-height: 24px;
}
.syt-antd-list-vertical .syt-antd-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.syt-antd-list-vertical .syt-antd-list-item-action > li {
  padding: 0 16px;
}
.syt-antd-list-vertical .syt-antd-list-item-action > li:first-child {
  padding-left: 0;
}
.syt-antd-list-grid .syt-antd-col > .syt-antd-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.syt-antd-list-item-no-flex {
  display: block;
}
.syt-antd-list:not(.syt-antd-list-vertical) .syt-antd-list-item-no-flex .syt-antd-list-item-action {
  float: right;
}
.syt-antd-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
}
.syt-antd-list * {
  outline: none;
}
.syt-antd-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.syt-antd-list-pagination .syt-antd-pagination-options {
  text-align: left;
}
.syt-antd-list-more {
  margin-top: 12px;
  text-align: center;
}
.syt-antd-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.syt-antd-list-spin {
  min-height: 40px;
  text-align: center;
}
.syt-antd-list-empty-text {
  padding: 16px;
  color: #c1c5c8;
  font-size: 14px;
  text-align: center;
}
.syt-antd-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.syt-antd-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: #14151C;
}
.syt-antd-list-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
}
.syt-antd-list-item-meta-avatar {
  margin-right: 16px;
}
.syt-antd-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: #14151C;
}
.syt-antd-list-item-meta-title {
  margin-bottom: 4px;
  color: #14151C;
  font-size: 14px;
  line-height: 20px;
}
.syt-antd-list-item-meta-title > a {
  color: #14151C;
  transition: all 0.3s;
}
.syt-antd-list-item-meta-title > a:hover {
  color: #0071CD;
}
.syt-antd-list-item-meta-description {
  color: #696F88;
  font-size: 14px;
  line-height: 20px;
}
.syt-antd-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.syt-antd-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: #696F88;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.syt-antd-list-item-action > li:first-child {
  padding-left: 0;
}
.syt-antd-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0;
}
.syt-antd-list-header {
  background: transparent;
}
.syt-antd-list-footer {
  background: transparent;
}
.syt-antd-list-header,
.syt-antd-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.syt-antd-list-empty {
  padding: 16px 0;
  color: #696F88;
  font-size: 12px;
  text-align: center;
}
.syt-antd-list-split .syt-antd-list-item {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-list-split .syt-antd-list-item:last-child {
  border-bottom: none;
}
.syt-antd-list-split .syt-antd-list-header {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-list-split.syt-antd-list-empty .syt-antd-list-footer {
  border-top: 1px solid #f0f0f0;
}
.syt-antd-list-loading .syt-antd-list-spin-nested-loading {
  min-height: 32px;
}
.syt-antd-list-split.syt-antd-list-something-after-last-item .syt-antd-spin-container > .syt-antd-list-items > .syt-antd-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-list-lg .syt-antd-list-item {
  padding: 16px 24px;
}
.syt-antd-list-sm .syt-antd-list-item {
  padding: 8px 16px;
}
.syt-antd-list-vertical .syt-antd-list-item {
  align-items: initial;
}
.syt-antd-list-vertical .syt-antd-list-item-main {
  display: block;
  flex: 1;
}
.syt-antd-list-vertical .syt-antd-list-item-extra {
  margin-left: 40px;
}
.syt-antd-list-vertical .syt-antd-list-item-meta {
  margin-bottom: 16px;
}
.syt-antd-list-vertical .syt-antd-list-item-meta-title {
  margin-bottom: 12px;
  color: #14151C;
  font-size: 16px;
  line-height: 24px;
}
.syt-antd-list-vertical .syt-antd-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.syt-antd-list-vertical .syt-antd-list-item-action > li {
  padding: 0 16px;
}
.syt-antd-list-vertical .syt-antd-list-item-action > li:first-child {
  padding-left: 0;
}
.syt-antd-list-grid .syt-antd-col > .syt-antd-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.syt-antd-list-item-no-flex {
  display: block;
}
.syt-antd-list:not(.syt-antd-list-vertical) .syt-antd-list-item-no-flex .syt-antd-list-item-action {
  float: right;
}
.syt-antd-list-bordered {
  border: 1px solid #0092e4;
  border-radius: 4px;
}
.syt-antd-list-bordered .syt-antd-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.syt-antd-list-bordered .syt-antd-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.syt-antd-list-bordered .syt-antd-list-item {
  padding-right: 24px;
  padding-left: 24px;
}
.syt-antd-list-bordered .syt-antd-list-pagination {
  margin: 16px 24px;
}
.syt-antd-list-bordered.syt-antd-list-sm .syt-antd-list-item {
  padding: 8px 16px;
}
.syt-antd-list-bordered.syt-antd-list-sm .syt-antd-list-header,
.syt-antd-list-bordered.syt-antd-list-sm .syt-antd-list-footer {
  padding: 8px 16px;
}
.syt-antd-list-bordered.syt-antd-list-lg .syt-antd-list-item {
  padding: 16px 24px;
}
.syt-antd-list-bordered.syt-antd-list-lg .syt-antd-list-header,
.syt-antd-list-bordered.syt-antd-list-lg .syt-antd-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .syt-antd-list-item-action {
    margin-left: 24px;
  }
  .syt-antd-list-vertical .syt-antd-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .syt-antd-list-item {
    flex-wrap: wrap;
  }
  .syt-antd-list-item-action {
    margin-left: 12px;
  }
  .syt-antd-list-vertical .syt-antd-list-item {
    flex-wrap: wrap-reverse;
  }
  .syt-antd-list-vertical .syt-antd-list-item-main {
    min-width: 220px;
  }
  .syt-antd-list-vertical .syt-antd-list-item-extra {
    margin: auto auto 16px;
  }
}
.syt-antd-list-rtl {
  direction: rtl;
  text-align: right;
}
.syt-antd-list-rtl .ReactVirtualized__List .syt-antd-list-item {
  direction: rtl;
}
.syt-antd-list-rtl .syt-antd-list-pagination {
  text-align: left;
}
.syt-antd-list-rtl .syt-antd-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px;
}
.syt-antd-list-rtl .syt-antd-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}
.syt-antd-list.syt-antd-list-rtl .syt-antd-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.syt-antd-list-rtl .syt-antd-list-item-action-split {
  right: auto;
  left: 0;
}
.syt-antd-list-rtl.syt-antd-list-vertical .syt-antd-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}
.syt-antd-list-rtl.syt-antd-list-vertical .syt-antd-list-item-action {
  margin-right: auto;
}
.syt-antd-list-rtl .syt-antd-list-vertical .syt-antd-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.syt-antd-list-rtl .syt-antd-list:not(.syt-antd-list-vertical) .syt-antd-list-item-no-flex .syt-antd-list-item-action {
  float: left;
}
@media screen and (max-width: 768px) {
  .syt-antd-list-rtl .syt-antd-list-item-action {
    margin-right: 24px;
    margin-left: 0;
  }
  .syt-antd-list-rtl .syt-antd-list-vertical .syt-antd-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .syt-antd-list-rtl .syt-antd-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }
  .syt-antd-list-rtl.syt-antd-list-vertical .syt-antd-list-item-extra {
    margin: auto auto 16px;
  }
}
.syt-antd-message {
  margin: 0;
  padding: 0;
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1;
  width: 100%;
  pointer-events: none;
}
.syt-antd-message .syt-antd-message-notice {
  font-size: 16px;
  padding: 8px;
  text-align: center;
  color: #14151C;
  background-color: #ffffff;
}
.syt-antd-message .syt-antd-message-notice .syt-antd-message-notice-content {
  border-radius: 4px;
  display: inline-block;
  padding: 12px 18px;
  box-shadow: 0 4px 12px #c1c5c8;
  line-height: 24px;
}
.syt-antd-message .syt-antd-message-notice .syt-antd-message-notice-content .syt-antd-message-custom-content {
  height: 24px;
}
.syt-antd-message .syt-antd-message-notice .syt-antd-message-notice-content .syt-antd-message-custom-content svg {
  margin-right: 10px;
}
.syt-antd-message .syt-antd-message-notice .syt-antd-message-notice-content .syt-antd-message-custom-content span {
  vertical-align: top;
}
.syt-antd-message .dark .syt-antd-message-notice-content {
  background-color: #232630;
  color: #FFFFFF;
}
.syt-antd-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 20px;
  font-feature-settings: 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: #14151C;
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.syt-antd-menu::before {
  display: table;
  content: '';
}
.syt-antd-menu::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-menu ul,
.syt-antd-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.syt-antd-menu-hidden {
  display: none;
}
.syt-antd-menu-item-group-title {
  height: 20px;
  padding: 8px 16px;
  color: #696F88;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.3s;
}
.syt-antd-menu-horizontal .syt-antd-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-submenu,
.syt-antd-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-submenu-selected {
  color: #0071CD;
}
.syt-antd-menu-item:active,
.syt-antd-menu-submenu-title:active {
  background: #e6f8ff;
}
.syt-antd-menu-submenu .syt-antd-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-item a {
  color: #14151C;
}
.syt-antd-menu-item a:hover {
  color: #0071CD;
}
.syt-antd-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.syt-antd-menu-item > .syt-antd-badge a {
  color: #14151C;
}
.syt-antd-menu-item > .syt-antd-badge a:hover {
  color: #0071CD;
}
.syt-antd-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.syt-antd-menu-item:hover,
.syt-antd-menu-item-active,
.syt-antd-menu:not(.syt-antd-menu-inline) .syt-antd-menu-submenu-open,
.syt-antd-menu-submenu-active,
.syt-antd-menu-submenu-title:hover {
  color: #0071CD;
}
.syt-antd-menu-horizontal .syt-antd-menu-item,
.syt-antd-menu-horizontal .syt-antd-menu-submenu {
  margin-top: -1px;
}
.syt-antd-menu-horizontal > .syt-antd-menu-item:hover,
.syt-antd-menu-horizontal > .syt-antd-menu-item-active,
.syt-antd-menu-horizontal > .syt-antd-menu-submenu .syt-antd-menu-submenu-title:hover {
  background-color: transparent;
}
.syt-antd-menu-item-selected {
  color: #0071CD;
}
.syt-antd-menu-item-selected a,
.syt-antd-menu-item-selected a:hover {
  color: #0071CD;
}
.syt-antd-menu:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-selected {
  background-color: #e6f8ff;
}
.syt-antd-menu-inline,
.syt-antd-menu-vertical,
.syt-antd-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.syt-antd-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.syt-antd-menu-vertical.syt-antd-menu-sub,
.syt-antd-menu-vertical-left.syt-antd-menu-sub,
.syt-antd-menu-vertical-right.syt-antd-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
  transform-origin: 0 0;
}
.syt-antd-menu-vertical.syt-antd-menu-sub:not([class*='-active']),
.syt-antd-menu-vertical-left.syt-antd-menu-sub:not([class*='-active']),
.syt-antd-menu-vertical-right.syt-antd-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.syt-antd-menu-vertical.syt-antd-menu-sub .syt-antd-menu-item,
.syt-antd-menu-vertical-left.syt-antd-menu-sub .syt-antd-menu-item,
.syt-antd-menu-vertical-right.syt-antd-menu-sub .syt-antd-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.syt-antd-menu-vertical.syt-antd-menu-sub .syt-antd-menu-item::after,
.syt-antd-menu-vertical-left.syt-antd-menu-sub .syt-antd-menu-item::after,
.syt-antd-menu-vertical-right.syt-antd-menu-sub .syt-antd-menu-item::after {
  border-right: 0;
}
.syt-antd-menu-vertical.syt-antd-menu-sub > .syt-antd-menu-item,
.syt-antd-menu-vertical-left.syt-antd-menu-sub > .syt-antd-menu-item,
.syt-antd-menu-vertical-right.syt-antd-menu-sub > .syt-antd-menu-item,
.syt-antd-menu-vertical.syt-antd-menu-sub > .syt-antd-menu-submenu,
.syt-antd-menu-vertical-left.syt-antd-menu-sub > .syt-antd-menu-submenu,
.syt-antd-menu-vertical-right.syt-antd-menu-sub > .syt-antd-menu-submenu {
  transform-origin: 0 0;
}
.syt-antd-menu-horizontal.syt-antd-menu-sub {
  min-width: 114px;
}
.syt-antd-menu-horizontal .syt-antd-menu-item,
.syt-antd-menu-horizontal .syt-antd-menu-submenu-title {
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-item,
.syt-antd-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-item .anticon,
.syt-antd-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-item .anticon + span,
.syt-antd-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-item.syt-antd-menu-item-only-child > .anticon,
.syt-antd-menu-submenu-title.syt-antd-menu-item-only-child > .anticon {
  margin-right: 0;
}
.syt-antd-menu > .syt-antd-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.syt-antd-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  border-radius: 4px;
  box-shadow: none;
}
.syt-antd-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.syt-antd-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.syt-antd-menu-submenu > .syt-antd-menu {
  background-color: #fff;
  border-radius: 4px;
}
.syt-antd-menu-submenu > .syt-antd-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-submenu-popup > .syt-antd-menu {
  background-color: #fff;
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-image: linear-gradient(to right, #14151C, #14151C);
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2px);
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2px);
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::before {
  background: linear-gradient(to right, #0071CD, #0071CD);
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2px);
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2px);
}
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2px);
}
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2px);
}
.syt-antd-menu-submenu-open.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow {
  transform: translateY(-2px);
}
.syt-antd-menu-submenu-open.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2px);
}
.syt-antd-menu-submenu-open.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2px);
}
.syt-antd-menu-vertical .syt-antd-menu-submenu-selected,
.syt-antd-menu-vertical-left .syt-antd-menu-submenu-selected,
.syt-antd-menu-vertical-right .syt-antd-menu-submenu-selected {
  color: #0071CD;
}
.syt-antd-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-item,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-submenu {
  margin: 0 20px;
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
  padding-right: 0;
  padding-left: 0;
}
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-item:hover,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-submenu:hover,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-item-active,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-submenu-active,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-item-open,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-submenu-open,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-item-selected,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-submenu-selected {
  color: #0071CD;
  border-bottom: 2px solid #0071CD;
}
.syt-antd-menu-horizontal > .syt-antd-menu-item,
.syt-antd-menu-horizontal > .syt-antd-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
.syt-antd-menu-horizontal > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title {
  padding: 0;
}
.syt-antd-menu-horizontal > .syt-antd-menu-item a {
  color: #14151C;
}
.syt-antd-menu-horizontal > .syt-antd-menu-item a:hover {
  color: #0071CD;
}
.syt-antd-menu-horizontal > .syt-antd-menu-item a::before {
  bottom: -2px;
}
.syt-antd-menu-horizontal > .syt-antd-menu-item-selected a {
  color: #0071CD;
}
.syt-antd-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.syt-antd-menu-vertical .syt-antd-menu-item,
.syt-antd-menu-vertical-left .syt-antd-menu-item,
.syt-antd-menu-vertical-right .syt-antd-menu-item,
.syt-antd-menu-inline .syt-antd-menu-item {
  position: relative;
}
.syt-antd-menu-vertical .syt-antd-menu-item::after,
.syt-antd-menu-vertical-left .syt-antd-menu-item::after,
.syt-antd-menu-vertical-right .syt-antd-menu-item::after,
.syt-antd-menu-inline .syt-antd-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #0071CD;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.syt-antd-menu-vertical .syt-antd-menu-item,
.syt-antd-menu-vertical-left .syt-antd-menu-item,
.syt-antd-menu-vertical-right .syt-antd-menu-item,
.syt-antd-menu-inline .syt-antd-menu-item,
.syt-antd-menu-vertical .syt-antd-menu-submenu-title,
.syt-antd-menu-vertical-left .syt-antd-menu-submenu-title,
.syt-antd-menu-vertical-right .syt-antd-menu-submenu-title,
.syt-antd-menu-inline .syt-antd-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.syt-antd-menu-vertical .syt-antd-menu-submenu,
.syt-antd-menu-vertical-left .syt-antd-menu-submenu,
.syt-antd-menu-vertical-right .syt-antd-menu-submenu,
.syt-antd-menu-inline .syt-antd-menu-submenu {
  padding-bottom: 0.02px;
}
.syt-antd-menu-vertical .syt-antd-menu-item:not(:last-child),
.syt-antd-menu-vertical-left .syt-antd-menu-item:not(:last-child),
.syt-antd-menu-vertical-right .syt-antd-menu-item:not(:last-child),
.syt-antd-menu-inline .syt-antd-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.syt-antd-menu-vertical > .syt-antd-menu-item,
.syt-antd-menu-vertical-left > .syt-antd-menu-item,
.syt-antd-menu-vertical-right > .syt-antd-menu-item,
.syt-antd-menu-inline > .syt-antd-menu-item,
.syt-antd-menu-vertical > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title,
.syt-antd-menu-vertical-left > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title,
.syt-antd-menu-vertical-right > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title,
.syt-antd-menu-inline > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.syt-antd-menu-vertical .syt-antd-menu-submenu-title {
  padding-right: 34px;
}
.syt-antd-menu-inline {
  width: 100%;
}
.syt-antd-menu-inline .syt-antd-menu-selected::after,
.syt-antd-menu-inline .syt-antd-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-inline .syt-antd-menu-item,
.syt-antd-menu-inline .syt-antd-menu-submenu-title {
  width: calc(100% + 1px);
}
.syt-antd-menu-inline .syt-antd-menu-submenu-title {
  padding-right: 34px;
}
.syt-antd-menu-inline-collapsed {
  width: 80px;
}
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-item,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item .syt-antd-menu-submenu-arrow,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-item .syt-antd-menu-submenu-arrow,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow {
  display: none;
}
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item .anticon,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-item .anticon,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title .anticon,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item .anticon + span,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-item .anticon + span,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title .anticon + span,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.syt-antd-menu-inline-collapsed .anticon {
  display: inline-block;
}
.syt-antd-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.syt-antd-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.syt-antd-menu-inline-collapsed-tooltip a {
  color: #FFFFFF;
}
.syt-antd-menu-inline-collapsed .syt-antd-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.syt-antd-menu-item-group-list .syt-antd-menu-item,
.syt-antd-menu-item-group-list .syt-antd-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.syt-antd-menu-root.syt-antd-menu-vertical,
.syt-antd-menu-root.syt-antd-menu-vertical-left,
.syt-antd-menu-root.syt-antd-menu-vertical-right,
.syt-antd-menu-root.syt-antd-menu-inline {
  box-shadow: none;
}
.syt-antd-menu-root.syt-antd-menu-inline-collapsed .syt-antd-menu-item > .syt-antd-menu-inline-collapsed-noicon,
.syt-antd-menu-root.syt-antd-menu-inline-collapsed .syt-antd-menu-submenu .syt-antd-menu-submenu-title > .syt-antd-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.syt-antd-menu-sub.syt-antd-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.syt-antd-menu-sub.syt-antd-menu-inline > .syt-antd-menu-item,
.syt-antd-menu-sub.syt-antd-menu-inline > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.syt-antd-menu-sub.syt-antd-menu-inline .syt-antd-menu-item-group-title {
  padding-left: 32px;
}
.syt-antd-menu-item-disabled,
.syt-antd-menu-submenu-disabled {
  color: #c1c5c8 !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}
.syt-antd-menu-item-disabled a,
.syt-antd-menu-submenu-disabled a {
  color: #c1c5c8 !important;
  pointer-events: none;
}
.syt-antd-menu-item-disabled > .syt-antd-menu-submenu-title,
.syt-antd-menu-submenu-disabled > .syt-antd-menu-submenu-title {
  color: #c1c5c8 !important;
  cursor: not-allowed;
}
.syt-antd-menu-item-disabled > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-disabled > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-item-disabled > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-disabled > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::after {
  background: #c1c5c8 !important;
}
.syt-antd-layout-header .syt-antd-menu {
  line-height: inherit;
}
.syt-antd-menu-item-danger.syt-antd-menu-item {
  color: #CF3537;
}
.syt-antd-menu-item-danger.syt-antd-menu-item:hover,
.syt-antd-menu-item-danger.syt-antd-menu-item-active {
  color: #CF3537;
}
.syt-antd-menu-item-danger.syt-antd-menu-item:active {
  background: #fff1f0;
}
.syt-antd-menu-item-danger.syt-antd-menu-item-selected {
  color: #CF3537;
}
.syt-antd-menu-item-danger.syt-antd-menu-item-selected > a,
.syt-antd-menu-item-danger.syt-antd-menu-item-selected > a:hover {
  color: #CF3537;
}
.syt-antd-menu:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-danger.syt-antd-menu-item-selected {
  background-color: #fff1f0;
}
.syt-antd-menu-inline .syt-antd-menu-item-danger.syt-antd-menu-item::after {
  border-right-color: #CF3537;
}
.syt-antd-menu-dark .syt-antd-menu-item-danger.syt-antd-menu-item,
.syt-antd-menu-dark .syt-antd-menu-item-danger.syt-antd-menu-item:hover,
.syt-antd-menu-dark .syt-antd-menu-item-danger.syt-antd-menu-item > a {
  color: #CF3537;
}
.syt-antd-menu-dark.syt-antd-menu-dark:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-danger.syt-antd-menu-item-selected {
  color: #fff;
  background-color: #CF3537;
}
.syt-antd-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 20px;
  font-feature-settings: 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: #14151C;
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.syt-antd-menu::before {
  display: table;
  content: '';
}
.syt-antd-menu::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-menu ul,
.syt-antd-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.syt-antd-menu-hidden {
  display: none;
}
.syt-antd-menu-item-group-title {
  height: 20px;
  padding: 8px 16px;
  color: #696F88;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.3s;
}
.syt-antd-menu-horizontal .syt-antd-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-submenu,
.syt-antd-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-submenu-selected {
  color: #0071CD;
}
.syt-antd-menu-item:active,
.syt-antd-menu-submenu-title:active {
  background: #e6f8ff;
}
.syt-antd-menu-submenu .syt-antd-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-item a {
  color: #14151C;
}
.syt-antd-menu-item a:hover {
  color: #0071CD;
}
.syt-antd-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.syt-antd-menu-item > .syt-antd-badge a {
  color: #14151C;
}
.syt-antd-menu-item > .syt-antd-badge a:hover {
  color: #0071CD;
}
.syt-antd-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.syt-antd-menu-item:hover,
.syt-antd-menu-item-active,
.syt-antd-menu:not(.syt-antd-menu-inline) .syt-antd-menu-submenu-open,
.syt-antd-menu-submenu-active,
.syt-antd-menu-submenu-title:hover {
  color: #0071CD;
}
.syt-antd-menu-horizontal .syt-antd-menu-item,
.syt-antd-menu-horizontal .syt-antd-menu-submenu {
  margin-top: -1px;
}
.syt-antd-menu-horizontal > .syt-antd-menu-item:hover,
.syt-antd-menu-horizontal > .syt-antd-menu-item-active,
.syt-antd-menu-horizontal > .syt-antd-menu-submenu .syt-antd-menu-submenu-title:hover {
  background-color: transparent;
}
.syt-antd-menu-item-selected {
  color: #0071CD;
}
.syt-antd-menu-item-selected a,
.syt-antd-menu-item-selected a:hover {
  color: #0071CD;
}
.syt-antd-menu:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-selected {
  background-color: #e6f8ff;
}
.syt-antd-menu-inline,
.syt-antd-menu-vertical,
.syt-antd-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.syt-antd-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.syt-antd-menu-vertical.syt-antd-menu-sub,
.syt-antd-menu-vertical-left.syt-antd-menu-sub,
.syt-antd-menu-vertical-right.syt-antd-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
  transform-origin: 0 0;
}
.syt-antd-menu-vertical.syt-antd-menu-sub:not([class*='-active']),
.syt-antd-menu-vertical-left.syt-antd-menu-sub:not([class*='-active']),
.syt-antd-menu-vertical-right.syt-antd-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.syt-antd-menu-vertical.syt-antd-menu-sub .syt-antd-menu-item,
.syt-antd-menu-vertical-left.syt-antd-menu-sub .syt-antd-menu-item,
.syt-antd-menu-vertical-right.syt-antd-menu-sub .syt-antd-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.syt-antd-menu-vertical.syt-antd-menu-sub .syt-antd-menu-item::after,
.syt-antd-menu-vertical-left.syt-antd-menu-sub .syt-antd-menu-item::after,
.syt-antd-menu-vertical-right.syt-antd-menu-sub .syt-antd-menu-item::after {
  border-right: 0;
}
.syt-antd-menu-vertical.syt-antd-menu-sub > .syt-antd-menu-item,
.syt-antd-menu-vertical-left.syt-antd-menu-sub > .syt-antd-menu-item,
.syt-antd-menu-vertical-right.syt-antd-menu-sub > .syt-antd-menu-item,
.syt-antd-menu-vertical.syt-antd-menu-sub > .syt-antd-menu-submenu,
.syt-antd-menu-vertical-left.syt-antd-menu-sub > .syt-antd-menu-submenu,
.syt-antd-menu-vertical-right.syt-antd-menu-sub > .syt-antd-menu-submenu {
  transform-origin: 0 0;
}
.syt-antd-menu-horizontal.syt-antd-menu-sub {
  min-width: 114px;
}
.syt-antd-menu-horizontal .syt-antd-menu-item,
.syt-antd-menu-horizontal .syt-antd-menu-submenu-title {
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-item,
.syt-antd-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-item .anticon,
.syt-antd-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-item .anticon + span,
.syt-antd-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-item.syt-antd-menu-item-only-child > .anticon,
.syt-antd-menu-submenu-title.syt-antd-menu-item-only-child > .anticon {
  margin-right: 0;
}
.syt-antd-menu > .syt-antd-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.syt-antd-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  border-radius: 4px;
  box-shadow: none;
}
.syt-antd-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.syt-antd-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.syt-antd-menu-submenu > .syt-antd-menu {
  background-color: #fff;
  border-radius: 4px;
}
.syt-antd-menu-submenu > .syt-antd-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-submenu-popup > .syt-antd-menu {
  background-color: #fff;
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-image: linear-gradient(to right, #14151C, #14151C);
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2px);
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2px);
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title:hover .syt-antd-menu-submenu-arrow::before {
  background: linear-gradient(to right, #0071CD, #0071CD);
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2px);
}
.syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2px);
}
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2px);
}
.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2px);
}
.syt-antd-menu-submenu-open.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow {
  transform: translateY(-2px);
}
.syt-antd-menu-submenu-open.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2px);
}
.syt-antd-menu-submenu-open.syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2px);
}
.syt-antd-menu-vertical .syt-antd-menu-submenu-selected,
.syt-antd-menu-vertical-left .syt-antd-menu-submenu-selected,
.syt-antd-menu-vertical-right .syt-antd-menu-submenu-selected {
  color: #0071CD;
}
.syt-antd-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-item,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-submenu {
  margin: 0 20px;
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
  padding-right: 0;
  padding-left: 0;
}
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-item:hover,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-submenu:hover,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-item-active,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-submenu-active,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-item-open,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-submenu-open,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-item-selected,
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-submenu-selected {
  color: #0071CD;
  border-bottom: 2px solid #0071CD;
}
.syt-antd-menu-horizontal > .syt-antd-menu-item,
.syt-antd-menu-horizontal > .syt-antd-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
.syt-antd-menu-horizontal > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title {
  padding: 0;
}
.syt-antd-menu-horizontal > .syt-antd-menu-item a {
  color: #14151C;
}
.syt-antd-menu-horizontal > .syt-antd-menu-item a:hover {
  color: #0071CD;
}
.syt-antd-menu-horizontal > .syt-antd-menu-item a::before {
  bottom: -2px;
}
.syt-antd-menu-horizontal > .syt-antd-menu-item-selected a {
  color: #0071CD;
}
.syt-antd-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.syt-antd-menu-vertical .syt-antd-menu-item,
.syt-antd-menu-vertical-left .syt-antd-menu-item,
.syt-antd-menu-vertical-right .syt-antd-menu-item,
.syt-antd-menu-inline .syt-antd-menu-item {
  position: relative;
}
.syt-antd-menu-vertical .syt-antd-menu-item::after,
.syt-antd-menu-vertical-left .syt-antd-menu-item::after,
.syt-antd-menu-vertical-right .syt-antd-menu-item::after,
.syt-antd-menu-inline .syt-antd-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #0071CD;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.syt-antd-menu-vertical .syt-antd-menu-item,
.syt-antd-menu-vertical-left .syt-antd-menu-item,
.syt-antd-menu-vertical-right .syt-antd-menu-item,
.syt-antd-menu-inline .syt-antd-menu-item,
.syt-antd-menu-vertical .syt-antd-menu-submenu-title,
.syt-antd-menu-vertical-left .syt-antd-menu-submenu-title,
.syt-antd-menu-vertical-right .syt-antd-menu-submenu-title,
.syt-antd-menu-inline .syt-antd-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.syt-antd-menu-vertical .syt-antd-menu-submenu,
.syt-antd-menu-vertical-left .syt-antd-menu-submenu,
.syt-antd-menu-vertical-right .syt-antd-menu-submenu,
.syt-antd-menu-inline .syt-antd-menu-submenu {
  padding-bottom: 0.02px;
}
.syt-antd-menu-vertical .syt-antd-menu-item:not(:last-child),
.syt-antd-menu-vertical-left .syt-antd-menu-item:not(:last-child),
.syt-antd-menu-vertical-right .syt-antd-menu-item:not(:last-child),
.syt-antd-menu-inline .syt-antd-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.syt-antd-menu-vertical > .syt-antd-menu-item,
.syt-antd-menu-vertical-left > .syt-antd-menu-item,
.syt-antd-menu-vertical-right > .syt-antd-menu-item,
.syt-antd-menu-inline > .syt-antd-menu-item,
.syt-antd-menu-vertical > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title,
.syt-antd-menu-vertical-left > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title,
.syt-antd-menu-vertical-right > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title,
.syt-antd-menu-inline > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.syt-antd-menu-vertical .syt-antd-menu-submenu-title {
  padding-right: 34px;
}
.syt-antd-menu-inline {
  width: 100%;
}
.syt-antd-menu-inline .syt-antd-menu-selected::after,
.syt-antd-menu-inline .syt-antd-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-menu-inline .syt-antd-menu-item,
.syt-antd-menu-inline .syt-antd-menu-submenu-title {
  width: calc(100% + 1px);
}
.syt-antd-menu-inline .syt-antd-menu-submenu-title {
  padding-right: 34px;
}
.syt-antd-menu-inline-collapsed {
  width: 80px;
}
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-item,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item .syt-antd-menu-submenu-arrow,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-item .syt-antd-menu-submenu-arrow,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow {
  display: none;
}
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item .anticon,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-item .anticon,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title .anticon,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item .anticon + span,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-item .anticon + span,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-item-group > .syt-antd-menu-item-group-list > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title .anticon + span,
.syt-antd-menu-inline-collapsed > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.syt-antd-menu-inline-collapsed .anticon {
  display: inline-block;
}
.syt-antd-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.syt-antd-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.syt-antd-menu-inline-collapsed-tooltip a {
  color: #FFFFFF;
}
.syt-antd-menu-inline-collapsed .syt-antd-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.syt-antd-menu-item-group-list .syt-antd-menu-item,
.syt-antd-menu-item-group-list .syt-antd-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.syt-antd-menu-root.syt-antd-menu-vertical,
.syt-antd-menu-root.syt-antd-menu-vertical-left,
.syt-antd-menu-root.syt-antd-menu-vertical-right,
.syt-antd-menu-root.syt-antd-menu-inline {
  box-shadow: none;
}
.syt-antd-menu-root.syt-antd-menu-inline-collapsed .syt-antd-menu-item > .syt-antd-menu-inline-collapsed-noicon,
.syt-antd-menu-root.syt-antd-menu-inline-collapsed .syt-antd-menu-submenu .syt-antd-menu-submenu-title > .syt-antd-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.syt-antd-menu-sub.syt-antd-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.syt-antd-menu-sub.syt-antd-menu-inline > .syt-antd-menu-item,
.syt-antd-menu-sub.syt-antd-menu-inline > .syt-antd-menu-submenu > .syt-antd-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.syt-antd-menu-sub.syt-antd-menu-inline .syt-antd-menu-item-group-title {
  padding-left: 32px;
}
.syt-antd-menu-item-disabled,
.syt-antd-menu-submenu-disabled {
  color: #c1c5c8 !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}
.syt-antd-menu-item-disabled a,
.syt-antd-menu-submenu-disabled a {
  color: #c1c5c8 !important;
  pointer-events: none;
}
.syt-antd-menu-item-disabled > .syt-antd-menu-submenu-title,
.syt-antd-menu-submenu-disabled > .syt-antd-menu-submenu-title {
  color: #c1c5c8 !important;
  cursor: not-allowed;
}
.syt-antd-menu-item-disabled > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-submenu-disabled > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-item-disabled > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-submenu-disabled > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::after {
  background: #c1c5c8 !important;
}
.syt-antd-layout-header .syt-antd-menu {
  line-height: inherit;
}
.syt-antd-menu.syt-antd-menu-dark,
.syt-antd-menu-dark .syt-antd-menu-sub,
.syt-antd-menu.syt-antd-menu-dark .syt-antd-menu-sub {
  color: #868CA2;
  background: #ffffff;
}
.syt-antd-menu.syt-antd-menu-dark .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu-dark .syt-antd-menu-sub .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu.syt-antd-menu-dark .syt-antd-menu-sub .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.syt-antd-menu.syt-antd-menu-dark .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-sub .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu.syt-antd-menu-dark .syt-antd-menu-sub .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu.syt-antd-menu-dark .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-sub .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu.syt-antd-menu-dark .syt-antd-menu-sub .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before {
  background: #fff;
}
.syt-antd-menu-dark.syt-antd-menu-submenu-popup {
  background: transparent;
}
.syt-antd-menu-dark .syt-antd-menu-inline.syt-antd-menu-sub {
  background: #000c17;
}
.syt-antd-menu-dark.syt-antd-menu-horizontal {
  border-bottom: 0;
}
.syt-antd-menu-dark.syt-antd-menu-horizontal > .syt-antd-menu-item,
.syt-antd-menu-dark.syt-antd-menu-horizontal > .syt-antd-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #ffffff;
  border-bottom: 0;
}
.syt-antd-menu-dark.syt-antd-menu-horizontal > .syt-antd-menu-item:hover {
  background-color: #0071CD;
}
.syt-antd-menu-dark.syt-antd-menu-horizontal > .syt-antd-menu-item > a::before {
  bottom: 0;
}
.syt-antd-menu-dark .syt-antd-menu-item,
.syt-antd-menu-dark .syt-antd-menu-item-group-title,
.syt-antd-menu-dark .syt-antd-menu-item > a,
.syt-antd-menu-dark .syt-antd-menu-item > span > a {
  color: #868CA2;
}
.syt-antd-menu-dark.syt-antd-menu-inline,
.syt-antd-menu-dark.syt-antd-menu-vertical,
.syt-antd-menu-dark.syt-antd-menu-vertical-left,
.syt-antd-menu-dark.syt-antd-menu-vertical-right {
  border-right: 0;
}
.syt-antd-menu-dark.syt-antd-menu-inline .syt-antd-menu-item,
.syt-antd-menu-dark.syt-antd-menu-vertical .syt-antd-menu-item,
.syt-antd-menu-dark.syt-antd-menu-vertical-left .syt-antd-menu-item,
.syt-antd-menu-dark.syt-antd-menu-vertical-right .syt-antd-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.syt-antd-menu-dark.syt-antd-menu-inline .syt-antd-menu-item::after,
.syt-antd-menu-dark.syt-antd-menu-vertical .syt-antd-menu-item::after,
.syt-antd-menu-dark.syt-antd-menu-vertical-left .syt-antd-menu-item::after,
.syt-antd-menu-dark.syt-antd-menu-vertical-right .syt-antd-menu-item::after {
  border-right: 0;
}
.syt-antd-menu-dark.syt-antd-menu-inline .syt-antd-menu-item,
.syt-antd-menu-dark.syt-antd-menu-inline .syt-antd-menu-submenu-title {
  width: 100%;
}
.syt-antd-menu-dark .syt-antd-menu-item:hover,
.syt-antd-menu-dark .syt-antd-menu-item-active,
.syt-antd-menu-dark .syt-antd-menu-submenu-active,
.syt-antd-menu-dark .syt-antd-menu-submenu-open,
.syt-antd-menu-dark .syt-antd-menu-submenu-selected,
.syt-antd-menu-dark .syt-antd-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.syt-antd-menu-dark .syt-antd-menu-item:hover > a,
.syt-antd-menu-dark .syt-antd-menu-item-active > a,
.syt-antd-menu-dark .syt-antd-menu-submenu-active > a,
.syt-antd-menu-dark .syt-antd-menu-submenu-open > a,
.syt-antd-menu-dark .syt-antd-menu-submenu-selected > a,
.syt-antd-menu-dark .syt-antd-menu-submenu-title:hover > a,
.syt-antd-menu-dark .syt-antd-menu-item:hover > span > a,
.syt-antd-menu-dark .syt-antd-menu-item-active > span > a,
.syt-antd-menu-dark .syt-antd-menu-submenu-active > span > a,
.syt-antd-menu-dark .syt-antd-menu-submenu-open > span > a,
.syt-antd-menu-dark .syt-antd-menu-submenu-selected > span > a,
.syt-antd-menu-dark .syt-antd-menu-submenu-title:hover > span > a {
  color: #fff;
}
.syt-antd-menu-dark .syt-antd-menu-item:hover > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow,
.syt-antd-menu-dark .syt-antd-menu-item-active > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow,
.syt-antd-menu-dark .syt-antd-menu-submenu-active > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow,
.syt-antd-menu-dark .syt-antd-menu-submenu-open > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow,
.syt-antd-menu-dark .syt-antd-menu-submenu-selected > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow,
.syt-antd-menu-dark .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow,
.syt-antd-menu-dark .syt-antd-menu-item:hover > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow,
.syt-antd-menu-dark .syt-antd-menu-item-active > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow,
.syt-antd-menu-dark .syt-antd-menu-submenu-active > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow,
.syt-antd-menu-dark .syt-antd-menu-submenu-open > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow,
.syt-antd-menu-dark .syt-antd-menu-submenu-selected > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow,
.syt-antd-menu-dark .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow {
  opacity: 1;
}
.syt-antd-menu-dark .syt-antd-menu-item:hover > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-item-active > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-submenu-active > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-submenu-open > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-submenu-selected > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-item:hover > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-item-active > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-submenu-active > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-submenu-open > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-submenu-selected > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-item:hover > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-item-active > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-submenu-active > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-submenu-open > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-submenu-selected > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-item:hover > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-item-active > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-submenu-active > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-submenu-open > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-submenu-selected > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-title:hover > .syt-antd-menu-submenu-arrow::before {
  background: #fff;
}
.syt-antd-menu-dark .syt-antd-menu-item:hover {
  background-color: transparent;
}
.syt-antd-menu-dark.syt-antd-menu-dark:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-selected {
  background-color: #0071CD;
}
.syt-antd-menu-dark .syt-antd-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.syt-antd-menu-dark .syt-antd-menu-item-selected::after {
  border-right: 0;
}
.syt-antd-menu-dark .syt-antd-menu-item-selected > a,
.syt-antd-menu-dark .syt-antd-menu-item-selected > span > a,
.syt-antd-menu-dark .syt-antd-menu-item-selected > a:hover,
.syt-antd-menu-dark .syt-antd-menu-item-selected > span > a:hover {
  color: #fff;
}
.syt-antd-menu-dark .syt-antd-menu-item-selected .anticon {
  color: #fff;
}
.syt-antd-menu-dark .syt-antd-menu-item-selected .anticon + span {
  color: #fff;
}
.syt-antd-menu.syt-antd-menu-dark .syt-antd-menu-item-selected,
.syt-antd-menu-submenu-popup.syt-antd-menu-dark .syt-antd-menu-item-selected {
  background-color: #0071CD;
}
.syt-antd-menu-dark .syt-antd-menu-item-disabled,
.syt-antd-menu-dark .syt-antd-menu-submenu-disabled,
.syt-antd-menu-dark .syt-antd-menu-item-disabled > a,
.syt-antd-menu-dark .syt-antd-menu-submenu-disabled > a,
.syt-antd-menu-dark .syt-antd-menu-item-disabled > span > a,
.syt-antd-menu-dark .syt-antd-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.syt-antd-menu-dark .syt-antd-menu-item-disabled > .syt-antd-menu-submenu-title,
.syt-antd-menu-dark .syt-antd-menu-submenu-disabled > .syt-antd-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.syt-antd-menu-dark .syt-antd-menu-item-disabled > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-submenu-disabled > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-dark .syt-antd-menu-item-disabled > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-dark .syt-antd-menu-submenu-disabled > .syt-antd-menu-submenu-title > .syt-antd-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.syt-antd-menu.syt-antd-menu-rtl {
  direction: rtl;
  text-align: right;
}
.syt-antd-menu-rtl .syt-antd-menu-item-group-title {
  text-align: right;
}
.syt-antd-menu-rtl.syt-antd-menu-inline,
.syt-antd-menu-rtl.syt-antd-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.syt-antd-menu-rtl.syt-antd-menu-dark.syt-antd-menu-inline,
.syt-antd-menu-rtl.syt-antd-menu-dark.syt-antd-menu-vertical {
  border-left: none;
}
.syt-antd-menu-rtl.syt-antd-menu-vertical.syt-antd-menu-sub,
.syt-antd-menu-rtl.syt-antd-menu-vertical-left.syt-antd-menu-sub,
.syt-antd-menu-rtl.syt-antd-menu-vertical-right.syt-antd-menu-sub {
  transform-origin: top right;
}
.syt-antd-menu-rtl.syt-antd-menu-vertical.syt-antd-menu-sub > .syt-antd-menu-item,
.syt-antd-menu-rtl.syt-antd-menu-vertical-left.syt-antd-menu-sub > .syt-antd-menu-item,
.syt-antd-menu-rtl.syt-antd-menu-vertical-right.syt-antd-menu-sub > .syt-antd-menu-item,
.syt-antd-menu-rtl.syt-antd-menu-vertical.syt-antd-menu-sub > .syt-antd-menu-submenu,
.syt-antd-menu-rtl.syt-antd-menu-vertical-left.syt-antd-menu-sub > .syt-antd-menu-submenu,
.syt-antd-menu-rtl.syt-antd-menu-vertical-right.syt-antd-menu-sub > .syt-antd-menu-submenu {
  transform-origin: top right;
}
.syt-antd-menu-rtl .syt-antd-menu-item .anticon,
.syt-antd-menu-rtl .syt-antd-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.syt-antd-menu-rtl .syt-antd-menu-item.syt-antd-menu-item-only-child > .anticon,
.syt-antd-menu-rtl .syt-antd-menu-submenu-title.syt-antd-menu-item-only-child > .anticon {
  margin-left: 0;
}
.syt-antd-menu-rtl .syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu-rtl .syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu-rtl .syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow,
.syt-antd-menu-rtl .syt-antd-menu-submenu-inline > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.syt-antd-menu-rtl .syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-rtl .syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before,
.syt-antd-menu-rtl .syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.syt-antd-menu-rtl .syt-antd-menu-submenu-vertical > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-rtl .syt-antd-menu-submenu-vertical-left > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after,
.syt-antd-menu-rtl .syt-antd-menu-submenu-vertical-right > .syt-antd-menu-submenu-title .syt-antd-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.syt-antd-menu-rtl.syt-antd-menu-vertical .syt-antd-menu-item::after,
.syt-antd-menu-rtl.syt-antd-menu-vertical-left .syt-antd-menu-item::after,
.syt-antd-menu-rtl.syt-antd-menu-vertical-right .syt-antd-menu-item::after,
.syt-antd-menu-rtl.syt-antd-menu-inline .syt-antd-menu-item::after {
  right: auto;
  left: 0;
}
.syt-antd-menu-rtl.syt-antd-menu-vertical .syt-antd-menu-item,
.syt-antd-menu-rtl.syt-antd-menu-vertical-left .syt-antd-menu-item,
.syt-antd-menu-rtl.syt-antd-menu-vertical-right .syt-antd-menu-item,
.syt-antd-menu-rtl.syt-antd-menu-inline .syt-antd-menu-item,
.syt-antd-menu-rtl.syt-antd-menu-vertical .syt-antd-menu-submenu-title,
.syt-antd-menu-rtl.syt-antd-menu-vertical-left .syt-antd-menu-submenu-title,
.syt-antd-menu-rtl.syt-antd-menu-vertical-right .syt-antd-menu-submenu-title,
.syt-antd-menu-rtl.syt-antd-menu-inline .syt-antd-menu-submenu-title {
  text-align: right;
}
.syt-antd-menu-rtl.syt-antd-menu-inline .syt-antd-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.syt-antd-menu-rtl.syt-antd-menu-vertical .syt-antd-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.syt-antd-menu-rtl.syt-antd-menu-inline-collapsed.syt-antd-menu-vertical .syt-antd-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.syt-antd-menu-rtl .syt-antd-menu-item-group-list .syt-antd-menu-item,
.syt-antd-menu-rtl .syt-antd-menu-item-group-list .syt-antd-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.syt-antd-menu-sub.syt-antd-menu-inline {
  border: 0;
}
.syt-antd-menu-rtl.syt-antd-menu-sub.syt-antd-menu-inline .syt-antd-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}
.sidebarLayout {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}
.cropwiseLogo {
  text-align: center;
  color: #FFFFFF;
  height: 60px;
  padding-top: 7px;
}
.cropwiseLogo img {
  max-height: 100%;
}
.sidebarSubmenuItem {
  padding-left: 16px;
}
.sidebarFooter {
  color: #696F88;
  text-align: justify;
  position: absolute;
  bottom: 40px;
  font-size: 12px;
  padding-left: 24px;
}
.submenuName {
  font-size: 14px;
  position: static;
  height: 30px;
  line-height: 33px;
  margin: 0px 10px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.submenuIcon {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
  fill: #696F88;
}
.submenuElementIcon {
  float: left;
  padding-right: 10px;
}
.sidebarContent {
  padding: 24;
  margin: 0;
  min-height: 280;
  text-align: center;
  background: #FFFFFF;
}
.sidebarLayoutContent {
  padding: 15px;
}
.triggerExpandButton {
  margin-top: 3px;
  fill: #696F88;
}
.triggerCloseButton {
  float: right;
  margin-top: 3px;
  margin-right: 20px;
  fill: #696F88;
}
.sibebarSyngentDigitalLogo {
  position: fixed;
  bottom: 0;
  height: 50px;
  z-index: 2;
  padding-left: 10px;
}
.syt-antd-menu-dark {
  background-color: #232630 !important;
}
.syt-antd-layout-sider-dark {
  background-color: #232630 !important;
}
.syt-antd-layout-sider-dark .syt-antd-layout-sider-trigger {
  background-color: #14151C !important;
}
.syt-antd-layout-sider-light .syt-antd-layout-sider-trigger {
  background-color: #F3F4F6 !important;
}
.syt-antd-menu:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-selected {
  background-color: #EAF6FF;
}
.syt-antd-menu:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-selected::before {
  content: "";
  width: 4px;
  height: 32px;
  background: #0071CD;
  position: absolute;
  top: 4px;
  left: 0px;
  border-radius: 0px 4px 4px 0px;
}
.syt-antd-menu-inline .syt-antd-menu-item::after {
  border-right: 0px !important;
}
.syt-antd-menu-item-selected {
  color: #0071CD;
}
.syt-antd-menu-dark.syt-antd-menu-dark:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-selected {
  background-color: #14151C !important;
}
.syt-antd-menu-dark.syt-antd-menu-dark:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-selected::before {
  content: "";
  width: 4px;
  height: 32px;
  background: #FFFFFF;
  position: absolute;
  top: 4px;
  left: 0px;
  border-radius: 0px 4px 4px 0px;
}
.syt-antd-menu-dark .syt-antd-menu-inline.syt-antd-menu-sub {
  background: #232630;
}
ul.syt-antd-menu-inline-collapsed {
  width: 64px !important;
}
.syt-antd-menu-item {
  padding: 3px 20px !important;
}
.syt-antd-mentions {
  box-sizing: border-box;
  margin: 0;
  color: #14151C;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  width: 100%;
  min-width: 0;
  padding: 3px 11px;
  color: #14151c;
  font-size: 14px;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #0092e4;
  border-radius: 4px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 20px;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.syt-antd-mentions::-moz-placeholder {
  opacity: 1;
}
.syt-antd-mentions::placeholder {
  color: #bfbfbf;
}
.syt-antd-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.syt-antd-mentions:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-rtl .syt-antd-mentions:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-mentions:focus,
.syt-antd-mentions-focused {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-mentions:focus,
.syt-antd-input-rtl .syt-antd-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-mentions-disabled {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-mentions-disabled:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-mentions[disabled] {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-mentions[disabled]:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-mentions-borderless,
.syt-antd-mentions-borderless:hover,
.syt-antd-mentions-borderless:focus,
.syt-antd-mentions-borderless-focused,
.syt-antd-mentions-borderless-disabled,
.syt-antd-mentions-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.syt-antd-mentions {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 20px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.syt-antd-mentions-lg {
  padding: -141px 11px;
  font-size: 16px;
}
.syt-antd-mentions-sm {
  padding: 0 7px;
}
.syt-antd-mentions-rtl {
  direction: rtl;
}
.syt-antd-mentions-disabled > textarea {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-mentions-disabled > textarea:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-mentions-focused {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-mentions > textarea,
.syt-antd-mentions-measure {
  min-height: 30px;
  margin: 0;
  padding: 3px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  tab-size: inherit;
}
.syt-antd-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.syt-antd-mentions > textarea::-moz-placeholder {
  opacity: 1;
}
.syt-antd-mentions > textarea::placeholder {
  color: #bfbfbf;
}
.syt-antd-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.syt-antd-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.syt-antd-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.syt-antd-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: #14151C;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
}
.syt-antd-mentions-dropdown-hidden {
  display: none;
}
.syt-antd-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.syt-antd-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: #14151C;
  font-weight: normal;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.syt-antd-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}
.syt-antd-mentions-dropdown-menu-item:first-child {
  border-radius: 4px 4px 0 0;
}
.syt-antd-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 4px 4px;
}
.syt-antd-mentions-dropdown-menu-item-disabled {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-mentions-dropdown-menu-item-disabled:hover {
  color: #c1c5c8;
  background-color: #fff;
  cursor: not-allowed;
}
.syt-antd-mentions-dropdown-menu-item-selected {
  color: #14151C;
  font-weight: 600;
  background-color: #fafafa;
}
.syt-antd-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}
.syt-antd-mentions-rtl {
  direction: rtl;
}
.syt-antd-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.syt-antd-modal-wrap {
  z-index: 1000;
}
.syt-antd-modal-title {
  margin: 0;
  color: #14151C;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.syt-antd-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.syt-antd-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: #696F88;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.syt-antd-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.syt-antd-modal-close:focus,
.syt-antd-modal-close:hover {
  color: #868CA2;
  text-decoration: none;
}
.syt-antd-modal-header {
  padding: 16px 24px;
  color: #14151C;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.syt-antd-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word;
}
.syt-antd-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 4px 4px;
}
.syt-antd-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.syt-antd-modal-open {
  overflow: hidden;
}
.syt-antd-modal-centered {
  text-align: center;
}
.syt-antd-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.syt-antd-modal-centered .syt-antd-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .syt-antd-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .syt-antd-modal-centered .syt-antd-modal {
    flex: 1;
  }
}
.syt-antd-modal-confirm .syt-antd-modal-header {
  display: none;
}
.syt-antd-modal-confirm .syt-antd-modal-close {
  display: none;
}
.syt-antd-modal-confirm .syt-antd-modal-body {
  padding: 32px 32px 24px;
}
.syt-antd-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.syt-antd-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-modal-confirm-body .syt-antd-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: #14151C;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.syt-antd-modal-confirm-body .syt-antd-modal-confirm-content {
  margin-top: 8px;
  color: #14151C;
  font-size: 14px;
}
.syt-antd-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.syt-antd-modal-confirm-body > .anticon + .syt-antd-modal-confirm-title + .syt-antd-modal-confirm-content {
  margin-left: 38px;
}
.syt-antd-modal-confirm .syt-antd-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.syt-antd-modal-confirm .syt-antd-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.syt-antd-modal-confirm-error .syt-antd-modal-confirm-body > .anticon {
  color: #CF3537;
}
.syt-antd-modal-confirm-warning .syt-antd-modal-confirm-body > .anticon,
.syt-antd-modal-confirm-confirm .syt-antd-modal-confirm-body > .anticon {
  color: #ffca41;
}
.syt-antd-modal-confirm-info .syt-antd-modal-confirm-body > .anticon {
  color: #0071CD;
}
.syt-antd-modal-confirm-success .syt-antd-modal-confirm-body > .anticon {
  color: #aab400;
}
.syt-antd-modal-wrap-rtl {
  direction: rtl;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-close {
  right: initial;
  left: 0;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-footer {
  text-align: left;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-footer button + button {
  margin-right: 8px;
  margin-left: 0;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-confirm-body {
  direction: rtl;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-confirm-body > .anticon + .syt-antd-modal-confirm-title + .syt-antd-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-confirm-btns {
  float: left;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-confirm-btns button + button {
  margin-right: 8px;
  margin-left: 0;
}
.syt-antd-modal-wrap-rtl.syt-antd-modal-centered .syt-antd-modal {
  text-align: right;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-body {
  margin-left: 40px;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-body svg {
  position: absolute;
  left: 25px;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-title {
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.0075em;
  color: #14151C;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  line-height: 20px;
  letter-spacing: -0.0075em;
}
.ant-modal-confirm .ant-modal-body .outlined-button {
  border-radius: 4px;
  color: #14151C;
  background-color: #FFFFFF;
  border-color: #C2C7D0;
}
.ant-modal-confirm .ant-modal-body .outlined-button:hover {
  color: #14151C;
  background-color: #F3F4F6;
  border-color: #C2C7D0;
}
.ant-modal-confirm .ant-modal-body .outlined-button:focus {
  color: #14151C;
  background-color: #F3F4F6;
  border-color: #C2C7D0;
  box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
}
.ant-modal-confirm .ant-modal-body .outlined-button:active {
  color: #14151C;
  background-color: #DFE2E7;
  border-color: #C2C7D0;
}
.ant-modal-confirm .ant-modal-body .danger-button {
  color: #FFFFFF;
  background-color: #CF3537;
  border-color: #CF3537;
}
.ant-modal-confirm .ant-modal-body .danger-button:hover,
.ant-modal-confirm .ant-modal-body .danger-button:focus {
  color: #FFFFFF;
  background-color: #EB4B4B;
  border-color: #EB4B4B;
}
.ant-modal-confirm .ant-modal-body .danger-button:focus {
  box-shadow: 0px 0px 0px 3px rgba(235, 75, 75, 0.25);
}
.ant-modal-confirm .ant-modal-body .danger-button:active {
  background-color: #9C2628;
  border-color: #9C2628;
}
.ant-modal-confirm .ant-modal-body .danger-button:disabled {
  background: #F3F4F6;
  border: 1px solid #DFE2E7;
  box-sizing: border-box;
}
.ant-modal-confirm .ant-btn-primary {
  color: #FFFFFF;
  background-color: #14803C;
  border-color: #14803C;
  border-radius: 4px;
}
.ant-modal-confirm .ant-btn-primary:hover,
.ant-modal-confirm .ant-btn-primary:focus {
  color: #FFFFFF;
  background-color: #19A04B;
  border-color: #19A04B;
}
.ant-modal-confirm .ant-btn-primary:focus {
  box-shadow: 0px 0px 0px 3px rgba(25, 160, 75, 0.25);
}
.ant-modal-confirm .ant-btn-primary:active {
  background-color: #0C612C;
  border-color: #0C612C;
}
.ant-modal-confirm .ant-btn-primary:disabled {
  background: #F3F4F6;
  border: 1px solid #DFE2E7;
  box-sizing: border-box;
}
.syt-antd-modal .syt-antd-modal-footer {
  border-top: none;
  padding: 24px;
}
.syt-antd-modal .syt-antd-modal-footer .syt-antd-btn {
  border-color: #C2C7D0;
}
.syt-antd-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
}
.syt-antd-statistic-title {
  margin-bottom: 4px;
  color: #696F88;
  font-size: 14px;
}
.syt-antd-statistic-content {
  color: #14151C;
  font-size: 24px;
  font-family: 'noto-sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.syt-antd-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.syt-antd-statistic-content-value-decimal {
  font-size: 16px;
}
.syt-antd-statistic-content-prefix,
.syt-antd-statistic-content-suffix {
  display: inline-block;
}
.syt-antd-statistic-content-prefix {
  margin-right: 4px;
}
.syt-antd-statistic-content-suffix {
  margin-left: 4px;
  font-size: 16px;
}
.syt-antd-statistic-rtl {
  direction: rtl;
}
.syt-antd-statistic-rtl .syt-antd-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.syt-antd-statistic-rtl .syt-antd-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.syt-antd-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.syt-antd-notification-topLeft,
.syt-antd-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.syt-antd-notification-topLeft .syt-antd-notification-fade-enter.syt-antd-notification-fade-enter-active,
.syt-antd-notification-bottomLeft .syt-antd-notification-fade-enter.syt-antd-notification-fade-enter-active,
.syt-antd-notification-topLeft .syt-antd-notification-fade-appear.syt-antd-notification-fade-appear-active,
.syt-antd-notification-bottomLeft .syt-antd-notification-fade-appear.syt-antd-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
.syt-antd-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.syt-antd-notification-hook-holder,
.syt-antd-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.syt-antd-notification-topLeft .syt-antd-notification-hook-holder,
.syt-antd-notification-topLeft .syt-antd-notification-notice,
.syt-antd-notification-bottomLeft .syt-antd-notification-hook-holder,
.syt-antd-notification-bottomLeft .syt-antd-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.syt-antd-notification-hook-holder > .syt-antd-notification-notice {
  margin-bottom: 0;
  box-shadow: none;
}
.syt-antd-notification-notice {
  padding: 16px 24px;
  line-height: 20px;
}
.syt-antd-notification-notice-message {
  display: inline-block;
  margin-bottom: 8px;
  color: #14151C;
  font-size: 16px;
  line-height: 24px;
}
.syt-antd-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.syt-antd-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.syt-antd-notification-notice-description {
  font-size: 14px;
}
.syt-antd-notification-notice-closable .syt-antd-notification-notice-message {
  padding-right: 24px;
}
.syt-antd-notification-notice-with-icon .syt-antd-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.syt-antd-notification-notice-with-icon .syt-antd-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.syt-antd-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.syt-antd-notification-notice-icon-success {
  color: #aab400;
}
.anticon.syt-antd-notification-notice-icon-info {
  color: #0071CD;
}
.anticon.syt-antd-notification-notice-icon-warning {
  color: #ffca41;
}
.anticon.syt-antd-notification-notice-icon-error {
  color: #CF3537;
}
.syt-antd-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: #696F88;
  outline: none;
}
.syt-antd-notification-notice-close:hover {
  color: #3f4352;
}
.syt-antd-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.syt-antd-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.syt-antd-notification-fade-enter,
.syt-antd-notification-fade-appear {
  opacity: 0;
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syt-antd-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.syt-antd-notification-fade-enter.syt-antd-notification-fade-enter-active,
.syt-antd-notification-fade-appear.syt-antd-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.syt-antd-notification-fade-leave.syt-antd-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.syt-antd-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.syt-antd-notification-topLeft,
.syt-antd-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.syt-antd-notification-topLeft .syt-antd-notification-fade-enter.syt-antd-notification-fade-enter-active,
.syt-antd-notification-bottomLeft .syt-antd-notification-fade-enter.syt-antd-notification-fade-enter-active,
.syt-antd-notification-topLeft .syt-antd-notification-fade-appear.syt-antd-notification-fade-appear-active,
.syt-antd-notification-bottomLeft .syt-antd-notification-fade-appear.syt-antd-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
.syt-antd-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.syt-antd-notification-hook-holder,
.syt-antd-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.syt-antd-notification-topLeft .syt-antd-notification-hook-holder,
.syt-antd-notification-topLeft .syt-antd-notification-notice,
.syt-antd-notification-bottomLeft .syt-antd-notification-hook-holder,
.syt-antd-notification-bottomLeft .syt-antd-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.syt-antd-notification-hook-holder > .syt-antd-notification-notice {
  margin-bottom: 0;
  box-shadow: none;
}
.syt-antd-notification-notice {
  padding: 16px 24px;
  line-height: 20px;
}
.syt-antd-notification-notice-message {
  display: inline-block;
  margin-bottom: 8px;
  color: #14151C;
  font-size: 16px;
  line-height: 24px;
}
.syt-antd-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.syt-antd-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.syt-antd-notification-notice-description {
  font-size: 14px;
}
.syt-antd-notification-notice-closable .syt-antd-notification-notice-message {
  padding-right: 24px;
}
.syt-antd-notification-notice-with-icon .syt-antd-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.syt-antd-notification-notice-with-icon .syt-antd-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.syt-antd-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.syt-antd-notification-notice-icon-success {
  color: #aab400;
}
.anticon.syt-antd-notification-notice-icon-info {
  color: #0071CD;
}
.anticon.syt-antd-notification-notice-icon-warning {
  color: #ffca41;
}
.anticon.syt-antd-notification-notice-icon-error {
  color: #CF3537;
}
.syt-antd-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: #696F88;
  outline: none;
}
.syt-antd-notification-notice-close:hover {
  color: #3f4352;
}
.syt-antd-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.syt-antd-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.syt-antd-notification-fade-enter,
.syt-antd-notification-fade-appear {
  opacity: 0;
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syt-antd-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.syt-antd-notification-fade-enter.syt-antd-notification-fade-enter-active,
.syt-antd-notification-fade-appear.syt-antd-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.syt-antd-notification-fade-leave.syt-antd-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.syt-antd-notification-rtl {
  direction: rtl;
}
.syt-antd-notification-rtl .syt-antd-notification-notice-closable .syt-antd-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.syt-antd-notification-rtl .syt-antd-notification-notice-with-icon .syt-antd-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.syt-antd-notification-rtl .syt-antd-notification-notice-with-icon .syt-antd-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}
.syt-antd-notification-rtl .syt-antd-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.syt-antd-notification-rtl .syt-antd-notification-notice-close {
  right: auto;
  left: 22px;
}
.syt-antd-notification-rtl .syt-antd-notification-notice-btn {
  float: left;
}
.syt-antd-notification {
  border-radius: 4px;
}
.syt-antd-notification .syt-antd-notification-notice-btn .syt-antd-btn-default {
  border: none;
  margin-right: 5px;
}
.syt-antd-notification .syt-antd-info-notification {
  border-left: solid #0092E4;
}
.syt-antd-notification .syt-antd-success-notification {
  border-left: solid #19A04B;
}
.syt-antd-notification .syt-antd-warning-notification {
  border-left: solid #C17E19;
}
.syt-antd-notification .syt-antd-error-notification {
  border-left: solid #EB4B4B;
}
.syt-antd-notification .syt-antd-error-notification .syt-antd-btn-primary {
  border-color: #CF3537;
  background-color: #CF3537;
}
.syt-antd-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.syt-antd-page-header-ghost {
  background-color: inherit;
}
.syt-antd-page-header.has-breadcrumb {
  padding-top: 12px;
}
.syt-antd-page-header.has-footer {
  padding-bottom: 0;
}
.syt-antd-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.syt-antd-page-header-back-button {
  color: #00a0be;
  text-decoration: none;
  outline: none;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}
.syt-antd-page-header-back-button:focus,
.syt-antd-page-header-back-button:hover {
  color: #21b8cc;
}
.syt-antd-page-header-back-button:active {
  color: #007d99;
}
.syt-antd-page-header .syt-antd-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.syt-antd-breadcrumb + .syt-antd-page-header-heading {
  margin-top: 8px;
}
.syt-antd-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.syt-antd-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.syt-antd-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: #14151C;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-page-header-heading .syt-antd-avatar {
  margin-right: 12px;
}
.syt-antd-page-header-heading-sub-title {
  margin-right: 12px;
  color: #696F88;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.syt-antd-page-header-heading-extra > * {
  margin-left: 12px;
  white-space: unset;
}
.syt-antd-page-header-heading-extra > *:first-child {
  margin-left: 0;
}
.syt-antd-page-header-content {
  padding-top: 12px;
}
.syt-antd-page-header-footer {
  margin-top: 16px;
}
.syt-antd-page-header-footer .syt-antd-tabs > .syt-antd-tabs-nav {
  margin: 0;
}
.syt-antd-page-header-footer .syt-antd-tabs > .syt-antd-tabs-nav::before {
  border: none;
}
.syt-antd-page-header-footer .syt-antd-tabs .syt-antd-tabs-tab {
  padding: 8px 0;
  font-size: 16px;
}
.syt-antd-page-header-compact .syt-antd-page-header-heading {
  flex-wrap: wrap;
}
.syt-antd-page-header-rtl {
  direction: rtl;
}
.syt-antd-page-header-rtl .syt-antd-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.syt-antd-page-header-rtl .syt-antd-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.syt-antd-page-header-rtl .syt-antd-page-header-heading .syt-antd-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.syt-antd-page-header-rtl .syt-antd-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.syt-antd-page-header-rtl .syt-antd-page-header-heading-tags {
  float: right;
}
.syt-antd-page-header-rtl .syt-antd-page-header-heading-extra {
  float: left;
}
.syt-antd-page-header-rtl .syt-antd-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.syt-antd-page-header-rtl .syt-antd-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.syt-antd-page-header-rtl .syt-antd-page-header-footer .syt-antd-tabs-bar .syt-antd-tabs-nav {
  float: right;
}
.syt-antd-page-header-heading-left,
.syt-antd-page-header-heading-extra {
  margin: 0px;
}
.syt-antd-page-header-back-button {
  display: inline-block;
  min-width: 40px;
  width: 40px;
  height: 40px;
  border: 1px solid #C2C7D0 !important;
  border-radius: 50%;
  color: #14151C !important;
  background-color: #FFFFFF !important;
  background-image: none;
  box-shadow: 0 2px 0 rgb(0 0 0%);
  position: relative;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
}
.syt-antd-page-header-back-button span {
  padding: 11px;
}
.syt-antd-page-header-back-button:hover {
  color: #14151C !important;
  background-color: #DFE2E7 !important;
  border-color: #DFE2E7 !important;
}
.syt-antd-page-header-back-button:focus {
  color: #14151C !important;
  background-color: #F3F4F6 !important;
  border-color: #C2C7D0 !important;
}
.syt-antd-page-header-back-button:active {
  background-color: #C2C7D0 !important;
  border-color: #C2C7D0 !important;
}
.syt-antd-page-header-back-button:disabled {
  background: #F3F4F6 !important;
  border: 1px solid #DFE2E7 !important;
  box-sizing: border-box;
}
.syt-antd-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
}
.syt-antd-pagination ul,
.syt-antd-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.syt-antd-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.syt-antd-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.syt-antd-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: 'noto-sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #0092e4;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
.syt-antd-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #14151C;
  transition: none;
}
.syt-antd-pagination-item a:hover {
  text-decoration: none;
}
.syt-antd-pagination-item:focus,
.syt-antd-pagination-item:hover {
  border-color: #0071CD;
  transition: all 0.3s;
}
.syt-antd-pagination-item:focus a,
.syt-antd-pagination-item:hover a {
  color: #0071CD;
}
.syt-antd-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #0071CD;
}
.syt-antd-pagination-item-active a {
  color: #0071CD;
}
.syt-antd-pagination-item-active:focus,
.syt-antd-pagination-item-active:hover {
  border-color: #238dd9;
}
.syt-antd-pagination-item-active:focus a,
.syt-antd-pagination-item-active:hover a {
  color: #238dd9;
}
.syt-antd-pagination-jump-prev,
.syt-antd-pagination-jump-next {
  outline: 0;
}
.syt-antd-pagination-jump-prev .syt-antd-pagination-item-container,
.syt-antd-pagination-jump-next .syt-antd-pagination-item-container {
  position: relative;
}
.syt-antd-pagination-jump-prev .syt-antd-pagination-item-container .syt-antd-pagination-item-link-icon,
.syt-antd-pagination-jump-next .syt-antd-pagination-item-container .syt-antd-pagination-item-link-icon {
  color: #0071CD;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.syt-antd-pagination-jump-prev .syt-antd-pagination-item-container .syt-antd-pagination-item-link-icon-svg,
.syt-antd-pagination-jump-next .syt-antd-pagination-item-container .syt-antd-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.syt-antd-pagination-jump-prev .syt-antd-pagination-item-container .syt-antd-pagination-item-ellipsis,
.syt-antd-pagination-jump-next .syt-antd-pagination-item-container .syt-antd-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: #c1c5c8;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.syt-antd-pagination-jump-prev:focus .syt-antd-pagination-item-link-icon,
.syt-antd-pagination-jump-next:focus .syt-antd-pagination-item-link-icon,
.syt-antd-pagination-jump-prev:hover .syt-antd-pagination-item-link-icon,
.syt-antd-pagination-jump-next:hover .syt-antd-pagination-item-link-icon {
  opacity: 1;
}
.syt-antd-pagination-jump-prev:focus .syt-antd-pagination-item-ellipsis,
.syt-antd-pagination-jump-next:focus .syt-antd-pagination-item-ellipsis,
.syt-antd-pagination-jump-prev:hover .syt-antd-pagination-item-ellipsis,
.syt-antd-pagination-jump-next:hover .syt-antd-pagination-item-ellipsis {
  opacity: 0;
}
.syt-antd-pagination-prev,
.syt-antd-pagination-jump-prev,
.syt-antd-pagination-jump-next {
  margin-right: 8px;
}
.syt-antd-pagination-prev,
.syt-antd-pagination-next,
.syt-antd-pagination-jump-prev,
.syt-antd-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: #14151C;
  font-family: 'noto-sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-pagination-prev,
.syt-antd-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.syt-antd-pagination-prev button,
.syt-antd-pagination-next button {
  color: #14151C;
  cursor: pointer;
  user-select: none;
}
.syt-antd-pagination-prev:hover button,
.syt-antd-pagination-next:hover button {
  border-color: #238dd9;
}
.syt-antd-pagination-prev .syt-antd-pagination-item-link,
.syt-antd-pagination-next .syt-antd-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #0092e4;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
}
.syt-antd-pagination-prev:focus .syt-antd-pagination-item-link,
.syt-antd-pagination-next:focus .syt-antd-pagination-item-link,
.syt-antd-pagination-prev:hover .syt-antd-pagination-item-link,
.syt-antd-pagination-next:hover .syt-antd-pagination-item-link {
  color: #0071CD;
  border-color: #0071CD;
}
.syt-antd-pagination-disabled,
.syt-antd-pagination-disabled:hover,
.syt-antd-pagination-disabled:focus {
  cursor: not-allowed;
}
.syt-antd-pagination-disabled .syt-antd-pagination-item-link,
.syt-antd-pagination-disabled:hover .syt-antd-pagination-item-link,
.syt-antd-pagination-disabled:focus .syt-antd-pagination-item-link {
  color: #c1c5c8;
  border-color: #0092e4;
  cursor: not-allowed;
}
.syt-antd-pagination-slash {
  margin: 0 10px 0 5px;
}
.syt-antd-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .syt-antd-pagination-options *::-ms-backdrop,
  .syt-antd-pagination-options {
    vertical-align: top;
  }
}
.syt-antd-pagination-options-size-changer.syt-antd-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.syt-antd-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: top;
}
.syt-antd-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 3px 11px;
  color: #14151c;
  font-size: 14px;
  line-height: 20px;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #0092e4;
  border-radius: 4px;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}
.syt-antd-pagination-options-quick-jumper input::-moz-placeholder {
  opacity: 1;
}
.syt-antd-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
}
.syt-antd-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.syt-antd-pagination-options-quick-jumper input:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-rtl .syt-antd-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-pagination-options-quick-jumper input:focus,
.syt-antd-pagination-options-quick-jumper input-focused {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-pagination-options-quick-jumper input:focus,
.syt-antd-input-rtl .syt-antd-pagination-options-quick-jumper input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-pagination-options-quick-jumper input-disabled {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-pagination-options-quick-jumper input-disabled:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-pagination-options-quick-jumper input[disabled] {
  color: #c1c5c8;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.syt-antd-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-pagination-options-quick-jumper input-borderless,
.syt-antd-pagination-options-quick-jumper input-borderless:hover,
.syt-antd-pagination-options-quick-jumper input-borderless:focus,
.syt-antd-pagination-options-quick-jumper input-borderless-focused,
.syt-antd-pagination-options-quick-jumper input-borderless-disabled,
.syt-antd-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.syt-antd-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 20px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.syt-antd-pagination-options-quick-jumper input-lg {
  padding: -141px 11px;
  font-size: 16px;
}
.syt-antd-pagination-options-quick-jumper input-sm {
  padding: 0 7px;
}
.syt-antd-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}
.syt-antd-pagination-simple .syt-antd-pagination-prev,
.syt-antd-pagination-simple .syt-antd-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.syt-antd-pagination-simple .syt-antd-pagination-prev .syt-antd-pagination-item-link,
.syt-antd-pagination-simple .syt-antd-pagination-next .syt-antd-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.syt-antd-pagination-simple .syt-antd-pagination-prev .syt-antd-pagination-item-link::after,
.syt-antd-pagination-simple .syt-antd-pagination-next .syt-antd-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.syt-antd-pagination-simple .syt-antd-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.syt-antd-pagination-simple .syt-antd-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #0092e4;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}
.syt-antd-pagination-simple .syt-antd-pagination-simple-pager input:hover {
  border-color: #0071CD;
}
.syt-antd-pagination-simple .syt-antd-pagination-simple-pager input[disabled] {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  cursor: not-allowed;
}
.syt-antd-pagination.mini .syt-antd-pagination-total-text,
.syt-antd-pagination.mini .syt-antd-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.syt-antd-pagination.mini .syt-antd-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.syt-antd-pagination.mini .syt-antd-pagination-item:not(.syt-antd-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.syt-antd-pagination.mini .syt-antd-pagination-prev,
.syt-antd-pagination.mini .syt-antd-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.syt-antd-pagination.mini .syt-antd-pagination-prev .syt-antd-pagination-item-link,
.syt-antd-pagination.mini .syt-antd-pagination-next .syt-antd-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.syt-antd-pagination.mini .syt-antd-pagination-prev .syt-antd-pagination-item-link::after,
.syt-antd-pagination.mini .syt-antd-pagination-next .syt-antd-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.syt-antd-pagination.mini .syt-antd-pagination-jump-prev,
.syt-antd-pagination.mini .syt-antd-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.syt-antd-pagination.mini .syt-antd-pagination-options {
  margin-left: 2px;
}
.syt-antd-pagination.mini .syt-antd-pagination-options-size-changer {
  top: 0px;
}
.syt-antd-pagination.mini .syt-antd-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.syt-antd-pagination.mini .syt-antd-pagination-options-quick-jumper input {
  padding: 0 7px;
  width: 44px;
}
.syt-antd-pagination.syt-antd-pagination-disabled {
  cursor: not-allowed;
}
.syt-antd-pagination.syt-antd-pagination-disabled .syt-antd-pagination-item {
  background: #f5f5f5;
  border-color: #0092e4;
  cursor: not-allowed;
}
.syt-antd-pagination.syt-antd-pagination-disabled .syt-antd-pagination-item a {
  color: #c1c5c8;
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.syt-antd-pagination.syt-antd-pagination-disabled .syt-antd-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}
.syt-antd-pagination.syt-antd-pagination-disabled .syt-antd-pagination-item-active a {
  color: #fff;
}
.syt-antd-pagination.syt-antd-pagination-disabled .syt-antd-pagination-item-link {
  color: #c1c5c8;
  background: #f5f5f5;
  border-color: #0092e4;
  cursor: not-allowed;
}
.syt-antd-pagination-simple.syt-antd-pagination.syt-antd-pagination-disabled .syt-antd-pagination-item-link {
  background: transparent;
}
.syt-antd-pagination.syt-antd-pagination-disabled .syt-antd-pagination-item-link-icon {
  opacity: 0;
}
.syt-antd-pagination.syt-antd-pagination-disabled .syt-antd-pagination-item-ellipsis {
  opacity: 1;
}
.syt-antd-pagination.syt-antd-pagination-disabled .syt-antd-pagination-simple-pager {
  color: #c1c5c8;
}
@media only screen and (max-width: 992px) {
  .syt-antd-pagination-item-after-jump-prev,
  .syt-antd-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .syt-antd-pagination-options {
    display: none;
  }
}
.syt-antd-pagination-rtl {
  direction: rtl;
}
.syt-antd-pagination-rtl .syt-antd-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-pagination-rtl .syt-antd-pagination-item {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-pagination-rtl .syt-antd-pagination-prev,
.syt-antd-pagination-rtl .syt-antd-pagination-jump-prev,
.syt-antd-pagination-rtl .syt-antd-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-pagination-rtl .syt-antd-pagination-slash {
  margin: 0 5px 0 10px;
}
.syt-antd-pagination-rtl .syt-antd-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.syt-antd-pagination-rtl .syt-antd-pagination-options-size-changer.syt-antd-select {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-pagination-rtl.syt-antd-pagination-simple .syt-antd-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-pagination-rtl.syt-antd-pagination-simple .syt-antd-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-pagination-rtl.syt-antd-pagination.mini .syt-antd-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
.syt-antd-pagination .syt-antd-pagination-item-link .syt-antd-pagination-item-container .syt-antd-pagination-item-ellipsis {
  color: #696F88;
}
.syt-antd-pagination .syt-antd-pagination-jump-next-custom-icon .syt-antd-pagination-item-link {
  border: none;
}
.syt-antd-pagination .syt-antd-pagination-jump-prev-custom-icon .syt-antd-pagination-item-link {
  border: none;
}
.syt-antd-pagination .syt-antd-pagination-item-link,
.syt-antd-pagination .syt-antd-pagination-item {
  border: 1px solid #C2C7D0;
}
.syt-antd-pagination .syt-antd-pagination-item-link:hover,
.syt-antd-pagination .syt-antd-pagination-item:hover,
.syt-antd-pagination .syt-antd-pagination-item-link:active,
.syt-antd-pagination .syt-antd-pagination-item:active {
  border: 1px solid #0092E4;
}
.syt-antd-pagination .syt-antd-pagination-item-link:hover a,
.syt-antd-pagination .syt-antd-pagination-item:hover a,
.syt-antd-pagination .syt-antd-pagination-item-link:active a,
.syt-antd-pagination .syt-antd-pagination-item:active a {
  color: #0092E4;
}
.syt-antd-pagination .syt-antd-pagination-item-link:focus,
.syt-antd-pagination .syt-antd-pagination-item:focus {
  border: 1px solid #0092E4;
}
.syt-antd-pagination .syt-antd-pagination-item-link:focus a,
.syt-antd-pagination .syt-antd-pagination-item:focus a {
  color: #0092E4;
}
.syt-antd-pagination .syt-antd-pagination-item-active {
  border: 1 solid #0071CD;
  box-shadow: 0 0 1px #0071CD;
  background-color: #0071CD;
}
.syt-antd-pagination .syt-antd-pagination-item-active a {
  color: #FFFFFF;
}
.syt-antd-pagination .syt-antd-pagination-item-active:focus a,
.syt-antd-pagination .syt-antd-pagination-item-active:hover a {
  color: #FFFFFF;
}
.syt-antd-pagination-next .syt-antd-pagination-item-link span {
  vertical-align: initial;
}
.syt-antd-pagination-prev .syt-antd-pagination-item-link span {
  vertical-align: initial;
}
ul.syt-antd-pagination-disabled .syt-antd-pagination-item-link,
ul.syt-antd-pagination-disabled li.syt-antd-pagination-item {
  border: none;
}
ul.syt-antd-pagination-disabled .syt-antd-pagination-item-link:focus,
ul.syt-antd-pagination-disabled li.syt-antd-pagination-item:focus,
ul.syt-antd-pagination-disabled .syt-antd-pagination-item-link:hover,
ul.syt-antd-pagination-disabled li.syt-antd-pagination-item:hover {
  border: none;
}
ul.syt-antd-pagination-disabled .syt-antd-pagination-item-link:focus a,
ul.syt-antd-pagination-disabled li.syt-antd-pagination-item:focus a,
ul.syt-antd-pagination-disabled .syt-antd-pagination-item-link:hover a,
ul.syt-antd-pagination-disabled li.syt-antd-pagination-item:hover a {
  color: #c1c5c8;
}
ul.syt-antd-pagination-disabled li.syt-antd-pagination-item-active {
  box-shadow: none;
}
ul.syt-antd-pagination-disabled li.syt-antd-pagination-item-active a:hover {
  color: #FFFFFF;
}
.syt-antd-popconfirm {
  z-index: 1060;
}
.syt-antd-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  user-select: text;
}
.syt-antd-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.syt-antd-popover-hidden {
  display: none;
}
.syt-antd-popover-placement-top,
.syt-antd-popover-placement-topLeft,
.syt-antd-popover-placement-topRight {
  padding-bottom: 10px;
}
.syt-antd-popover-placement-right,
.syt-antd-popover-placement-rightTop,
.syt-antd-popover-placement-rightBottom {
  padding-left: 10px;
}
.syt-antd-popover-placement-bottom,
.syt-antd-popover-placement-bottomLeft,
.syt-antd-popover-placement-bottomRight {
  padding-top: 10px;
}
.syt-antd-popover-placement-left,
.syt-antd-popover-placement-leftTop,
.syt-antd-popover-placement-leftBottom {
  padding-right: 10px;
}
.syt-antd-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .syt-antd-popover {
    /* IE10+ */
  }
  .syt-antd-popover-inner {
    box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
  }
}
.syt-antd-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: #14151C;
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-popover-inner-content {
  padding: 12px 16px;
  color: #14151C;
}
.syt-antd-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: #14151C;
  font-size: 14px;
}
.syt-antd-popover-message > .anticon {
  position: absolute;
  top: 137px;
  color: #ffca41;
  font-size: 14px;
}
.syt-antd-popover-message-title {
  padding-left: 22px;
}
.syt-antd-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.syt-antd-popover-buttons button {
  margin-left: 8px;
}
.syt-antd-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
.syt-antd-popover-placement-top > .syt-antd-popover-content > .syt-antd-popover-arrow,
.syt-antd-popover-placement-topLeft > .syt-antd-popover-content > .syt-antd-popover-arrow,
.syt-antd-popover-placement-topRight > .syt-antd-popover-content > .syt-antd-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.syt-antd-popover-placement-top > .syt-antd-popover-content > .syt-antd-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.syt-antd-popover-placement-topLeft > .syt-antd-popover-content > .syt-antd-popover-arrow {
  left: 16px;
}
.syt-antd-popover-placement-topRight > .syt-antd-popover-content > .syt-antd-popover-arrow {
  right: 16px;
}
.syt-antd-popover-placement-right > .syt-antd-popover-content > .syt-antd-popover-arrow,
.syt-antd-popover-placement-rightTop > .syt-antd-popover-content > .syt-antd-popover-arrow,
.syt-antd-popover-placement-rightBottom > .syt-antd-popover-content > .syt-antd-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
.syt-antd-popover-placement-right > .syt-antd-popover-content > .syt-antd-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.syt-antd-popover-placement-rightTop > .syt-antd-popover-content > .syt-antd-popover-arrow {
  top: 12px;
}
.syt-antd-popover-placement-rightBottom > .syt-antd-popover-content > .syt-antd-popover-arrow {
  bottom: 12px;
}
.syt-antd-popover-placement-bottom > .syt-antd-popover-content > .syt-antd-popover-arrow,
.syt-antd-popover-placement-bottomLeft > .syt-antd-popover-content > .syt-antd-popover-arrow,
.syt-antd-popover-placement-bottomRight > .syt-antd-popover-content > .syt-antd-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.syt-antd-popover-placement-bottom > .syt-antd-popover-content > .syt-antd-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.syt-antd-popover-placement-bottomLeft > .syt-antd-popover-content > .syt-antd-popover-arrow {
  left: 16px;
}
.syt-antd-popover-placement-bottomRight > .syt-antd-popover-content > .syt-antd-popover-arrow {
  right: 16px;
}
.syt-antd-popover-placement-left > .syt-antd-popover-content > .syt-antd-popover-arrow,
.syt-antd-popover-placement-leftTop > .syt-antd-popover-content > .syt-antd-popover-arrow,
.syt-antd-popover-placement-leftBottom > .syt-antd-popover-content > .syt-antd-popover-arrow {
  right: 6px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
.syt-antd-popover-placement-left > .syt-antd-popover-content > .syt-antd-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.syt-antd-popover-placement-leftTop > .syt-antd-popover-content > .syt-antd-popover-arrow {
  top: 12px;
}
.syt-antd-popover-placement-leftBottom > .syt-antd-popover-content > .syt-antd-popover-arrow {
  bottom: 12px;
}
.syt-antd-popover-rtl {
  direction: rtl;
  text-align: right;
}
.syt-antd-popover-rtl .syt-antd-popover-message-title {
  padding-right: 22px;
  padding-left: 16px;
}
.syt-antd-popover-rtl .syt-antd-popover-buttons {
  text-align: left;
}
.syt-antd-popover-rtl .syt-antd-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.syt-antd-popover-content {
  width: 280px;
}
.syt-antd-popover-content .syt-antd-popover-inner-content {
  padding-top: 5px;
  padding-bottom: 20px;
}
.syt-antd-popover-content .syt-antd-popover-title {
  padding-top: 15px;
  border-bottom: none;
}
.dark .syt-antd-popover-inner .syt-antd-popover-title {
  color: #FFFFFF;
}
.dark .syt-antd-btn-default {
  color: #FFFFFF;
  background-color: #232630;
}
.dark .syt-antd-popover-inner {
  background-color: #232630;
}
.dark .syt-antd-popover-inner-content {
  color: #FFFFFF;
}
.dark .syt-antd-popover-content .syt-antd-popover-arrow {
  border-right-color: #232630;
  border-bottom-color: #232630;
}
.syt-antd-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.syt-antd-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.syt-antd-progress-steps {
  display: inline-block;
}
.syt-antd-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.syt-antd-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.syt-antd-progress-steps-item-active {
  background: #1890ff;
}
.syt-antd-progress-small.syt-antd-progress-line,
.syt-antd-progress-small.syt-antd-progress-line .syt-antd-progress-text .anticon {
  font-size: 12px;
}
.syt-antd-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.syt-antd-progress-show-info .syt-antd-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.syt-antd-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.syt-antd-progress-circle-trail {
  stroke: #f5f5f5;
}
.syt-antd-progress-circle-path {
  animation: syt-antd-progress-appear 0.3s;
}
.syt-antd-progress-inner:not(.syt-antd-progress-circle-gradient) .syt-antd-progress-circle-path {
  stroke: #1890ff;
}
.syt-antd-progress-success-bg,
.syt-antd-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.syt-antd-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #aab400;
}
.syt-antd-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: #696F88;
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.syt-antd-progress-text .anticon {
  font-size: 14px;
}
.syt-antd-progress-status-active .syt-antd-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: syt-antd-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.syt-antd-progress-status-exception .syt-antd-progress-bg {
  background-color: #CF3537;
}
.syt-antd-progress-status-exception .syt-antd-progress-text {
  color: #CF3537;
}
.syt-antd-progress-status-exception .syt-antd-progress-inner:not(.syt-antd-progress-circle-gradient) .syt-antd-progress-circle-path {
  stroke: #CF3537;
}
.syt-antd-progress-status-success .syt-antd-progress-bg {
  background-color: #aab400;
}
.syt-antd-progress-status-success .syt-antd-progress-text {
  color: #aab400;
}
.syt-antd-progress-status-success .syt-antd-progress-inner:not(.syt-antd-progress-circle-gradient) .syt-antd-progress-circle-path {
  stroke: #aab400;
}
.syt-antd-progress-circle .syt-antd-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.syt-antd-progress-circle .syt-antd-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.syt-antd-progress-circle .syt-antd-progress-text .anticon {
  font-size: 1.16666667em;
}
.syt-antd-progress-circle.syt-antd-progress-status-exception .syt-antd-progress-text {
  color: #CF3537;
}
.syt-antd-progress-circle.syt-antd-progress-status-success .syt-antd-progress-text {
  color: #aab400;
}
@keyframes syt-antd-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
.syt-antd-progress-rtl {
  direction: rtl;
}
.syt-antd-progress-rtl.syt-antd-progress-show-info .syt-antd-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.syt-antd-progress-rtl .syt-antd-progress-success-bg {
  right: 0;
  left: auto;
}
.syt-antd-progress-rtl.syt-antd-progress-line .syt-antd-progress-text,
.syt-antd-progress-rtl.syt-antd-progress-steps .syt-antd-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.syt-antd-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  font-size: 0;
  line-height: unset;
}
.syt-antd-radio-group .syt-antd-badge-count {
  z-index: 1;
}
.syt-antd-radio-group > .syt-antd-badge:not(:first-child) > .syt-antd-radio-button-wrapper {
  border-left: none;
}
.syt-antd-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}
.syt-antd-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer;
}
.syt-antd-radio-wrapper:hover .syt-antd-radio,
.syt-antd-radio:hover .syt-antd-radio-inner,
.syt-antd-radio-input:focus + .syt-antd-radio-inner {
  border-color: #0071CD;
}
.syt-antd-radio-input:focus + .syt-antd-radio-inner {
  box-shadow: 0 0 0 3px rgba(0, 113, 205, 0.08);
}
.syt-antd-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #0071CD;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.syt-antd-radio:hover::after,
.syt-antd-radio-wrapper:hover .syt-antd-radio::after {
  visibility: visible;
}
.syt-antd-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #0092e4;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  transition: all 0.3s;
}
.syt-antd-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #0071CD;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.syt-antd-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.syt-antd-radio-checked .syt-antd-radio-inner {
  border-color: #0071CD;
}
.syt-antd-radio-checked .syt-antd-radio-inner::after {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.syt-antd-radio-disabled {
  cursor: not-allowed;
}
.syt-antd-radio-disabled .syt-antd-radio-inner {
  background-color: #f5f5f5;
  border-color: #0092e4 !important;
  cursor: not-allowed;
}
.syt-antd-radio-disabled .syt-antd-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.syt-antd-radio-disabled .syt-antd-radio-input {
  cursor: not-allowed;
}
.syt-antd-radio-disabled + span {
  color: #c1c5c8;
  cursor: not-allowed;
}
span.syt-antd-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.syt-antd-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: #14151C;
  font-size: 14px;
  line-height: 30px;
  background: #fff;
  border: 1px solid #0092e4;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.syt-antd-radio-button-wrapper a {
  color: #14151C;
}
.syt-antd-radio-button-wrapper > .syt-antd-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.syt-antd-radio-group-large .syt-antd-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.syt-antd-radio-group-small .syt-antd-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.syt-antd-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #0092e4;
  transition: background-color 0.3s;
  content: '';
}
.syt-antd-radio-button-wrapper:first-child {
  border-left: 1px solid #0092e4;
  border-radius: 4px 0 0 4px;
}
.syt-antd-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
.syt-antd-radio-button-wrapper:first-child:last-child {
  border-radius: 4px;
}
.syt-antd-radio-button-wrapper:hover {
  position: relative;
  color: #0071CD;
}
.syt-antd-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(0, 113, 205, 0.08);
}
.syt-antd-radio-button-wrapper .syt-antd-radio-inner,
.syt-antd-radio-button-wrapper input[type='checkbox'],
.syt-antd-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #0071CD;
  background: #fff;
  border-color: #0071CD;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled)::before {
  background-color: #0071CD;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):first-child {
  border-color: #0071CD;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):hover {
  color: #238dd9;
  border-color: #238dd9;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):hover::before {
  background-color: #238dd9;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):active {
  color: #0056a6;
  border-color: #0056a6;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):active::before {
  background-color: #0056a6;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(0, 113, 205, 0.08);
}
.syt-antd-radio-group-solid .syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled) {
  color: #fff;
  background: #0071CD;
  border-color: #0071CD;
}
.syt-antd-radio-group-solid .syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #238dd9;
  border-color: #238dd9;
}
.syt-antd-radio-group-solid .syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #0056a6;
  border-color: #0056a6;
}
.syt-antd-radio-group-solid .syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(0, 113, 205, 0.08);
}
.syt-antd-radio-button-wrapper-disabled {
  color: #c1c5c8;
  background-color: #f5f5f5;
  border-color: #0092e4;
  cursor: not-allowed;
}
.syt-antd-radio-button-wrapper-disabled:first-child,
.syt-antd-radio-button-wrapper-disabled:hover {
  color: #c1c5c8;
  background-color: #f5f5f5;
  border-color: #0092e4;
}
.syt-antd-radio-button-wrapper-disabled:first-child {
  border-left-color: #0092e4;
}
.syt-antd-radio-button-wrapper-disabled.syt-antd-radio-button-wrapper-checked {
  color: #c1c5c8;
  background-color: #e6e6e6;
  border-color: #0092e4;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .syt-antd-radio {
    vertical-align: text-bottom;
  }
}
.syt-antd-radio-group.syt-antd-radio-group-rtl {
  direction: rtl;
}
.syt-antd-radio-wrapper.syt-antd-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.syt-antd-radio-button-wrapper.syt-antd-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.syt-antd-radio-button-wrapper.syt-antd-radio-button-wrapper-rtl.syt-antd-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.syt-antd-radio-button-wrapper.syt-antd-radio-button-wrapper-rtl.syt-antd-radio-button-wrapper:first-child {
  border-right: 1px solid #0092e4;
  border-radius: 0 4px 4px 0;
}
.syt-antd-radio-button-wrapper-checked:not([class*=' syt-antd-radio-button-wrapper-disabled']).syt-antd-radio-button-wrapper:first-child {
  border-right-color: #238dd9;
}
.syt-antd-radio-button-wrapper.syt-antd-radio-button-wrapper-rtl.syt-antd-radio-button-wrapper:last-child {
  border-radius: 4px 0 0 4px;
}
.syt-antd-radio-button-wrapper.syt-antd-radio-button-wrapper-rtl.syt-antd-radio-button-wrapper-disabled:first-child {
  border-right-color: #0092e4;
}
.syt-antd-radio-inner::after {
  top: 4px;
  left: 4px;
  background-color: #0071CD;
}
.syt-antd-radio-checked::after {
  animation: none;
  border: none;
}
.syt-antd-radio .syt-antd-radio-inner {
  border-color: #0071CD;
}
.syt-antd-radio:hover .syt-antd-radio-inner {
  border-color: #0092E4;
}
.syt-antd-radio:hover .syt-antd-radio-inner::after {
  background-color: #0092E4;
}
.syt-antd-radio:focus-within .syt-antd-radio-inner {
  border-color: #0092E4;
  box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
}
.syt-antd-radio:active .syt-antd-radio-inner {
  border-color: #005693;
}
.syt-antd-radio:active .syt-antd-radio-inner::after {
  background-color: #005693;
}
.syt-antd-radio-button-wrapper {
  border: 1px solid #A3A9B9;
}
.syt-antd-radio-button-wrapper:hover {
  color: #0092E4;
  border-color: #0092E4;
}
.syt-antd-radio-button-wrapper:active {
  color: #005693;
  border-color: #005693;
}
.syt-antd-radio-disabled .syt-antd-radio-inner {
  border-color: #C2C7D0 !important;
  background: #F3F4F6;
}
.syt-antd-radio-disabled + span {
  color: #A3A9B9;
}
.syt-antd-radio-disabled .syt-antd-radio-inner::after {
  background-color: #A3A9B9;
}
.syt-antd-radio-button-wrapper:first-child {
  border-left: 1px solid #A3A9B9;
}
.syt-antd-radio-button-wrapper:last-child {
  border-left: 1px solid #A3A9B9;
}
.syt-antd-radio-group-solid .syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled) {
  background-color: #0071CD;
  border-color: #0071CD;
}
.syt-antd-radio-group-solid .syt-antd-radio-button-wrapper:hover {
  background-color: #0092E4;
  border-color: #0092E4;
  color: #FFFFFF;
}
.syt-antd-radio-group-solid .syt-antd-radio-button-wrapper:active {
  background-color: #005693;
  border-color: #005693;
  color: #FFFFFF;
}
.syt-antd-radio-group-solid .syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):hover {
  background-color: #0092E4;
  border-color: #0092E4;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):hover {
  color: #0092E4;
  border-color: #0092E4;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled) {
  color: #0071CD;
  border-color: #0071CD;
}
.syt-antd-radio-group .syt-antd-radio-group-outline .syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):hover {
  color: #0071CD;
  border-color: #0071CD;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled)::before {
  border-color: #0071CD;
}
.syt-antd-radio-button-wrapper-checked:not([class*=' syt-antd-radio-button-wrapper-disabled']).syt-antd-radio-button-wrapper:first-child {
  border-color: #0071CD;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):first-child {
  border-color: #0071CD;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):hover::before {
  background-color: transparent;
}
.syt-antd-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent;
}
.syt-antd-radio-button-wrapper-disabled {
  color: #A3A9B9;
  background-color: #DFE2E7;
  border: 1px solid #C2C7D0;
}
.syt-antd-radio-button-wrapper-disabled:hover {
  color: #A3A9B9;
  background-color: #DFE2E7;
  border: 1px solid #C2C7D0;
}
.syt-antd-radio-button-wrapper-disabled:first-child,
.syt-antd-radio-button-wrapper-disabled:hover {
  color: #A3A9B9;
  background-color: #DFE2E7;
  border: 1px solid #C2C7D0;
}
.syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #0092E420;
}
.syt-antd-radio-group-solid .syt-antd-radio-button-wrapper-checked:not(.syt-antd-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #0092E420;
}
.syt-antd-rate {
  box-sizing: border-box;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  font-feature-settings: 'tnum';
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.syt-antd-rate-disabled .syt-antd-rate-star {
  cursor: default;
}
.syt-antd-rate-disabled .syt-antd-rate-star:hover {
  transform: scale(1);
}
.syt-antd-rate-star {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-rate-star:not(:last-child) {
  margin-right: 8px;
}
.syt-antd-rate-star > div:focus {
  outline: 0;
}
.syt-antd-rate-star > div:hover,
.syt-antd-rate-star > div:focus {
  transform: scale(1.1);
}
.syt-antd-rate-star-first,
.syt-antd-rate-star-second {
  color: #f0f0f0;
  transition: all 0.3s;
  user-select: none;
}
.syt-antd-rate-star-first .anticon,
.syt-antd-rate-star-second .anticon {
  vertical-align: middle;
}
.syt-antd-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.syt-antd-rate-star-half .syt-antd-rate-star-first,
.syt-antd-rate-star-half .syt-antd-rate-star-second {
  opacity: 1;
}
.syt-antd-rate-star-half .syt-antd-rate-star-first,
.syt-antd-rate-star-full .syt-antd-rate-star-second {
  color: inherit;
}
.syt-antd-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.syt-antd-rate-rtl {
  direction: rtl;
}
.syt-antd-rate-rtl .syt-antd-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-rate-rtl .syt-antd-rate-star-first {
  right: 0;
  left: auto;
}
.syt-antd-result {
  padding: 48px 32px;
}
.syt-antd-result-success .syt-antd-result-icon > .anticon {
  color: #aab400;
}
.syt-antd-result-error .syt-antd-result-icon > .anticon {
  color: #CF3537;
}
.syt-antd-result-info .syt-antd-result-icon > .anticon {
  color: #0071CD;
}
.syt-antd-result-warning .syt-antd-result-icon > .anticon {
  color: #ffca41;
}
.syt-antd-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.syt-antd-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.syt-antd-result-icon > .anticon {
  font-size: 72px;
}
.syt-antd-result-title {
  color: #14151C;
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.syt-antd-result-subtitle {
  color: #696F88;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.syt-antd-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}
.syt-antd-result-extra > * {
  margin-right: 8px;
}
.syt-antd-result-extra > *:last-child {
  margin-right: 0;
}
.syt-antd-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.syt-antd-result-rtl {
  direction: rtl;
}
.syt-antd-result-rtl .syt-antd-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-result-rtl .syt-antd-result-extra > *:last-child {
  margin-left: 0;
}
/* Reset search input style */
.syt-antd-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.syt-antd-select:not(.syt-antd-select-disabled):hover .syt-antd-select-selector {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-rtl .syt-antd-select:not(.syt-antd-select-disabled):hover .syt-antd-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-select-selection-item {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .syt-antd-select-selection-item *::-ms-backdrop,
  .syt-antd-select-selection-item {
    flex: auto;
  }
}
.syt-antd-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .syt-antd-select-selection-placeholder *::-ms-backdrop,
  .syt-antd-select-selection-placeholder {
    flex: auto;
  }
}
.syt-antd-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 53%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #c1c5c8;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.syt-antd-select-arrow > * {
  line-height: 1;
}
.syt-antd-select-arrow svg {
  display: inline-block;
}
.syt-antd-select-arrow::before {
  display: none;
}
.syt-antd-select-arrow .syt-antd-select-arrow-icon {
  display: block;
}
.syt-antd-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.syt-antd-select-arrow .anticon > svg {
  vertical-align: top;
}
.syt-antd-select-arrow .anticon:not(.syt-antd-select-suffix) {
  pointer-events: auto;
}
.syt-antd-select-disabled .syt-antd-select-arrow {
  cursor: not-allowed;
}
.syt-antd-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #c1c5c8;
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.syt-antd-select-clear::before {
  display: block;
}
.syt-antd-select-clear:hover {
  color: #696F88;
}
.syt-antd-select:hover .syt-antd-select-clear {
  opacity: 1;
}
.syt-antd-select-dropdown {
  margin: 0;
  padding: 0;
  color: #14151C;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
}
.syt-antd-select-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-select-dropdown-placement-bottomLeft,
.syt-antd-select-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.syt-antd-select-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-select-dropdown-placement-topLeft,
.syt-antd-select-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.syt-antd-select-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.syt-antd-select-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.syt-antd-select-dropdown-hidden {
  display: none;
}
.syt-antd-select-dropdown-empty {
  color: #c1c5c8;
}
.syt-antd-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #c1c5c8;
}
.syt-antd-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.syt-antd-select-item-group {
  color: #696F88;
  font-size: 12px;
  cursor: default;
}
.syt-antd-select-item-option {
  display: flex;
}
.syt-antd-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-select-item-option-state {
  flex: none;
}
.syt-antd-select-item-option-active:not(.syt-antd-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.syt-antd-select-item-option-selected:not(.syt-antd-select-item-option-disabled) {
  color: #14151C;
  font-weight: 600;
  background-color: #e6f8ff;
}
.syt-antd-select-item-option-selected:not(.syt-antd-select-item-option-disabled) .syt-antd-select-item-option-state {
  color: #0071CD;
}
.syt-antd-select-item-option-disabled {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-select-item-option-grouped {
  padding-left: 24px;
}
.syt-antd-select-lg {
  font-size: 16px;
}
.syt-antd-select-borderless .syt-antd-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.syt-antd-select-single .syt-antd-select-selector {
  display: flex;
}
.syt-antd-select-single .syt-antd-select-selector .syt-antd-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.syt-antd-select-single .syt-antd-select-selector .syt-antd-select-selection-search-input {
  width: 100%;
}
.syt-antd-select-single .syt-antd-select-selector .syt-antd-select-selection-item,
.syt-antd-select-single .syt-antd-select-selector .syt-antd-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s;
}
@supports (-moz-appearance: meterbar) {
  .syt-antd-select-single .syt-antd-select-selector .syt-antd-select-selection-item,
  .syt-antd-select-single .syt-antd-select-selector .syt-antd-select-selection-placeholder {
    line-height: 30px;
  }
}
.syt-antd-select-single .syt-antd-select-selector .syt-antd-select-selection-item {
  position: relative;
  user-select: none;
}
.syt-antd-select-single .syt-antd-select-selector .syt-antd-select-selection-placeholder {
  pointer-events: none;
}
.syt-antd-select-single .syt-antd-select-selector::after,
.syt-antd-select-single .syt-antd-select-selector .syt-antd-select-selection-item::after,
.syt-antd-select-single .syt-antd-select-selector .syt-antd-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.syt-antd-select-single.syt-antd-select-show-arrow .syt-antd-select-selection-search {
  right: 25px;
}
.syt-antd-select-single.syt-antd-select-show-arrow .syt-antd-select-selection-item,
.syt-antd-select-single.syt-antd-select-show-arrow .syt-antd-select-selection-placeholder {
  padding-right: 18px;
}
.syt-antd-select-single.syt-antd-select-open .syt-antd-select-selection-item {
  color: #bfbfbf;
}
.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #0092e4;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector input {
  cursor: pointer;
}
.syt-antd-select-show-search.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  cursor: text;
}
.syt-antd-select-show-search.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector input {
  cursor: auto;
}
.syt-antd-select-focused:not(.syt-antd-select-disabled).syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-select-focused:not(.syt-antd-select-disabled).syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-select-disabled.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  color: #c1c5c8;
  background: #f5f5f5;
  cursor: not-allowed;
}
.syt-antd-select-disabled.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector input {
  cursor: not-allowed;
}
.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector input {
  cursor: pointer;
}
.syt-antd-select-show-search.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  cursor: text;
}
.syt-antd-select-show-search.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector input {
  cursor: auto;
}
.syt-antd-select-focused:not(.syt-antd-select-disabled).syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-select-focused:not(.syt-antd-select-disabled).syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-select-disabled.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  color: #c1c5c8;
  background: #f5f5f5;
  cursor: not-allowed;
}
.syt-antd-select-disabled.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector input {
  cursor: not-allowed;
}
.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector .syt-antd-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
}
.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector .syt-antd-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
}
.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector .syt-antd-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
}
.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector .syt-antd-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
}
.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector .syt-antd-select-selection-search-input {
  height: 30px;
}
.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector::after {
  line-height: 30px;
}
.syt-antd-select-single.syt-antd-select-customize-input .syt-antd-select-selector::after {
  display: none;
}
.syt-antd-select-single.syt-antd-select-customize-input .syt-antd-select-selector .syt-antd-select-selection-search {
  position: static;
  width: 100%;
}
.syt-antd-select-single.syt-antd-select-customize-input .syt-antd-select-selector .syt-antd-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.syt-antd-select-single.syt-antd-select-customize-input .syt-antd-select-selector .syt-antd-select-selection-placeholder::after {
  display: none;
}
.syt-antd-select-single.syt-antd-select-lg:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  height: 40px;
}
.syt-antd-select-single.syt-antd-select-lg:not(.syt-antd-select-customize-input) .syt-antd-select-selector::after,
.syt-antd-select-single.syt-antd-select-lg:not(.syt-antd-select-customize-input) .syt-antd-select-selector .syt-antd-select-selection-item,
.syt-antd-select-single.syt-antd-select-lg:not(.syt-antd-select-customize-input) .syt-antd-select-selector .syt-antd-select-selection-placeholder {
  line-height: 38px;
}
.syt-antd-select-single.syt-antd-select-lg:not(.syt-antd-select-customize-input):not(.syt-antd-select-customize-input) .syt-antd-select-selection-search-input {
  height: 38px;
}
.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  height: 24px;
}
.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input) .syt-antd-select-selector::after,
.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input) .syt-antd-select-selector .syt-antd-select-selection-item,
.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input) .syt-antd-select-selector .syt-antd-select-selection-placeholder {
  line-height: 22px;
}
.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input):not(.syt-antd-select-customize-input) .syt-antd-select-selection-search-input {
  height: 22px;
}
.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input) .syt-antd-select-selection-search {
  right: 7px;
  left: 7px;
}
.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  padding: 0 7px;
}
.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input).syt-antd-select-show-arrow .syt-antd-select-selection-search {
  right: 28px;
}
.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input).syt-antd-select-show-arrow .syt-antd-select-selection-item,
.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input).syt-antd-select-show-arrow .syt-antd-select-selection-placeholder {
  padding-right: 21px;
}
.syt-antd-select-single.syt-antd-select-lg:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.syt-antd-select-multiple .syt-antd-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #0092e4;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 3px;
}
.syt-antd-select-multiple .syt-antd-select-selector input {
  cursor: pointer;
}
.syt-antd-select-show-search.syt-antd-select-multiple .syt-antd-select-selector {
  cursor: text;
}
.syt-antd-select-show-search.syt-antd-select-multiple .syt-antd-select-selector input {
  cursor: auto;
}
.syt-antd-select-focused:not(.syt-antd-select-disabled).syt-antd-select-multiple .syt-antd-select-selector {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-select-focused:not(.syt-antd-select-disabled).syt-antd-select-multiple .syt-antd-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-select-disabled.syt-antd-select-multiple .syt-antd-select-selector {
  color: #c1c5c8;
  background: #f5f5f5;
  cursor: not-allowed;
}
.syt-antd-select-disabled.syt-antd-select-multiple .syt-antd-select-selector input {
  cursor: not-allowed;
}
.syt-antd-select-multiple .syt-antd-select-selector input {
  cursor: pointer;
}
.syt-antd-select-show-search.syt-antd-select-multiple .syt-antd-select-selector {
  cursor: text;
}
.syt-antd-select-show-search.syt-antd-select-multiple .syt-antd-select-selector input {
  cursor: auto;
}
.syt-antd-select-focused:not(.syt-antd-select-disabled).syt-antd-select-multiple .syt-antd-select-selector {
  border-color: #238dd9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-input-rtl .syt-antd-select-focused:not(.syt-antd-select-disabled).syt-antd-select-multiple .syt-antd-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-select-disabled.syt-antd-select-multiple .syt-antd-select-selector {
  color: #c1c5c8;
  background: #f5f5f5;
  cursor: not-allowed;
}
.syt-antd-select-disabled.syt-antd-select-multiple .syt-antd-select-selector input {
  cursor: not-allowed;
}
.syt-antd-select-multiple .syt-antd-select-selector .syt-antd-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
}
.syt-antd-select-multiple .syt-antd-select-selector .syt-antd-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
}
.syt-antd-select-multiple .syt-antd-select-selector .syt-antd-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
}
.syt-antd-select-multiple .syt-antd-select-selector .syt-antd-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
}
.syt-antd-select-show-search.syt-antd-select-multiple .syt-antd-select-selector {
  cursor: text;
}
.syt-antd-select-disabled.syt-antd-select-multiple .syt-antd-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}
.syt-antd-select-multiple .syt-antd-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 26px;
  content: '\a0';
}
.syt-antd-select-multiple.syt-antd-select-show-arrow .syt-antd-select-selector,
.syt-antd-select-multiple.syt-antd-select-allow-clear .syt-antd-select-selector {
  padding-right: 24px;
}
.syt-antd-select-multiple .syt-antd-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 26px;
  margin-top: 2px;
  margin-right: 3px;
  margin-bottom: 2px;
  padding: 0 4px 0 8px;
  line-height: 24px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  user-select: none;
}
.syt-antd-select-disabled.syt-antd-select-multiple .syt-antd-select-selection-item {
  color: #bfbfbf;
  border-color: #0092e4;
  cursor: not-allowed;
}
.syt-antd-select-multiple .syt-antd-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-select-multiple .syt-antd-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: #696F88;
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.syt-antd-select-multiple .syt-antd-select-selection-item-remove > * {
  line-height: 1;
}
.syt-antd-select-multiple .syt-antd-select-selection-item-remove svg {
  display: inline-block;
}
.syt-antd-select-multiple .syt-antd-select-selection-item-remove::before {
  display: none;
}
.syt-antd-select-multiple .syt-antd-select-selection-item-remove .syt-antd-select-multiple .syt-antd-select-selection-item-remove-icon {
  display: block;
}
.syt-antd-select-multiple .syt-antd-select-selection-item-remove > .anticon {
  vertical-align: -0.2em;
}
.syt-antd-select-multiple .syt-antd-select-selection-item-remove:hover {
  color: #868CA2;
}
.syt-antd-select-multiple .syt-antd-select-selection-search {
  position: relative;
  margin-left: 0px;
}
.syt-antd-select-multiple .syt-antd-select-selection-search-input,
.syt-antd-select-multiple .syt-antd-select-selection-search-mirror {
  font-family: 'noto-sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 20px;
  transition: all 0.3s;
}
.syt-antd-select-multiple .syt-antd-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.syt-antd-select-multiple .syt-antd-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: nowrap;
  visibility: hidden;
}
.syt-antd-select-multiple .syt-antd-select-selection-search:first-child .syt-antd-select-selection-search-input {
  margin-left: 6.5px;
}
.syt-antd-select-multiple .syt-antd-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.syt-antd-select-multiple.syt-antd-select-lg .syt-antd-select-selector::after {
  line-height: 34px;
}
.syt-antd-select-multiple.syt-antd-select-lg .syt-antd-select-selection-item {
  height: 34px;
  line-height: 32px;
}
.syt-antd-select-multiple.syt-antd-select-lg .syt-antd-select-selection-search {
  height: 34px;
  line-height: 34px;
}
.syt-antd-select-multiple.syt-antd-select-lg .syt-antd-select-selection-search-input,
.syt-antd-select-multiple.syt-antd-select-lg .syt-antd-select-selection-search-mirror {
  height: 34px;
  line-height: 32px;
}
.syt-antd-select-multiple.syt-antd-select-sm .syt-antd-select-selector::after {
  line-height: 18px;
}
.syt-antd-select-multiple.syt-antd-select-sm .syt-antd-select-selection-item {
  height: 18px;
  line-height: 16px;
}
.syt-antd-select-multiple.syt-antd-select-sm .syt-antd-select-selection-search {
  height: 18px;
  line-height: 18px;
}
.syt-antd-select-multiple.syt-antd-select-sm .syt-antd-select-selection-search-input,
.syt-antd-select-multiple.syt-antd-select-sm .syt-antd-select-selection-search-mirror {
  height: 18px;
  line-height: 16px;
}
.syt-antd-select-multiple.syt-antd-select-sm .syt-antd-select-selection-placeholder {
  left: 7px;
}
.syt-antd-select-multiple.syt-antd-select-sm .syt-antd-select-selection-search:first-child .syt-antd-select-selection-search-input {
  margin-left: 3px;
}
.syt-antd-select-multiple.syt-antd-select-lg .syt-antd-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.syt-antd-select-disabled .syt-antd-select-selection-item-remove {
  display: none;
}
/* Reset search input style */
.syt-antd-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.syt-antd-select:not(.syt-antd-select-disabled):hover .syt-antd-select-selector {
  border-color: #0092e4;
  border-right-width: 1px !important;
}
.syt-antd-input-rtl .syt-antd-select:not(.syt-antd-select-disabled):hover .syt-antd-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syt-antd-select-selection-item {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .syt-antd-select-selection-item *::-ms-backdrop,
  .syt-antd-select-selection-item {
    flex: auto;
  }
}
.syt-antd-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .syt-antd-select-selection-placeholder *::-ms-backdrop,
  .syt-antd-select-selection-placeholder {
    flex: auto;
  }
}
.syt-antd-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 53%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #c1c5c8;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.syt-antd-select-arrow > * {
  line-height: 1;
}
.syt-antd-select-arrow svg {
  display: inline-block;
}
.syt-antd-select-arrow::before {
  display: none;
}
.syt-antd-select-arrow .syt-antd-select-arrow-icon {
  display: block;
}
.syt-antd-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.syt-antd-select-arrow .anticon > svg {
  vertical-align: top;
}
.syt-antd-select-arrow .anticon:not(.syt-antd-select-suffix) {
  pointer-events: auto;
}
.syt-antd-select-disabled .syt-antd-select-arrow {
  cursor: not-allowed;
}
.syt-antd-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #c1c5c8;
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.syt-antd-select-clear::before {
  display: block;
}
.syt-antd-select-clear:hover {
  color: #696F88;
}
.syt-antd-select:hover .syt-antd-select-clear {
  opacity: 1;
}
.syt-antd-select-dropdown {
  margin: 0;
  padding: 0;
  color: #14151C;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
}
.syt-antd-select-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-select-dropdown-placement-bottomLeft,
.syt-antd-select-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.syt-antd-select-dropdown.slide-up-enter.slide-up-enter-active.syt-antd-select-dropdown-placement-topLeft,
.syt-antd-select-dropdown.slide-up-appear.slide-up-appear-active.syt-antd-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.syt-antd-select-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.syt-antd-select-dropdown.slide-up-leave.slide-up-leave-active.syt-antd-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.syt-antd-select-dropdown-hidden {
  display: none;
}
.syt-antd-select-dropdown-empty {
  color: #c1c5c8;
}
.syt-antd-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #c1c5c8;
}
.syt-antd-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.syt-antd-select-item-group {
  color: #696F88;
  font-size: 12px;
  cursor: default;
}
.syt-antd-select-item-option {
  display: flex;
}
.syt-antd-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-select-item-option-state {
  flex: none;
}
.syt-antd-select-item-option-active:not(.syt-antd-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.syt-antd-select-item-option-selected:not(.syt-antd-select-item-option-disabled) {
  color: #14151C;
  font-weight: 600;
  background-color: #e6f8ff;
}
.syt-antd-select-item-option-selected:not(.syt-antd-select-item-option-disabled) .syt-antd-select-item-option-state {
  color: #0071CD;
}
.syt-antd-select-item-option-disabled {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-select-item-option-grouped {
  padding-left: 24px;
}
.syt-antd-select-lg {
  font-size: 16px;
}
.syt-antd-select-borderless .syt-antd-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.syt-antd-select-rtl {
  direction: rtl;
}
.syt-antd-select-rtl .syt-antd-select-arrow {
  right: initial;
  left: 11px;
}
.syt-antd-select-rtl .syt-antd-select-clear {
  right: initial;
  left: 11px;
}
.syt-antd-select-dropdown-rtl {
  direction: rtl;
}
.syt-antd-select-dropdown-rtl .syt-antd-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.syt-antd-select-rtl.syt-antd-select-multiple.syt-antd-select-show-arrow .syt-antd-select-selector,
.syt-antd-select-rtl.syt-antd-select-multiple.syt-antd-select-allow-clear .syt-antd-select-selector {
  padding-right: 3px;
  padding-left: 24px;
}
.syt-antd-select-rtl.syt-antd-select-multiple .syt-antd-select-selection-item {
  margin-right: 0;
  margin-left: 3px;
  padding: 0 8px 0 4px;
  text-align: right;
}
.syt-antd-select-rtl.syt-antd-select-multiple .syt-antd-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.syt-antd-select-rtl.syt-antd-select-multiple .syt-antd-select-selection-search {
  margin-right: 0px;
  margin-left: 3px;
}
.syt-antd-select-rtl.syt-antd-select-multiple .syt-antd-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.syt-antd-select-rtl.syt-antd-select-multiple .syt-antd-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.syt-antd-select-rtl.syt-antd-select-multiple.syt-antd-select-sm .syt-antd-select-selection-placeholder {
  right: 7px;
}
.syt-antd-select-rtl.syt-antd-select-single .syt-antd-select-selector .syt-antd-select-selection-item,
.syt-antd-select-rtl.syt-antd-select-single .syt-antd-select-selector .syt-antd-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.syt-antd-select-rtl.syt-antd-select-single.syt-antd-select-show-arrow .syt-antd-select-selection-search {
  right: 11px;
  left: 25px;
}
.syt-antd-select-rtl.syt-antd-select-single.syt-antd-select-show-arrow .syt-antd-select-selection-item,
.syt-antd-select-rtl.syt-antd-select-single.syt-antd-select-show-arrow .syt-antd-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.syt-antd-select-rtl.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  padding: 0 11px;
}
.syt-antd-select-rtl.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input).syt-antd-select-show-arrow .syt-antd-select-selection-search {
  right: 0;
}
.syt-antd-select-rtl.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input).syt-antd-select-show-arrow .syt-antd-select-selection-item,
.syt-antd-select-rtl.syt-antd-select-single.syt-antd-select-sm:not(.syt-antd-select-customize-input).syt-antd-select-show-arrow .syt-antd-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}
.syt-antd-skeleton {
  display: table;
  width: 100%;
}
.syt-antd-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.syt-antd-skeleton-header .syt-antd-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.syt-antd-skeleton-header .syt-antd-skeleton-avatar.syt-antd-skeleton-avatar-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-header .syt-antd-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.syt-antd-skeleton-header .syt-antd-skeleton-avatar-lg.syt-antd-skeleton-avatar-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-header .syt-antd-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.syt-antd-skeleton-header .syt-antd-skeleton-avatar-sm.syt-antd-skeleton-avatar-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.syt-antd-skeleton-content .syt-antd-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: #f2f2f2;
}
.syt-antd-skeleton-content .syt-antd-skeleton-title + .syt-antd-skeleton-paragraph {
  margin-top: 24px;
}
.syt-antd-skeleton-content .syt-antd-skeleton-paragraph {
  padding: 0;
}
.syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: #f2f2f2;
}
.syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.syt-antd-skeleton-with-avatar .syt-antd-skeleton-content .syt-antd-skeleton-title {
  margin-top: 12px;
}
.syt-antd-skeleton-with-avatar .syt-antd-skeleton-content .syt-antd-skeleton-title + .syt-antd-skeleton-paragraph {
  margin-top: 28px;
}
.syt-antd-skeleton-round .syt-antd-skeleton-content .syt-antd-skeleton-title,
.syt-antd-skeleton-round .syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li {
  border-radius: 100px;
}
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-content .syt-antd-skeleton-title,
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: syt-antd-skeleton-loading 1.4s ease infinite;
}
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-avatar {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: syt-antd-skeleton-loading 1.4s ease infinite;
}
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-button {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: syt-antd-skeleton-loading 1.4s ease infinite;
}
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-input {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: syt-antd-skeleton-loading 1.4s ease infinite;
}
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-image {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: syt-antd-skeleton-loading 1.4s ease infinite;
}
.syt-antd-skeleton-element {
  display: inline-block;
  width: auto;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  border-radius: 4px;
  width: 64px;
  height: 32px;
  line-height: 32px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button.syt-antd-skeleton-button-circle {
  width: 32px;
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button.syt-antd-skeleton-button-round {
  border-radius: 32px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button-lg {
  width: 80px;
  height: 40px;
  line-height: 40px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button-lg.syt-antd-skeleton-button-circle {
  width: 40px;
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button-lg.syt-antd-skeleton-button-round {
  border-radius: 40px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button-sm {
  width: 48px;
  height: 24px;
  line-height: 24px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button-sm.syt-antd-skeleton-button-circle {
  width: 24px;
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button-sm.syt-antd-skeleton-button-round {
  border-radius: 24px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-avatar.syt-antd-skeleton-avatar-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-avatar-lg.syt-antd-skeleton-avatar-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-avatar-sm.syt-antd-skeleton-avatar-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 100%;
  height: 32px;
  line-height: 32px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-input-lg {
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-input-sm {
  width: 100%;
  height: 24px;
  line-height: 24px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: #f2f2f2;
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-image.syt-antd-skeleton-image-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-image-path {
  fill: #bfbfbf;
}
.syt-antd-skeleton-element .syt-antd-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-image-svg.syt-antd-skeleton-image-circle {
  border-radius: 50%;
}
@keyframes syt-antd-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.syt-antd-skeleton-rtl {
  direction: rtl;
}
.syt-antd-skeleton-rtl .syt-antd-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.syt-antd-skeleton-rtl.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-content .syt-antd-skeleton-title,
.syt-antd-skeleton-rtl.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li {
  animation-name: syt-antd-skeleton-loading-rtl;
}
.syt-antd-skeleton-rtl.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-avatar {
  animation-name: syt-antd-skeleton-loading-rtl;
}
@keyframes syt-antd-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.syt-antd-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.syt-antd-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.syt-antd-slider-vertical .syt-antd-slider-rail {
  width: 4px;
  height: 100%;
}
.syt-antd-slider-vertical .syt-antd-slider-track {
  width: 4px;
}
.syt-antd-slider-vertical .syt-antd-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.syt-antd-slider-vertical .syt-antd-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.syt-antd-slider-vertical .syt-antd-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.syt-antd-slider-vertical .syt-antd-slider-step {
  width: 4px;
  height: 100%;
}
.syt-antd-slider-vertical .syt-antd-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.syt-antd-slider-tooltip .syt-antd-tooltip-inner {
  min-width: unset;
}
.syt-antd-slider-rtl.syt-antd-slider-vertical .syt-antd-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.syt-antd-slider-rtl.syt-antd-slider-vertical .syt-antd-slider-mark {
  right: 12px;
  left: auto;
}
.syt-antd-slider-rtl.syt-antd-slider-vertical .syt-antd-slider-mark-text {
  right: 4px;
  left: auto;
}
.syt-antd-slider-rtl.syt-antd-slider-vertical .syt-antd-slider-dot {
  right: 2px;
  left: auto;
}
.syt-antd-slider-with-marks {
  margin-bottom: 28px;
}
.syt-antd-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.syt-antd-slider-track {
  position: absolute;
  height: 4px;
  background-color: #74c6f2;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.syt-antd-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #74c6f2;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.syt-antd-slider-handle-dragging.syt-antd-slider-handle-dragging.syt-antd-slider-handle-dragging {
  border-color: #338dd7;
  box-shadow: 0 0 0 5px rgba(0, 113, 205, 0.12);
}
.syt-antd-slider-handle:focus {
  border-color: #338dd7;
  outline: none;
  box-shadow: 0 0 0 5px rgba(0, 113, 205, 0.12);
}
.syt-antd-slider-handle.syt-antd-tooltip-open {
  border-color: #0071CD;
}
.syt-antd-slider:hover .syt-antd-slider-rail {
  background-color: #e1e1e1;
}
.syt-antd-slider:hover .syt-antd-slider-track {
  background-color: #49aae6;
}
.syt-antd-slider:hover .syt-antd-slider-handle:not(.syt-antd-tooltip-open) {
  border-color: #49aae6;
}
.syt-antd-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.syt-antd-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #696F88;
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  user-select: none;
}
.syt-antd-slider-mark-text-active {
  color: #14151C;
}
.syt-antd-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.syt-antd-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}
.syt-antd-slider-dot:first-child {
  margin-left: -4px;
}
.syt-antd-slider-dot:last-child {
  margin-left: -4px;
}
.syt-antd-slider-dot-active {
  border-color: #80b8e6;
}
.syt-antd-slider-disabled {
  cursor: not-allowed;
}
.syt-antd-slider-disabled .syt-antd-slider-track {
  background-color: #c1c5c8 !important;
}
.syt-antd-slider-disabled .syt-antd-slider-handle,
.syt-antd-slider-disabled .syt-antd-slider-dot {
  background-color: #fff;
  border-color: #c1c5c8 !important;
  box-shadow: none;
  cursor: not-allowed;
}
.syt-antd-slider-disabled .syt-antd-slider-mark-text,
.syt-antd-slider-disabled .syt-antd-slider-dot {
  cursor: not-allowed !important;
}
.syt-antd-slider-rtl {
  direction: rtl;
}
.syt-antd-slider-rtl .syt-antd-slider-mark {
  right: 0;
  left: auto;
}
.syt-antd-slider-rtl .syt-antd-slider-dot {
  margin-right: -4px;
  margin-left: 0;
}
.syt-antd-slider-rtl .syt-antd-slider-dot:first-child {
  margin-right: -4px;
  margin-left: 0;
}
.syt-antd-slider-rtl .syt-antd-slider-dot:last-child {
  margin-right: -4px;
  margin-left: 0;
}
.syt-antd-slider-track,
.syt-antd-slider:hover .syt-antd-slider-track {
  background-color: #0092E4;
}
.syt-antd-slider-rail,
.syt-antd-slider:hover .syt-antd-slider-rail {
  background-color: #C2C7D0;
}
.syt-antd-slider-handle,
.syt-antd-slider:hover .syt-antd-slider-handle:not(.syt-antd-tooltip-open) {
  border: 2px solid #0092E4;
}
.syt-antd-slider-handle.syt-antd-tooltip-open {
  border: 2px solid #0071CD;
}
.syt-antd-slider-handle:focus,
.syt-antd-slider-handle.syt-antd-slider-handle-click-focused {
  border: 2px solid #0092E4;
  box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
}
.syt-antd-slider-dot {
  border: 2px solid #A3A9B9;
}
.syt-antd-slider-dot.syt-antd-slider-dot-active {
  border-color: #0071CD;
}
.syt-antd-slider-disabled .syt-antd-slider-handle {
  background-color: #F3F4F6;
}
.syt-antd-space {
  display: inline-flex;
}
.syt-antd-space-vertical {
  flex-direction: column;
}
.syt-antd-space-align-center {
  align-items: center;
}
.syt-antd-space-align-start {
  align-items: flex-start;
}
.syt-antd-space-align-end {
  align-items: flex-end;
}
.syt-antd-space-align-baseline {
  align-items: baseline;
}
.syt-antd-space-item:empty {
  display: none;
}
.syt-antd-space-rtl {
  direction: rtl;
}
.syt-antd-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #0071CD;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.syt-antd-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.syt-antd-spin-nested-loading {
  position: relative;
}
.syt-antd-spin-nested-loading > div > .syt-antd-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.syt-antd-spin-nested-loading > div > .syt-antd-spin .syt-antd-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.syt-antd-spin-nested-loading > div > .syt-antd-spin .syt-antd-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.syt-antd-spin-nested-loading > div > .syt-antd-spin.syt-antd-spin-show-text .syt-antd-spin-dot {
  margin-top: -20px;
}
.syt-antd-spin-nested-loading > div > .syt-antd-spin-sm .syt-antd-spin-dot {
  margin: -7px;
}
.syt-antd-spin-nested-loading > div > .syt-antd-spin-sm .syt-antd-spin-text {
  padding-top: 2px;
}
.syt-antd-spin-nested-loading > div > .syt-antd-spin-sm.syt-antd-spin-show-text .syt-antd-spin-dot {
  margin-top: -17px;
}
.syt-antd-spin-nested-loading > div > .syt-antd-spin-lg .syt-antd-spin-dot {
  margin: -16px;
}
.syt-antd-spin-nested-loading > div > .syt-antd-spin-lg .syt-antd-spin-text {
  padding-top: 11px;
}
.syt-antd-spin-nested-loading > div > .syt-antd-spin-lg.syt-antd-spin-show-text .syt-antd-spin-dot {
  margin-top: -26px;
}
.syt-antd-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.syt-antd-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.syt-antd-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
.syt-antd-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.syt-antd-spin-tip {
  color: #696F88;
}
.syt-antd-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.syt-antd-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #0071CD;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.syt-antd-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.syt-antd-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
.syt-antd-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.syt-antd-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
.syt-antd-spin-dot-spin {
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}
.syt-antd-spin-sm .syt-antd-spin-dot {
  font-size: 14px;
}
.syt-antd-spin-sm .syt-antd-spin-dot i {
  width: 6px;
  height: 6px;
}
.syt-antd-spin-lg .syt-antd-spin-dot {
  font-size: 32px;
}
.syt-antd-spin-lg .syt-antd-spin-dot i {
  width: 14px;
  height: 14px;
}
.syt-antd-spin.syt-antd-spin-show-text .syt-antd-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .syt-antd-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
.syt-antd-spin-rtl {
  direction: rtl;
}
.syt-antd-spin-rtl .syt-antd-spin-dot-spin {
  transform: rotate(-45deg);
  animation-name: antRotateRtl;
}
@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
.syt-default .syt-antd-spin-text {
  top: -35px;
  position: relative;
  font-weight: bold;
}
.syt-default .syt-antd-spin-dot {
  top: 40px;
}
.syt-antd-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
}
.syt-antd-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}
.syt-antd-steps-item-container {
  outline: none;
}
.syt-antd-steps-item:last-child {
  flex: none;
}
.syt-antd-steps-item:last-child > .syt-antd-steps-item-container > .syt-antd-steps-item-tail,
.syt-antd-steps-item:last-child > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-title::after {
  display: none;
}
.syt-antd-steps-item-icon,
.syt-antd-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.syt-antd-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: 'noto-sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  border: 1px solid #c1c5c8;
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.syt-antd-steps-item-icon .syt-antd-steps-icon {
  position: relative;
  top: -1px;
  color: #0071CD;
  line-height: 1;
}
.syt-antd-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.syt-antd-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.syt-antd-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: #14151C;
  font-size: 16px;
  line-height: 32px;
}
.syt-antd-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}
.syt-antd-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: #696F88;
  font-weight: normal;
  font-size: 14px;
}
.syt-antd-steps-item-description {
  color: #696F88;
  font-size: 14px;
}
.syt-antd-steps-item-wait .syt-antd-steps-item-icon {
  background-color: #fff;
  border-color: #c1c5c8;
}
.syt-antd-steps-item-wait .syt-antd-steps-item-icon > .syt-antd-steps-icon {
  color: #c1c5c8;
}
.syt-antd-steps-item-wait .syt-antd-steps-item-icon > .syt-antd-steps-icon .syt-antd-steps-icon-dot {
  background: #c1c5c8;
}
.syt-antd-steps-item-wait > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-title {
  color: #696F88;
}
.syt-antd-steps-item-wait > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-title::after {
  background-color: #f0f0f0;
}
.syt-antd-steps-item-wait > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-description {
  color: #696F88;
}
.syt-antd-steps-item-wait > .syt-antd-steps-item-container > .syt-antd-steps-item-tail::after {
  background-color: #f0f0f0;
}
.syt-antd-steps-item-process .syt-antd-steps-item-icon {
  background-color: #fff;
  border-color: #0071CD;
}
.syt-antd-steps-item-process .syt-antd-steps-item-icon > .syt-antd-steps-icon {
  color: #0071CD;
}
.syt-antd-steps-item-process .syt-antd-steps-item-icon > .syt-antd-steps-icon .syt-antd-steps-icon-dot {
  background: #0071CD;
}
.syt-antd-steps-item-process > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-title {
  color: #14151C;
}
.syt-antd-steps-item-process > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-title::after {
  background-color: #f0f0f0;
}
.syt-antd-steps-item-process > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-description {
  color: #14151C;
}
.syt-antd-steps-item-process > .syt-antd-steps-item-container > .syt-antd-steps-item-tail::after {
  background-color: #f0f0f0;
}
.syt-antd-steps-item-process .syt-antd-steps-item-icon {
  background: #0071CD;
}
.syt-antd-steps-item-process .syt-antd-steps-item-icon .syt-antd-steps-icon {
  color: #FFFFFF;
}
.syt-antd-steps-item-process .syt-antd-steps-item-title {
  font-weight: 500;
}
.syt-antd-steps-item-finish .syt-antd-steps-item-icon {
  background-color: #fff;
  border-color: #0071CD;
}
.syt-antd-steps-item-finish .syt-antd-steps-item-icon > .syt-antd-steps-icon {
  color: #0071CD;
}
.syt-antd-steps-item-finish .syt-antd-steps-item-icon > .syt-antd-steps-icon .syt-antd-steps-icon-dot {
  background: #0071CD;
}
.syt-antd-steps-item-finish > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-title {
  color: #14151C;
}
.syt-antd-steps-item-finish > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-title::after {
  background-color: #0071CD;
}
.syt-antd-steps-item-finish > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-description {
  color: #696F88;
}
.syt-antd-steps-item-finish > .syt-antd-steps-item-container > .syt-antd-steps-item-tail::after {
  background-color: #0071CD;
}
.syt-antd-steps-item-error .syt-antd-steps-item-icon {
  background-color: #fff;
  border-color: #CF3537;
}
.syt-antd-steps-item-error .syt-antd-steps-item-icon > .syt-antd-steps-icon {
  color: #CF3537;
}
.syt-antd-steps-item-error .syt-antd-steps-item-icon > .syt-antd-steps-icon .syt-antd-steps-icon-dot {
  background: #CF3537;
}
.syt-antd-steps-item-error > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-title {
  color: #CF3537;
}
.syt-antd-steps-item-error > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-title::after {
  background-color: #f0f0f0;
}
.syt-antd-steps-item-error > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-description {
  color: #CF3537;
}
.syt-antd-steps-item-error > .syt-antd-steps-item-container > .syt-antd-steps-item-tail::after {
  background-color: #f0f0f0;
}
.syt-antd-steps-item.syt-antd-steps-next-error .syt-antd-steps-item-title::after {
  background: #CF3537;
}
.syt-antd-steps-item-disabled {
  cursor: not-allowed;
}
.syt-antd-steps .syt-antd-steps-item:not(.syt-antd-steps-item-active) > .syt-antd-steps-item-container[role='button'] {
  cursor: pointer;
}
.syt-antd-steps .syt-antd-steps-item:not(.syt-antd-steps-item-active) > .syt-antd-steps-item-container[role='button'] .syt-antd-steps-item-title,
.syt-antd-steps .syt-antd-steps-item:not(.syt-antd-steps-item-active) > .syt-antd-steps-item-container[role='button'] .syt-antd-steps-item-subtitle,
.syt-antd-steps .syt-antd-steps-item:not(.syt-antd-steps-item-active) > .syt-antd-steps-item-container[role='button'] .syt-antd-steps-item-description,
.syt-antd-steps .syt-antd-steps-item:not(.syt-antd-steps-item-active) > .syt-antd-steps-item-container[role='button'] .syt-antd-steps-item-icon .syt-antd-steps-icon {
  transition: color 0.3s;
}
.syt-antd-steps .syt-antd-steps-item:not(.syt-antd-steps-item-active) > .syt-antd-steps-item-container[role='button']:hover .syt-antd-steps-item-title,
.syt-antd-steps .syt-antd-steps-item:not(.syt-antd-steps-item-active) > .syt-antd-steps-item-container[role='button']:hover .syt-antd-steps-item-subtitle,
.syt-antd-steps .syt-antd-steps-item:not(.syt-antd-steps-item-active) > .syt-antd-steps-item-container[role='button']:hover .syt-antd-steps-item-description {
  color: #0071CD;
}
.syt-antd-steps .syt-antd-steps-item:not(.syt-antd-steps-item-active):not(.syt-antd-steps-item-process) > .syt-antd-steps-item-container[role='button']:hover .syt-antd-steps-item-icon {
  border-color: #0071CD;
}
.syt-antd-steps .syt-antd-steps-item:not(.syt-antd-steps-item-active):not(.syt-antd-steps-item-process) > .syt-antd-steps-item-container[role='button']:hover .syt-antd-steps-item-icon .syt-antd-steps-icon {
  color: #0071CD;
}
.syt-antd-steps-horizontal:not(.syt-antd-steps-label-vertical) .syt-antd-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.syt-antd-steps-horizontal:not(.syt-antd-steps-label-vertical) .syt-antd-steps-item:first-child {
  padding-left: 0;
}
.syt-antd-steps-horizontal:not(.syt-antd-steps-label-vertical) .syt-antd-steps-item:last-child .syt-antd-steps-item-title {
  padding-right: 0;
}
.syt-antd-steps-horizontal:not(.syt-antd-steps-label-vertical) .syt-antd-steps-item-tail {
  display: none;
}
.syt-antd-steps-horizontal:not(.syt-antd-steps-label-vertical) .syt-antd-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.syt-antd-steps-item-custom .syt-antd-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.syt-antd-steps-item-custom .syt-antd-steps-item-icon > .syt-antd-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.syt-antd-steps-item-custom.syt-antd-steps-item-process .syt-antd-steps-item-icon > .syt-antd-steps-icon {
  color: #0071CD;
}
.syt-antd-steps:not(.syt-antd-steps-vertical) .syt-antd-steps-item-custom .syt-antd-steps-item-icon {
  width: auto;
  background: none;
}
.syt-antd-steps-small.syt-antd-steps-horizontal:not(.syt-antd-steps-label-vertical) .syt-antd-steps-item {
  padding-left: 12px;
}
.syt-antd-steps-small.syt-antd-steps-horizontal:not(.syt-antd-steps-label-vertical) .syt-antd-steps-item:first-child {
  padding-left: 0;
}
.syt-antd-steps-small .syt-antd-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.syt-antd-steps-small .syt-antd-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.syt-antd-steps-small .syt-antd-steps-item-title::after {
  top: 12px;
}
.syt-antd-steps-small .syt-antd-steps-item-description {
  color: #696F88;
  font-size: 14px;
}
.syt-antd-steps-small .syt-antd-steps-item-tail {
  top: 8px;
}
.syt-antd-steps-small .syt-antd-steps-item-custom .syt-antd-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.syt-antd-steps-small .syt-antd-steps-item-custom .syt-antd-steps-item-icon > .syt-antd-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.syt-antd-steps-vertical {
  display: flex;
  flex-direction: column;
}
.syt-antd-steps-vertical .syt-antd-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.syt-antd-steps-vertical .syt-antd-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.syt-antd-steps-vertical .syt-antd-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.syt-antd-steps-vertical .syt-antd-steps-item-title {
  line-height: 32px;
}
.syt-antd-steps-vertical .syt-antd-steps-item-description {
  padding-bottom: 12px;
}
.syt-antd-steps-vertical > .syt-antd-steps-item > .syt-antd-steps-item-container > .syt-antd-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.syt-antd-steps-vertical > .syt-antd-steps-item > .syt-antd-steps-item-container > .syt-antd-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.syt-antd-steps-vertical > .syt-antd-steps-item:not(:last-child) > .syt-antd-steps-item-container > .syt-antd-steps-item-tail {
  display: block;
}
.syt-antd-steps-vertical > .syt-antd-steps-item > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-title::after {
  display: none;
}
.syt-antd-steps-vertical.syt-antd-steps-small .syt-antd-steps-item-container .syt-antd-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.syt-antd-steps-vertical.syt-antd-steps-small .syt-antd-steps-item-container .syt-antd-steps-item-title {
  line-height: 24px;
}
.syt-antd-steps-rtl.syt-antd-steps-vertical .syt-antd-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.syt-antd-steps-rtl.syt-antd-steps-vertical > .syt-antd-steps-item > .syt-antd-steps-item-container > .syt-antd-steps-item-tail {
  right: 16px;
  left: auto;
}
.syt-antd-steps-rtl.syt-antd-steps-vertical.syt-antd-steps-small .syt-antd-steps-item-container .syt-antd-steps-item-tail {
  right: 12px;
  left: auto;
}
@media (max-width: 480px) {
  .syt-antd-steps-horizontal.syt-antd-steps-label-horizontal {
    display: flex;
    flex-direction: column;
  }
  .syt-antd-steps-horizontal.syt-antd-steps-label-horizontal .syt-antd-steps-item {
    display: block;
    flex: 1 0 auto;
    padding-left: 0;
    overflow: visible;
  }
  .syt-antd-steps-horizontal.syt-antd-steps-label-horizontal .syt-antd-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  .syt-antd-steps-horizontal.syt-antd-steps-label-horizontal .syt-antd-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  .syt-antd-steps-horizontal.syt-antd-steps-label-horizontal .syt-antd-steps-item-title {
    line-height: 32px;
  }
  .syt-antd-steps-horizontal.syt-antd-steps-label-horizontal .syt-antd-steps-item-description {
    padding-bottom: 12px;
  }
  .syt-antd-steps-horizontal.syt-antd-steps-label-horizontal > .syt-antd-steps-item > .syt-antd-steps-item-container > .syt-antd-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
  }
  .syt-antd-steps-horizontal.syt-antd-steps-label-horizontal > .syt-antd-steps-item > .syt-antd-steps-item-container > .syt-antd-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  .syt-antd-steps-horizontal.syt-antd-steps-label-horizontal > .syt-antd-steps-item:not(:last-child) > .syt-antd-steps-item-container > .syt-antd-steps-item-tail {
    display: block;
  }
  .syt-antd-steps-horizontal.syt-antd-steps-label-horizontal > .syt-antd-steps-item > .syt-antd-steps-item-container > .syt-antd-steps-item-content > .syt-antd-steps-item-title::after {
    display: none;
  }
  .syt-antd-steps-horizontal.syt-antd-steps-label-horizontal.syt-antd-steps-small .syt-antd-steps-item-container .syt-antd-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px;
  }
  .syt-antd-steps-horizontal.syt-antd-steps-label-horizontal.syt-antd-steps-small .syt-antd-steps-item-container .syt-antd-steps-item-title {
    line-height: 24px;
  }
  .syt-antd-steps-rtl.syt-antd-steps-horizontal.syt-antd-steps-label-horizontal .syt-antd-steps-item-icon {
    float: right;
    margin-right: 0;
    margin-left: 16px;
  }
  .syt-antd-steps-rtl.syt-antd-steps-horizontal.syt-antd-steps-label-horizontal > .syt-antd-steps-item > .syt-antd-steps-item-container > .syt-antd-steps-item-tail {
    right: 16px;
    left: auto;
  }
  .syt-antd-steps-rtl.syt-antd-steps-horizontal.syt-antd-steps-label-horizontal.syt-antd-steps-small .syt-antd-steps-item-container .syt-antd-steps-item-tail {
    right: 12px;
    left: auto;
  }
}
.syt-antd-steps-label-vertical .syt-antd-steps-item {
  overflow: visible;
}
.syt-antd-steps-label-vertical .syt-antd-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.syt-antd-steps-label-vertical .syt-antd-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.syt-antd-steps-label-vertical .syt-antd-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.syt-antd-steps-label-vertical .syt-antd-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.syt-antd-steps-label-vertical .syt-antd-steps-item-title::after {
  display: none;
}
.syt-antd-steps-label-vertical .syt-antd-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 20px;
}
.syt-antd-steps-label-vertical.syt-antd-steps-small:not(.syt-antd-steps-dot) .syt-antd-steps-item-icon {
  margin-left: 46px;
}
.syt-antd-steps-dot .syt-antd-steps-item-title,
.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-title {
  line-height: 20px;
}
.syt-antd-steps-dot .syt-antd-steps-item-tail,
.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.syt-antd-steps-dot .syt-antd-steps-item-tail::after,
.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.syt-antd-steps-dot .syt-antd-steps-item:first-child .syt-antd-steps-icon-dot,
.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item:first-child .syt-antd-steps-icon-dot {
  left: 2px;
}
.syt-antd-steps-dot .syt-antd-steps-item-icon,
.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.syt-antd-steps-dot .syt-antd-steps-item-icon .syt-antd-steps-icon-dot,
.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-icon .syt-antd-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.syt-antd-steps-dot .syt-antd-steps-item-icon .syt-antd-steps-icon-dot::after,
.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-icon .syt-antd-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.syt-antd-steps-dot .syt-antd-steps-item-process .syt-antd-steps-item-icon,
.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-process .syt-antd-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.syt-antd-steps-vertical.syt-antd-steps-dot .syt-antd-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
  background: none;
}
.syt-antd-steps-vertical.syt-antd-steps-dot .syt-antd-steps-item > .syt-antd-steps-item-container > .syt-antd-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.syt-antd-steps-vertical.syt-antd-steps-dot .syt-antd-steps-item:first-child .syt-antd-steps-icon-dot {
  left: 0;
}
.syt-antd-steps-vertical.syt-antd-steps-dot .syt-antd-steps-item-process .syt-antd-steps-icon-dot {
  left: -2px;
}
.syt-antd-steps-navigation {
  padding-top: 12px;
}
.syt-antd-steps-navigation.syt-antd-steps-small .syt-antd-steps-item-container {
  margin-left: -12px;
}
.syt-antd-steps-navigation .syt-antd-steps-item {
  overflow: visible;
  text-align: center;
}
.syt-antd-steps-navigation .syt-antd-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.syt-antd-steps-navigation .syt-antd-steps-item-container .syt-antd-steps-item-content {
  max-width: auto;
}
.syt-antd-steps-navigation .syt-antd-steps-item-container .syt-antd-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-steps-navigation .syt-antd-steps-item-container .syt-antd-steps-item-title::after {
  display: none;
}
.syt-antd-steps-navigation .syt-antd-steps-item:not(.syt-antd-steps-item-active) .syt-antd-steps-item-container[role='button'] {
  cursor: pointer;
}
.syt-antd-steps-navigation .syt-antd-steps-item:not(.syt-antd-steps-item-active) .syt-antd-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.syt-antd-steps-navigation .syt-antd-steps-item:last-child {
  flex: 1;
}
.syt-antd-steps-navigation .syt-antd-steps-item:last-child::after {
  display: none;
}
.syt-antd-steps-navigation .syt-antd-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: '';
}
.syt-antd-steps-navigation .syt-antd-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #0071CD;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.syt-antd-steps-navigation .syt-antd-steps-item.syt-antd-steps-item-active::before {
  left: 0;
  width: 100%;
}
@media (max-width: 480px) {
  .syt-antd-steps-navigation > .syt-antd-steps-item {
    margin-right: 0 !important;
  }
  .syt-antd-steps-navigation > .syt-antd-steps-item::before {
    display: none;
  }
  .syt-antd-steps-navigation > .syt-antd-steps-item.syt-antd-steps-item-active::before {
    top: 0;
    right: 0;
    left: unset;
    display: block;
    width: 3px;
    height: calc(100% - 24px);
  }
  .syt-antd-steps-navigation > .syt-antd-steps-item::after {
    position: relative;
    top: -2px;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-bottom: 8px;
    text-align: center;
    transform: rotate(135deg);
  }
  .syt-antd-steps-navigation > .syt-antd-steps-item > .syt-antd-steps-item-container > .syt-antd-steps-item-tail {
    visibility: hidden;
  }
}
.syt-antd-steps-rtl {
  direction: rtl;
}
.syt-antd-steps.syt-antd-steps-rtl .syt-antd-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-steps-rtl .syt-antd-steps-item-tail {
  right: 0;
  left: auto;
}
.syt-antd-steps-rtl .syt-antd-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.syt-antd-steps-rtl .syt-antd-steps-item-title::after {
  right: 100%;
  left: auto;
}
.syt-antd-steps-rtl.syt-antd-steps-horizontal:not(.syt-antd-steps-label-vertical) .syt-antd-steps-item {
  padding-right: 16px;
  padding-left: 0;
}
.syt-antd-steps-rtl.syt-antd-steps-horizontal:not(.syt-antd-steps-label-vertical) .syt-antd-steps-item:first-child {
  padding-right: 0;
}
.syt-antd-steps-rtl.syt-antd-steps-horizontal:not(.syt-antd-steps-label-vertical) .syt-antd-steps-item:last-child .syt-antd-steps-item-title {
  padding-left: 0;
}
.syt-antd-steps-rtl .syt-antd-steps-item-custom .syt-antd-steps-item-icon > .syt-antd-steps-icon {
  right: 0.5px;
  left: auto;
}
.syt-antd-steps-rtl.syt-antd-steps-navigation.syt-antd-steps-small .syt-antd-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.syt-antd-steps-rtl.syt-antd-steps-navigation .syt-antd-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.syt-antd-steps-rtl.syt-antd-steps-navigation .syt-antd-steps-item-container .syt-antd-steps-item-title {
  padding-left: 0;
}
.syt-antd-steps-rtl.syt-antd-steps-navigation .syt-antd-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  transform: rotate(225deg);
}
.syt-antd-steps-rtl.syt-antd-steps-small.syt-antd-steps-horizontal:not(.syt-antd-steps-label-vertical) .syt-antd-steps-item {
  padding-right: 12px;
  padding-left: 0;
}
.syt-antd-steps-rtl.syt-antd-steps-small.syt-antd-steps-horizontal:not(.syt-antd-steps-label-vertical) .syt-antd-steps-item:first-child {
  padding-right: 0;
}
.syt-antd-steps-rtl.syt-antd-steps-small .syt-antd-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.syt-antd-steps-rtl.syt-antd-steps-label-vertical .syt-antd-steps-item-title {
  padding-left: 0;
}
.syt-antd-steps-rtl.syt-antd-steps-dot .syt-antd-steps-item-tail,
.syt-antd-steps-rtl.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-tail {
  margin: 0 70px 0 0;
}
.syt-antd-steps-rtl.syt-antd-steps-dot .syt-antd-steps-item-tail::after,
.syt-antd-steps-rtl.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.syt-antd-steps-rtl.syt-antd-steps-dot .syt-antd-steps-item:first-child .syt-antd-steps-icon-dot,
.syt-antd-steps-rtl.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item:first-child .syt-antd-steps-icon-dot {
  right: 2px;
  left: auto;
}
.syt-antd-steps-rtl.syt-antd-steps-dot .syt-antd-steps-item-icon,
.syt-antd-steps-rtl.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.syt-antd-steps-dot .syt-antd-steps-item-icon .syt-antd-steps-icon-dot,
.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-icon .syt-antd-steps-icon-dot {
  /* expand hover area */
}
.syt-antd-steps-rtl.syt-antd-steps-dot .syt-antd-steps-item-icon .syt-antd-steps-icon-dot,
.syt-antd-steps-rtl.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-icon .syt-antd-steps-icon-dot {
  float: right;
}
.syt-antd-steps-rtl.syt-antd-steps-dot .syt-antd-steps-item-icon .syt-antd-steps-icon-dot::after,
.syt-antd-steps-rtl.syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-icon .syt-antd-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.syt-antd-steps-rtl.syt-antd-steps-vertical.syt-antd-steps-dot .syt-antd-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.syt-antd-steps-rtl.syt-antd-steps-vertical.syt-antd-steps-dot .syt-antd-steps-item > .syt-antd-steps-item-container > .syt-antd-steps-item-tail {
  right: -9px;
  left: auto;
}
.syt-antd-steps-rtl.syt-antd-steps-vertical.syt-antd-steps-dot .syt-antd-steps-item:first-child .syt-antd-steps-icon-dot {
  right: 0;
  left: auto;
}
.syt-antd-steps-rtl.syt-antd-steps-vertical.syt-antd-steps-dot .syt-antd-steps-item-process .syt-antd-steps-icon-dot {
  right: -2px;
  left: auto;
}
.syt-antd-steps:not(.syt-antd-steps-dot):not(.syt-antd-steps-navigation):not(.syt-antd-steps-vertical) .syt-antd-steps-item {
  padding-top: 4px;
}
.syt-antd-steps:not(.syt-antd-steps-dot):not(.syt-antd-steps-navigation) .syt-antd-steps-item .syt-antd-steps-item-icon {
  position: relative;
}
.syt-antd-steps:not(.syt-antd-steps-dot):not(.syt-antd-steps-navigation) .syt-antd-steps-item .syt-antd-steps-item-icon .syt-antd-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.syt-antd-switch {
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: #c1c5c8;
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}
.syt-antd-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(193, 197, 200, 0.1);
}
.syt-antd-switch-checked:focus {
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-switch:focus:hover {
  box-shadow: none;
}
.syt-antd-switch-checked {
  background-color: #0071CD;
}
.syt-antd-switch-loading,
.syt-antd-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.syt-antd-switch-loading *,
.syt-antd-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.syt-antd-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #FFFFFF;
  font-size: 12px;
  transition: margin 0.2s;
}
.syt-antd-switch-checked .syt-antd-switch-inner {
  margin: 0 25px 0 7px;
}
.syt-antd-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.syt-antd-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.syt-antd-switch-checked .syt-antd-switch-handle {
  left: calc(100% - 18px - 2px);
}
.syt-antd-switch:not(.syt-antd-switch-disabled):active .syt-antd-switch-handle::before {
  right: -30%;
  left: 0;
}
.syt-antd-switch:not(.syt-antd-switch-disabled):active.syt-antd-switch-checked .syt-antd-switch-handle::before {
  right: 0;
  left: -30%;
}
.syt-antd-switch-loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  color: rgba(0, 0, 0, 0.65);
  transform: translate(-50%, -50%);
}
.syt-antd-switch-checked .syt-antd-switch-loading-icon {
  color: #0071CD;
}
.syt-antd-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.syt-antd-switch-small .syt-antd-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.syt-antd-switch-small .syt-antd-switch-handle {
  width: 12px;
  height: 12px;
}
.syt-antd-switch-small .syt-antd-switch-loading-icon {
  transform: translate(-50%, -50%) scale(0.66667);
}
.syt-antd-switch-small.syt-antd-switch-checked .syt-antd-switch-inner {
  margin: 0 18px 0 5px;
}
.syt-antd-switch-small.syt-antd-switch-checked .syt-antd-switch-handle {
  left: calc(100% - 12px - 2px);
}
.syt-antd-switch {
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: #c1c5c8;
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}
.syt-antd-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(193, 197, 200, 0.1);
}
.syt-antd-switch-checked:focus {
  box-shadow: 0 0 0 2px rgba(0, 113, 205, 0.2);
}
.syt-antd-switch:focus:hover {
  box-shadow: none;
}
.syt-antd-switch-checked {
  background-color: #0071CD;
}
.syt-antd-switch-loading,
.syt-antd-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.syt-antd-switch-loading *,
.syt-antd-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.syt-antd-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #FFFFFF;
  font-size: 12px;
  transition: margin 0.2s;
}
.syt-antd-switch-checked .syt-antd-switch-inner {
  margin: 0 25px 0 7px;
}
.syt-antd-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.syt-antd-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.syt-antd-switch-checked .syt-antd-switch-handle {
  left: calc(100% - 18px - 2px);
}
.syt-antd-switch:not(.syt-antd-switch-disabled):active .syt-antd-switch-handle::before {
  right: -30%;
  left: 0;
}
.syt-antd-switch:not(.syt-antd-switch-disabled):active.syt-antd-switch-checked .syt-antd-switch-handle::before {
  right: 0;
  left: -30%;
}
.syt-antd-switch-loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  color: rgba(0, 0, 0, 0.65);
  transform: translate(-50%, -50%);
}
.syt-antd-switch-checked .syt-antd-switch-loading-icon {
  color: #0071CD;
}
.syt-antd-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.syt-antd-switch-small .syt-antd-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.syt-antd-switch-small .syt-antd-switch-handle {
  width: 12px;
  height: 12px;
}
.syt-antd-switch-small .syt-antd-switch-loading-icon {
  transform: translate(-50%, -50%) scale(0.66667);
}
.syt-antd-switch-small.syt-antd-switch-checked .syt-antd-switch-inner {
  margin: 0 18px 0 5px;
}
.syt-antd-switch-small.syt-antd-switch-checked .syt-antd-switch-handle {
  left: calc(100% - 12px - 2px);
}
.syt-antd-switch-rtl {
  direction: rtl;
}
.syt-antd-switch-rtl .syt-antd-switch-inner {
  margin: 0 25px 0 7px;
}
.syt-antd-switch-rtl .syt-antd-switch-handle {
  right: 2px;
  left: auto;
}
.syt-antd-switch-rtl:not(.syt-antd-switch-rtl-disabled):active .syt-antd-switch-handle::before {
  right: 0;
  left: -30%;
}
.syt-antd-switch-rtl:not(.syt-antd-switch-rtl-disabled):active.syt-antd-switch-checked .syt-antd-switch-handle::before {
  right: -30%;
  left: 0;
}
.syt-antd-switch-rtl.syt-antd-switch-checked .syt-antd-switch-inner {
  margin: 0 7px 0 25px;
}
.syt-antd-switch-rtl.syt-antd-switch-checked .syt-antd-switch-handle {
  right: calc(100% - 18px - 2px);
}
.syt-antd-switch-rtl.syt-antd-switch-small.syt-antd-switch-checked .syt-antd-switch-handle {
  right: calc(100% - 12px - 2px);
}
.syt-antd-switch {
  background-color: #A3A9B9;
}
.syt-antd-switch:focus {
  box-shadow: none;
}
.syt-antd-switch:disabled {
  opacity: 0.4;
}
.syt-antd-switch-checked {
  background-color: #0071CD;
}
.syt-antd-switch-checked:focus {
  box-shadow: none;
}
.syt-antd-switch-checked:disabled {
  opacity: 0.4;
}
.syt-antd-table-wrapper {
  clear: both;
  max-width: 100%;
}
.syt-antd-table-wrapper::before {
  display: table;
  content: '';
}
.syt-antd-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  z-index: 0;
  font-size: 14px;
  background: #fff;
  border-radius: 4px;
}
.syt-antd-table table {
  width: 100%;
  text-align: left;
  border-radius: 4px 4px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.syt-antd-table-thead > tr > th,
.syt-antd-table-tbody > tr > td,
.syt-antd-table tfoot > tr > th,
.syt-antd-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.syt-antd-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.syt-antd-table-cell-ellipsis.syt-antd-table-cell-fix-left-last,
.syt-antd-table-cell-ellipsis.syt-antd-table-cell-fix-right-first {
  overflow: visible;
}
.syt-antd-table-cell-ellipsis.syt-antd-table-cell-fix-left-last .syt-antd-table-cell-content,
.syt-antd-table-cell-ellipsis.syt-antd-table-cell-fix-right-first .syt-antd-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.syt-antd-table-title {
  padding: 16px 16px;
}
.syt-antd-table-footer {
  padding: 16px 16px;
  color: #14151C;
  background: #fafafa;
}
.syt-antd-table-thead > tr > th {
  color: #14151C;
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}
.syt-antd-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.syt-antd-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.syt-antd-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}
.syt-antd-table-tbody > tr > td > .syt-antd-table-wrapper:only-child .syt-antd-table {
  margin: -16px -16px -16px 33px;
}
.syt-antd-table-tbody > tr > td > .syt-antd-table-wrapper:only-child .syt-antd-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.syt-antd-table-tbody > tr > td > .syt-antd-table-wrapper:only-child .syt-antd-table-tbody > tr:last-child > td:first-child,
.syt-antd-table-tbody > tr > td > .syt-antd-table-wrapper:only-child .syt-antd-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.syt-antd-table-tbody > tr.syt-antd-table-row:hover > td {
  background: #fafafa;
}
.syt-antd-table-tbody > tr.syt-antd-table-row-selected > td {
  background: #e6f8ff;
  border-color: rgba(0, 0, 0, 0.03);
}
.syt-antd-table-tbody > tr.syt-antd-table-row-selected:hover > td {
  background: #dcf5ff;
}
.syt-antd-table tfoot > tr > th,
.syt-antd-table tfoot > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-table-pagination.syt-antd-pagination {
  margin: 16px 0;
}
.syt-antd-table-pagination {
  display: flex;
}
.syt-antd-table-pagination-left {
  justify-content: flex-start;
}
.syt-antd-table-pagination-center {
  justify-content: center;
}
.syt-antd-table-pagination-right {
  justify-content: flex-end;
}
.syt-antd-table-thead th.syt-antd-table-column-has-sorters {
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-table-thead th.syt-antd-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.syt-antd-table-thead th.syt-antd-table-column-has-sorters:hover .syt-antd-table-filter-trigger-container {
  background: #f7f7f7;
}
.syt-antd-table-thead th.syt-antd-table-column-sort {
  background: #f5f5f5;
}
td.syt-antd-table-column-sort {
  background: #fafafa;
}
.syt-antd-table-column-sorters-with-tooltip {
  display: inline-block;
  width: 100%;
}
.syt-antd-table-column-sorters {
  display: inline-flex;
  align-items: center;
  padding: 16px 16px;
}
.syt-antd-table-column-sorter {
  margin-top: 0.15em;
  margin-bottom: -0.15em;
  margin-left: 8px;
  color: #bfbfbf;
}
.syt-antd-table-column-sorter-full {
  margin-top: -0.2em;
  margin-bottom: 0;
}
.syt-antd-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.syt-antd-table-column-sorter-up,
.syt-antd-table-column-sorter-down {
  font-size: 11px;
}
.syt-antd-table-column-sorter-up.active,
.syt-antd-table-column-sorter-down.active {
  color: #0071CD;
}
.syt-antd-table-column-sorter-up + .syt-antd-table-column-sorter-down {
  margin-top: -0.3em;
}
.syt-antd-table-filter-column {
  display: flex;
  align-items: center;
  margin: -16px -16px;
}
.syt-antd-table-filter-column-title {
  flex: auto;
  padding: 16px 2.3em 16px 16px;
}
.syt-antd-table-thead tr th.syt-antd-table-column-has-sorters .syt-antd-table-filter-column {
  margin: 0;
}
.syt-antd-table-thead tr th.syt-antd-table-column-has-sorters .syt-antd-table-filter-column-title {
  padding: 0 2.3em 0 0;
}
.syt-antd-table-filter-trigger-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: none;
  align-items: stretch;
  align-self: stretch;
  cursor: pointer;
  transition: background-color 0.3s;
}
.syt-antd-table-filter-trigger-container-open,
.syt-antd-table-filter-trigger-container:hover,
.syt-antd-table-thead th.syt-antd-table-column-has-sorters:hover .syt-antd-table-filter-trigger-container:hover {
  background: #e5e5e5;
}
.syt-antd-table-filter-trigger {
  display: block;
  width: 2.3em;
  color: #bfbfbf;
  font-size: 12px;
  transition: color 0.3s;
}
.syt-antd-table-filter-trigger .anticon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.syt-antd-table-filter-trigger-container-open .syt-antd-table-filter-trigger,
.syt-antd-table-filter-trigger:hover {
  color: #696F88;
}
.syt-antd-table-filter-trigger.active {
  color: #0071CD;
}
.syt-antd-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  min-width: 120px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
}
.syt-antd-table-filter-dropdown .syt-antd-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.syt-antd-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.syt-antd-table-filter-dropdown .syt-antd-checkbox-wrapper + span,
.syt-antd-table-filter-dropdown-submenu .syt-antd-checkbox-wrapper + span {
  padding-left: 8px;
}
.syt-antd-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px 7px 3px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
}
.syt-antd-table .syt-antd-table-selection-col {
  width: 60px;
}
table tr th.syt-antd-table-selection-column,
table tr td.syt-antd-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.syt-antd-table-selection-column .syt-antd-radio-wrapper,
table tr td.syt-antd-table-selection-column .syt-antd-radio-wrapper {
  margin-right: 0;
}
.syt-antd-table-selection {
  position: relative;
}
.syt-antd-table-selection-extra {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.syt-antd-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.syt-antd-table-expand-icon-col {
  width: 48px;
}
.syt-antd-table-row-expand-icon-cell {
  text-align: center;
}
.syt-antd-table-row-indent {
  float: left;
  height: 1px;
}
.syt-antd-table-row-expand-icon {
  color: #00a0be;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  display: inline-flex;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  user-select: none;
}
.syt-antd-table-row-expand-icon:focus,
.syt-antd-table-row-expand-icon:hover {
  color: #21b8cc;
}
.syt-antd-table-row-expand-icon:active {
  color: #007d99;
}
.syt-antd-table-row-expand-icon:focus,
.syt-antd-table-row-expand-icon:hover,
.syt-antd-table-row-expand-icon:active {
  border-color: currentColor;
}
.syt-antd-table-row-expand-icon::before,
.syt-antd-table-row-expand-icon::after {
  position: absolute;
  background: currentColor;
  transition: transform 0.3s ease-out;
  content: '';
}
.syt-antd-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.syt-antd-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.syt-antd-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.syt-antd-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.syt-antd-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.syt-antd-table-row-expand-icon-spaced::before,
.syt-antd-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.syt-antd-table-row-indent + .syt-antd-table-row-expand-icon {
  margin-top: 131.5px;
  margin-right: 8px;
}
tr.syt-antd-table-expanded-row > td,
tr.syt-antd-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.syt-antd-table-expanded-row .syt-antd-descriptions-view table {
  width: auto;
}
.syt-antd-table .syt-antd-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.syt-antd-table-tbody > tr.syt-antd-table-placeholder {
  text-align: center;
}
.syt-antd-table-empty .syt-antd-table-tbody > tr.syt-antd-table-placeholder {
  color: #c1c5c8;
}
.syt-antd-table-tbody > tr.syt-antd-table-placeholder:hover > td {
  background: #fff;
}
.syt-antd-table-cell-fix-left,
.syt-antd-table-cell-fix-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.syt-antd-table-cell-fix-left-first::after,
.syt-antd-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.syt-antd-table-cell-fix-right-first::after,
.syt-antd-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.syt-antd-table .syt-antd-table-container::before,
.syt-antd-table .syt-antd-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.syt-antd-table .syt-antd-table-container::before {
  left: 0;
}
.syt-antd-table .syt-antd-table-container::after {
  right: 0;
}
.syt-antd-table-ping-left:not(.syt-antd-table-has-fix-left) .syt-antd-table-container {
  position: relative;
}
.syt-antd-table-ping-left:not(.syt-antd-table-has-fix-left) .syt-antd-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.syt-antd-table-ping-left .syt-antd-table-cell-fix-left-first::after,
.syt-antd-table-ping-left .syt-antd-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.syt-antd-table-ping-right:not(.syt-antd-table-has-fix-right) .syt-antd-table-container {
  position: relative;
}
.syt-antd-table-ping-right:not(.syt-antd-table-has-fix-right) .syt-antd-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.syt-antd-table-ping-right .syt-antd-table-cell-fix-right-first::after,
.syt-antd-table-ping-right .syt-antd-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.syt-antd-table-sticky-header {
  position: sticky;
  z-index: calc(2 + 1);
}
.syt-antd-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  opacity: 0.6;
}
.syt-antd-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.syt-antd-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.syt-antd-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.syt-antd-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .syt-antd-table-ping-left .syt-antd-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .syt-antd-table-ping-right .syt-antd-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.syt-antd-table.syt-antd-table-middle {
  font-size: 14px;
}
.syt-antd-table.syt-antd-table-middle .syt-antd-table-title,
.syt-antd-table.syt-antd-table-middle .syt-antd-table-footer,
.syt-antd-table.syt-antd-table-middle .syt-antd-table-thead > tr > th,
.syt-antd-table.syt-antd-table-middle .syt-antd-table-tbody > tr > td,
.syt-antd-table.syt-antd-table-middle tfoot > tr > th,
.syt-antd-table.syt-antd-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.syt-antd-table.syt-antd-table-middle .syt-antd-table-thead th.syt-antd-table-column-has-sorters {
  padding: 0;
}
.syt-antd-table.syt-antd-table-middle .syt-antd-table-thead .syt-antd-table-filter-column {
  margin: -12px -8px;
}
.syt-antd-table.syt-antd-table-middle .syt-antd-table-thead .syt-antd-table-filter-column-title {
  padding: 12px 2.3em 12px 8px;
}
.syt-antd-table.syt-antd-table-middle .syt-antd-table-thead .syt-antd-table-column-sorters {
  padding: 12px 8px;
}
.syt-antd-table.syt-antd-table-middle .syt-antd-table-expanded-row-fixed {
  margin: -12px -8px;
}
.syt-antd-table.syt-antd-table-middle .syt-antd-table-tbody .syt-antd-table-wrapper:only-child .syt-antd-table {
  margin: -12px -8px -12px 25px;
}
.syt-antd-table.syt-antd-table-small {
  font-size: 14px;
}
.syt-antd-table.syt-antd-table-small .syt-antd-table-title,
.syt-antd-table.syt-antd-table-small .syt-antd-table-footer,
.syt-antd-table.syt-antd-table-small .syt-antd-table-thead > tr > th,
.syt-antd-table.syt-antd-table-small .syt-antd-table-tbody > tr > td,
.syt-antd-table.syt-antd-table-small tfoot > tr > th,
.syt-antd-table.syt-antd-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.syt-antd-table.syt-antd-table-small .syt-antd-table-thead th.syt-antd-table-column-has-sorters {
  padding: 0;
}
.syt-antd-table.syt-antd-table-small .syt-antd-table-thead .syt-antd-table-filter-column {
  margin: -8px -8px;
}
.syt-antd-table.syt-antd-table-small .syt-antd-table-thead .syt-antd-table-filter-column-title {
  padding: 8px 2.3em 8px 8px;
}
.syt-antd-table.syt-antd-table-small .syt-antd-table-thead .syt-antd-table-column-sorters {
  padding: 8px 8px;
}
.syt-antd-table.syt-antd-table-small .syt-antd-table-expanded-row-fixed {
  margin: -8px -8px;
}
.syt-antd-table.syt-antd-table-small .syt-antd-table-tbody .syt-antd-table-wrapper:only-child .syt-antd-table {
  margin: -8px -8px -8px 25px;
}
.syt-antd-table-small .syt-antd-table-thead > tr > th {
  background-color: #fafafa;
}
.syt-antd-table-small .syt-antd-table-selection-column {
  width: 46px;
  min-width: 46px;
}
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container {
  border: 1px solid #f0f0f0;
  border-right: 0;
  border-bottom: 0;
}
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-content > table > thead > tr > th,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-header > table > thead > tr > th,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-body > table > thead > tr > th,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-content > table > tbody > tr > td,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-header > table > tbody > tr > td,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-body > table > tbody > tr > td,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-content > table > tfoot > tr > th,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-header > table > tfoot > tr > th,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-body > table > tfoot > tr > th,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-content > table > tfoot > tr > td,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-header > table > tfoot > tr > td,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-body > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-content > table > thead > tr:not(:last-child) > th,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-header > table > thead > tr:not(:last-child) > th,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-body > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-content > table > thead > tr > .syt-antd-table-cell-fix-right-first::after,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-header > table > thead > tr > .syt-antd-table-cell-fix-right-first::after,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-body > table > thead > tr > .syt-antd-table-cell-fix-right-first::after,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-content > table > tbody > tr > .syt-antd-table-cell-fix-right-first::after,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-header > table > tbody > tr > .syt-antd-table-cell-fix-right-first::after,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-body > table > tbody > tr > .syt-antd-table-cell-fix-right-first::after,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-content > table > tfoot > tr > .syt-antd-table-cell-fix-right-first::after,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-header > table > tfoot > tr > .syt-antd-table-cell-fix-right-first::after,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-body > table > tfoot > tr > .syt-antd-table-cell-fix-right-first::after {
  border-right: 1px solid #f0f0f0;
}
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-content > table > tbody > tr > td > .syt-antd-table-expanded-row-fixed,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-header > table > tbody > tr > td > .syt-antd-table-expanded-row-fixed,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-body > table > tbody > tr > td > .syt-antd-table-expanded-row-fixed {
  margin: -16px -17px;
}
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-content > table > tbody > tr > td > .syt-antd-table-expanded-row-fixed::after,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-header > table > tbody > tr > td > .syt-antd-table-expanded-row-fixed::after,
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container > .syt-antd-table-body > table > tbody > tr > td > .syt-antd-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #f0f0f0;
  content: '';
}
.syt-antd-table.syt-antd-table-bordered.syt-antd-table-scroll-horizontal > .syt-antd-table-container > .syt-antd-table-body > table > tbody > tr.syt-antd-table-expanded-row > td,
.syt-antd-table.syt-antd-table-bordered.syt-antd-table-scroll-horizontal > .syt-antd-table-container > .syt-antd-table-body > table > tbody > tr.syt-antd-table-placeholder > td {
  border-right: 0;
}
.syt-antd-table.syt-antd-table-bordered.syt-antd-table-middle > .syt-antd-table-container > .syt-antd-table-content > table > tbody > tr > td > .syt-antd-table-expanded-row-fixed,
.syt-antd-table.syt-antd-table-bordered.syt-antd-table-middle > .syt-antd-table-container > .syt-antd-table-body > table > tbody > tr > td > .syt-antd-table-expanded-row-fixed {
  margin: -12px -9px;
}
.syt-antd-table.syt-antd-table-bordered.syt-antd-table-small > .syt-antd-table-container > .syt-antd-table-content > table > tbody > tr > td > .syt-antd-table-expanded-row-fixed,
.syt-antd-table.syt-antd-table-bordered.syt-antd-table-small > .syt-antd-table-container > .syt-antd-table-body > table > tbody > tr > td > .syt-antd-table-expanded-row-fixed {
  margin: -8px -9px;
}
.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}
.syt-antd-table-cell .syt-antd-table-container:first-child {
  border-top: 0;
}
.syt-antd-table-cell-scrollbar {
  box-shadow: 0 1px 0 1px #fafafa;
}
.syt-antd-table-wrapper {
  clear: both;
  max-width: 100%;
}
.syt-antd-table-wrapper::before {
  display: table;
  content: '';
}
.syt-antd-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  z-index: 0;
  font-size: 14px;
  background: #fff;
  border-radius: 4px;
}
.syt-antd-table table {
  width: 100%;
  text-align: left;
  border-radius: 4px 4px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.syt-antd-table-thead > tr > th,
.syt-antd-table-tbody > tr > td,
.syt-antd-table tfoot > tr > th,
.syt-antd-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.syt-antd-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.syt-antd-table-cell-ellipsis.syt-antd-table-cell-fix-left-last,
.syt-antd-table-cell-ellipsis.syt-antd-table-cell-fix-right-first {
  overflow: visible;
}
.syt-antd-table-cell-ellipsis.syt-antd-table-cell-fix-left-last .syt-antd-table-cell-content,
.syt-antd-table-cell-ellipsis.syt-antd-table-cell-fix-right-first .syt-antd-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.syt-antd-table-title {
  padding: 16px 16px;
}
.syt-antd-table-footer {
  padding: 16px 16px;
  color: #14151C;
  background: #fafafa;
}
.syt-antd-table-thead > tr > th {
  color: #14151C;
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}
.syt-antd-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.syt-antd-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.syt-antd-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}
.syt-antd-table-tbody > tr > td > .syt-antd-table-wrapper:only-child .syt-antd-table {
  margin: -16px -16px -16px 33px;
}
.syt-antd-table-tbody > tr > td > .syt-antd-table-wrapper:only-child .syt-antd-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.syt-antd-table-tbody > tr > td > .syt-antd-table-wrapper:only-child .syt-antd-table-tbody > tr:last-child > td:first-child,
.syt-antd-table-tbody > tr > td > .syt-antd-table-wrapper:only-child .syt-antd-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.syt-antd-table-tbody > tr.syt-antd-table-row:hover > td {
  background: #fafafa;
}
.syt-antd-table-tbody > tr.syt-antd-table-row-selected > td {
  background: #e6f8ff;
  border-color: rgba(0, 0, 0, 0.03);
}
.syt-antd-table-tbody > tr.syt-antd-table-row-selected:hover > td {
  background: #dcf5ff;
}
.syt-antd-table tfoot > tr > th,
.syt-antd-table tfoot > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-table-pagination.syt-antd-pagination {
  margin: 16px 0;
}
.syt-antd-table-pagination {
  display: flex;
}
.syt-antd-table-pagination-left {
  justify-content: flex-start;
}
.syt-antd-table-pagination-center {
  justify-content: center;
}
.syt-antd-table-pagination-right {
  justify-content: flex-end;
}
.syt-antd-table-thead th.syt-antd-table-column-has-sorters {
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-table-thead th.syt-antd-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.syt-antd-table-thead th.syt-antd-table-column-has-sorters:hover .syt-antd-table-filter-trigger-container {
  background: #f7f7f7;
}
.syt-antd-table-thead th.syt-antd-table-column-sort {
  background: #f5f5f5;
}
td.syt-antd-table-column-sort {
  background: #fafafa;
}
.syt-antd-table-column-sorters-with-tooltip {
  display: inline-block;
  width: 100%;
}
.syt-antd-table-column-sorters {
  display: inline-flex;
  align-items: center;
  padding: 16px 16px;
}
.syt-antd-table-column-sorter {
  margin-top: 0.15em;
  margin-bottom: -0.15em;
  margin-left: 8px;
  color: #bfbfbf;
}
.syt-antd-table-column-sorter-full {
  margin-top: -0.2em;
  margin-bottom: 0;
}
.syt-antd-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.syt-antd-table-column-sorter-up,
.syt-antd-table-column-sorter-down {
  font-size: 11px;
}
.syt-antd-table-column-sorter-up.active,
.syt-antd-table-column-sorter-down.active {
  color: #0071CD;
}
.syt-antd-table-column-sorter-up + .syt-antd-table-column-sorter-down {
  margin-top: -0.3em;
}
.syt-antd-table-filter-column {
  display: flex;
  align-items: center;
  margin: -16px -16px;
}
.syt-antd-table-filter-column-title {
  flex: auto;
  padding: 16px 2.3em 16px 16px;
}
.syt-antd-table-thead tr th.syt-antd-table-column-has-sorters .syt-antd-table-filter-column {
  margin: 0;
}
.syt-antd-table-thead tr th.syt-antd-table-column-has-sorters .syt-antd-table-filter-column-title {
  padding: 0 2.3em 0 0;
}
.syt-antd-table-filter-trigger-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: none;
  align-items: stretch;
  align-self: stretch;
  cursor: pointer;
  transition: background-color 0.3s;
}
.syt-antd-table-filter-trigger-container-open,
.syt-antd-table-filter-trigger-container:hover,
.syt-antd-table-thead th.syt-antd-table-column-has-sorters:hover .syt-antd-table-filter-trigger-container:hover {
  background: #e5e5e5;
}
.syt-antd-table-filter-trigger {
  display: block;
  width: 2.3em;
  color: #bfbfbf;
  font-size: 12px;
  transition: color 0.3s;
}
.syt-antd-table-filter-trigger .anticon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.syt-antd-table-filter-trigger-container-open .syt-antd-table-filter-trigger,
.syt-antd-table-filter-trigger:hover {
  color: #696F88;
}
.syt-antd-table-filter-trigger.active {
  color: #0071CD;
}
.syt-antd-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  min-width: 120px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
}
.syt-antd-table-filter-dropdown .syt-antd-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.syt-antd-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.syt-antd-table-filter-dropdown .syt-antd-checkbox-wrapper + span,
.syt-antd-table-filter-dropdown-submenu .syt-antd-checkbox-wrapper + span {
  padding-left: 8px;
}
.syt-antd-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px 7px 3px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
}
.syt-antd-table .syt-antd-table-selection-col {
  width: 60px;
}
table tr th.syt-antd-table-selection-column,
table tr td.syt-antd-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.syt-antd-table-selection-column .syt-antd-radio-wrapper,
table tr td.syt-antd-table-selection-column .syt-antd-radio-wrapper {
  margin-right: 0;
}
.syt-antd-table-selection {
  position: relative;
}
.syt-antd-table-selection-extra {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.syt-antd-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.syt-antd-table-expand-icon-col {
  width: 48px;
}
.syt-antd-table-row-expand-icon-cell {
  text-align: center;
}
.syt-antd-table-row-indent {
  float: left;
  height: 1px;
}
.syt-antd-table-row-expand-icon {
  color: #00a0be;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  display: inline-flex;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  user-select: none;
}
.syt-antd-table-row-expand-icon:focus,
.syt-antd-table-row-expand-icon:hover {
  color: #21b8cc;
}
.syt-antd-table-row-expand-icon:active {
  color: #007d99;
}
.syt-antd-table-row-expand-icon:focus,
.syt-antd-table-row-expand-icon:hover,
.syt-antd-table-row-expand-icon:active {
  border-color: currentColor;
}
.syt-antd-table-row-expand-icon::before,
.syt-antd-table-row-expand-icon::after {
  position: absolute;
  background: currentColor;
  transition: transform 0.3s ease-out;
  content: '';
}
.syt-antd-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.syt-antd-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.syt-antd-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.syt-antd-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.syt-antd-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.syt-antd-table-row-expand-icon-spaced::before,
.syt-antd-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.syt-antd-table-row-indent + .syt-antd-table-row-expand-icon {
  margin-top: 131.5px;
  margin-right: 8px;
}
tr.syt-antd-table-expanded-row > td,
tr.syt-antd-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.syt-antd-table-expanded-row .syt-antd-descriptions-view table {
  width: auto;
}
.syt-antd-table .syt-antd-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.syt-antd-table-tbody > tr.syt-antd-table-placeholder {
  text-align: center;
}
.syt-antd-table-empty .syt-antd-table-tbody > tr.syt-antd-table-placeholder {
  color: #c1c5c8;
}
.syt-antd-table-tbody > tr.syt-antd-table-placeholder:hover > td {
  background: #fff;
}
.syt-antd-table-cell-fix-left,
.syt-antd-table-cell-fix-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.syt-antd-table-cell-fix-left-first::after,
.syt-antd-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.syt-antd-table-cell-fix-right-first::after,
.syt-antd-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.syt-antd-table .syt-antd-table-container::before,
.syt-antd-table .syt-antd-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.syt-antd-table .syt-antd-table-container::before {
  left: 0;
}
.syt-antd-table .syt-antd-table-container::after {
  right: 0;
}
.syt-antd-table-ping-left:not(.syt-antd-table-has-fix-left) .syt-antd-table-container {
  position: relative;
}
.syt-antd-table-ping-left:not(.syt-antd-table-has-fix-left) .syt-antd-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.syt-antd-table-ping-left .syt-antd-table-cell-fix-left-first::after,
.syt-antd-table-ping-left .syt-antd-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.syt-antd-table-ping-right:not(.syt-antd-table-has-fix-right) .syt-antd-table-container {
  position: relative;
}
.syt-antd-table-ping-right:not(.syt-antd-table-has-fix-right) .syt-antd-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.syt-antd-table-ping-right .syt-antd-table-cell-fix-right-first::after,
.syt-antd-table-ping-right .syt-antd-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.syt-antd-table-sticky-header {
  position: sticky;
  z-index: calc(2 + 1);
}
.syt-antd-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  opacity: 0.6;
}
.syt-antd-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.syt-antd-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.syt-antd-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.syt-antd-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .syt-antd-table-ping-left .syt-antd-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .syt-antd-table-ping-right .syt-antd-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.syt-antd-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.syt-antd-table-title {
  border-radius: 4px 4px 0 0;
}
.syt-antd-table-title + .syt-antd-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.syt-antd-table-title + .syt-antd-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.syt-antd-table-title + .syt-antd-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.syt-antd-table-container {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.syt-antd-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 4px;
}
.syt-antd-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 4px;
}
.syt-antd-table-footer {
  border-radius: 0 0 4px 4px;
}
.syt-antd-table-wrapper-rtl {
  direction: rtl;
}
.syt-antd-table-rtl {
  direction: rtl;
}
.syt-antd-table-wrapper-rtl .syt-antd-table table {
  text-align: right;
}
.syt-antd-table-wrapper-rtl .syt-antd-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.syt-antd-table-wrapper-rtl .syt-antd-table-thead > tr > th {
  text-align: right;
}
.syt-antd-table-tbody > tr .syt-antd-table-wrapper:only-child .syt-antd-table.syt-antd-table-rtl {
  margin: -16px 33px -16px -16px;
}
.syt-antd-table-wrapper.syt-antd-table-wrapper-rtl .syt-antd-table-pagination-left {
  justify-content: flex-end;
}
.syt-antd-table-wrapper.syt-antd-table-wrapper-rtl .syt-antd-table-pagination-right {
  justify-content: flex-start;
}
.syt-antd-table-wrapper-rtl .syt-antd-table-column-sorter {
  margin-right: 8px;
  margin-left: 0;
}
.syt-antd-table-wrapper-rtl .syt-antd-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.syt-antd-table-rtl .syt-antd-table-thead tr th.syt-antd-table-column-has-sorters .syt-antd-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.syt-antd-table-wrapper-rtl .syt-antd-table-filter-trigger-container {
  right: auto;
  left: 0;
}
.syt-antd-dropdown-rtl .syt-antd-table-filter-dropdown .syt-antd-checkbox-wrapper + span,
.syt-antd-dropdown-rtl .syt-antd-table-filter-dropdown-submenu .syt-antd-checkbox-wrapper + span,
.syt-antd-dropdown-menu-submenu-rtl.syt-antd-table-filter-dropdown .syt-antd-checkbox-wrapper + span,
.syt-antd-dropdown-menu-submenu-rtl.syt-antd-table-filter-dropdown-submenu .syt-antd-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.syt-antd-table-wrapper-rtl .syt-antd-table-selection {
  text-align: center;
}
.syt-antd-table-wrapper-rtl .syt-antd-table-selection-extra {
  right: auto;
  left: 0;
}
.syt-antd-table-wrapper-rtl .syt-antd-table-row-indent {
  float: right;
}
.syt-antd-table-wrapper-rtl .syt-antd-table-row-expand-icon {
  float: right;
}
.syt-antd-table-wrapper-rtl .syt-antd-table-row-indent + .syt-antd-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-table-wrapper-rtl .syt-antd-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.syt-antd-table-wrapper-rtl .syt-antd-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.syt-antd-table-wrapper-rtl .syt-antd-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.syt-antd-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: stretch;
}
.syt-antd-transfer-disabled .syt-antd-transfer-list {
  background: #f5f5f5;
}
.syt-antd-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #0092e4;
  border-radius: 4px;
}
.syt-antd-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.syt-antd-transfer-list-search {
  padding-right: 24px;
  padding-left: 8px;
}
.syt-antd-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: #c1c5c8;
  line-height: 32px;
  text-align: center;
}
.syt-antd-transfer-list-search-action .anticon {
  color: #c1c5c8;
  transition: all 0.3s;
}
.syt-antd-transfer-list-search-action .anticon:hover {
  color: #696F88;
}
span.syt-antd-transfer-list-search-action {
  pointer-events: none;
}
.syt-antd-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: -121px 12px -120px;
  color: #14151C;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.syt-antd-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}
.syt-antd-transfer-list-header > * {
  flex: none;
}
.syt-antd-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.syt-antd-transfer-list-header-dropdown {
  font-size: 10px;
  transform: translateY(10%);
  cursor: pointer;
}
.syt-antd-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.syt-antd-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.syt-antd-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}
.syt-antd-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.syt-antd-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  overflow: hidden;
  line-height: 20px;
  transition: all 0.3s;
}
.syt-antd-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}
.syt-antd-transfer-list-content-item > * {
  flex: none;
}
.syt-antd-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-transfer-list-content-item-remove {
  color: #00a0be;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  color: #0092e4;
}
.syt-antd-transfer-list-content-item-remove:focus,
.syt-antd-transfer-list-content-item-remove:hover {
  color: #21b8cc;
}
.syt-antd-transfer-list-content-item-remove:active {
  color: #007d99;
}
.syt-antd-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}
.syt-antd-transfer-list-content-item-remove:hover {
  color: #21b8cc;
}
.syt-antd-transfer-list-content-item:not(.syt-antd-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.syt-antd-transfer-list-content-item:not(.syt-antd-transfer-list-content-item-disabled).syt-antd-transfer-list-content-item-checked:hover {
  background-color: #dcf5ff;
}
.syt-antd-transfer-list-content-show-remove .syt-antd-transfer-list-content-item:not(.syt-antd-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}
.syt-antd-transfer-list-content-item-checked {
  background-color: #e6f8ff;
}
.syt-antd-transfer-list-content-item-disabled {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}
.syt-antd-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: #c1c5c8;
  text-align: center;
}
.syt-antd-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}
.syt-antd-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle;
}
.syt-antd-transfer-operation .syt-antd-btn {
  display: block;
}
.syt-antd-transfer-operation .syt-antd-btn:first-child {
  margin-bottom: 4px;
}
.syt-antd-transfer-operation .syt-antd-btn .anticon {
  font-size: 12px;
}
.syt-antd-transfer .syt-antd-empty-image {
  max-height: -2px;
}
.syt-antd-transfer-customize-list .syt-antd-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}
.syt-antd-transfer-customize-list .syt-antd-table-wrapper .syt-antd-table-small {
  border: 0;
  border-radius: 0;
}
.syt-antd-transfer-customize-list .syt-antd-table-wrapper .syt-antd-table-small > .syt-antd-table-content > .syt-antd-table-body > table > .syt-antd-table-thead > tr > th {
  background: #fafafa;
}
.syt-antd-transfer-customize-list .syt-antd-table-wrapper .syt-antd-table-small > .syt-antd-table-content .syt-antd-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}
.syt-antd-transfer-customize-list .syt-antd-table-wrapper .syt-antd-table-small .syt-antd-table-body {
  margin: 0;
}
.syt-antd-transfer-customize-list .syt-antd-table-wrapper .syt-antd-table-pagination.syt-antd-pagination {
  margin: 16px 0 4px;
}
.syt-antd-transfer-customize-list .syt-antd-input[disabled] {
  background-color: transparent;
}
.syt-antd-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: stretch;
}
.syt-antd-transfer-disabled .syt-antd-transfer-list {
  background: #f5f5f5;
}
.syt-antd-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #0092e4;
  border-radius: 4px;
}
.syt-antd-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.syt-antd-transfer-list-search {
  padding-right: 24px;
  padding-left: 8px;
}
.syt-antd-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: #c1c5c8;
  line-height: 32px;
  text-align: center;
}
.syt-antd-transfer-list-search-action .anticon {
  color: #c1c5c8;
  transition: all 0.3s;
}
.syt-antd-transfer-list-search-action .anticon:hover {
  color: #696F88;
}
span.syt-antd-transfer-list-search-action {
  pointer-events: none;
}
.syt-antd-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: -121px 12px -120px;
  color: #14151C;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.syt-antd-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}
.syt-antd-transfer-list-header > * {
  flex: none;
}
.syt-antd-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.syt-antd-transfer-list-header-dropdown {
  font-size: 10px;
  transform: translateY(10%);
  cursor: pointer;
}
.syt-antd-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.syt-antd-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.syt-antd-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}
.syt-antd-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.syt-antd-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  overflow: hidden;
  line-height: 20px;
  transition: all 0.3s;
}
.syt-antd-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}
.syt-antd-transfer-list-content-item > * {
  flex: none;
}
.syt-antd-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-transfer-list-content-item-remove {
  color: #00a0be;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  color: #0092e4;
}
.syt-antd-transfer-list-content-item-remove:focus,
.syt-antd-transfer-list-content-item-remove:hover {
  color: #21b8cc;
}
.syt-antd-transfer-list-content-item-remove:active {
  color: #007d99;
}
.syt-antd-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}
.syt-antd-transfer-list-content-item-remove:hover {
  color: #21b8cc;
}
.syt-antd-transfer-list-content-item:not(.syt-antd-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.syt-antd-transfer-list-content-item:not(.syt-antd-transfer-list-content-item-disabled).syt-antd-transfer-list-content-item-checked:hover {
  background-color: #dcf5ff;
}
.syt-antd-transfer-list-content-show-remove .syt-antd-transfer-list-content-item:not(.syt-antd-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}
.syt-antd-transfer-list-content-item-checked {
  background-color: #e6f8ff;
}
.syt-antd-transfer-list-content-item-disabled {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}
.syt-antd-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: #c1c5c8;
  text-align: center;
}
.syt-antd-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}
.syt-antd-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle;
}
.syt-antd-transfer-operation .syt-antd-btn {
  display: block;
}
.syt-antd-transfer-operation .syt-antd-btn:first-child {
  margin-bottom: 4px;
}
.syt-antd-transfer-operation .syt-antd-btn .anticon {
  font-size: 12px;
}
.syt-antd-transfer .syt-antd-empty-image {
  max-height: -2px;
}
.syt-antd-transfer-rtl {
  direction: rtl;
}
.syt-antd-transfer-rtl .syt-antd-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}
.syt-antd-transfer-rtl .syt-antd-transfer-list-search-action {
  right: auto;
  left: 12px;
}
.syt-antd-transfer-rtl .syt-antd-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.syt-antd-transfer-rtl .syt-antd-transfer-list-header {
  right: 0;
  left: auto;
}
.syt-antd-transfer-rtl .syt-antd-transfer-list-header-title {
  text-align: left;
}
.syt-antd-transfer-rtl .syt-antd-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-transfer-rtl .syt-antd-transfer-list-pagination {
  text-align: left;
}
.syt-antd-transfer-rtl .syt-antd-transfer-list-footer {
  right: 0;
  left: auto;
}
.syt-antd-tree-treenode-leaf-last .syt-antd-tree-switcher-leaf-line::before {
  height: 14px !important;
}
.syt-antd-tree.syt-antd-tree-directory .syt-antd-tree-treenode {
  position: relative;
}
.syt-antd-tree.syt-antd-tree-directory .syt-antd-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}
.syt-antd-tree.syt-antd-tree-directory .syt-antd-tree-treenode:hover::before {
  background: #f5f5f5;
}
.syt-antd-tree.syt-antd-tree-directory .syt-antd-tree-treenode > * {
  z-index: 1;
}
.syt-antd-tree.syt-antd-tree-directory .syt-antd-tree-treenode .syt-antd-tree-switcher {
  transition: color 0.3s;
}
.syt-antd-tree.syt-antd-tree-directory .syt-antd-tree-treenode .syt-antd-tree-node-content-wrapper {
  border-radius: 0;
  user-select: none;
}
.syt-antd-tree.syt-antd-tree-directory .syt-antd-tree-treenode .syt-antd-tree-node-content-wrapper:hover {
  background: transparent;
}
.syt-antd-tree.syt-antd-tree-directory .syt-antd-tree-treenode .syt-antd-tree-node-content-wrapper.syt-antd-tree-node-selected {
  color: #fff;
  background: transparent;
}
.syt-antd-tree.syt-antd-tree-directory .syt-antd-tree-treenode-selected:hover::before,
.syt-antd-tree.syt-antd-tree-directory .syt-antd-tree-treenode-selected::before {
  background: #0071CD;
}
.syt-antd-tree.syt-antd-tree-directory .syt-antd-tree-treenode-selected .syt-antd-tree-switcher {
  color: #fff;
}
.syt-antd-tree.syt-antd-tree-directory .syt-antd-tree-treenode-selected .syt-antd-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.syt-antd-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.syt-antd-tree-checkbox-wrapper:hover .syt-antd-tree-checkbox-inner,
.syt-antd-tree-checkbox:hover .syt-antd-tree-checkbox-inner,
.syt-antd-tree-checkbox-input:focus + .syt-antd-tree-checkbox-inner {
  border-color: #0071CD;
}
.syt-antd-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #0071CD;
  border-radius: 4px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.syt-antd-tree-checkbox:hover::after,
.syt-antd-tree-checkbox-wrapper:hover .syt-antd-tree-checkbox::after {
  visibility: visible;
}
.syt-antd-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #0092e4;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}
.syt-antd-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.syt-antd-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.syt-antd-tree-checkbox-checked .syt-antd-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.syt-antd-tree-checkbox-checked .syt-antd-tree-checkbox-inner {
  background-color: #0071CD;
  border-color: #0071CD;
}
.syt-antd-tree-checkbox-disabled {
  cursor: not-allowed;
}
.syt-antd-tree-checkbox-disabled.syt-antd-tree-checkbox-checked .syt-antd-tree-checkbox-inner::after {
  border-color: #c1c5c8;
  animation-name: none;
}
.syt-antd-tree-checkbox-disabled .syt-antd-tree-checkbox-input {
  cursor: not-allowed;
}
.syt-antd-tree-checkbox-disabled .syt-antd-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #0092e4 !important;
}
.syt-antd-tree-checkbox-disabled .syt-antd-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.syt-antd-tree-checkbox-disabled + span {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-tree-checkbox-disabled:hover::after,
.syt-antd-tree-checkbox-wrapper:hover .syt-antd-tree-checkbox-disabled::after {
  visibility: hidden;
}
.syt-antd-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.syt-antd-tree-checkbox-wrapper.syt-antd-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.syt-antd-tree-checkbox-wrapper + .syt-antd-tree-checkbox-wrapper {
  margin-left: 8px;
}
.syt-antd-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.syt-antd-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.syt-antd-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.syt-antd-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.syt-antd-tree-checkbox-group-item + .syt-antd-tree-checkbox-group-item {
  margin-left: 0;
}
.syt-antd-tree-checkbox-indeterminate .syt-antd-tree-checkbox-inner {
  background-color: #fff;
  border-color: #0092e4;
}
.syt-antd-tree-checkbox-indeterminate .syt-antd-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #0071CD;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.syt-antd-tree-checkbox-indeterminate.syt-antd-tree-checkbox-disabled .syt-antd-tree-checkbox-inner::after {
  background-color: #c1c5c8;
  border-color: #c1c5c8;
}
.syt-antd-tree-checkbox-rtl {
  direction: rtl;
}
.syt-antd-tree-checkbox-group-rtl .syt-antd-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-tree-checkbox-group-rtl .syt-antd-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.syt-antd-tree-checkbox-group-rtl .syt-antd-tree-checkbox-group-item + .syt-antd-tree-checkbox-group-item {
  margin-left: 8px;
}
.syt-antd-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.syt-antd-tree-focused:not(:hover):not(.syt-antd-tree-active-focused) {
  background: #e6f8ff;
}
.syt-antd-tree-list-holder-inner {
  align-items: flex-start;
}
.syt-antd-tree.syt-antd-tree-block-node .syt-antd-tree-list-holder-inner {
  align-items: stretch;
}
.syt-antd-tree.syt-antd-tree-block-node .syt-antd-tree-list-holder-inner .syt-antd-tree-node-content-wrapper {
  flex: auto;
}
.syt-antd-tree .syt-antd-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.syt-antd-tree .syt-antd-tree-treenode-disabled .syt-antd-tree-node-content-wrapper {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-tree .syt-antd-tree-treenode-disabled .syt-antd-tree-node-content-wrapper:hover {
  background: transparent;
}
.syt-antd-tree .syt-antd-tree-treenode-active .syt-antd-tree-node-content-wrapper {
  background: #f5f5f5;
}
.syt-antd-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  user-select: none;
}
.syt-antd-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.syt-antd-tree .syt-antd-tree-switcher {
  flex: none;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
.syt-antd-tree .syt-antd-tree-switcher .syt-antd-tree-switcher-icon,
.syt-antd-tree .syt-antd-tree-switcher .syt-antd-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.syt-antd-tree .syt-antd-tree-switcher .syt-antd-tree-switcher-icon svg,
.syt-antd-tree .syt-antd-tree-switcher .syt-antd-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.syt-antd-tree .syt-antd-tree-switcher-noop {
  cursor: default;
}
.syt-antd-tree .syt-antd-tree-switcher_close .syt-antd-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.syt-antd-tree .syt-antd-tree-switcher-loading-icon {
  color: #0071CD;
}
.syt-antd-tree .syt-antd-tree-switcher-leaf-line {
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.syt-antd-tree .syt-antd-tree-switcher-leaf-line::before {
  position: absolute;
  height: 24px;
  margin-left: -1px;
  border-left: 1px solid #d9d9d9;
  content: ' ';
}
.syt-antd-tree .syt-antd-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  margin-left: -1px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.syt-antd-tree .syt-antd-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.syt-antd-tree .syt-antd-tree-node-content-wrapper {
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s;
}
.syt-antd-tree .syt-antd-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.syt-antd-tree .syt-antd-tree-node-content-wrapper.syt-antd-tree-node-selected {
  background-color: #a3e2ff;
}
.syt-antd-tree .syt-antd-tree-node-content-wrapper .syt-antd-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.syt-antd-tree .syt-antd-tree-node-content-wrapper .syt-antd-tree-iconEle:empty {
  display: none;
}
.syt-antd-tree-node-content-wrapper[draggable='true'] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  user-select: none;
}
.syt-antd-tree .syt-antd-tree-treenode.drag-over > [draggable] {
  color: white;
  background-color: #0071CD;
  opacity: 0.8;
}
.syt-antd-tree .syt-antd-tree-treenode.drag-over-gap-top > [draggable] {
  border-top-color: #0071CD;
}
.syt-antd-tree .syt-antd-tree-treenode.drag-over-gap-bottom > [draggable] {
  border-bottom-color: #0071CD;
}
.syt-antd-tree-show-line {
  /* Motion should hide line of measure */
}
.syt-antd-tree-show-line .syt-antd-tree-indent-unit {
  position: relative;
  height: 100%;
}
.syt-antd-tree-show-line .syt-antd-tree-indent-unit:first-child::after {
  position: absolute;
  top: calc(100% - 24px - 4px);
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #0092e4;
  content: '';
}
.syt-antd-tree-show-line .syt-antd-tree-indent-unit::before {
  position: absolute;
  top: calc(100% - 4px);
  right: -12px;
  bottom: -28px;
  border-right: 1px solid #0092e4;
  content: '';
}
.syt-antd-tree-show-line .syt-antd-tree-indent-unit-end::before,
.syt-antd-tree-show-line .syt-antd-tree-indent-unit-end-first-level::after {
  display: none;
}
.syt-antd-tree-show-line .syt-antd-tree-treenode-motion:not(.syt-antd-motion-collapse-leave):not(.syt-antd-motion-collapse-appear-active) .syt-antd-tree-indent-unit::after,
.syt-antd-tree-show-line .syt-antd-tree-treenode-motion:not(.syt-antd-motion-collapse-leave):not(.syt-antd-motion-collapse-appear-active) .syt-antd-tree-indent-unit::before {
  display: none;
}
.syt-antd-tree-show-line .syt-antd-tree-switcher {
  z-index: 1;
  background: #fff;
}
.syt-antd-tree-rtl {
  direction: rtl;
}
.syt-antd-tree .syt-antd-tree-treenode-rtl {
  direction: rtl;
}
.syt-antd-tree-rtl.syt-antd-tree .syt-antd-tree-switcher_close .syt-antd-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.syt-antd-tree-rtl.syt-antd-tree .syt-antd-tree-switcher-loading-icon {
  transform: scaleY(-1);
}
.syt-antd-tree-rtl.syt-antd-tree-show-line .syt-antd-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #0092e4;
}
.syt-antd-tree-rtl.syt-antd-tree .syt-antd-tree-checkbox {
  margin: 4px 0 0 8px;
}
.syt-antd-tree-select-dropdown-rtl .syt-antd-select-tree .syt-antd-select-tree-checkbox {
  margin: 4px 0 0 8px;
}
.syt-antd-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.syt-antd-select-tree-checkbox-wrapper:hover .syt-antd-select-tree-checkbox-inner,
.syt-antd-select-tree-checkbox:hover .syt-antd-select-tree-checkbox-inner,
.syt-antd-select-tree-checkbox-input:focus + .syt-antd-select-tree-checkbox-inner {
  border-color: #0071CD;
}
.syt-antd-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #0071CD;
  border-radius: 4px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.syt-antd-select-tree-checkbox:hover::after,
.syt-antd-select-tree-checkbox-wrapper:hover .syt-antd-select-tree-checkbox::after {
  visibility: visible;
}
.syt-antd-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #0092e4;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}
.syt-antd-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.syt-antd-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.syt-antd-select-tree-checkbox-checked .syt-antd-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.syt-antd-select-tree-checkbox-checked .syt-antd-select-tree-checkbox-inner {
  background-color: #0071CD;
  border-color: #0071CD;
}
.syt-antd-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.syt-antd-select-tree-checkbox-disabled.syt-antd-select-tree-checkbox-checked .syt-antd-select-tree-checkbox-inner::after {
  border-color: #c1c5c8;
  animation-name: none;
}
.syt-antd-select-tree-checkbox-disabled .syt-antd-select-tree-checkbox-input {
  cursor: not-allowed;
}
.syt-antd-select-tree-checkbox-disabled .syt-antd-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #0092e4 !important;
}
.syt-antd-select-tree-checkbox-disabled .syt-antd-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.syt-antd-select-tree-checkbox-disabled + span {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-select-tree-checkbox-disabled:hover::after,
.syt-antd-select-tree-checkbox-wrapper:hover .syt-antd-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.syt-antd-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.syt-antd-select-tree-checkbox-wrapper.syt-antd-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.syt-antd-select-tree-checkbox-wrapper + .syt-antd-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.syt-antd-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.syt-antd-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.syt-antd-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.syt-antd-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.syt-antd-select-tree-checkbox-group-item + .syt-antd-select-tree-checkbox-group-item {
  margin-left: 0;
}
.syt-antd-select-tree-checkbox-indeterminate .syt-antd-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #0092e4;
}
.syt-antd-select-tree-checkbox-indeterminate .syt-antd-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #0071CD;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.syt-antd-select-tree-checkbox-indeterminate.syt-antd-select-tree-checkbox-disabled .syt-antd-select-tree-checkbox-inner::after {
  background-color: #c1c5c8;
  border-color: #c1c5c8;
}
.syt-antd-select-tree-checkbox-rtl {
  direction: rtl;
}
.syt-antd-select-tree-checkbox-group-rtl .syt-antd-select-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.syt-antd-select-tree-checkbox-group-rtl .syt-antd-select-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.syt-antd-select-tree-checkbox-group-rtl .syt-antd-select-tree-checkbox-group-item + .syt-antd-select-tree-checkbox-group-item {
  margin-left: 8px;
}
.syt-antd-tree-select-dropdown {
  padding: 8px 4px 0;
}
.syt-antd-tree-select-dropdown-rtl {
  direction: rtl;
}
.syt-antd-tree-select-dropdown .syt-antd-select-tree {
  border-radius: 0;
}
.syt-antd-tree-select-dropdown .syt-antd-select-tree-list-holder-inner {
  align-items: stretch;
}
.syt-antd-tree-select-dropdown .syt-antd-select-tree-list-holder-inner .syt-antd-select-tree-treenode {
  padding-bottom: 8px;
}
.syt-antd-tree-select-dropdown .syt-antd-select-tree-list-holder-inner .syt-antd-select-tree-treenode .syt-antd-select-tree-node-content-wrapper {
  flex: auto;
}
.syt-antd-select-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.syt-antd-select-tree-focused:not(:hover):not(.syt-antd-select-tree-active-focused) {
  background: #e6f8ff;
}
.syt-antd-select-tree-list-holder-inner {
  align-items: flex-start;
}
.syt-antd-select-tree.syt-antd-select-tree-block-node .syt-antd-select-tree-list-holder-inner {
  align-items: stretch;
}
.syt-antd-select-tree.syt-antd-select-tree-block-node .syt-antd-select-tree-list-holder-inner .syt-antd-select-tree-node-content-wrapper {
  flex: auto;
}
.syt-antd-select-tree .syt-antd-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.syt-antd-select-tree .syt-antd-select-tree-treenode-disabled .syt-antd-select-tree-node-content-wrapper {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-select-tree .syt-antd-select-tree-treenode-disabled .syt-antd-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.syt-antd-select-tree .syt-antd-select-tree-treenode-active .syt-antd-select-tree-node-content-wrapper {
  background: #f5f5f5;
}
.syt-antd-select-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  user-select: none;
}
.syt-antd-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.syt-antd-select-tree .syt-antd-select-tree-switcher {
  flex: none;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
.syt-antd-select-tree .syt-antd-select-tree-switcher .syt-antd-tree-switcher-icon,
.syt-antd-select-tree .syt-antd-select-tree-switcher .syt-antd-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.syt-antd-select-tree .syt-antd-select-tree-switcher .syt-antd-tree-switcher-icon svg,
.syt-antd-select-tree .syt-antd-select-tree-switcher .syt-antd-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.syt-antd-select-tree .syt-antd-select-tree-switcher-noop {
  cursor: default;
}
.syt-antd-select-tree .syt-antd-select-tree-switcher_close .syt-antd-select-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.syt-antd-select-tree .syt-antd-select-tree-switcher-loading-icon {
  color: #0071CD;
}
.syt-antd-select-tree .syt-antd-select-tree-switcher-leaf-line {
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.syt-antd-select-tree .syt-antd-select-tree-switcher-leaf-line::before {
  position: absolute;
  height: 24px;
  margin-left: -1px;
  border-left: 1px solid #d9d9d9;
  content: ' ';
}
.syt-antd-select-tree .syt-antd-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  margin-left: -1px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.syt-antd-select-tree .syt-antd-select-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.syt-antd-select-tree .syt-antd-select-tree-node-content-wrapper {
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s;
}
.syt-antd-select-tree .syt-antd-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.syt-antd-select-tree .syt-antd-select-tree-node-content-wrapper.syt-antd-select-tree-node-selected {
  background-color: #a3e2ff;
}
.syt-antd-select-tree .syt-antd-select-tree-node-content-wrapper .syt-antd-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.syt-antd-select-tree .syt-antd-select-tree-node-content-wrapper .syt-antd-select-tree-iconEle:empty {
  display: none;
}
.syt-antd-select-tree-node-content-wrapper[draggable='true'] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  user-select: none;
}
.syt-antd-select-tree .syt-antd-select-tree-treenode.drag-over > [draggable] {
  color: white;
  background-color: #0071CD;
  opacity: 0.8;
}
.syt-antd-select-tree .syt-antd-select-tree-treenode.drag-over-gap-top > [draggable] {
  border-top-color: #0071CD;
}
.syt-antd-select-tree .syt-antd-select-tree-treenode.drag-over-gap-bottom > [draggable] {
  border-bottom-color: #0071CD;
}
.syt-antd-select-tree-show-line {
  /* Motion should hide line of measure */
}
.syt-antd-select-tree-show-line .syt-antd-select-tree-indent-unit {
  position: relative;
  height: 100%;
}
.syt-antd-select-tree-show-line .syt-antd-select-tree-indent-unit:first-child::after {
  position: absolute;
  top: calc(100% - 24px - 4px);
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #0092e4;
  content: '';
}
.syt-antd-select-tree-show-line .syt-antd-select-tree-indent-unit::before {
  position: absolute;
  top: calc(100% - 4px);
  right: -12px;
  bottom: -28px;
  border-right: 1px solid #0092e4;
  content: '';
}
.syt-antd-select-tree-show-line .syt-antd-select-tree-indent-unit-end::before,
.syt-antd-select-tree-show-line .syt-antd-select-tree-indent-unit-end-first-level::after {
  display: none;
}
.syt-antd-select-tree-show-line .syt-antd-select-tree-treenode-motion:not(.syt-antd-motion-collapse-leave):not(.syt-antd-motion-collapse-appear-active) .syt-antd-select-tree-indent-unit::after,
.syt-antd-select-tree-show-line .syt-antd-select-tree-treenode-motion:not(.syt-antd-motion-collapse-leave):not(.syt-antd-motion-collapse-appear-active) .syt-antd-select-tree-indent-unit::before {
  display: none;
}
.syt-antd-select-tree-show-line .syt-antd-select-tree-switcher {
  z-index: 1;
  background: #fff;
}
.syt-antd-tree-select-dropdown-rtl .syt-antd-select-tree .syt-antd-select-tree-switcher_close .syt-antd-select-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.syt-antd-tree-select-dropdown-rtl .syt-antd-select-tree .syt-antd-select-tree-switcher-loading-icon {
  transform: scaleY(-1);
}
.syt-antd-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  overflow: hidden;
}
.syt-antd-tabs > .syt-antd-tabs-nav,
.syt-antd-tabs > div > .syt-antd-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-list,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-operations,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-operations-hidden,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-more,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-more {
  position: relative;
  padding: -121px 16px;
  background: transparent;
  border: 0;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-more::after,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: '';
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-add,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-add {
  min-width: 40px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-add:hover,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-add:hover {
  color: #238dd9;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-add:active,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-add:active,
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-add:focus,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-add:focus {
  color: #0056a6;
}
.syt-antd-tabs-extra-content {
  flex: none;
}
.syt-antd-tabs-centered > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap:not([class*='syt-antd-tabs-nav-wrap-ping']),
.syt-antd-tabs-centered > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap:not([class*='syt-antd-tabs-nav-wrap-ping']) {
  justify-content: center;
}
.syt-antd-tabs-ink-bar {
  position: absolute;
  background: #0071CD;
  pointer-events: none;
}
.syt-antd-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0 32px 0 0;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.syt-antd-tabs-tab:last-of-type {
  margin-right: 0;
  margin-left: 0;
}
.syt-antd-tabs-tab-btn:focus,
.syt-antd-tabs-tab-remove:focus,
.syt-antd-tabs-tab-btn:active,
.syt-antd-tabs-tab-remove:active {
  color: #0056a6;
}
.syt-antd-tabs-tab-btn {
  outline: none;
}
.syt-antd-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: #696F88;
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-tabs-tab-remove:hover {
  color: #14151C;
}
.syt-antd-tabs-tab:hover {
  color: #238dd9;
}
.syt-antd-tabs-tab.syt-antd-tabs-tab-active .syt-antd-tabs-tab-btn {
  color: #0071CD;
  font-weight: 500;
}
.syt-antd-tabs-tab.syt-antd-tabs-tab-disabled {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-tabs-tab.syt-antd-tabs-tab-disabled .syt-antd-tabs-tab-btn:focus,
.syt-antd-tabs-tab.syt-antd-tabs-tab-disabled .syt-antd-tabs-tab-remove:focus,
.syt-antd-tabs-tab.syt-antd-tabs-tab-disabled .syt-antd-tabs-tab-btn:active,
.syt-antd-tabs-tab.syt-antd-tabs-tab-disabled .syt-antd-tabs-tab-remove:active {
  color: #c1c5c8;
}
.syt-antd-tabs-tab .syt-antd-tabs-tab-remove .anticon {
  margin: 0;
}
.syt-antd-tabs-tab .anticon {
  margin-right: 12px;
}
.syt-antd-tabs-content {
  display: flex;
  width: 100%;
}
.syt-antd-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.syt-antd-tabs-content-animated {
  transition: margin 0.3s;
}
.syt-antd-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: none;
}
.syt-antd-tabs-small > .syt-antd-tabs-nav .syt-antd-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}
.syt-antd-tabs-large > .syt-antd-tabs-nav .syt-antd-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}
.syt-antd-tabs-card.syt-antd-tabs-small > .syt-antd-tabs-nav .syt-antd-tabs-tab {
  padding: 6px 16px;
}
.syt-antd-tabs-card.syt-antd-tabs-large > .syt-antd-tabs-nav .syt-antd-tabs-tab {
  padding: 7px 16px 6px;
}
.syt-antd-tabs-rtl {
  direction: rtl;
}
.syt-antd-tabs-rtl .syt-antd-tabs-nav .syt-antd-tabs-tab {
  margin: 0 0 0 32px;
}
.syt-antd-tabs-rtl .syt-antd-tabs-nav .syt-antd-tabs-tab:last-of-type {
  margin-left: 0;
}
.syt-antd-tabs-rtl .syt-antd-tabs-nav .syt-antd-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}
.syt-antd-tabs-rtl .syt-antd-tabs-nav .syt-antd-tabs-tab .syt-antd-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}
.syt-antd-tabs-rtl .syt-antd-tabs-nav .syt-antd-tabs-tab .syt-antd-tabs-tab-remove .anticon {
  margin: 0;
}
.syt-antd-tabs-rtl.syt-antd-tabs-left > .syt-antd-tabs-nav {
  order: 1;
}
.syt-antd-tabs-rtl.syt-antd-tabs-left > .syt-antd-tabs-content-holder {
  order: 0;
}
.syt-antd-tabs-rtl.syt-antd-tabs-right > .syt-antd-tabs-nav {
  order: 0;
}
.syt-antd-tabs-rtl.syt-antd-tabs-right > .syt-antd-tabs-content-holder {
  order: 1;
}
.syt-antd-tabs-rtl.syt-antd-tabs-card.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-tab:not(:last-of-type),
.syt-antd-tabs-rtl.syt-antd-tabs-card.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-tab:not(:last-of-type),
.syt-antd-tabs-rtl.syt-antd-tabs-card.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-tab:not(:last-of-type),
.syt-antd-tabs-rtl.syt-antd-tabs-card.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-tab:not(:last-of-type) {
  margin-right: 0;
  margin-left: 2px;
}
.syt-antd-tabs-dropdown-rtl {
  direction: rtl;
}
.syt-antd-tabs-dropdown-rtl .syt-antd-tabs-dropdown-menu-item {
  text-align: right;
}
.syt-antd-tabs-top,
.syt-antd-tabs-bottom {
  flex-direction: column;
}
.syt-antd-tabs-top > .syt-antd-tabs-nav,
.syt-antd-tabs-bottom > .syt-antd-tabs-nav,
.syt-antd-tabs-top > div > .syt-antd-tabs-nav,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav {
  margin: 0 0 16px 0;
}
.syt-antd-tabs-top > .syt-antd-tabs-nav::before,
.syt-antd-tabs-bottom > .syt-antd-tabs-nav::before,
.syt-antd-tabs-top > div > .syt-antd-tabs-nav::before,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: '';
}
.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar,
.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar,
.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar {
  height: 2px;
}
.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar-animated,
.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar-animated,
.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar-animated,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}
.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px;
}
.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-left::before,
.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-left::before,
.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-left::before,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}
.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-right::after,
.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-right::after,
.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-right::after,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}
.syt-antd-tabs-top > .syt-antd-tabs-nav::before,
.syt-antd-tabs-top > div > .syt-antd-tabs-nav::before {
  bottom: 0;
}
.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar,
.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar {
  bottom: 0;
}
.syt-antd-tabs-bottom > .syt-antd-tabs-nav,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
.syt-antd-tabs-bottom > .syt-antd-tabs-nav::before,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav::before {
  top: 0;
}
.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar {
  top: 0;
}
.syt-antd-tabs-bottom > .syt-antd-tabs-content-holder,
.syt-antd-tabs-bottom > div > .syt-antd-tabs-content-holder {
  order: 0;
}
.syt-antd-tabs-left > .syt-antd-tabs-nav,
.syt-antd-tabs-right > .syt-antd-tabs-nav,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-tab,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-tab,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-tab,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-tab {
  margin: 0 0 16px 0;
  padding: 8px 24px;
  text-align: center;
}
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-tab:last-of-type,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-tab:last-of-type,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-tab:last-of-type,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-tab:last-of-type {
  margin-bottom: 0;
}
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap {
  flex-direction: column;
}
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px;
}
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-top::before,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-top::before,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-top::before,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-bottom::after,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-bottom::after,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-bottom::after,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap.syt-antd-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar {
  width: 2px;
}
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar-animated,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar-animated,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar-animated,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-nav-list,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-nav-list,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-list,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-list,
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-nav-operations,
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-nav-operations,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-operations,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-operations {
  flex-direction: column;
}
.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar,
.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar {
  right: 0;
}
.syt-antd-tabs-left > .syt-antd-tabs-content-holder,
.syt-antd-tabs-left > div > .syt-antd-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #f0f0f0;
}
.syt-antd-tabs-left > .syt-antd-tabs-content-holder > .syt-antd-tabs-content > .syt-antd-tabs-tabpane,
.syt-antd-tabs-left > div > .syt-antd-tabs-content-holder > .syt-antd-tabs-content > .syt-antd-tabs-tabpane {
  padding-left: 24px;
}
.syt-antd-tabs-right > .syt-antd-tabs-nav,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav {
  order: 1;
}
.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar,
.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar {
  left: 0;
}
.syt-antd-tabs-right > .syt-antd-tabs-content-holder,
.syt-antd-tabs-right > div > .syt-antd-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #f0f0f0;
}
.syt-antd-tabs-right > .syt-antd-tabs-content-holder > .syt-antd-tabs-content > .syt-antd-tabs-tabpane,
.syt-antd-tabs-right > div > .syt-antd-tabs-content-holder > .syt-antd-tabs-content > .syt-antd-tabs-tabpane {
  padding-right: 24px;
}
.syt-antd-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.syt-antd-tabs-dropdown-hidden {
  display: none;
}
.syt-antd-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
}
.syt-antd-tabs-dropdown-menu-item {
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: #14151C;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.syt-antd-tabs-dropdown-menu-item-disabled,
.syt-antd-tabs-dropdown-menu-item-disabled:hover {
  color: #c1c5c8;
  background: transparent;
  cursor: not-allowed;
}
.syt-antd-tabs-card > .syt-antd-tabs-nav .syt-antd-tabs-tab,
.syt-antd-tabs-card > div > .syt-antd-tabs-nav .syt-antd-tabs-tab {
  margin: 0;
  padding: -121px 16px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-tabs-card > .syt-antd-tabs-nav .syt-antd-tabs-tab-active,
.syt-antd-tabs-card > div > .syt-antd-tabs-nav .syt-antd-tabs-tab-active {
  color: #0071CD;
  background: #fff;
}
.syt-antd-tabs-card > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar,
.syt-antd-tabs-card > div > .syt-antd-tabs-nav .syt-antd-tabs-ink-bar {
  visibility: hidden;
}
.syt-antd-tabs-card.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-tab:not(:last-of-type),
.syt-antd-tabs-card.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-tab:not(:last-of-type),
.syt-antd-tabs-card.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-tab:not(:last-of-type),
.syt-antd-tabs-card.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-tab:not(:last-of-type) {
  margin-right: 2px;
}
.syt-antd-tabs-card.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-tab,
.syt-antd-tabs-card.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-tab {
  border-radius: 4px 4px 0 0;
}
.syt-antd-tabs-card.syt-antd-tabs-top > .syt-antd-tabs-nav .syt-antd-tabs-tab-active,
.syt-antd-tabs-card.syt-antd-tabs-top > div > .syt-antd-tabs-nav .syt-antd-tabs-tab-active {
  border-bottom-color: #fff;
}
.syt-antd-tabs-card.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-tab,
.syt-antd-tabs-card.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-tab {
  border-radius: 0 0 4px 4px;
}
.syt-antd-tabs-card.syt-antd-tabs-bottom > .syt-antd-tabs-nav .syt-antd-tabs-tab-active,
.syt-antd-tabs-card.syt-antd-tabs-bottom > div > .syt-antd-tabs-nav .syt-antd-tabs-tab-active {
  border-top-color: #fff;
}
.syt-antd-tabs-card.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-tab:not(:last-of-type),
.syt-antd-tabs-card.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-tab:not(:last-of-type),
.syt-antd-tabs-card.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-tab:not(:last-of-type),
.syt-antd-tabs-card.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-tab:not(:last-of-type) {
  margin-bottom: 2px;
}
.syt-antd-tabs-card.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-tab,
.syt-antd-tabs-card.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-tab {
  border-radius: 4px 0 0 4px;
}
.syt-antd-tabs-card.syt-antd-tabs-left > .syt-antd-tabs-nav .syt-antd-tabs-tab-active,
.syt-antd-tabs-card.syt-antd-tabs-left > div > .syt-antd-tabs-nav .syt-antd-tabs-tab-active {
  border-right-color: #fff;
}
.syt-antd-tabs-card.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-tab,
.syt-antd-tabs-card.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-tab {
  border-radius: 0 4px 4px 0;
}
.syt-antd-tabs-card.syt-antd-tabs-right > .syt-antd-tabs-nav .syt-antd-tabs-tab-active,
.syt-antd-tabs-card.syt-antd-tabs-right > div > .syt-antd-tabs-nav .syt-antd-tabs-tab-active {
  border-left-color: #fff;
}
.syt-antd-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  overflow: hidden;
}
.syt-antd-tabs > .syt-antd-tabs-nav,
.syt-antd-tabs > div > .syt-antd-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::before,
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-list,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-operations,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-operations-hidden,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-more,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-more {
  position: relative;
  padding: -121px 16px;
  background: transparent;
  border: 0;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-more::after,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: '';
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-add,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-add {
  min-width: 40px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-add:hover,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-add:hover {
  color: #238dd9;
}
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-add:active,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-add:active,
.syt-antd-tabs > .syt-antd-tabs-nav .syt-antd-tabs-nav-add:focus,
.syt-antd-tabs > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-add:focus {
  color: #0056a6;
}
.syt-antd-tabs-extra-content {
  flex: none;
}
.syt-antd-tabs-centered > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap:not([class*='syt-antd-tabs-nav-wrap-ping']),
.syt-antd-tabs-centered > div > .syt-antd-tabs-nav .syt-antd-tabs-nav-wrap:not([class*='syt-antd-tabs-nav-wrap-ping']) {
  justify-content: center;
}
.syt-antd-tabs-ink-bar {
  position: absolute;
  background: #0071CD;
  pointer-events: none;
}
.syt-antd-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0 32px 0 0;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.syt-antd-tabs-tab:last-of-type {
  margin-right: 0;
  margin-left: 0;
}
.syt-antd-tabs-tab-btn:focus,
.syt-antd-tabs-tab-remove:focus,
.syt-antd-tabs-tab-btn:active,
.syt-antd-tabs-tab-remove:active {
  color: #0056a6;
}
.syt-antd-tabs-tab-btn {
  outline: none;
}
.syt-antd-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: #696F88;
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-tabs-tab-remove:hover {
  color: #14151C;
}
.syt-antd-tabs-tab:hover {
  color: #238dd9;
}
.syt-antd-tabs-tab.syt-antd-tabs-tab-active .syt-antd-tabs-tab-btn {
  color: #0071CD;
  font-weight: 500;
}
.syt-antd-tabs-tab.syt-antd-tabs-tab-disabled {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-tabs-tab.syt-antd-tabs-tab-disabled .syt-antd-tabs-tab-btn:focus,
.syt-antd-tabs-tab.syt-antd-tabs-tab-disabled .syt-antd-tabs-tab-remove:focus,
.syt-antd-tabs-tab.syt-antd-tabs-tab-disabled .syt-antd-tabs-tab-btn:active,
.syt-antd-tabs-tab.syt-antd-tabs-tab-disabled .syt-antd-tabs-tab-remove:active {
  color: #c1c5c8;
}
.syt-antd-tabs-tab .syt-antd-tabs-tab-remove .anticon {
  margin: 0;
}
.syt-antd-tabs-tab .anticon {
  margin-right: 12px;
}
.syt-antd-tabs-content {
  display: flex;
  width: 100%;
}
.syt-antd-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.syt-antd-tabs-content-animated {
  transition: margin 0.3s;
}
.syt-antd-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: none;
}
.syt-antd-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #0092e4;
  border-radius: 4px;
  cursor: default;
  opacity: 1;
  transition: all 0.3s;
}
.syt-antd-tag:hover {
  opacity: 0.85;
}
.syt-antd-tag,
.syt-antd-tag a,
.syt-antd-tag a:hover {
  color: #14151C;
}
.syt-antd-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.syt-antd-tag-close-icon {
  margin-left: 3px;
  color: #696F88;
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-tag-close-icon:hover {
  color: #14151C;
}
.syt-antd-tag-has-color {
  border-color: transparent;
}
.syt-antd-tag-has-color,
.syt-antd-tag-has-color a,
.syt-antd-tag-has-color a:hover,
.syt-antd-tag-has-color .anticon-close,
.syt-antd-tag-has-color .anticon-close:hover {
  color: #FFFFFF;
}
.syt-antd-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.syt-antd-tag-checkable:not(.syt-antd-tag-checkable-checked):hover {
  color: #0071CD;
}
.syt-antd-tag-checkable:active,
.syt-antd-tag-checkable-checked {
  color: #FFFFFF;
}
.syt-antd-tag-checkable-checked {
  background-color: #0071CD;
}
.syt-antd-tag-checkable:active {
  background-color: #0056a6;
}
.syt-antd-tag-hidden {
  display: none;
}
.syt-antd-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.syt-antd-tag-pink-inverse {
  color: #FFFFFF;
  background: #eb2f96;
  border-color: #eb2f96;
}
.syt-antd-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.syt-antd-tag-magenta-inverse {
  color: #FFFFFF;
  background: #eb2f96;
  border-color: #eb2f96;
}
.syt-antd-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.syt-antd-tag-red-inverse {
  color: #FFFFFF;
  background: #f5222d;
  border-color: #f5222d;
}
.syt-antd-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
.syt-antd-tag-volcano-inverse {
  color: #FFFFFF;
  background: #fa541c;
  border-color: #fa541c;
}
.syt-antd-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.syt-antd-tag-orange-inverse {
  color: #FFFFFF;
  background: #fa8c16;
  border-color: #fa8c16;
}
.syt-antd-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f;
}
.syt-antd-tag-yellow-inverse {
  color: #FFFFFF;
  background: #fadb14;
  border-color: #fadb14;
}
.syt-antd-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.syt-antd-tag-gold-inverse {
  color: #FFFFFF;
  background: #faad14;
  border-color: #faad14;
}
.syt-antd-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.syt-antd-tag-cyan-inverse {
  color: #FFFFFF;
  background: #13c2c2;
  border-color: #13c2c2;
}
.syt-antd-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f;
}
.syt-antd-tag-lime-inverse {
  color: #FFFFFF;
  background: #a0d911;
  border-color: #a0d911;
}
.syt-antd-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.syt-antd-tag-green-inverse {
  color: #FFFFFF;
  background: #52c41a;
  border-color: #52c41a;
}
.syt-antd-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.syt-antd-tag-blue-inverse {
  color: #FFFFFF;
  background: #1890ff;
  border-color: #1890ff;
}
.syt-antd-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.syt-antd-tag-geekblue-inverse {
  color: #FFFFFF;
  background: #2f54eb;
  border-color: #2f54eb;
}
.syt-antd-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.syt-antd-tag-purple-inverse {
  color: #FFFFFF;
  background: #722ed1;
  border-color: #722ed1;
}
.syt-antd-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.syt-antd-tag-processing {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.syt-antd-tag-error {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.syt-antd-tag-warning {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.syt-antd-tag > .anticon + span,
.syt-antd-tag > span + .anticon {
  margin-left: 7px;
}
.syt-antd-tag.syt-antd-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.syt-antd-tag-rtl .syt-antd-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.syt-antd-tag-rtl.syt-antd-tag > .anticon + span,
.syt-antd-tag-rtl.syt-antd-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}
span.syt-antd-tag {
  height: 16px;
  left: 4px;
  right: 4px;
  top: calc(50% - 16px/2);
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.0075em;
  text-transform: uppercase;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  order: 1;
  align-items: center;
  padding: 0px 4px;
  color: #4D5165;
  background: #DFE2E7;
  border: 1px solid #DFE2E7;
  box-sizing: border-box;
  border-radius: 4px;
}
span.syt-antd-tag.bold {
  color: #FFFFFF;
  background: #696F88;
  border: 1px solid #696F88;
}
span.syt-antd-tag:hover {
  opacity: 1 !important;
}
span.syt-antd-tag.closable:hover {
  border: 1px solid #C2C7D0;
}
span.syt-antd-tag:focus {
  outline: none;
}
span.syt-antd-tag.closable:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
}
span.syt-antd-tag.syt-antd-tag-green {
  color: #0C612C;
  background: #C3EAD1;
  border: 1px solid #C3EAD1;
}
span.syt-antd-tag.syt-antd-tag-green.bold {
  color: #FFFFFF;
  background: #14803C;
  border: 1px solid #14803C;
}
span.syt-antd-tag.syt-antd-tag-blue {
  color: #005693;
  background: #C6E6FF;
  border: 1px solid #C6E6FF;
}
span.syt-antd-tag.syt-antd-tag-blue.bold {
  color: #FFFFFF;
  background: #0071CD;
  border: 1px solid #0071CD;
}
span.syt-antd-tag.syt-antd-tag-red {
  color: #9C2628;
  background: #FFD9D9;
  border: 1px solid #FFD9D9;
}
span.syt-antd-tag.syt-antd-tag-red.bold {
  color: #FFFFFF;
  background: #CF3537;
  border: 1px solid #CF3537;
}
span.syt-antd-tag.syt-antd-tag-orange {
  color: #92331E;
  background: #FFDACE;
  border: 1px solid #FFDACE;
}
span.syt-antd-tag.syt-antd-tag-orange.bold {
  color: #FFFFFF;
  background: #C1462B;
  border: 1px solid #C1462B;
}
span.syt-antd-tag.syt-antd-tag-yellow {
  color: #744A0B;
  background: #FFE4AE;
  border: 1px solid #FFE4AE;
}
span.syt-antd-tag.syt-antd-tag-yellow.bold {
  color: #FFFFFF;
  background: #9A6412;
  border: 1px solid #9A6412;
}
span.syt-antd-tag[style*="background-color: teal;"] {
  color: #165C56;
  background: #A9EFE8 !important;
  border: 1px solid #A9EFE8;
}
span.syt-antd-tag[style*="background-color: teal;"].bold {
  color: #FFFFFF;
  background: #217C74 !important;
  border: 1px solid #217C74;
}
span.syt-antd-tag.syt-antd-tag-purple {
  color: #643AAA;
  background: #E7DDFC;
  border: 1px solid #E7DDFC;
}
span.syt-antd-tag.syt-antd-tag-purple.bold {
  color: #FFFFFF;
  background: #8354D6;
  border: 1px solid #8354D6;
}
span.syt-antd-tag.syt-antd-tag-pink {
  color: #8D3545;
  background: #FFD8DF;
  border: 1px solid #FFD8DF;
}
span.syt-antd-tag.syt-antd-tag-pink.bold {
  color: #FFFFFF;
  background: #BB485D;
  border: 1px solid #BB485D;
}
.syt-antd-tag-close-icon svg {
  width: 10px;
  height: 10px;
  border-radius: 4px;
}
.syt-antd-tag-close-icon svg:hover {
  background: #C2C7D0;
}
span.syt-antd-tag.syt-antd-tag-checkable:not(.syt-antd-tag-checkable-checked) {
  background: #FFFFFF;
  border: 1px solid #C2C7D0;
  color: #14151C;
  nav-index: 1;
  tab-index: 1;
}
span.syt-antd-tag.syt-antd-tag-checkable:not(.syt-antd-tag-checkable-checked):hover,
span.syt-antd-tag.syt-antd-tag-checkable:not(.syt-antd-tag-checkable-checked):focus {
  color: #0092E4 !important;
}
span.syt-antd-tag.syt-antd-tag-checkable:not(.syt-antd-tag-checkable-checked):focus {
  color: #FFFFFF;
  outline: none;
  box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25) !important;
}
span.syt-antd-tag.syt-antd-tag-checkable:not(.syt-antd-tag-checkable-checked):active {
  color: #FFFFFF !important;
  background: #005693 !important;
  border: 1px solid #005693 !important;
}
.cws-tag-focus-wrapper {
  display: inline-block;
}
span.syt-antd-tag.syt-antd-tag-checkable.syt-antd-tag-checkable-checked {
  background: #0071CD;
  border: 1px solid #0071CD;
  color: #FFFFFF;
}
span.syt-antd-tag.syt-antd-tag-checkable.syt-antd-tag-checkable-checked:hover,
span.syt-antd-tag.syt-antd-tag-checkable.syt-antd-tag-checkable-checked:focus {
  background: #0092E4 !important;
  border: 1px solid #0092E4 !important;
}
span.syt-antd-tag.syt-antd-tag-checkable.syt-antd-tag-checkable-checked:focus {
  box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25) !important;
}
span.syt-antd-tag.syt-antd-tag-checkable.syt-antd-tag-checkable-checked:active {
  background: #005693 !important;
  border: 1px solid #005693 !important;
}
.syt-antd-timeline {
  box-sizing: border-box;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  font-feature-settings: 'tnum';
  margin: 0;
  padding: 0;
  list-style: none;
}
.syt-antd-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
}
.syt-antd-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #f0f0f0;
}
.syt-antd-timeline-item-pending .syt-antd-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.syt-antd-timeline-item-pending .syt-antd-timeline-item-tail {
  display: none;
}
.syt-antd-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.syt-antd-timeline-item-head-blue {
  color: #0071CD;
  border-color: #0071CD;
}
.syt-antd-timeline-item-head-red {
  color: #CF3537;
  border-color: #CF3537;
}
.syt-antd-timeline-item-head-green {
  color: #aab400;
  border-color: #aab400;
}
.syt-antd-timeline-item-head-gray {
  color: #c1c5c8;
  border-color: #c1c5c8;
}
.syt-antd-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.syt-antd-timeline-item-content {
  position: relative;
  top: -265px;
  margin: 0 0 0 26px;
  word-break: break-word;
}
.syt-antd-timeline-item-last > .syt-antd-timeline-item-tail {
  display: none;
}
.syt-antd-timeline-item-last > .syt-antd-timeline-item-content {
  min-height: 48px;
}
.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-tail,
.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-tail,
.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-tail,
.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-head,
.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-head,
.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-head,
.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-head-custom,
.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-head-custom,
.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-head-custom {
  left: 50%;
}
.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-head,
.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-head,
.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-head {
  margin-left: -4px;
}
.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-head-custom,
.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-head-custom,
.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-head-custom {
  margin-left: 1px;
}
.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-left .syt-antd-timeline-item-content,
.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-left .syt-antd-timeline-item-content,
.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-left .syt-antd-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-right .syt-antd-timeline-item-content,
.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-right .syt-antd-timeline-item-content,
.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-right .syt-antd-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-right .syt-antd-timeline-item-tail,
.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-right .syt-antd-timeline-item-head,
.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-right .syt-antd-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-right .syt-antd-timeline-item-content {
  width: calc(100% - 18px);
}
.syt-antd-timeline.syt-antd-timeline-pending .syt-antd-timeline-item-last .syt-antd-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #f0f0f0;
}
.syt-antd-timeline.syt-antd-timeline-reverse .syt-antd-timeline-item-last .syt-antd-timeline-item-tail {
  display: none;
}
.syt-antd-timeline.syt-antd-timeline-reverse .syt-antd-timeline-item-pending .syt-antd-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #f0f0f0;
}
.syt-antd-timeline.syt-antd-timeline-reverse .syt-antd-timeline-item-pending .syt-antd-timeline-item-content {
  min-height: 48px;
}
.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-label {
  position: absolute;
  top: -265px;
  width: calc(50% - 12px);
  text-align: right;
}
.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-right .syt-antd-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left;
}
.syt-antd-timeline-rtl {
  direction: rtl;
}
.syt-antd-timeline-rtl .syt-antd-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid #f0f0f0;
  border-left: none;
}
.syt-antd-timeline-rtl .syt-antd-timeline-item-head-custom {
  right: 5px;
  left: auto;
  transform: translate(50%, -50%);
}
.syt-antd-timeline-rtl .syt-antd-timeline-item-content {
  margin: 0 18px 0 0;
}
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-tail,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-tail,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-tail,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-head,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-head,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-head,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-head-custom,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-head-custom,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-head-custom {
  right: 50%;
  left: auto;
}
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-head,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-head,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-head {
  margin-right: -4px;
  margin-left: 0;
}
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-head-custom,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-head-custom,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0;
}
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-left .syt-antd-timeline-item-content,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-left .syt-antd-timeline-item-content,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-left .syt-antd-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right;
}
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-alternate .syt-antd-timeline-item-right .syt-antd-timeline-item-content,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-right .syt-antd-timeline-item-content,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-right .syt-antd-timeline-item-content {
  text-align: left;
}
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-right .syt-antd-timeline-item-tail,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-right .syt-antd-timeline-item-head,
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-right .syt-antd-timeline-item-head-custom {
  right: 0;
  left: auto;
}
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-right .syt-antd-timeline-item-right .syt-antd-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right;
}
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-pending .syt-antd-timeline-item-last .syt-antd-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-reverse .syt-antd-timeline-item-pending .syt-antd-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-label {
  text-align: left;
}
.syt-antd-timeline-rtl.syt-antd-timeline.syt-antd-timeline-label .syt-antd-timeline-item-right .syt-antd-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right;
}
.syt-antd-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1070;
  display: block;
  max-width: 250px;
  visibility: visible;
}
.syt-antd-tooltip-hidden {
  display: none;
}
.syt-antd-tooltip-placement-top,
.syt-antd-tooltip-placement-topLeft,
.syt-antd-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.syt-antd-tooltip-placement-right,
.syt-antd-tooltip-placement-rightTop,
.syt-antd-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.syt-antd-tooltip-placement-bottom,
.syt-antd-tooltip-placement-bottomLeft,
.syt-antd-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.syt-antd-tooltip-placement-left,
.syt-antd-tooltip-placement-leftTop,
.syt-antd-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.syt-antd-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
}
.syt-antd-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.syt-antd-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto;
}
.syt-antd-tooltip-placement-top .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-topLeft .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-topRight .syt-antd-tooltip-arrow {
  bottom: -5.07106781px;
}
.syt-antd-tooltip-placement-top .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-topLeft .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-topRight .syt-antd-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-6.53553391px) rotate(45deg);
}
.syt-antd-tooltip-placement-top .syt-antd-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.syt-antd-tooltip-placement-topLeft .syt-antd-tooltip-arrow {
  left: 13px;
}
.syt-antd-tooltip-placement-topRight .syt-antd-tooltip-arrow {
  right: 13px;
}
.syt-antd-tooltip-placement-right .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-rightTop .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-rightBottom .syt-antd-tooltip-arrow {
  left: -5.07106781px;
}
.syt-antd-tooltip-placement-right .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-rightTop .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-rightBottom .syt-antd-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(6.53553391px) rotate(45deg);
}
.syt-antd-tooltip-placement-right .syt-antd-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.syt-antd-tooltip-placement-rightTop .syt-antd-tooltip-arrow {
  top: 5px;
}
.syt-antd-tooltip-placement-rightBottom .syt-antd-tooltip-arrow {
  bottom: 5px;
}
.syt-antd-tooltip-placement-left .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-leftTop .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-leftBottom .syt-antd-tooltip-arrow {
  right: -5.07106781px;
}
.syt-antd-tooltip-placement-left .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-leftTop .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-leftBottom .syt-antd-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-6.53553391px) rotate(45deg);
}
.syt-antd-tooltip-placement-left .syt-antd-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.syt-antd-tooltip-placement-leftTop .syt-antd-tooltip-arrow {
  top: 5px;
}
.syt-antd-tooltip-placement-leftBottom .syt-antd-tooltip-arrow {
  bottom: 5px;
}
.syt-antd-tooltip-placement-bottom .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-bottomLeft .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-bottomRight .syt-antd-tooltip-arrow {
  top: -5.07106781px;
}
.syt-antd-tooltip-placement-bottom .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-bottomLeft .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-bottomRight .syt-antd-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(6.53553391px) rotate(45deg);
}
.syt-antd-tooltip-placement-bottom .syt-antd-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.syt-antd-tooltip-placement-bottomLeft .syt-antd-tooltip-arrow {
  left: 13px;
}
.syt-antd-tooltip-placement-bottomRight .syt-antd-tooltip-arrow {
  right: 13px;
}
.syt-antd-tooltip-pink .syt-antd-tooltip-inner {
  background-color: #eb2f96;
}
.syt-antd-tooltip-pink .syt-antd-tooltip-arrow-content {
  background-color: #eb2f96;
}
.syt-antd-tooltip-magenta .syt-antd-tooltip-inner {
  background-color: #eb2f96;
}
.syt-antd-tooltip-magenta .syt-antd-tooltip-arrow-content {
  background-color: #eb2f96;
}
.syt-antd-tooltip-red .syt-antd-tooltip-inner {
  background-color: #f5222d;
}
.syt-antd-tooltip-red .syt-antd-tooltip-arrow-content {
  background-color: #f5222d;
}
.syt-antd-tooltip-volcano .syt-antd-tooltip-inner {
  background-color: #fa541c;
}
.syt-antd-tooltip-volcano .syt-antd-tooltip-arrow-content {
  background-color: #fa541c;
}
.syt-antd-tooltip-orange .syt-antd-tooltip-inner {
  background-color: #fa8c16;
}
.syt-antd-tooltip-orange .syt-antd-tooltip-arrow-content {
  background-color: #fa8c16;
}
.syt-antd-tooltip-yellow .syt-antd-tooltip-inner {
  background-color: #fadb14;
}
.syt-antd-tooltip-yellow .syt-antd-tooltip-arrow-content {
  background-color: #fadb14;
}
.syt-antd-tooltip-gold .syt-antd-tooltip-inner {
  background-color: #faad14;
}
.syt-antd-tooltip-gold .syt-antd-tooltip-arrow-content {
  background-color: #faad14;
}
.syt-antd-tooltip-cyan .syt-antd-tooltip-inner {
  background-color: #13c2c2;
}
.syt-antd-tooltip-cyan .syt-antd-tooltip-arrow-content {
  background-color: #13c2c2;
}
.syt-antd-tooltip-lime .syt-antd-tooltip-inner {
  background-color: #a0d911;
}
.syt-antd-tooltip-lime .syt-antd-tooltip-arrow-content {
  background-color: #a0d911;
}
.syt-antd-tooltip-green .syt-antd-tooltip-inner {
  background-color: #52c41a;
}
.syt-antd-tooltip-green .syt-antd-tooltip-arrow-content {
  background-color: #52c41a;
}
.syt-antd-tooltip-blue .syt-antd-tooltip-inner {
  background-color: #1890ff;
}
.syt-antd-tooltip-blue .syt-antd-tooltip-arrow-content {
  background-color: #1890ff;
}
.syt-antd-tooltip-geekblue .syt-antd-tooltip-inner {
  background-color: #2f54eb;
}
.syt-antd-tooltip-geekblue .syt-antd-tooltip-arrow-content {
  background-color: #2f54eb;
}
.syt-antd-tooltip-purple .syt-antd-tooltip-inner {
  background-color: #722ed1;
}
.syt-antd-tooltip-purple .syt-antd-tooltip-arrow-content {
  background-color: #722ed1;
}
.syt-antd-tooltip-rtl {
  direction: rtl;
}
.syt-antd-tooltip-rtl .syt-antd-tooltip-inner {
  text-align: right;
}
.syt-antd-typography,
h1.cwsTypography,
h2.cwsTypography,
h3.cwsTypography,
h4.cwsTypography,
span.cwsTypography,
div.cwsTypography {
  color: #14151C;
  overflow-wrap: break-word;
}
.syt-antd-typography.syt-antd-typography-secondary {
  color: #696F88;
}
.syt-antd-typography.syt-antd-typography-success {
  color: #aab400;
}
.syt-antd-typography.syt-antd-typography-warning {
  color: #ffca41;
}
.syt-antd-typography.syt-antd-typography-danger {
  color: #CF3537;
}
a.syt-antd-typography.syt-antd-typography-danger:active,
a.syt-antd-typography.syt-antd-typography-danger:focus,
a.syt-antd-typography.syt-antd-typography-danger:hover {
  color: #db5e5c;
}
.syt-antd-typography.syt-antd-typography-disabled {
  color: #c1c5c8;
  cursor: not-allowed;
  user-select: none;
}
div.syt-antd-typography,
.syt-antd-typography p {
  margin-bottom: 1em;
}
h1.syt-antd-typography,
.syt-antd-typography h1 {
  margin-bottom: 0.5em;
  color: #14151C;
  font-weight: 600;
  font-size: 36px;
  line-height: 1.23;
}
h2.syt-antd-typography,
.syt-antd-typography h2 {
  margin-bottom: 0.5em;
  color: #14151C;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.syt-antd-typography,
.syt-antd-typography h3 {
  margin-bottom: 0.5em;
  color: #14151C;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.syt-antd-typography,
.syt-antd-typography h4 {
  margin-bottom: 0.5em;
  color: #14151C;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
h5.syt-antd-typography,
.syt-antd-typography h5 {
  margin-bottom: 0.5em;
  color: #14151C;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.syt-antd-typography + h1.syt-antd-typography,
.syt-antd-typography + h2.syt-antd-typography,
.syt-antd-typography + h3.syt-antd-typography,
.syt-antd-typography + h4.syt-antd-typography,
.syt-antd-typography + h5.syt-antd-typography {
  margin-top: 1.2em;
}
.syt-antd-typography div + h1,
.syt-antd-typography ul + h1,
.syt-antd-typography li + h1,
.syt-antd-typography p + h1,
.syt-antd-typography h1 + h1,
.syt-antd-typography h2 + h1,
.syt-antd-typography h3 + h1,
.syt-antd-typography h4 + h1,
.syt-antd-typography h5 + h1,
.syt-antd-typography div + h2,
.syt-antd-typography ul + h2,
.syt-antd-typography li + h2,
.syt-antd-typography p + h2,
.syt-antd-typography h1 + h2,
.syt-antd-typography h2 + h2,
.syt-antd-typography h3 + h2,
.syt-antd-typography h4 + h2,
.syt-antd-typography h5 + h2,
.syt-antd-typography div + h3,
.syt-antd-typography ul + h3,
.syt-antd-typography li + h3,
.syt-antd-typography p + h3,
.syt-antd-typography h1 + h3,
.syt-antd-typography h2 + h3,
.syt-antd-typography h3 + h3,
.syt-antd-typography h4 + h3,
.syt-antd-typography h5 + h3,
.syt-antd-typography div + h4,
.syt-antd-typography ul + h4,
.syt-antd-typography li + h4,
.syt-antd-typography p + h4,
.syt-antd-typography h1 + h4,
.syt-antd-typography h2 + h4,
.syt-antd-typography h3 + h4,
.syt-antd-typography h4 + h4,
.syt-antd-typography h5 + h4,
.syt-antd-typography div + h5,
.syt-antd-typography ul + h5,
.syt-antd-typography li + h5,
.syt-antd-typography p + h5,
.syt-antd-typography h1 + h5,
.syt-antd-typography h2 + h5,
.syt-antd-typography h3 + h5,
.syt-antd-typography h4 + h5,
.syt-antd-typography h5 + h5 {
  margin-top: 1.2em;
}
a.syt-antd-typography-ellipsis,
span.syt-antd-typography-ellipsis {
  display: inline-block;
}
a.syt-antd-typography,
.syt-antd-typography a {
  color: #00a0be;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}
a.syt-antd-typography:focus,
.syt-antd-typography a:focus,
a.syt-antd-typography:hover,
.syt-antd-typography a:hover {
  color: #21b8cc;
}
a.syt-antd-typography:active,
.syt-antd-typography a:active {
  color: #007d99;
}
a.syt-antd-typography:active,
.syt-antd-typography a:active,
a.syt-antd-typography:hover,
.syt-antd-typography a:hover {
  text-decoration: none;
}
a.syt-antd-typography[disabled],
.syt-antd-typography a[disabled] {
  color: #c1c5c8;
  cursor: not-allowed;
  pointer-events: none;
}
.syt-antd-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.syt-antd-typography kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}
.syt-antd-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.syt-antd-typography u,
.syt-antd-typography ins {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.syt-antd-typography s,
.syt-antd-typography del {
  text-decoration: line-through;
}
.syt-antd-typography strong {
  font-weight: 600;
}
.syt-antd-typography-expand,
.syt-antd-typography-edit,
.syt-antd-typography-copy {
  color: #00a0be;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
}
.syt-antd-typography-expand:focus,
.syt-antd-typography-edit:focus,
.syt-antd-typography-copy:focus,
.syt-antd-typography-expand:hover,
.syt-antd-typography-edit:hover,
.syt-antd-typography-copy:hover {
  color: #21b8cc;
}
.syt-antd-typography-expand:active,
.syt-antd-typography-edit:active,
.syt-antd-typography-copy:active {
  color: #007d99;
}
.syt-antd-typography-copy-success,
.syt-antd-typography-copy-success:hover,
.syt-antd-typography-copy-success:focus {
  color: #aab400;
}
.syt-antd-typography-edit-content {
  position: relative;
}
div.syt-antd-typography-edit-content {
  left: -12px;
  margin-top: -4px;
  margin-bottom: calc(1em - 3px - 1px);
}
.syt-antd-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: #696F88;
  pointer-events: none;
}
.syt-antd-typography-edit-content textarea {
  -moz-transition: none;
}
.syt-antd-typography ul,
.syt-antd-typography ol {
  margin: 0 0 1em 0;
  padding: 0;
}
.syt-antd-typography ul li,
.syt-antd-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.syt-antd-typography ul {
  list-style-type: circle;
}
.syt-antd-typography ul ul {
  list-style-type: disc;
}
.syt-antd-typography ol {
  list-style-type: decimal;
}
.syt-antd-typography pre,
.syt-antd-typography blockquote {
  margin: 1em 0;
}
.syt-antd-typography pre {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.syt-antd-typography pre code {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  border: 0;
}
.syt-antd-typography blockquote {
  padding: 0 0 0 0.6em;
  border-left: 4px solid rgba(100, 100, 100, 0.2);
  opacity: 0.85;
}
.syt-antd-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
a.syt-antd-typography-ellipsis-single-line,
span.syt-antd-typography-ellipsis-single-line {
  vertical-align: bottom;
}
.syt-antd-typography-ellipsis-multiple-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.syt-antd-typography-rtl {
  direction: rtl;
}
.syt-antd-typography-rtl .syt-antd-typography-expand,
.syt-antd-typography-rtl .syt-antd-typography-edit,
.syt-antd-typography-rtl .syt-antd-typography-copy {
  margin-right: 4px;
  margin-left: 0;
}
.syt-antd-typography-rtl .syt-antd-typography-expand {
  float: left;
}
div.syt-antd-typography-edit-content.syt-antd-typography-rtl {
  right: -12px;
  left: auto;
}
.syt-antd-typography-rtl .syt-antd-typography-edit-content-confirm {
  right: auto;
  left: 10px;
}
.syt-antd-typography-rtl.syt-antd-typography ul li,
.syt-antd-typography-rtl.syt-antd-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}
h1.cwsTypography {
  height: 64px;
  left: 30.83%;
  right: 55.35%;
  top: calc(50% - 64px/2 + 554.5px);
  font-family: "noto-sans";
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
  line-height: 64px;
  display: flex;
  letter-spacing: -0.0175em;
  color: #14151C;
}
h1.cwsTypography strong {
  font-weight: normal;
}
h2.cwsTypography {
  height: 54px;
  left: 30.83%;
  right: 57.78%;
  top: calc(50% - 54px/2 + 661.5px);
  font-family: "noto-sans";
  font-style: normal;
  font-weight: normal;
  font-size: 46px;
  line-height: 54px;
  display: flex;
  letter-spacing: -0.0175em;
  color: #14151C;
}
h2.cwsTypography strong {
  font-weight: normal;
}
h3.cwsTypography {
  height: 44px;
  left: 30.83%;
  right: 58.75%;
  top: calc(50% - 44px/2 + 750.5px);
  font-family: "noto-sans";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  letter-spacing: -0.0175em;
  color: #14151C;
}
h3.cwsTypography strong {
  font-family: "noto-sans";
  font-weight: 600;
}
h4.cwsTypography {
  height: 38px;
  left: 30.83%;
  right: 60.42%;
  top: calc(50% - 38px/2 + 827.5px);
  font-family: "noto-sans";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  display: flex;
  letter-spacing: -0.0175em;
  color: #14151C;
}
h4.cwsTypography strong {
  font-family: "noto-sans";
  font-weight: 600;
}
span.cwsTypography,
div.cwsTypography {
  height: 24px;
  left: 30.83%;
  right: 64.44%;
  top: calc(50% - 24px/2 + 1165.5px);
  font-family: "noto-sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  letter-spacing: -0.0075em;
  color: #14151C;
}
span.cwsTypography strong,
div.cwsTypography strong {
  font-family: "noto-sans";
  font-weight: 600;
}
.stories-row {
  margin-top: 30px;
}
.syt-antd-typography-copy {
  margin-left: 10px !important;
}
.syt-antd-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: 'tnum';
  outline: 0;
}
.syt-antd-upload p {
  margin: 0;
}
.syt-antd-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.syt-antd-upload input[type='file'] {
  cursor: pointer;
}
.syt-antd-upload.syt-antd-upload-select {
  display: inline-block;
}
.syt-antd-upload.syt-antd-upload-disabled {
  cursor: not-allowed;
}
.syt-antd-upload.syt-antd-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #0092e4;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.syt-antd-upload.syt-antd-upload-select-picture-card > .syt-antd-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.syt-antd-upload.syt-antd-upload-select-picture-card:hover {
  border-color: #0071CD;
}
.syt-antd-upload-disabled.syt-antd-upload.syt-antd-upload-select-picture-card:hover {
  border-color: #0092e4;
}
.syt-antd-upload.syt-antd-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #0092e4;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.syt-antd-upload.syt-antd-upload-drag .syt-antd-upload {
  padding: 16px 0;
}
.syt-antd-upload.syt-antd-upload-drag.syt-antd-upload-drag-hover:not(.syt-antd-upload-disabled) {
  border-color: #0056a6;
}
.syt-antd-upload.syt-antd-upload-drag.syt-antd-upload-disabled {
  cursor: not-allowed;
}
.syt-antd-upload.syt-antd-upload-drag .syt-antd-upload-btn {
  display: table;
  height: 100%;
}
.syt-antd-upload.syt-antd-upload-drag .syt-antd-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.syt-antd-upload.syt-antd-upload-drag:not(.syt-antd-upload-disabled):hover {
  border-color: #238dd9;
}
.syt-antd-upload.syt-antd-upload-drag p.syt-antd-upload-drag-icon {
  margin-bottom: 20px;
}
.syt-antd-upload.syt-antd-upload-drag p.syt-antd-upload-drag-icon .anticon {
  color: #238dd9;
  font-size: 48px;
}
.syt-antd-upload.syt-antd-upload-drag p.syt-antd-upload-text {
  margin: 0 0 4px;
  color: #14151C;
  font-size: 16px;
}
.syt-antd-upload.syt-antd-upload-drag p.syt-antd-upload-hint {
  color: #696F88;
  font-size: 14px;
}
.syt-antd-upload.syt-antd-upload-drag .anticon-plus {
  color: #c1c5c8;
  font-size: 30px;
  transition: all 0.3s;
}
.syt-antd-upload.syt-antd-upload-drag .anticon-plus:hover {
  color: #696F88;
}
.syt-antd-upload.syt-antd-upload-drag:hover .anticon-plus {
  color: #696F88;
}
.syt-antd-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.syt-antd-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.syt-antd-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151C;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  line-height: 20px;
}
.syt-antd-upload-list::before {
  display: table;
  content: '';
}
.syt-antd-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-upload-list-item-list-type-text:hover .syt-antd-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}
.syt-antd-upload-list-item-list-type-text:hover .syt-antd-upload-list-item-name-icon-count-2 {
  padding-right: 28px;
}
.syt-antd-upload-list-item {
  position: relative;
  height: 280px;
  margin-top: 8px;
  font-size: 14px;
}
.syt-antd-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syt-antd-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}
.syt-antd-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.syt-antd-upload-list-item-card-actions-btn {
  opacity: 0;
}
.syt-antd-upload-list-item-card-actions-btn.syt-antd-btn-sm {
  height: 20px;
  line-height: 1;
}
.syt-antd-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.syt-antd-upload-list-item-card-actions-btn:focus,
.syt-antd-upload-list-item-card-actions.picture .syt-antd-upload-list-item-card-actions-btn {
  opacity: 1;
}
.syt-antd-upload-list-item-card-actions .anticon {
  color: #696F88;
}
.syt-antd-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  transition: background-color 0.3s;
}
.syt-antd-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.syt-antd-upload-list-item-info .anticon-loading .anticon,
.syt-antd-upload-list-item-info .syt-antd-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: #696F88;
  font-size: 14px;
}
.syt-antd-upload-list-item .anticon-close {
  position: absolute;
  top: 6px;
  right: 4px;
  color: #696F88;
  font-size: 10px;
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
.syt-antd-upload-list-item .anticon-close:hover {
  color: #14151C;
}
.syt-antd-upload-list-item:hover .syt-antd-upload-list-item-info {
  background-color: #f5f5f5;
}
.syt-antd-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.syt-antd-upload-list-item:hover .syt-antd-upload-list-item-card-actions-btn {
  opacity: 1;
}
.syt-antd-upload-list-item-error,
.syt-antd-upload-list-item-error .syt-antd-upload-text-icon > .anticon,
.syt-antd-upload-list-item-error .syt-antd-upload-list-item-name {
  color: #CF3537;
}
.syt-antd-upload-list-item-error .syt-antd-upload-list-item-card-actions .anticon {
  color: #CF3537;
}
.syt-antd-upload-list-item-error .syt-antd-upload-list-item-card-actions-btn {
  opacity: 1;
}
.syt-antd-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #0092e4;
  border-radius: 4px;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item:hover,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item:hover {
  background: transparent;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-error,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-error {
  border-color: #CF3537;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-info,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-info {
  padding: 0;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item:hover .syt-antd-upload-list-item-info,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item:hover .syt-antd-upload-list-item-info {
  background: transparent;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-uploading,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-uploading {
  border-style: dashed;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-thumbnail,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 48px;
  height: 48px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-thumbnail .anticon,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-error .syt-antd-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-error .syt-antd-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #fff2f0;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-error .syt-antd-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-error .syt-antd-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #CF3537;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-icon,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-icon .anticon,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-icon .anticon {
  font-size: 26px;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-image,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-image {
  max-width: 100%;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-thumbnail img,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-name,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-name-icon-count-1,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-name-icon-count-1 {
  padding-right: 18px;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-name-icon-count-2,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-name-icon-count-2 {
  padding-right: 36px;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-uploading .syt-antd-upload-list-item-name,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-uploading .syt-antd-upload-list-item-name {
  line-height: 28px;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-progress,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.syt-antd-upload-list-picture .anticon-close,
.syt-antd-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.syt-antd-upload-list-picture-card.syt-antd-upload-list::after {
  display: none;
}
.syt-antd-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item {
  height: 100%;
  margin: 0;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item:hover .syt-antd-upload-list-item-info::before {
  opacity: 1;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-actions .anticon-eye,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-actions .anticon-download,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: #FFFFFF;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-actions .anticon-eye:hover,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-actions .anticon-download:hover,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-actions .anticon-delete:hover {
  color: #FFFFFF;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-info:hover + .syt-antd-upload-list-item-actions,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-actions:hover {
  opacity: 1;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-thumbnail,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 20px;
  text-align: center;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-file + .syt-antd-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-uploading.syt-antd-upload-list-item {
  background-color: #fafafa;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-uploading .syt-antd-upload-list-item-info {
  height: auto;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-uploading .syt-antd-upload-list-item-info::before,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-uploading .syt-antd-upload-list-item-info .anticon-eye,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-uploading .syt-antd-upload-list-item-info .anticon-delete {
  display: none;
}
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.syt-antd-upload-list .syt-antd-upload-success-icon {
  color: #aab400;
  font-weight: bold;
}
.syt-antd-upload-list .syt-antd-upload-animate-enter,
.syt-antd-upload-list .syt-antd-upload-animate-leave,
.syt-antd-upload-list .syt-antd-upload-animate-inline-enter,
.syt-antd-upload-list .syt-antd-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.syt-antd-upload-list .syt-antd-upload-animate-enter {
  animation-name: uploadAnimateIn;
}
.syt-antd-upload-list .syt-antd-upload-animate-leave {
  animation-name: uploadAnimateOut;
}
.syt-antd-upload-list .syt-antd-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.syt-antd-upload-list .syt-antd-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.syt-antd-upload-rtl {
  direction: rtl;
}
.syt-antd-upload-rtl.syt-antd-upload.syt-antd-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.syt-antd-upload-list-rtl {
  direction: rtl;
}
.syt-antd-upload-list-rtl .syt-antd-upload-list-item-list-type-text:hover .syt-antd-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.syt-antd-upload-list-rtl .syt-antd-upload-list-item-list-type-text:hover .syt-antd-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.syt-antd-upload-list-rtl .syt-antd-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.syt-antd-upload-list-rtl .syt-antd-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.syt-antd-upload-list-rtl .syt-antd-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.syt-antd-upload-list-rtl .syt-antd-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.syt-antd-upload-list-rtl .syt-antd-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.syt-antd-upload-list-rtl .syt-antd-upload-list-item .anticon-close {
  right: auto;
  left: 4px;
}
.syt-antd-upload-list-rtl .syt-antd-upload-list-item-error .syt-antd-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.syt-antd-upload-list-rtl .syt-antd-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.syt-antd-upload-list-picture .syt-antd-upload-list-item-info,
.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-info {
  padding: 0;
}
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture .syt-antd-upload-list-item-thumbnail,
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture .syt-antd-upload-list-item-icon,
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture .syt-antd-upload-list-item-name,
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture .syt-antd-upload-list-item-name-icon-count-1,
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture .syt-antd-upload-list-item-name-icon-count-2,
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture .syt-antd-upload-list-item-progress,
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture .anticon-close,
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture-card .anticon-close {
  right: auto;
  left: 8px;
}
.syt-antd-upload-list-rtl .syt-antd-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.syt-antd-upload-list-rtl.syt-antd-upload-list-picture-card .syt-antd-upload-list-item-file + .syt-antd-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}
.syt-antd-upload-list-item-name {
  display: inline;
}
.syt-antd-upload-list-item-list-type-text,
.syt-antd-upload-list-item-list-type-picture {
  height: auto !important;
}
.syt-antd-upload-list-item-list-type-text .syt-antd-upload-list-item-info:hover {
  background-color: #EAF6FF !important;
}
.syt-antd-upload .defaultButton {
  background-color: #FFFFFF;
  border: 1px solid #DFE2E7;
}
.syt-antd-upload .defaultButton:hover,
.syt-antd-upload .defaultButton:focus {
  border: 1px solid #19A04B;
  color: #19A04B;
  box-shadow: none;
}
.syt-antd-upload .defaultButton:active {
  border: 1px solid #0C612C;
  color: #0C612C;
}
.syt-antd-upload-list-item-card-actions-btn {
  height: 100%;
}
.syt-antd-upload-list-item-card-actions-btn svg {
  position: absolute;
  left: 20.83%;
  right: 20.83%;
  top: -20.83%;
  bottom: 20.83%;
}
.syt-antd-upload-list-item-list-type-picture {
  border-radius: 2px !important;
}
.syt-antd-upload-list-item-card-actions.picture {
  top: 10px !important;
  right: 4px !important;
}
.syt-antd-upload.syt-antd-upload-drag,
.syt-antd-upload.syt-antd-upload-select-picture-card {
  border: 1px dashed #C2C7D0;
}
.syt-antd-upload.syt-antd-upload-drag:hover,
.syt-antd-upload.syt-antd-upload-select-picture-card:hover,
.syt-antd-upload.syt-antd-upload-drag:focus,
.syt-antd-upload.syt-antd-upload-select-picture-card:focus {
  border: 1px dashed #19A04B;
}
.syt-antd-upload.syt-antd-upload-drag:active,
.syt-antd-upload.syt-antd-upload-select-picture-card:active {
  border: 1px dashed #0C612C;
}
.syt-antd-upload-drag-container {
  padding: 16px 0 !important;
}
.cws-upload-drag-icon {
  margin-bottom: 20px !important;
}
.cws-upload-drag-icon .anticon {
  color: #0071CD;
  font-size: 48px;
}
.cws-upload-text {
  height: 24px;
  left: 0px;
  right: 0px;
  top: 0px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.0075em;
  color: #14151C;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 4px !important;
}
.cws-upload-hint {
  left: 0px;
  right: 0px;
  top: 28px;
  bottom: 0px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.0075em;
  color: #696F88;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto !important;
}
.syt-antd-upload.syt-antd-upload-btn {
  padding: 0 !important;
}
.syt-antd-upload.syt-antd-upload-select.syt-antd-upload-select-picture-card,
.syt-antd-upload-list-picture-card-container {
  width: 122px !important;
  height: 122px !important;
}
.withSelectMenu {
  margin-top: 50px;
}
.navbarLogo {
  float: left;
  padding-right: 40px;
  height: 48px;
  line-height: 50px;
}
.navbarLogoPlaceholder {
  background-color: #FFFFFF !important;
}
.navbarUserArea {
  float: right;
  height: 48px;
  padding-right: 10px;
  line-height: 45px;
}
.navbarContextBar {
  height: 48px;
  width: 208px;
  padding-left: 8px;
  position: static;
  border-radius: 4px;
}
.navbarContextBar:hover {
  background-color: #DFE2E7;
}
.submenuContextBar {
  height: 54px;
  width: 208px;
  padding-left: 8px;
  position: static;
  border-radius: 4px;
  float: left;
}
.submenuContextBar:hover {
  background-color: #DFE2E7;
}
.navbarContextIcon {
  float: left;
  padding-right: 5px;
  align-items: center;
  line-height: 45px;
}
.submenuContextIcon {
  float: left;
  padding-right: 10px;
  align-items: center;
  line-height: 35px;
}
.contextBarTitle {
  position: static;
  width: 75px;
  height: 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  align-items: center;
  color: #696F88;
  margin: 4px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contextBarSubTitle {
  font-size: 14px;
  position: static;
  width: 114px;
  height: 20px;
  line-height: 20px;
  align-items: center;
  color: #14151C;
  margin: 0px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contexBarArrow {
  float: right;
  height: 48px;
  line-height: 50px;
  align-items: center;
  margin-right: 4px;
}
.submenuArrow {
  float: right;
  height: 48px;
  line-height: 45px;
  align-items: center;
  margin-right: 4px;
}
.submenuTitle {
  font-size: 14px;
  position: static;
  height: 30px;
  line-height: 35px;
  margin: 0px 10px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.syt-antd-menu-item-group-title {
  color: #14151C !important;
  font-weight: 600;
}
.syt-antd-layout-header {
  height: 56px;
}
.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-item {
  margin: 0px;
}
.syt-antd-layout-header .syt-antd-menu {
  height: 56px;
}
.navbarDivider {
  height: 20px;
}
.navbarSeparator {
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  vertical-align: middle;
}
