@import '~antd/lib/date-picker/style/index';

/* -------- Input -------- */

// Default size
.syt-antd-picker {
  border: 1px solid @cws-neutral-30;
  height: 32px;

  .syt-antd-picker-input > input {
    height: 24px;
    left: 0px;
    right: 0px;
    top: calc(50% - 24px/2);

    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    align-items: center;
    letter-spacing: -0.0075em;
  }
}


// Large size
.syt-antd-picker-large {
  height: 40px;

  .syt-antd-picker-input > input {
    height: 24px;
    left: 0px;
    right: 0px;
    top: calc(50% - 24px/2);

    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    align-items: center;
    letter-spacing: -0.0075em;
  }
}

// Small size
.syt-antd-picker-small {
  height: 24px;

  .syt-antd-picker-input > input {
    height: 24px;
    left: 0px;
    right: 0px;
    top: calc(50% - 24px/2);

    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    align-items: center;
    letter-spacing: -0.0075em;
  }
}

// Input placeholder and icons
.syt-antd-picker-input > input::placeholder,
.syt-antd-picker-separator,
.syt-antd-picker-suffix {
  color: @cws-neutral-60;
}

// Input focused
.syt-antd-picker-focused {
  border: 1px solid @cws-blue-50;
  box-shadow: 0 0 0 3px rgba(@cws-blue-50, 0.25);
}

// Input disabled
.syt-antd-picker.syt-antd-picker-disabled {
  background: @cws-neutral-20 !important;
  border: 1px solid @cws-neutral-30 !important;

  .syt-antd-picker-suffix {
    color: @cws-neutral-50;
  }

  .syt-antd-picker-input > input[disabled] {
    color: @cws-neutral-50;

    &::placeholder {
      color: @cws-neutral-50;
    }
  }
}


/* -------- Header -------- */

.syt-antd-picker-header button {
  color: @cws-neutral-70;
}
.syt-antd-picker-header-view button {
  color: @cws-neutral-100;
}

/* -------- Footer -------- */

.syt-antd-picker-footer a {
  color: @cws-blue-60;
}

li.syt-antd-picker-ok button {
  color: @cws-neutral-00;
  background-color: @cws-green-60;
  border-color: @cws-green-60;
  border-radius: 4px;

  &:hover,
  &:focus {
      color: @cws-neutral-00;
      background-color: @cws-green-50;
      border-color: @cws-green-50;
  }
  &:focus {
      box-shadow: 0px 0px 0px 3px rgba(@cws-green-50, 0.25);
  }
  &:active {
      background-color: @cws-green-70;
      border-color: @cws-green-70;
  }
  &:disabled {
      background: @cws-neutral-10;
      border: 1px solid @cws-neutral-20;
      box-sizing: border-box;
  }
}

/* -------- Date Cells -------- */

// Cell size and layout
.syt-antd-picker-cell.syt-antd-picker-cell-in-view {
  padding: 0px;

  .syt-antd-picker-cell-inner {
    width: 36px;
    height: 32px;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 0;
  }
}

// Today cell
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-today .syt-antd-picker-cell-inner {
  text-decoration: underline;
  text-decoration-color: @cws-blue-60;
  text-decoration-thickness: 2px;

  &::before {
    border: none;
  }
}

.syt-antd-picker-cell:not(.syt-antd-picker-cell-in-view) {
  color: @cws-neutral-50;
}

// Hover cell
.syt-antd-picker-cell:hover:not(.syt-antd-picker-cell-in-view) .syt-antd-picker-cell-inner,
.syt-antd-picker-cell:hover:not(.syt-antd-picker-cell-selected):not(.syt-antd-picker-cell-range-start):not(.syt-antd-picker-cell-range-end):not(.syt-antd-picker-cell-range-hover-start):not(.syt-antd-picker-cell-range-hover-end)
.syt-antd-picker-cell-inner {
  background: @cws-neutral-10;
}

// Active/focused cell
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-selected .syt-antd-picker-cell-inner,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-start .syt-antd-picker-cell-inner,
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-range-end .syt-antd-picker-cell-inner {
  background: @cws-blue-60;
  box-shadow: 0 0 0 3px rgba(@cws-blue-50, 0.25);
}

// Disabled cell
.syt-antd-picker-cell-disabled {
  background: @cws-neutral-10;

  &::before {
    background: transparent;
  }

   .syt-antd-picker-cell-inner {
     color: @cws-neutral-50;
   }
}

// Cells within selected range
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range {
  background: @cws-blue-10;

  &::before {
    background: transparent;
  }
}

// Hover within selected range
.syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover::before,
.syt-antd-picker-date-panel .syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover-end .syt-antd-picker-cell-inner::after,
.syt-antd-picker-date-panel .syt-antd-picker-cell-in-view.syt-antd-picker-cell-in-range.syt-antd-picker-cell-range-hover-start .syt-antd-picker-cell-inner::after {
  background: @cws-blue-30;
}

/* -------- Time Cells --------*/
.syt-antd-picker-time-panel-column
> li.syt-antd-picker-time-panel-cell.syt-antd-picker-time-panel-cell-selected
.syt-antd-picker-time-panel-cell-inner {
  background: @cws-blue-10;

  &:hover {
    background: @cws-neutral-10;
  }
}
