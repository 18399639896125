@theme: 'cropwise-light';

@import '../../fonts/google-font';
@import '../../core/colors';

@ant-prefix: syt-antd;

@base-font-family-bold: 'Noto Sans';
@base-font-family: 'Noto Sans';

@base-font-weight: 400;
@base-font-style: normal;

@base-font-bold-weight: 700;
@base-font-bold-style: normal;
@base-font-size: 14px;

* {
  font-family: @base-font-family;
  //font-size: @base-font-size;
}

@base-text-grey: @base-dark-grey;
@base-text-black: @base-black;

// buttons colors as per figma
@primary-button-color: #0c612c;
@danger-button-color: #9c2628;

// input colors as per figma
@border-color-base: #0092e4;
@input-hover-border-color: #0092e4;
@input-color: #14151c;
@input-bg: #ffffff;

.text-default {
  color: @base-black;
}

.bold {
  color: @base-black;
}

@base-box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
@base-primary-color: @cws-blue-60;
@base-default-color: @cws-neutral-20;
@base-success-color: @base-light-green;
@base-warn-color: @base-yellow;
@base-danger-color: @cws-red-60;
@base-disabled-color: @base-dark-grey;
@base-info-color: @base-light-grey;
@base-header-color: @base-white;

/* Override Ant Design variables */
@primary-color: @base-primary-color; // primary color for all components
@link-color: @base-dark-blue; // link color
@success-color: @base-success-color; // success state color
@warning-color: @base-warn-color; // warning state color
@error-color: @base-danger-color; // error state color
@font-size-base: @base-font-size; // major text font size
@heading-color: @base-text-black; // heading text color
@text-color: @base-text-black; // major text color
@text-color-secondary: @base-text-grey; // secondary text color
@disabled-color: @base-dark-grey; // disable state color
@border-radius-base: 4px; // major border radius
//@border-color-base: transparent; // major border color
@box-shadow-base: @base-box-shadow; // major shadow for layers
@font-family: @base-font-family;
@layout-header-background: @base-header-color;

.ant-result-success .ant-result-icon>.anticon {
  color: #14803c;
}
