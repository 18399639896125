@import '~antd/lib/dropdown/style/index';

.syt-antd-dropdown-menu:not(.syt-antd-dropdown-menu-submenu) {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

a.syt-antd-dropdown-trigger {
  color: @cws-blue-60;

  &:hover {
    color: @cws-blue-50;
  }

  &:active {
    color: @cws-blue-70;
  }

  &:disabled {
    color: @cws-neutral-40;
  }
}

.syt-antd-dropdown-menu-item, .syt-antd-dropdown-menu-submenu-title {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  padding: 6px 12px;
}

.syt-antd-dropdown-menu-item:hover {
  background-color: @cws-neutral-10;
}

.syt-antd-dropdown-menu-item-group-title {
  color: @cws-neutral-60;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.0075em;
}

.syt-antd-dropdown-menu-item-divider, .syt-antd-dropdown-menu-submenu-title-divider {
  background-color: @cws-neutral-20;
}

.syt-antd-dropdown-menu-item.syt-antd-dropdown-menu-item-danger {
  color: @cws-red-60;

  &:hover {
    background-color: @cws-red-10;
    color: @cws-red-60;
  }
}

.syt-antd-dropdown-menu-item-disabled,
.syt-antd-dropdown-menu-submenu.syt-antd-dropdown-menu-submenu-disabled .syt-antd-dropdown-menu-submenu-title {
  color: @cws-neutral-40;

  &:hover {
    color: @cws-neutral-40;
  }
}
