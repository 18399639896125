@import '~antd/lib/input-number/style/index';

.syt-antd-input-number {
  border: 1px solid @cws-neutral-30;
}

.syt-antd-input-number-focused {
  border: 1px solid @cws-blue-50;
  box-shadow: 0 0 0 3px rgba(@cws-blue-50, 0.25);
}

.syt-antd-input-number-handler-wrap {
  border-left: 1px solid @cws-neutral-30;
}

.syt-antd-input-number-handler-down {
  border-top: 1px solid @cws-neutral-30;
}

.syt-antd-input-number-disabled {
  color: @cws-neutral-50;
  border: 1px solid @cws-neutral-30;

  &:hover {
    border: 1px solid @cws-neutral-30;
  }
}
