@import '~antd/lib/notification/style/index';

.syt-antd-notification {
  border-radius: 4px;
  .syt-antd-notification-notice-btn {
    .syt-antd-btn-default{
      border: none;
      margin-right: 5px;
    }
  }
  
  .syt-antd-info-notification {
    border-left: solid @cws-blue-50; 
  }
  .syt-antd-success-notification{
    border-left: solid @cws-green-50;
  }
  .syt-antd-warning-notification {
    border-left: solid @cws-yellow-50;
  }

  .syt-antd-error-notification{
    border-left: solid @cws-red-50;
    .syt-antd-btn-primary {
      border-color: @cws-red-60;
      background-color: @cws-red-60;
    }
  }
}
