@import '~antd/lib/spin/style/index';

.syt-default .syt-antd-spin-text {
    top: -35px;
    position: relative;
    font-weight: bold;
}

.syt-default .syt-antd-spin-dot {
    top: 40px;
}