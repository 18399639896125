@import '~antd/lib/input/style/index';

input.syt-antd-input {
  color: @cws-neutral-100;
  background-color: @cws-neutral-00;
  // margin: 2px 0px;

      padding: 2px 11px 3px 11px;

  &:hover,
  &:focus {
    color: @cws-neutral-100;
    background-color: @cws-neutral-00;
    color: @cws-neutral-100;
    background-color: @cws-neutral-00;
  }

  &::placeholder {
    color: @cws-neutral-60;
  }

  &[disabled] {
    background: @cws-neutral-20;
    color: @cws-neutral-50;
  }
}

.syt-antd-input-affix-wrapper-disabled {
  background: @cws-neutral-20;
  color: @cws-neutral-50;

  svg {
    color: @cws-neutral-60 !important;
  }
}

:not(.syt-antd-input-affix-wrapper) > input.syt-antd-input,
.syt-antd-input-affix-wrapper {
  height: 32px;

  &, input.syt-antd-input {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    align-items: center;
    letter-spacing: -0.0075em;
  }
}

:not(.syt-antd-input-affix-wrapper-lg) > input.syt-antd-input-lg,
.syt-antd-input-affix-wrapper-lg {
  height: 40px;

  &, input.syt-antd-input-lg {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    align-items: center;
    letter-spacing: -0.0075em;
  }
}

:not(.syt-antd-input-affix-wrapper-sm) > input.syt-antd-input-sm,
.syt-antd-input-affix-wrapper-sm {
  height: 24px;

  &, input.syt-antd-input-sm {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    align-items: center;
    letter-spacing: -0.0075em;
  }
}

.syt-antd-input-label {
  height: 16px;
  left: 0px;
  right: 0px;
  top: 0px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-items: center;
  letter-spacing: -0.0075em;

  color: @cws-neutral-100 !important;
}

.syt-antd-input-caption {
  left: 0px;
  right: 0px;
  top: 64px;
  bottom: 0px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.0075em;

  display: flex;
  align-items: center;
  letter-spacing: -0.0075em;

  color: @cws-neutral-60;
}

.syt-antd-input-wrapper-default,
.syt-antd-input-wrapper-success {
  & > input.syt-antd-input,
  .syt-antd-input-affix-wrapper {
    border: 1px solid @cws-neutral-30;
    margin: 2px 0px;

    svg {
      color: @cws-neutral-60;
    }

    &:hover {
      border: 1px solid @cws-blue-50;
    }

    &:focus,
    &-focused {
      border: 1px solid @cws-blue-50 !important;
      box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25) !important;
      svg {
        color: @cws-neutral-60;
      }
    }
  }

  .syt-antd-input-label,
  .syt-antd-input-caption {
    color: @cws-neutral-60;
  }
}

.syt-antd-input-wrapper-warn {
  & > input.syt-antd-input,
  .syt-antd-input-affix-wrapper {
    border: 1px solid @cws-yellow-50;

    svg {
      color: @cws-neutral-60;
    }

    &:hover {
      border: 1px solid @cws-yellow-50;
    }

    &:focus,
    &-focused {
      border: 1px solid @cws-yellow-50 !important;
      box-shadow: 0px 0px 0px 3px rgba(@cws-yellow-40, 0.25) !important;
      svg {
        color: @cws-neutral-60;
      }
    }
  }

  .syt-antd-input-label,
  .syt-antd-input-caption {
    color: @cws-yellow-60;
  }
}

.syt-antd-input-wrapper-danger {
  & > input.syt-antd-input,
  .syt-antd-input-affix-wrapper {
    border: 1px solid @cws-red-50;

    svg {
      color: @cws-neutral-60;
    }

    &:hover {
      border: 1px solid @cws-red-50;
    }

    &:focus {
      border: 1px solid @cws-red-50 !important;
      box-shadow: 0px 0px 0px 3px rgba(@cws-red-50, 0.25) !important;
      svg {
        color: @cws-neutral-60;
      }
    }

  }

  .syt-antd-input-label,
  .syt-antd-input-caption {
    color: @cws-red-60;
  }
}

textarea.syt-antd-input {
  border: 1px solid @cws-neutral-30;
  padding: 8px 12px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0075em;

  &::placeholder {
    color: @cws-neutral-60;
  }

  &:hover {
    border: 1px solid @cws-blue-50;
  }

  &:focus {
    border: 1px solid @cws-blue-50 !important;
    box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25) !important;
    svg {
      color: @cws-neutral-60;
    }
  }

  &:disabled {
    background: @cws-neutral-20;
    color: @cws-neutral-60;

    &::placeholder {
      color: @cws-neutral-50;
    }
  }
}
