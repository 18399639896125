@import '~antd/lib/breadcrumb/style/index';

.syt-antd-breadcrumb a {
    color: @cws-neutral-60;

    &:hover {
      color: @cws-neutral-100;
      text-decoration: underline;
    }
}
