@import '~antd/lib/upload/style/index';

.syt-antd-upload-list-item-name {
  display: inline;
}
.syt-antd-upload-list-item-list-type-text,
.syt-antd-upload-list-item-list-type-picture {
  height: auto !important;
}
.syt-antd-upload-list-item-list-type-text .syt-antd-upload-list-item-info:hover {
    background-color: @cws-blue-10 !important;
}

.syt-antd-upload .defaultButton {
  background-color: @cws-neutral-00;
  border: 1px solid @cws-neutral-20;

  &:hover, &:focus {
    border: 1px solid @cws-green-50;
    color: @cws-green-50;
    box-shadow: none;
  }
  &:active {
    border: 1px solid @cws-green-70;
    color: @cws-green-70;
  }
}

.syt-antd-upload-list-item-card-actions-btn, {
  height: 100%;

  svg {
    position: absolute;
    left: 20.83%;
    right: 20.83%;
    top: -20.83%;
    bottom: 20.83%;
  }
}

.syt-antd-upload-list-item-list-type-picture {
  border-radius: 2px !important;
}

.syt-antd-upload-list-item-card-actions.picture {
  top: 10px !important;
  right: 4px !important;
}

.syt-antd-upload.syt-antd-upload-drag,
.syt-antd-upload.syt-antd-upload-select-picture-card {
  border: 1px dashed @cws-neutral-30;

  &:hover, &:focus {
    border: 1px dashed @cws-green-50;
  }
  &:active {
    border: 1px dashed @cws-green-70;
  }
}

.syt-antd-upload-drag-container {
  padding: 16px 0 !important;
}

.cws-upload-drag-icon:extend(.ant-upload-drag-icon) {
  margin-bottom: 20px !important;

  .anticon {
    color: @cws-blue-60;
    font-size: 48px;
  }
}

.cws-upload-text:extend(.ant-upload-text) {
  height: 24px;
  left: 0px;
  right: 0px;
  top: 0px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  align-items: center;
  text-align: center;
  letter-spacing: -0.0075em;

  color: @cws-neutral-100;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 4px !important;
}

.cws-upload-hint:extend(.ant-upload-hint) {
  left: 0px;
  right: 0px;
  top: 28px;
  bottom: 0px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  align-items: center;
  text-align: center;
  letter-spacing: -0.0075em;

  color: @cws-neutral-60;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto !important;
}

.syt-antd-upload.syt-antd-upload-btn {
  padding: 0 !important;
}

.syt-antd-upload.syt-antd-upload-select.syt-antd-upload-select-picture-card,
.syt-antd-upload-list-picture-card-container {
  width: 122px !important;
  height: 122px !important;
}

.syt-antd-upload-list-item-list-type-picture-card {

}
