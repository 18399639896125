@import '~antd/lib/badge/style/index';

.head-example {
  background: #eee;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  vertical-align: middle;
  width: 42px;
}

.notification-example-container {
  display: flex;
  justify-content: center;
  margin-top: 5px; 
}

.syt-antd-badge-count {
  background-color: @cws-red-60;
}

.syt-antd-badge-status {
  .syt-antd-badge-status-success {
    background-color: @cws-green-50;
  }
  
  .syt-antd-badge-status-error {
    background-color: @cws-red-50;
  }

  .syt-antd-badge-status-default {
    background-color: @cws-neutral-50;
  }

  .syt-antd-badge-status-processing {
    background-color: @cws-blue-50;
  }

  .syt-antd-badge-status-warning {
    background-color: @cws-yellow-40;
  }
  
}